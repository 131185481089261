import {
  AvailableHotelStarRating,
  AvailableHotelStarRatingValue,
  HotelComparator,
  HotelSortOptionEnum,
  HotelStarRatingWithoutNotAvailable,
  StarRatingValue,
} from "@b2bportal/core-types";
import { ensureExhaustive } from "../common";
import { HotelStarRating, Lodging } from "@b2bportal/lodging-api";

export const toStarRating = (value: HotelStarRating): StarRatingValue => {
  if (value === HotelStarRating.NotAvailable) {
    return undefined;
  }
  return toStarRatingForAvailableRating(value);
};

export const toHotelStarRating = (
  value: number
): HotelStarRating | undefined => {
  switch (value) {
    case 1:
      return HotelStarRatingWithoutNotAvailable.One;
    case 2:
      return HotelStarRatingWithoutNotAvailable.Two;
    case 3:
      return HotelStarRatingWithoutNotAvailable.Three;
    case 4:
      return HotelStarRatingWithoutNotAvailable.Four;
    case 5:
      return HotelStarRatingWithoutNotAvailable.Five;
    default:
      return undefined;
  }
};

export const toStarRatingForAvailableRating = (
  value: keyof AvailableHotelStarRating
): AvailableHotelStarRatingValue => {
  switch (value) {
    case HotelStarRatingWithoutNotAvailable.One:
      return 1;
    case HotelStarRatingWithoutNotAvailable.Two:
      return 2;
    case HotelStarRatingWithoutNotAvailable.Three:
      return 3;
    case HotelStarRatingWithoutNotAvailable.Four:
      return 4;
    case HotelStarRatingWithoutNotAvailable.Five:
      return 5;
    default:
      ensureExhaustive(value);
  }
};

export const sortByPrice = (a: Lodging, b: Lodging) => {
  const aPrice = a.price?.totalPrice?.fiat.value;
  const bPrice = b.price?.totalPrice?.fiat.value;
  // If a hotel does not have a price, it's unavailable and should be pushed to the end
  if (aPrice == null && bPrice == null) {
    return 0;
  } else if (aPrice == null) {
    return 1;
  } else if (bPrice == null) {
    return -1;
  } else {
    return aPrice - bPrice;
  }
};

export const sortByStarRating = (a: Lodging, b: Lodging) => {
  const aNum = toStarRating(a.lodging.starRating) ?? 0;
  const bNum = toStarRating(b.lodging.starRating) ?? 0;
  // We want to sort in descending order
  return bNum - aNum;
};

export const sortByUserRating = (a: Lodging, b: Lodging) => {
  return b.lodging.score - a.lodging.score;
};

/**
 * A map of sort options to their respective comparators.
 *
 * A `null` value here means the hotels will not be re-ordered, and they will be shown in the order
 * in which they are received from the API.
 *
 * All options should be set, but we have to use a PartialRecord to allow for extending the
 * HotelSortOptionEnum. If a new option is added by an override, this map should also be overridden
 * to support that option.
 */
export const hotelListSortComparatorsMap: Record<
  HotelSortOptionEnum,
  HotelComparator | null
> = {
  [HotelSortOptionEnum.Recommended]: null,
  [HotelSortOptionEnum.Price]: sortByPrice,
  [HotelSortOptionEnum.StarRating]: sortByStarRating,
  [HotelSortOptionEnum.UserRating]: sortByUserRating,
};
