import { Brand } from "@b2bportal/lloyds-auth-api";
import {
  AxiosInterceptors,
  createRequestLog,
  createResponseErrorLog,
  createResponseLog,
} from "@hopper-b2b/api";
import { useExperiments } from "@hopper-b2b/experiments";
import { getDelegatedTo } from "@hopper-b2b/self-serve";
import { getLang } from "@hopper-b2b/i18n";
import { useUserSource } from "@hopper-b2b/utilities";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { UserSessionContext, useUserSessionBrand } from "../../context";

export interface IAxiosInterceptors {
  children?: React.ReactNode;
  version?: string;
}

export const AxiosInterceptorWrapper = ({
  children,
  version,
}: IAxiosInterceptors) => {
  const { userSessionInfo } = useContext(UserSessionContext);

  const { trackingProperties } = useExperiments();
  const delegatedTo = useSelector(getDelegatedTo);
  const userSource = useUserSource();
  const brand = useUserSessionBrand();
  const sessionId = sessionStorage.getItem("sessionId");
  const locale = getLang();
  // TODO: Needs to not be hardcoded
  const currency = "GBP";
  const headers = {};
  if (userSessionInfo && userSessionInfo.accessToken)
    headers["Hopper-Session"] = userSessionInfo.accessToken;
  if (sessionId) headers["Riskified-Session-Id"] = sessionId;

  const tenant = brand?.valueOf() ?? Brand.Lloyds;

  return (
    <AxiosInterceptors
      delegatedTo={delegatedTo}
      trackingProperties={trackingProperties}
      userSource={userSource}
      tenant={tenant}
      locale={locale}
      currency={currency}
      requestHeaders={headers}
      version={version}
      logResponse={createResponseLog}
      logRequest={createRequestLog}
      logError={createResponseErrorLog}
      retryTransientErrors={true}
    >
      {children}
    </AxiosInterceptors>
  );
};
