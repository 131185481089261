import { createContext, PropsWithChildren, useContext } from "react";

export type CommonFeatureFlagsContext = {
  enablePricePrediction?: boolean;
};

export type FeatureFlagsContext = CommonFeatureFlagsContext;

export type FeatureFlagsContextProps = {
  featureFlags: FeatureFlagsContext;
};

export const FeatureFlagsContext = createContext<FeatureFlagsContext>({});

export const useFeatureFlagsContext = (): FeatureFlagsContext => {
  const ctx = useContext(FeatureFlagsContext);
  if (!ctx) {
    console.warn(`Must be used within a FeatureFlagsContext`);
  }

  return ctx as FeatureFlagsContext;
};

export const useEnablePricePrediction = (): boolean => {
  const featureFlagsContext = useFeatureFlagsContext();
  return Boolean(featureFlagsContext?.enablePricePrediction);
};

export const FeatureFlagsContextProvider = ({
  children,
  featureFlags,
}: PropsWithChildren<FeatureFlagsContextProps>) => {
  return (
    <FeatureFlagsContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
