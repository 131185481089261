import { NETWORK_CALL_FAILED, IApiConfig } from "@hopper-b2b/types";
import { trackEvent } from "../tracking/trackEvent";
import { travelerApi as defaultTravelerApi } from "@b2bportal/traveler-api";
import axios from "axios";
import { ListTravelersResponse } from "@b2bportal/traveler-api/lib/api";

export const fetchTravelers = (
  apiConfig?: IApiConfig
): Promise<ListTravelersResponse> => {
  const travelerApi = defaultTravelerApi(axios);
  return new Promise((resolve, reject) => {
    travelerApi
      .apiV0TravelerListPost(
        {},
        {
          method: "PUT",
        }
      )
      .then((response) => resolve(response.data))
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });
};
