import { FiatPrice, Person } from "@b2bportal/air-booking-api";
import { TrackingEventEntryPoint } from "@hopper-b2b/types";
import { set } from "lodash-es";
import { StateValue } from "xstate";
import { ParentState } from "./types";
import { getDateTimeWithFormat } from "@hopper-b2b/utilities";
import dayjs from "dayjs";

/**
 * ------------------------------------------------------
 * Parent state = outer most state
 * child states => nested 1 level
 * nestedChild states => nested 2 levels
 * ------------------------------------------------------
 * eg: Machine config
 *  "states": {
 *       "parentState": {
 *         "states": {
 *           "childState1": {
 *              "states": {
 *                "nestedChildState1": {}
 *              }
 *            },
 *           "childState2": {}
 *         }
 *       }
 *     }
 * ------------------------------------------------------
 * eg: State value
 * { "parentState": "childState1" }
 * ------------------------------------------------------
 **/

export const getParentState = (state: StateValue) => {
  if (typeof state === "object") {
    return Object.keys(state)[0];
  }
  return state;
};

export const getChildState = (state: StateValue) => {
  if (typeof state === "object") {
    const childState = state[Object.keys(state)[0]];
    // If there's nested states, just return the first key
    // Parallel states are not handled
    if (typeof childState == "object") {
      return Object.keys(childState)[0];
    }
    // childState => string
    return childState;
  }
  // If type is string, no child state present
  return null;
};

export const getNestedChildState = (state: StateValue) => {
  if (typeof state === "object") {
    const childState = state[Object.keys(state)[0]];
    if (typeof childState == "object") {
      return childState[Object.keys(childState)[0]];
    }
  }
  return null;
};

export const setContextWithKey = <T extends object, V>(
  ctx: T,
  key: string,
  value: V
): T => set(ctx, key, value);

export const combineServices = (services: Array<Promise<unknown>>) =>
  Promise.all(services);

type IObjectKeyTypes<T extends object> = {
  [K in keyof T]: K;
};
export const getObjectKeysAsObject = <T extends object>(
  genericObject: T
): IObjectKeyTypes<T> =>
  Object.keys(genericObject).reduce(
    (prev, cur) => ({ ...prev, [cur]: cur }),
    {} as IObjectKeyTypes<T>
  );

type FiatPriceWithoutSymbol = Omit<FiatPrice, "currencySymbol">;
export const getHasPriceChanged = (
  allowed: FiatPriceWithoutSymbol,
  actual: FiatPriceWithoutSymbol
) => {
  if (allowed.currencyCode === actual.currencyCode) {
    return actual.value >= allowed.value;
  } else {
    if (actual.currencyCode === "USD") {
      return actual.value >= 1;
    }
    if (actual.currencyCode === "MXN") {
      return actual.value >= 1;
    }
    if (actual.currencyCode === "COP") {
      return actual.value >= 5000;
    }
    if (actual.currencyCode === "GBP") {
      return actual.value >= 1;
    }
    if (actual.currencyCode === "BRL") {
      return actual.value >= 5;
    }
    // unknown currency, return false till we understand which currency and adjust default above
    return false;
  }
};

export const parentStateToEntryPointMap = {
  [ParentState.contactInformation]: TrackingEventEntryPoint.ContactInformation,
  [ParentState.auth]: TrackingEventEntryPoint.TravelerInformation,
  [ParentState.passengerInformation]:
    TrackingEventEntryPoint.TravelerInformation,
  [ParentState.passport]: TrackingEventEntryPoint.TravelerInformation,
  [ParentState.bagsSelection]: TrackingEventEntryPoint.Bags,
  [ParentState.cardPayment]: TrackingEventEntryPoint.Payment,
  [ParentState.guestCardPayment]: TrackingEventEntryPoint.Payment,
  [ParentState.review]: TrackingEventEntryPoint.BookReview,
};

export const getIsMinor = (passenger: Person, minimumCheckinAge?: number) => {
  const age = dayjs().diff(
    getDateTimeWithFormat(passenger.dateOfBirth, "YYYY-MM-DD"),
    "year"
  );
  const isNotMinor = minimumCheckinAge ? age > minimumCheckinAge : age > 17;
  if (isNotMinor) {
    return false;
  } else {
    return true;
  }
};
