import { ThunkDispatch } from "@reduxjs/toolkit";
import { AnyAction } from "redux";

export type AppDispatch = ThunkDispatch<{}, any, AnyAction>;

export enum CallState {
  NotCalled,
  InProcess,
  Success,
  Failed,
}
