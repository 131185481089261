import { config } from "@lloyds/utils";
import { isMockMode } from "@hopper-b2b/mock-utils";
import { Environment } from "@hopper-b2b/utilities";

export const maybeLoadMocks = async () => {
  const _isMockMode = isMockMode();

  // Only use MOCK mode if enabled and not in production
  if (config.env !== Environment.production && _isMockMode) {
    const { setupMockWorker } = await import("@lloyds/mock");
    setupMockWorker().start();
  }

  return;
};
