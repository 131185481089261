import { PATH_HOME } from "@hopper-b2b/utilities";

export const PATH_ERROR = PATH_HOME + "error";

export const PATH_TERMS = PATH_HOME + "terms";
export const PATH_FAQ = PATH_HOME + "faq";
export const PATH_SUPPORT = PATH_HOME + "support";
export const PATH_TRAVEL_HUB = "nga://travel-hub";

export const LLOYDS_URL_PARAMS = {
  SHOW_BACK_BUTTON: "showBackButton",
  YOUTH: "youth",
  HIGH_CONTRAST: "highContrast",
};
