import { ITraveler, PersonId, ProductType } from "@hopper-b2b/types";

export enum TravelerEventTypes {
  DELETE_TRAVELER = "DELETE_TRAVELER",
  SET_CURRENT_TRAVELER = "SET_CURRENT_TRAVELER",
  CLEAR_TRAVELER_INFORMATION_ERROR = "CLEAR_TRAVELER_INFORMATION_ERROR",
  OPEN_TRAVELER_PICKER = "OPEN_TRAVELER_PICKER",
  OPEN_TRAVELER_FORM = "OPEN_TRAVELER_FORM",

  ADD_TRAVELER = "ADD_TRAVELER",
  COMPLETE = "COMPLETE",
  GO_IDLE = "GO_IDLE",
  NEXT = "NEXT",
  SELECT_TRAVELER = "SELECT_TRAVELER",
  UPDATE_TRAVELER = "UPDATE_TRAVELER",
  PREVIOUS = "PREVIOUS",
  OPEN_FORM_AND_SET_TRAVELER = "OPEN_FORM_AND_SET_TRAVELER",
}

export interface DeleteTravelerEvent {
  type: TravelerEventTypes.DELETE_TRAVELER;
  travelerId: PersonId;
}

export interface SetCurrentTravelerEvent {
  type: TravelerEventTypes.SET_CURRENT_TRAVELER;
  traveler: ITraveler;
}

export interface ClearTravelerInformationError {
  type: TravelerEventTypes.CLEAR_TRAVELER_INFORMATION_ERROR;
}

export interface OpenTravelerPickerEvent {
  type: TravelerEventTypes.OPEN_TRAVELER_PICKER;
}

export interface OpenTravelerFormEvent {
  type: TravelerEventTypes.OPEN_TRAVELER_FORM;
}

export interface SelectTravelerEvent {
  type: TravelerEventTypes.SELECT_TRAVELER;
  travelerId: PersonId;
  singleTravelerWorkflow?: boolean;
  onUpdate?: boolean;
}

export interface AddTravelerEvent {
  type: TravelerEventTypes.ADD_TRAVELER;
}
export interface UpdateTravelerEvent {
  type: TravelerEventTypes.UPDATE_TRAVELER;
  traveler: ITraveler;
  onUpdate?: boolean;
  singleTravelerWorkflow?: boolean;
}

export interface GoIdleEvent {
  type: TravelerEventTypes.GO_IDLE;
}

export interface NextEventType {
  type: TravelerEventTypes.NEXT;
  productType: ProductType;
}

export interface PreviousEventType {
  type: TravelerEventTypes.PREVIOUS;
}

export interface OpenTravelerFormAndSetCurrentTraveler {
  type: TravelerEventTypes.OPEN_FORM_AND_SET_TRAVELER;
  traveler: ITraveler;
}

export type TravelerEvents =
  | AddTravelerEvent
  | ClearTravelerInformationError
  | DeleteTravelerEvent
  | GoIdleEvent
  | NextEventType
  | OpenTravelerFormEvent
  | OpenTravelerPickerEvent
  | SelectTravelerEvent
  | SetCurrentTravelerEvent
  | UpdateTravelerEvent
  | PreviousEventType
  | OpenTravelerFormAndSetCurrentTraveler;
