import { Store } from "@reduxjs/toolkit";
import { PortalConfig } from "../../types";
import { CoreProviders } from "./Core";
import { Root } from "./Root";
import { bootSetup } from "./Root/bootSetup";

export * as serviceWorker from "./serviceWorker";

export interface PortalProps<T extends Store> extends React.PropsWithChildren {
  config: PortalConfig;
  store: T;
  testLocale?: string;
  isRouterInitialized?: boolean;
}

/**
 * PortalCore Component
 *
 * Renders minimum providers required for core libraries to function.
 * This component is designed for tenants needing a hybrid approach where they set up their own app.
 * Tenants must implement an i18n provider for the core to function properly.
 */
export const PortalCore = <T extends Store>({
  config,
  store,
  children,
}: PortalProps<T>) => {
  return (
    <CoreProviders config={config} store={store}>
      {children}
    </CoreProviders>
  );
};

/**
 * Portal
 *
 * Provides a comprehensive set of providers necessary for the B2B portal implementation.
 */
export const Portal = <T extends Store>({
  config,
  store,
  children,
  testLocale,
}: PortalProps<T>) => {
  bootSetup(config.bootConfig);
  return (
    <PortalCore config={config} store={store}>
      <Root config={config} testLocale={testLocale}>
        {children}
      </Root>
    </PortalCore>
  );
};
