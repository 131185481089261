import { authApi } from "@b2bportal/auth-api";

import { axiosInstance } from "../AxiosInterceptor";

export async function getSessionInfo() {
  try {
    const res = await authApi(axiosInstance).apiV0AuthSessionInfoPost();

    return res.data;
  } catch (error) {
    console.error("Fetch session info error:", error);
    throw error;
  }
}
