import { Box, Typography } from "@material-ui/core";
import { useI18nContext } from "@hopper-b2b/i18n";
import { DesktopHotelSearch } from "./DesktopHotelSearch/DesktopHotelSearch";
import { MobileHotelSearch } from "./MobileHotelSearch/MobileHotelSearch";
import { useDeviceTypes } from "@hopper-b2b/utilities";

export const HotelSearch = () => {
  const { matchesMobile } = useDeviceTypes();

  const { t } = useI18nContext();

  return !matchesMobile ? (
    <DesktopHotelSearch />
  ) : (
    <Box>
      <Box marginBottom={4} marginTop={4}>
        <Typography variant="h3">
          {t("lodging.search.selectDestinationTitle")}
        </Typography>
      </Box>

      <MobileHotelSearch />
    </Box>
  );
};
