import type { Brand } from "@b2bportal/lloyds-auth-api";
import { getLang } from "@hopper-b2b/i18n";
import { getFaviconFromBrand } from "@lloyds/assets";
import { UserSessionProvider, useUserSessionBrand } from "@lloyds/auth";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import { App } from "./App/App";
import { getSessionId } from "./utils";

export const Root = () => (
  <BrowserRouter>
    <CompatRouter>
      <UserSessionProvider>
        <Head />
        <App />
      </UserSessionProvider>
    </CompatRouter>
  </BrowserRouter>
);

const titleMap: Record<Brand, string> = {
  Lloyds: "Lloyds | Travel Portal",
  Halifax: "Halifax | Travel Portal",
  BankOfScotland: "Bank of Scotland | Travel Portal",
  MBNA: "MBNA | Travel Portal",
};

const Head = () => {
  const sessionId = getSessionId();
  const brand = useUserSessionBrand();
  return (
    <Helmet>
      <html lang={getLang()} />
      <title>{titleMap[brand]}</title>
      <link
        rel="shorcut icon"
        type="image/x-icon"
        href={getFaviconFromBrand(brand)}
      />
      <script
        type="text/javascript"
        async
        src={`https://beacon.riskified.com?shop=lloyds.com&sid=${sessionId}`}
      />
    </Helmet>
  );
};
