import type { Lodging } from "@b2bportal/lodging-api";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { Slot, StarRating, Tags } from "@hopper-b2b/ui";
import { TripAdvisor } from "../TripAdvisor";
import { Features } from "../Features";
import { Amenities } from "../Amenities";
import { Price } from "../Price";
import styles from "../../LodgingCard.module.scss";
import { getEnvVariables, useEnableWallet } from "@hopper-b2b/utilities";
import { useSelector } from "react-redux";
import {
  getWalletBestOffers,
  getWalletOffersNoCredits,
} from "../../../../../wallet/reducer";
import { useTrackEvents } from "../../../../../../tracking";
import { useCallback } from "react";
import { ClientName, LodgingShopTrackingEvents } from "@hopper-b2b/types";

interface Props {
  currentLodging: Lodging;
  loading?: boolean;
  onlyStarRating?: boolean;
}

export function LodgingSummary({
  currentLodging,
  loading = false,
  onlyStarRating = false,
}: Props) {
  const { lodging, price, available, isFreeCancel, isPreferred } =
    currentLodging;

  const isLloydsClient = getEnvVariables("clientName") === ClientName.LLOYDS;
  const enableWallet = useEnableWallet();
  const bestOffer = useSelector(getWalletBestOffers);
  const offers = useSelector(getWalletOffersNoCredits);
  const trackEvent = useTrackEvents();

  const handleOfferBannerTap = useCallback(() => {
    trackEvent(LodgingShopTrackingEvents.viewed_offer, {
      screen: "shop",
    });
  }, [trackEvent]);

  return (
    <div className="Content">
      <Tags className="Tags" available={available} isPreferred={isPreferred} />
      <div className={clsx(styles.TitleWrapper, "TitleWrapper")}>
        <Typography variant="h2" className="HotelName">
          {lodging.name}
        </Typography>
        <Slot id="lodging-card-policy" lodgingId={lodging.id} autoMarginLeft />
      </div>

      <div className={clsx(styles.GridArea, "GridArea")}>
        <div className={clsx(styles.Ratings, "Ratings")}>
          <StarRating
            starRating={lodging.starRating}
            className="ratings"
            onlyStarRating={onlyStarRating}
          />
          <TripAdvisor
            tripAdvisorReviews={lodging.tripAdvisorReviews}
            className="trip-advisor-ratings TripAdvisorRatings"
          />
        </div>
        <Features
          className={clsx(styles.Features, "Features")}
          isFreeCancel={isFreeCancel}
        />
        <Amenities
          className={clsx(styles.Amenities, "Amenities")}
          amenities={lodging.amenities}
        />
        {enableWallet && !isLloydsClient ? (
          <Slot
            id="hotel-voucher-offer-banner"
            isEligible
            showMore
            minimal
            offer={bestOffer}
            offers={offers}
            onClick={handleOfferBannerTap}
          />
        ) : null}
        {available ? (
          <Slot
            id="hotel-summary-price"
            className={clsx(styles.Price, "Price")}
            loading={loading}
            price={price}
            component={
              <Price className={clsx(styles.Price, "Price")} price={price} />
            }
          />
        ) : null}
        <div className="additional-features">
          <Slot
            id="lodging-free-cancellation"
            isFreeCancel={isFreeCancel}
            component={
              isFreeCancel && (
                <Features
                  className={clsx(styles.HiddenFeatures, "HiddenFeatures")}
                  isFreeCancel={isFreeCancel}
                />
              )
            }
          />
          <Slot id="lodging-card-cash-back" price={price} />
          <Slot id="lodging-card-wallet" />
        </div>
      </div>
    </div>
  );
}
