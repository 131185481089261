import { CoreUiComponents } from "@b2bportal/core-types";
import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import defaultStyles from "./MapMarker.module.scss";
import clsx from "clsx";
import { MapMarkerComponentProps } from "../../types";

const COMPONENT_KEY = CoreUiComponents.MapMarker;

export const MapMarker = <T,>({
  children,
  buttonProps,
  className,
  isHighlighted = false,
  onClick,
}: MapMarkerComponentProps<T>) => {
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [block] = useModuleBEM(styles, COMPONENT_KEY, {
    highlighted: isHighlighted,
  });
  return (
    <button
      {...buttonProps}
      onClick={onClick}
      className={clsx(block, className)}
    >
      {children}
    </button>
  );
};
