import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { LbgModal } from "../../LbgModal";
import { TextInputField } from "../../TextInputField";
import type { GuestsSelection } from "@hopper-b2b/types";
import { useDeviceTypes, useTenantIcons } from "@hopper-b2b/utilities";
import { Box, InputAdornment } from "@material-ui/core";
import { useCallback, useState } from "react";
import styles from "./GuestPickerInput.module.scss";
import { CountPicker } from "../components/CountPicker";
import { ActionButton } from "@hopper-b2b/ui";
import { ChildrenAgePicker } from "../components/ChildAgePicker";

export type GuestPickerInputProps = {
  guestCounts: GuestsSelection;
  updateGuestCounts: (count: GuestsSelection) => void;
};

const MINIMUM_ADULTS_COUNT = 1;
const MAXIMUM_COUNT = 6;
const MAX_CHILD_AGE = 17;

export const GuestPickerInput = ({
  guestCounts,
  updateGuestCounts,
}: GuestPickerInputProps) => {
  const tenantIcons = useTenantIcons();
  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();
  const texts = {
    adultTitle: t("guestCount.adultTitle"),
    adultSubtitle: t("guestCount.adultSubtitle"),
    childrenTitle: t("guestCount.childrenTitle"),
    childrenSubtitle: t("guestCount.childrenSubtitle"),
    applyButtonText: t("guestCount.apply"),
  };
  const [showGuestCountPicker, setShowGuestCountPicker] =
    useState<boolean>(false);

  const [adultsCount, setAdultsCount] = useState(guestCounts.adults);
  const [childrenCount, setChildrenCount] = useState(
    guestCounts.children.length
  );
  const [childrenAges, setChildrenAges] = useState(guestCounts.children);

  const numTravelers = guestCounts.adults + guestCounts.children.length;
  const maximumReached = adultsCount + childrenCount >= MAXIMUM_COUNT;
  const missingChildrenAges =
    childrenAges.length < childrenCount ||
    childrenAges.some(
      (age) => !Number.isInteger(age) || age < 0 || age > MAX_CHILD_AGE
    );

  const handleApplyClick = useCallback(() => {
    updateGuestCounts({
      adults: adultsCount,
      children: childrenAges,
      rooms: guestCounts.rooms,
    });
    setShowGuestCountPicker(false);
  }, [adultsCount, childrenAges, guestCounts, updateGuestCounts]);

  const handleResetValues = useCallback(() => {
    setAdultsCount(guestCounts.adults);
    setChildrenCount(guestCounts.children.length);
    setChildrenAges(guestCounts.children);
  }, [guestCounts]);

  const handleClose = useCallback(() => {
    setShowGuestCountPicker(false);
    handleResetValues();
  }, [handleResetValues]);

  const onInputClick = useCallback(() => {
    setShowGuestCountPicker(true);
    trackEvent({
      eventName: "hotel_tapped_guests",
      properties: {
        adults_count: guestCounts.adults,
        children_count: guestCounts.children.length,
      },
    });
  }, [guestCounts]);

  const handleChangeChildrenCount = useCallback(
    (count: number) => {
      setChildrenCount(count);
      setChildrenAges(childrenAges.slice(0, count));
    },
    [childrenAges]
  );

  return (
    <>
      <TextInputField
        clearable={false}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img
                height={16}
                width={16}
                className={styles.tenantIcon}
                src={tenantIcons.guest}
                alt=""
              />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        onClick={onInputClick}
        value={t("searchFilter.pickerTraveler", {
          count: numTravelers,
        })}
        aria-label={t("guestPicker")}
        inputProps={{
          className: styles.cursor,
        }}
      />
      <LbgModal
        open={showGuestCountPicker}
        onClose={handleClose}
        title={t("guestCount.modalTitle")}
        subtitle={t("guestCount.warningMessage", {
          count: 6,
        })}
        primaryBottomButton={
          <ActionButton
            onClick={handleApplyClick}
            disabled={missingChildrenAges}
            message={texts.applyButtonText}
          />
        }
        diableContentPadding
      >
        <Box width={matchesMobile ? "unset" : "350px"}>
          <Box>
            <CountPicker
              title={texts.adultTitle}
              subtitle={texts.adultSubtitle}
              maximumReached={maximumReached}
              minimumCount={MINIMUM_ADULTS_COUNT}
              count={adultsCount}
              setCount={setAdultsCount}
            />
            <CountPicker
              title={texts.childrenTitle}
              subtitle={texts.childrenSubtitle}
              maximumReached={maximumReached}
              count={childrenCount}
              setCount={handleChangeChildrenCount}
            />
            <ChildrenAgePicker
              childCount={childrenCount}
              childrenAges={childrenAges}
              onChildAgesChange={setChildrenAges}
            />
          </Box>
        </Box>
      </LbgModal>
    </>
  );
};
