import clsx from "clsx";

import styles from "./ChatDivider.module.scss";

export interface IChatDividerProps {
  value: string;
}

const ChatDivider = (props: IChatDividerProps) => {
  return (
    <div className={clsx(styles.chatDivider)}>
      <div className={clsx(styles.chatDividerLine)}></div>
      <span className={clsx(styles.chatDividerText)}>{props.value}</span>
      <div className={clsx(styles.chatDividerLine)}></div>
    </div>
  );
};

export default ChatDivider;
