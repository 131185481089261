import {
  CacheableExternalResource,
  CallState,
  ExternalResourceNotCalled,
} from "@b2bportal/core-types";
import { ActionReducerMapBuilder, AsyncThunk, Draft } from "@reduxjs/toolkit";
import { processErrorForThunk, ThunkErrorType } from "./errors";
import {
  BaseThunkConfig,
  GetThunkConfig,
  GetThunkResponse,
} from "./thunk-types";

/**
 * Defines the overload signatures for `handleAsyncThunkCacheable`.
 */
type HandleAsyncThunkCacheable = {
  /**
   * Handles the lifecycle of an `AsyncThunk` and maps it to a
   * `CacheableExternalResource` stored on the `State`.
   *
   * This overload is used when the response of the thunk needs to be processed
   * or distilled for storage.
   *
   * @template State The type of the state managed by the reducer.
   * @template Value The type of the value extracted from the thunk response.
   * @template Thunk The type of the `AsyncThunk`.
   *
   * @param builder The builder used to add cases to the reducer.
   * @param thunk The `AsyncThunk` to handle.
   * @param handlers An object containing the following handlers:
   *    - `get`: A function to get the current cached `Value` from the state.
   *    - `set`: A function to set the `Value` given a
   *        `CacheableExternalResource`.
   *    - `extract`: A function to extract the `Value` from the thunk response.
   *    - `update?`: A function to update the state with the extracted `Value`.
   */
  <State, Value, Thunk extends BaseAsyncThunk>(
    builder: ActionReducerMapBuilder<State>,
    thunk: Thunk,
    handlers: GetSetType<State, Thunk, Value> &
      ExtractUpdateType<State, GetThunkResponse<Thunk>, Value>
  );

  /**
   * Handles the lifecycle of an `AsyncThunk` and maps it to a
   * `CacheableExternalResource` stored on the `State`.
   *
   * This overload is used when the response of the thunk is directly stored in
   * the `State`.
   *
   * @template State The type of the state managed by the reducer.
   * @template Thunk The type of the `AsyncThunk`.
   *
   * @param builder The builder used to add cases to the reducer.
   * @param thunk The `AsyncThunk` to handle.
   * @param handlers An object containing the following handlers:
   *    - `get`: A function to get the current cached value from the state.
   *    - `set`: A function to set the resource on the state.
   */
  <State, Thunk extends BaseAsyncThunk>(
    builder: ActionReducerMapBuilder<State>,
    thunk: Thunk,
    handlers: GetSetType<State, Thunk>
  ): void;
};

/**
 * The function can be used in two ways:
 * 1. Mapping an `AsyncThunk` response `Res` directly to a
 *    `CacheableExternalResource<Res, ...>` value.
 * 2. Deriving a `Value` from the `AsyncThunk` response `Res` and mapping it to
 *    a `CacheableExternalResource<Value, ...>`.
 *
 * See individual overloads for more detailed documentation.
 */
export const handleAsyncThunkCacheable: HandleAsyncThunkCacheable = <
  State,
  Thunk extends BaseAsyncThunk,
  Value = GetThunkResponse<Thunk>
>(
  builder: ActionReducerMapBuilder<State>,
  thunk: Thunk,
  handlers: GetSetType<State, Thunk, Value> &
    // The type below is a bit complicated to read, but basically we want to
    // include `extract` and `update` keys if the thunk response is not the
    // same as the `Value` type. If they're the same, we just want to support
    // `get`/`set`. But since this is the union type of all the overloads, a
    // strict type check isn't necessary here.
    (| Record<never, never>
      | ExtractUpdateType<State, GetThunkResponse<Thunk>, Value>
    )
) => {
  const { get, set } = handlers;
  const { extract, update } =
    "extract" in handlers
      ? handlers
      : ({
          extract: (response: GetThunkResponse<Thunk>) => response,
        } as ExtractUpdateType<State, GetThunkResponse<Thunk>, Value>);
  builder
    .addCase(thunk.pending, (state) => {
      set(state, {
        state: CallState.InProcess,
        data: get(state),
      });
    })
    .addCase(thunk.rejected, (state, action) => {
      set(state, {
        state: CallState.Failed,
        error: processErrorForThunk(action),
        data: get(state),
      });
    })
    .addCase(thunk.fulfilled, (state, action) => {
      set(state, {
        state: CallState.Success,
        data: extract(action.payload as GetThunkResponse<Thunk>),
      });
      update?.(state, action.payload as GetThunkResponse<Thunk>);
    });
};

type BaseAsyncThunk = AsyncThunk<unknown, unknown, BaseThunkConfig>;

interface GetSetType<
  State,
  Thunk extends BaseAsyncThunk,
  Value = GetThunkResponse<Thunk>
> {
  /**
   * Defines the getter which given a `state` returns the current value of the
   * relevant `CacheableExternalResource`.
   *
   * @param state The full current state as presented by the
   *    `ActionReducerMapBuilder`.
   */
  get(state: Draft<State>): Value | undefined;

  /**
   * Defines the setter which updates the relevant `CacheableExternalResource`
   * on the given `state` from the given `resource`.
   * @param state The `Draft<State>` that should be updated.
   * @param resource The resulting `CacheableExternalResource` that should be
   *   set on the `state`.
   */
  set(
    state: Draft<State>,
    resource: Exclude<
      CacheableExternalResource<
        Value,
        ThunkErrorType<GetThunkConfig<Thunk>["rejectValue"]>
      >,
      ExternalResourceNotCalled
    >
  ): void;
}

interface ExtractUpdateType<State, Response, Value> {
  /**
   * Defines the function that extracts the `Value` from the given `response`.
   *
   * @param response The raw `AsyncThunk` success response.
   */
  extract(response: Response): Value;

  /**
   * An optional function that can be defined in order to update `state` from
   * the raw response, since information may be lost during the `extract`ion.
   *
   * @param state The `Draft<State>` that should be updated.
   * @param response The raw `AsyncThunk` response.
   */
  update?(state: Draft<State>, response: Response): void;
}
