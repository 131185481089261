import { useTenantIcons } from "@hopper-b2b/utilities";
import { InputAdornment } from "@material-ui/core";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import styles from "./SearchButton.module.scss";
import { TextInputField } from "@lloyds/ui-connected";

export const SearchButton = ({
  path,
  placeholder,
}: {
  path: string;
  placeholder: string;
}) => {
  const navigate = useNavigate();
  const icons = useTenantIcons();

  const handleSearch = useCallback(() => {
    navigate(path);
  }, [navigate, path]);

  return (
    <TextInputField
      clearable={false}
      value={placeholder}
      onClick={handleSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img
              src={icons.search}
              alt="search-hotels"
              className={styles.searchIcon}
            />
          </InputAdornment>
        ),
        readOnly: true,
      }}
      className={styles.searchPrompt}
    />
  );
};
