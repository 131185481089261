import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import {
  CoreUiComponents,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import { ReactNode, useEffect, useState } from "react";
import defaultStyles from "./styles.module.scss";
import clsx from "clsx";
import { FiatPrice } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { TextField } from "@components/ui";
import { useRoundedFiatPriceToText } from "@b2bportal/core-utilities";

export interface PriceFieldComponentProps extends PlatformComponentProps {
  onChange?: (value: FiatPrice) => void;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  placeholder?: string;
  label?: string | ReactNode;
  defaultValue?: string | number;
  required?: boolean;
  error?: boolean;
  errorHelper?: string;
  type?: string;
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value: FiatPrice;
  autoFocus?: boolean;
  disabled?: boolean;
  id?: string;
  min?: number;
  max?: number;
  fallbackValue?: number;
}

const inputValueToNumber = (inputValue: string) => {
  return parseInt(inputValue.replace(/[^0-9]/g, ""));
};

export const PriceField = ({
  className,
  onChange,
  onBlur,
  onFocus,
  value,
  min,
  max,
  fallbackValue = 0,
  ...props
}: PriceFieldComponentProps) => {
  const COMPONENT_KEY = CoreUiComponents.PriceField;
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const fiatPriceToText = useRoundedFiatPriceToText();
  const minPrice = min ?? 0;
  const maxPrice = max ?? Number.MAX_SAFE_INTEGER;

  const priceToInputValue = (price: number) =>
    fiatPriceToText({ ...value, value: price });

  const [inputValue, setValue] = useState(priceToInputValue(value.value));
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (value.value !== inputValueToNumber(inputValue) && !isFocused) {
      setValue(priceToInputValue(value.value));
    }
  }, [value]);

  const numberToPrice = (number: number) => {
    return {
      ...value,
      value: number,
    };
  };

  const handleChange = (newValue: string) => {
    const onlyValueNumbers = newValue.replace(/[^0-9]/g, "");

    if (onlyValueNumbers === "") {
      setValue(onlyValueNumbers);
      return updateParentValue(fallbackValue);
    }

    const numberValue = parseInt(onlyValueNumbers);

    setValue(numberValue.toString());
    if (numberValue < minPrice) {
      return updateParentValue(minPrice);
    }
    if (numberValue > maxPrice) {
      return updateParentValue(maxPrice);
    }

    updateParentValue(numberValue);
  };

  const updateParentValue = (newValue: number) => {
    if (!onChange) {
      return;
    }
    onChange(numberToPrice(newValue));
  };

  const handleBlur = () => {
    setIsFocused(false);
    const parsedInputValue = parseInt(inputValue);
    const numberValue = isNaN(parsedInputValue)
      ? value.value
      : parsedInputValue;
    const validNumber = Math.min(maxPrice, Math.max(minPrice, numberValue));
    const newValue = priceToInputValue(validNumber);
    setValue(newValue);
    if (!onBlur) {
      return;
    }
    onBlur(newValue);
  };

  const handleFocus = () => {
    // Remove price formatting on focus to allow editing
    const numberValueAsText = inputValueToNumber(inputValue).toString();
    setIsFocused(true);
    setValue(numberValueAsText);
    if (!onFocus) {
      return;
    }
    onFocus(numberValueAsText);
  };
  return (
    <TextField
      {...props}
      value={inputValue}
      className={clsx(block, className)}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
};
