import { useI18nContext } from "@hopper-b2b/i18n";
import { MobileFloatingButton } from "@hopper-b2b/ui";

export type MobileBottomSectionProps = {
  buttonDisabled: boolean;
  onClick: () => void;
};

export const MobileBottomSection = ({ onClick, buttonDisabled }) => {
  const { t } = useI18nContext();
  return (
    <MobileFloatingButton onClick={onClick} disabled={buttonDisabled}>
      {t("continue")}
    </MobileFloatingButton>
  );
};
