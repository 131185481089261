import { trackEvent } from "@hopper-b2b/api";
import { ChatFab, testChatProvider } from "@hopper-b2b/chat";
import { useExperiment, useTrackingProperties } from "@hopper-b2b/experiments";
import {
  ClientName,
  type LaunchedApplicationProperties,
} from "@hopper-b2b/types";
import {
  PATH_FLIGHTS,
  PATH_HOME,
  PATH_HOTELS,
  PATH_TRIPS,
  PATH_WALLET,
  getLandingScreen,
  useLaunchedApplicationEvent,
} from "@hopper-b2b/utilities";
import { ProtectedRoute } from "@lloyds/auth";
import { FEATURE_FLAGS } from "@lloyds/theming";
import { LoadingScreen } from "@lloyds/ui-connected";
import { PATH_ERROR } from "@lloyds/utils";
import { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import Maintenance from "../Maintenance/maintenance";
// import { useKeepalive } from "../utils";
import ErrorRouteComponent from "./ErrorRoute";
import { HomeRouteComponent } from "./HomeRoute";

const AirApp = lazy(() => import("./air"));
const TripsApp = lazy(() => import("./trips"));
const LodgingRoutes = lazy(() => import("./lodging"));
const WalletApp = lazy(() => import("./wallet"));

export const AppRoutes = () => {
  // useKeepalive();

  const [launchEventSent, setLaunchEventSent] = useState(false);

  const isInMaintenance = useExperiment(FEATURE_FLAGS.MAINTENANCE);
  const enableChatSupport = useExperiment(FEATURE_FLAGS.CHAT_SUPPORT);

  const trackingProperties: LaunchedApplicationProperties =
    useTrackingProperties({
      landing_screen: getLandingScreen(ClientName.HOPPER),
      url: document.location.pathname,
    });

  const launchSent = useLaunchedApplicationEvent(
    launchEventSent,
    trackingProperties,
    trackEvent
  );

  useEffect(() => {
    if (launchSent) {
      setLaunchEventSent(true);
    }
  }, [launchSent, setLaunchEventSent]);

  return isInMaintenance ? (
    <Maintenance />
  ) : (
    <ProtectedRoute>
      {enableChatSupport && (
        <ChatFab chatProvider={testChatProvider} showPaths={[PATH_TRIPS]} />
      )}
      <Routes>
        <Route path={PATH_HOME} Component={HomeRouteComponent} />

        <Route
          path={`${PATH_FLIGHTS}*`}
          element={
            <Suspense fallback={<LoadingScreen />}>
              <AirApp />
            </Suspense>
          }
        />
        <Route
          path={PATH_HOTELS}
          element={
            <Suspense fallback={<LoadingScreen />}>
              <LodgingRoutes />
            </Suspense>
          }
        />

        <Route
          path={`${PATH_TRIPS}*`}
          element={
            <Suspense fallback={<LoadingScreen />}>
              <TripsApp />
            </Suspense>
          }
        />

        <Route
          path={`${PATH_WALLET}*`}
          element={
            <Suspense fallback={<LoadingScreen />}>
              <WalletApp />
            </Suspense>
          }
        />

        <Route path={PATH_ERROR} Component={ErrorRouteComponent} />
      </Routes>
    </ProtectedRoute>
  );
};
