import {
  AmenityStatus,
  Lodging,
  LodgingAddress,
  LodgingAddressEnum,
} from "@b2bportal/lodging-api";
import { type SortOption } from "@hopper-b2b/lodging-utils";
import { HotelAvailabilityTrackingProperties } from "@hopper-b2b/types";
import { ITrackingProperties } from "@hopper-b2b/utilities";
import dayjs from "dayjs";
import {
  ILodgingFilterState,
  PriceRange,
} from "../modules/availability/reducer";

const AmenitiesOrdered = [
  "adultsonly",
  "pool",
  "swimmingpool",
  "freebreakfast",
  "petfriendly",
  "petsallowed",
  "petsallowednofees",
  "roundtheclockcheckin",
  "twentyfourhourfrontdesk",
  "spa",
  "fullservicespa",
  "fitnesscenter",
  "fitnessfacilities",
  "twentyfourhourfitnessfacilities",
  "roomwifi",
  "freewifi",
  "accessibility",
  "inroomaccessibility",
  "laundry",
  "laundryfacilities",
  "airconditioning",
  "parking",
  "freeselfparking",
  "paidselfparking",
  "paidvaletparking",
];
export const orderedAmenitiesLength = AmenitiesOrdered.length;
export const mappedOrderedAmenities = AmenitiesOrdered.reduce(
  (obj, cur, i) => ({ [cur]: i, ...obj }),
  {} as Record<string, number>
);

const getSortIndex = (amenity: string) => {
  const i = AmenitiesOrdered.indexOf(amenity.toLowerCase());
  return i >= 0 ? i : AmenitiesOrdered.length;
};

export const sortAmenities = (amenities: AmenityStatus[]) => {
  return [...amenities].sort(
    (a, b) => getSortIndex(a.amenity) - getSortIndex(b.amenity)
  );
};

export const scrollTopWithOffset = (element: HTMLDivElement) => {
  /**
   * TODO this is not tenant friendly;
   * They don't all have the same header height neither a sticker header.
   * Plus we should rely on SECTION_ID and update the anchor.
   */
  const offset = 74; //header (64) + padding below header (10px)
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

export const getAddressText = (address: LodgingAddress): string | null => {
  switch (address.LodgingAddress) {
    case LodgingAddressEnum.Exact:
      return address.line1;
    case LodgingAddressEnum.Obfuscated:
      return address?.city;
    default:
      return null;
  }
};

export const getLodgingInListIndex = (
  lodgingId: string,
  lodgings: Lodging[]
): number => {
  let lodging_list_index = lodgings.findIndex(
    (l) => l?.lodging?.id === lodgingId
  );
  lodging_list_index = lodging_list_index !== -1 ? lodging_list_index : null;
  return lodging_list_index;
};

export const getLodgingAvailabilityTrackingProperties = (
  fromDate: string,
  untilDate: string,
  sort: SortOption,
  filters: ILodgingFilterState,
  lodgings: Lodging[],
  adults_count: number,
  children_count: number
): HotelAvailabilityTrackingProperties => {
  const advance = dayjs(fromDate).diff(dayjs(), "day");
  const check_in_date = dayjs(fromDate).format("YYYY-MM-DD");
  const check_out_date = dayjs(untilDate).format("YYYY-MM-DD");
  const los = dayjs(untilDate).diff(dayjs(fromDate), "day");
  const sort_order = sort;
  const number_of_filters_applied =
    Object.values(filters).filter(Boolean).length;
  const number_of_properties = lodgings.length;
  return {
    advance,
    check_in_date,
    check_out_date,
    los,
    number_of_filters_applied,
    number_of_properties,
    sort_order,
    adults_count,
    children_count,
  };
};

export const getLodgingShopTrackingProperties = (
  lodging: Lodging,
  lodging_list_index: number
): ITrackingProperties => {
  const hopper_preferred_hotel = lodging?.isPreferred;

  const { properties, encryptedProperties } =
    lodging?.trackingPropertiesV2 || {};

  return {
    properties: {
      ...properties,
      hopper_preferred_hotel,
      lodging_list_index,
    },
    encryptedProperties,
  };
};

export const calculatePriceRangeFilter = (
  lodgings: Lodging[],
  {
    min: currentMin,
    max: currentMax,
    lowest: currentLowest,
    highest: currentHighest,
  }: PriceRange
): PriceRange => {
  if (lodgings.length === 0) {
    return {
      min: 0,
      max: 0,
      lowest: 0,
      highest: 0,
    };
  }
  const lodgingsSortedByPrice = lodgings.sort(
    (a, b) =>
      a?.price?.nightlyPrice?.fiat.value - b?.price?.nightlyPrice?.fiat.value
  );
  const lowestPrice = Math.floor(
    lodgingsSortedByPrice[0]?.price?.nightlyPrice?.fiat.value || 0
  );
  const highestPrice = Math.ceil(
    lodgingsSortedByPrice[lodgingsSortedByPrice.length - 1]?.price?.nightlyPrice
      ?.fiat.value || Infinity
  );

  return {
    min: currentMin === currentLowest ? lowestPrice : currentMin,
    max: currentMax === currentHighest ? highestPrice : currentMax,
    lowest: lowestPrice,
    highest: highestPrice,
  };
};
