import { useCallback } from "react";
import { useNavigationFlow } from "./FlowContext";
import { useNavigate, useSearchParams } from "react-router-dom-v5-compat";
import type { AppDispatch, RouteStep } from "@b2bportal/core-types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

const useGoToNextStep = <T extends string>(currentStep: T) => {
  const {
    flow: { stepOrder, steps },
  } = useNavigationFlow<T>();
  const goToStep = useGoToStep();

  const findNextValidStep = useCallback(
    (currentStep: T): T | undefined => {
      const currentFlightStepIndex = stepOrder.findIndex(
        (step) => step === currentStep
      );

      if (currentFlightStepIndex === -1) {
        return undefined;
      }

      const nextStep: T | undefined = stepOrder
        .slice(currentFlightStepIndex + 1)
        .find((stepId) => steps[stepId].show);

      return nextStep;
    },
    [stepOrder, steps]
  );

  return useCallback(
    (replace = false) => {
      const nextFlightShopStepId: T | undefined =
        findNextValidStep(currentStep);

      // If has next step go to next step.
      nextFlightShopStepId != null
        ? goToStep(nextFlightShopStepId, replace)
        : console.error(
            `Calling next step on last step could not find step [${currentStep}] in the navigation flow [${stepOrder.toString()}]`
          );
    },
    [stepOrder, goToStep, findNextValidStep, currentStep]
  );
};

const useGoToStep = <T extends string>() => {
  const { flow, setCurrentStep } = useNavigationFlow<T>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  return useCallback(
    (stepId: T, replace = false) => {
      const step: RouteStep<T> | undefined = flow.steps[stepId];

      if (step == null) {
        console.error(
          `Step not found [${stepId}] in the navigation flow [${flow.stepOrder.toString()}]`
        );
        return;
      }
      setCurrentStep(step.id);
      // TODO: The use of a thunk here is not ideal, but it's necessary to
      // work around an issue with the way NavigationFlow works.
      //
      // Specifically, it should be possible to `dispatch(setSomeState)` and
      // call `goToNextStep` or `goToStep` immediately after, but
      // NavigationFlow will pre-create the steps, and encode the necessary
      // `queryParams` at creation time (when `useGoToStep` is called), so the
      // resulting `goToStep` function will always have an outdated state.
      //
      // Since thunks allow us to access state at runtime, we can work around
      // this issue by allow steps to provide a function to compute the query
      // params given a state.
      dispatch(
        goToStepThunk({
          step,
          flow,
          searchParams,
          navigate,
          replace,
        })
      );
    },
    [flow, setCurrentStep, dispatch, searchParams, navigate]
  );
};

const goToStepThunk = createAsyncThunk<
  void,
  {
    flow: ReturnType<typeof useNavigationFlow>["flow"];
    step: RouteStep<string>;
    navigate: ReturnType<typeof useNavigate>;
    replace: boolean;
    searchParams: URLSearchParams;
  }
>(
  "NavigationFlow/goToStepThunk",
  ({ step, navigate, flow, replace, searchParams }, thunkAPI) => {
    const queryString = (() => {
      if (step.queryParams != null) {
        if (typeof step.queryParams === "string") {
          return step.queryParams;
        }
        const queryParams = step.queryParams(thunkAPI.getState());
        if (queryParams != null) {
          return queryParams;
        }
      }
      return searchParams.toString();
    })();

    const stepPath = step.buildPath ? step.buildPath() : step.relativePath;
    const path = getAbsolutePath(flow.funnelPath, stepPath, queryString);
    navigate(path, {
      replace: replace,
    });
  }
);

const getAbsolutePath = (
  funnelPath: string,
  relativePath: string,
  queryParams?: string
): string => {
  // Ensure paths are properly joined and normalized
  const normalizedFunnelPath = funnelPath.endsWith("/")
    ? funnelPath.slice(0, -1)
    : funnelPath;
  const normalizedRelativePath = relativePath.startsWith("/")
    ? relativePath.slice(1)
    : relativePath;

  const path = `${normalizedFunnelPath}/${normalizedRelativePath}`;

  // If there are query parameters, add them to the path
  if (queryParams != null) {
    // Ensure the query parameters string starts with '?'
    const normalizedQueryParams = queryParams.startsWith("?")
      ? queryParams
      : `?${queryParams}`;
    return `${path}${normalizedQueryParams}`;
  }

  return path;
};

const useGetStepRoute = <T extends string>(step: T) => {
  const { flow } = useNavigationFlow<T>();
  const routeSegment = flow.steps[step].relativePath;

  const [searchParams] = useSearchParams();
  const fullRoute = getAbsolutePath(flow.funnelPath, routeSegment, "");

  const fullRouteWithCurrentQueryParams = getAbsolutePath(
    flow.funnelPath,
    routeSegment,
    searchParams.toString()
  );

  return { routeSegment, fullRoute, fullRouteWithCurrentQueryParams };
};

export const NavigationFlow = {
  useGoToNextStep,
  useGoToStep,
  useGetStepRoute,
};
