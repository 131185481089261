import { TenantFlightSteps } from "@tenant/types";
import { FlowProvider, useNavigationFlow } from "../../common";

export enum CoreFlightSteps {
  SEARCH = "SEARCH",
  DEPARTURE = "DEPARTURE",
  RETURN = "RETURN",
  REVIEW = "REVIEW",
  REVIEW_FARE = "REVIEW_FARE",
  BOOK = "BOOK",
}

export const FlightSteps = {
  ...CoreFlightSteps,
  ...TenantFlightSteps,
} as const;
export type FlightSteps = (typeof FlightSteps)[keyof typeof FlightSteps];

export const FlightNavigationFlowProvider = ({ children, navigationFlow }) => {
  return (
    <FlowProvider<FlightSteps> flow={navigationFlow}>{children}</FlowProvider>
  );
};

export const useFlightNavigationFlow = () => {
  const context = useNavigationFlow();
  if (!context) {
    throw new Error("useFlow must be used within a FlightNavigationFlow");
  }
  return context;
};
