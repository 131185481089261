import { Suggestion } from "@b2bportal/air-shopping-api";
import { IIdFlight, ITripTerminus } from "@b2bportal/core-types";

export const suggestionToITripTerminus = (
  suggestion?: Suggestion
): ITripTerminus | undefined =>
  suggestion
    ? {
        id: suggestion.id as IIdFlight,
        label: suggestion.label,
        trackingProperties: suggestion.trackingPropertiesV2,
      }
    : undefined;
export const terminusToSuggestion = (
  term?: ITripTerminus
): Suggestion | undefined =>
  term
    ? {
        trackingPropertiesV2: term.trackingProperties,
        label: term.label,
        id: term.id,
      }
    : undefined;
