import { Brand } from "@b2bportal/lloyds-auth-api";
import BankOfScotlandLogo from "../assets/logo/bank_of_scotland_logo_light.png";
import HalifaxLogo from "../assets/logo/halifax_logo_light.png";
import { ReactComponent as LloydsLogo } from "../assets/logo/lloyds-header-logo.svg";
import MBNALogo from "../assets/logo/mbna_logo_light.png";

const logoMap = {
  [Brand.Lloyds]: LloydsLogo,
  [Brand.Halifax]: HalifaxLogo,
  [Brand.BankOfScotland]: BankOfScotlandLogo,
  [Brand.MBNA]: MBNALogo,
};

export type LogoProps = {
  id: string;
  brand: Brand;
};

export const Logo = ({ id, brand = Brand.Lloyds }: LogoProps) => {
  const Logo = logoMap[brand];

  return typeof Logo === "string" ? (
    <img data-testid={id} src={logoMap[brand] as string} alt="" />
  ) : (
    <Logo width={180} height={45} />
  );
};
