import clsx from "clsx";
import "./RadioInput.scss";
import { useI18nContext } from "@hopper-b2b/i18n";

export const RadioInput = ({
  checked,
  onClick,
  variant = "outlined",
  disabled,
  className,
}: {
  checked: boolean;
  onClick?: () => void;
  variant?: "filled" | "outlined";
  disabled?: boolean;
  className?: string;
}) => {
  const { t } = useI18nContext();

  return (
    <input
      className={clsx(className, "radio", variant, { disabled: disabled })}
      checked={checked}
      onClick={onClick}
      readOnly
      type="radio"
      aria-label={t("radioInput")}
    />
  );
};
