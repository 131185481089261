import { type AmenityStatus, Amenity } from "@b2bportal/lodging-api";

export type PopularAmenity = Omit<AmenityStatus, "displayName"> & {
  // identifier used to retrieve the display name from the i18n file
  key: string;
  // Hotels with at least one of these amenities will display this popular amenity
  children: Amenity[] | Amenity[];
  // the order in which the popular amenities will be displayed
  priority: number;
};

const HotelPopularAmenity: Record<string, PopularAmenity> = {
  Wifi: {
    key: "wifi",
    amenity: Amenity.FreeWifi,
    children: [Amenity.FreeWifi],
    priority: 1,
  },
  AirportShuttle: {
    key: "airportShuttle",
    amenity: Amenity.AirportTransportation,
    children: [
      Amenity.AirportTransportation,
      Amenity.FreeAirportTransportation,
      Amenity.AirportTransportationAvailableGeneral,
    ],
    priority: 2,
  },
  Parking: {
    key: "parking",
    amenity: Amenity.FreeSelfParking,
    children: [
      Amenity.FreeSelfParking,
      Amenity.PaidSelfParking,
      Amenity.PaidValetParking,
      Amenity.ParkingAvailableGeneral,
    ],
    priority: 3,
  },
  BreakfastAvailable: {
    key: "breakfastAvailable",
    amenity: Amenity.PaidBreakfast,
    children: [
      Amenity.BreakfastIncluded,
      Amenity.FreeBreakfast,
      Amenity.PaidBreakfast,
    ],
    priority: 5,
  },
  Restaurant: {
    key: "restaurant",
    amenity: Amenity.Restaurant,
    children: [Amenity.Restaurant, Amenity.NumberOfRestaurants],
    priority: 6,
  },
  Bar: {
    key: "bar",
    amenity: Amenity.Bar,
    children: [
      Amenity.Bar,
      Amenity.BarOrLounge,
      Amenity.PoolsideBar,
      Amenity.NumberOfBarsOrLounges,
    ],
    priority: 7,
  },
  Pool: {
    key: "pool",
    amenity: Amenity.Pool,
    children: [
      Amenity.Pool,
      Amenity.OutdoorPool,
      Amenity.IndoorPool,
      Amenity.SwimmingPool,
      Amenity.OutdoorSeasonalPool,
      Amenity.PoolGeneral,
    ],
    priority: 8,
  },
  Gym: {
    key: "gym",
    amenity: Amenity.FitnessFacilities,
    children: [
      Amenity.FitnessFacilities,
      Amenity.TwentyFourHourFitnessFacilities,
    ],
    priority: 9,
  },
  TwentyFourHourFrontDesk: {
    key: "twentyFourHourFrontDesk",
    amenity: Amenity.TwentyFourHourFrontDesk,
    children: [Amenity.TwentyFourHourFrontDesk],
    priority: 10,
  },
  Laundry: {
    key: "laundry",
    amenity: Amenity.LaundryFacilities,
    children: [Amenity.LaundryFacilities, Amenity.DryCleaningLaundryService],
    priority: 11,
  },
  Spa: {
    key: "spa",
    amenity: Amenity.FullServiceSpa,
    children: [
      Amenity.FullServiceSpa,
      Amenity.SpaTreatmentRoom,
      Amenity.SteamRoom,
    ],
    priority: 12,
  },
  BusinessServices: {
    key: "businessServices",
    amenity: Amenity.BusinessCenter,
    children: [
      Amenity.BusinessCenter,
      Amenity.ConferenceSpace,
      Amenity.MeetingRooms,
    ],
    priority: 13,
  },
  Accessibility: {
    key: "accessibility",
    amenity: Amenity.Accessibility,
    children: [
      Amenity.Accessibility,
      Amenity.InRoomAccessibility,
      Amenity.AccessibleBathroom,
      Amenity.RollInShower,
      Amenity.BrailleOrRaisedSignage,
      Amenity.WheelchairAccessibleParking,
      Amenity.WheelchairAccessiblePathOfTravel,
      Amenity.SomeAccessibilityFeaturesAvailableGeneral,
    ],
    priority: 14,
  },
  PetFriendly: {
    key: "petFriendly",
    amenity: Amenity.PetFriendly,
    children: [
      Amenity.PetFriendly,
      Amenity.PetsAllowed,
      Amenity.PetsAllowedNoFees,
      Amenity.PetsOnlyDogsAndCats,
      Amenity.PetsOnlyDogs,
      Amenity.PetsOnlyCats,
    ],
    priority: 15,
  },
  AdultsOnly: {
    key: "adultsOnly",
    amenity: Amenity.AdultsOnly,
    children: [Amenity.AdultsOnly],
    priority: 16,
  },
};

const AllPopularHotelAmenities: PopularAmenity[] = Object.values(
  HotelPopularAmenity
).sort((a, b) => a.priority - b.priority);

const getPopularAmenity = (amenity: Amenity): PopularAmenity =>
  AllPopularHotelAmenities.find((popularAmenity: PopularAmenity) =>
    popularAmenity.children.includes(amenity)
  );

const getPriorityForAmenity = (amenity: AmenityStatus) =>
  (HotelPopularAmenity[amenity.amenity] ?? getPopularAmenity(amenity.amenity))
    ?.priority ?? Infinity;

export const getSortedAmenities = (
  amenities: AmenityStatus[]
): AmenityStatus[] =>
  amenities
    .map((amenity) => {
      return {
        ...amenity,
        priority: getPriorityForAmenity(amenity),
      };
    })
    .filter((a) => a.priority !== -1)
    .sort((a, b) => a.priority - b.priority);

export const getSortedPopularAmenities = (
  amenities: AmenityStatus[]
): PopularAmenity[] =>
  getSortedAmenities(amenities).reduce(
    (list: PopularAmenity[], amenity: AmenityStatus) => {
      const popularAmenity = getPopularAmenity(amenity.amenity);
      if (
        popularAmenity &&
        list.findIndex(
          (amenity: PopularAmenity) => amenity.key === popularAmenity.key
        ) === -1
      ) {
        list.push(popularAmenity);
      }
      return list;
    },
    []
  );
