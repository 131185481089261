import { useI18nContext } from "@hopper-b2b/i18n";
import { FloatingBox } from "@hopper-b2b/ui";
import { ActionButton } from "@lloyds/ui-core";

export interface FrequentFlyerModalButtonsProps {
  focusedTrackingId: number | null;
  handleSaveFlyer: () => void;
  handleRemoveFlyer: (id: number) => void;
  isSaveButtonDisabled: boolean;
}

export const FrequentFlyerModalButtons = ({
  focusedTrackingId,
  handleSaveFlyer,
  handleRemoveFlyer,
  isSaveButtonDisabled,
}: FrequentFlyerModalButtonsProps) => {
  const { t } = useI18nContext();

  return (
    <FloatingBox>
      {focusedTrackingId !== null && (
        <ActionButton
          className="frequent-flyer-remove-button"
          disabled={false}
          onClick={() => handleRemoveFlyer(focusedTrackingId)}
          message={t("remove")}
          defaultStyle="h4r-secondary"
        />
      )}
      <ActionButton
        message={t("saveFrequentFlyer")}
        onClick={handleSaveFlyer}
        disabled={isSaveButtonDisabled}
      />
    </FloatingBox>
  );
};
