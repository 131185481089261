import { useCallback } from "react";
import { FLIGHTS_SHOP_DEPARTURE_PATH } from "@b2bportal/core-utilities";
import { useNavigate, useLocation } from "react-router-dom-v5-compat";
import {
  buildFlightShopQuery,
  type BuildFlightShopQueryArgs,
} from "@b2bportal/core-flights";
import { useFlightSearch as useCoreFlightSearch } from "@b2bportal/core-flights";
import { saveRecentSearch } from "./useFlightRecentSearch";

export const useFlightSearch = () => {
  const { context, handlers } = useCoreFlightSearch();
  const navigate = useNavigate();
  const location = useLocation();

  const onSearch = useCallback(
    (params: BuildFlightShopQueryArgs) => {
      const shopQuery = buildFlightShopQuery(params);
      saveRecentSearch(params);
      navigate(
        `${FLIGHTS_SHOP_DEPARTURE_PATH}?${shopQuery}${location.search.replace(
          "?",
          "&"
        )}`
      );
    },
    [navigate, location]
  );

  const onRecentSearchClick = useCallback(
    (value: BuildFlightShopQueryArgs) => {
      onSearch(value);
    },
    [onSearch]
  );

  return {
    context,
    handlers: {
      ...handlers,
      onSearch,
      onRecentSearchClick,
    },
  };
};
