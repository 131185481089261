import { useI18nContext } from "@hopper-b2b/i18n";
import { IMonthBucket, TripCategory } from "@hopper-b2b/types";
import { DatePickerButton } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { useCallback, useEffect, useState } from "react";
import {
  CalendarPicker,
  CalendarTrackingProperties,
} from "./CalendarPicker/CalendarPicker";
import styles from "./CalendarPickerButton.module.scss";

export interface ICalendarPickerButtonProps {
  returnDate: Date | null;
  departureDate: Date | null;
  classes?: string[];
  onComplete?: () => void;
  disabled?: boolean;
  tripCategory: TripCategory;
  setDepartureDate: (value: Date | null) => void;
  setReturnDate: (value: Date | null) => void;
  trackingProperties?: CalendarTrackingProperties;
  startLabel?: string;
  endLabel?: string;
  months?: IMonthBucket[];
  prices?: string[];
  headerTitle: string;
  onCalendarClick?: () => void;
  paperClassName?: string;
  dateFormat?: string;
  openDatesModal?: boolean;
  onSetOpenCalendarModal?: (value: boolean) => void;
  minAllowedDate?: Date;
  maxAllowedDate?: Date;
  isDateUnavailable?: (date: Date, selectingReturnDate: boolean) => boolean;
  showResetDatesBtn?: boolean;
}

export const CalendarPickerButton = ({
  classes = [],
  returnDate,
  departureDate,
  tripCategory,
  disabled,
  onComplete,
  setDepartureDate,
  setReturnDate,
  trackingProperties,
  startLabel,
  endLabel,
  months,
  prices,
  headerTitle,
  onCalendarClick,
  paperClassName,
  dateFormat,
  openDatesModal,
  onSetOpenCalendarModal,
  minAllowedDate,
  maxAllowedDate,
  isDateUnavailable,
  showResetDatesBtn,
}: ICalendarPickerButtonProps) => {
  const { t } = useI18nContext();

  const { matchesMobile } = useDeviceTypes();

  const [openCalendar, setOpenCalendar] = useState(false);

  // effect to sync state from redux, with local state
  useEffect(() => {
    if (openDatesModal) {
      handleCalendarClick();
    }
  }, [openDatesModal]);

  const handleCalendarClick = useCallback(() => {
    onCalendarClick?.();
    setOpenCalendar(!openCalendar);
  }, [onCalendarClick, openCalendar, setOpenCalendar]);

  const closeCalendar = useCallback(() => {
    setOpenCalendar(false);
    if (onSetOpenCalendarModal) {
      onSetOpenCalendarModal(false);
    }
  }, []);

  const handleComplete = useCallback(() => {
    onComplete?.();
    closeCalendar();
  }, [closeCalendar, onComplete]);

  return (
    <DatePickerButton
      classes={[styles.button, ...classes]}
      startDate={departureDate}
      startLabel={startLabel || t?.("departure")}
      endDate={returnDate}
      endLabel={endLabel || t?.("return")}
      disabled={disabled}
      onClick={handleCalendarClick}
      dateFormat={dateFormat ?? "MMM D"}
      hideEndDate={tripCategory === TripCategory.ONE_WAY}
      renderCalendarPopup={(calendarProps) => (
        <CalendarPicker
          {...calendarProps}
          prices={prices}
          paperClassName={paperClassName}
          onComplete={handleComplete}
          open={openCalendar}
          departureDate={departureDate}
          returnDate={returnDate}
          setDepartureDate={setDepartureDate}
          setReturnDate={setReturnDate}
          tripCategory={tripCategory}
          closePopup={closeCalendar}
          disabled={disabled}
          trackingProperties={trackingProperties}
          isMobile={matchesMobile}
          months={months}
          headerTitle={headerTitle}
          startDateLabel={startLabel}
          endDateLabel={endLabel}
          minAllowedDate={minAllowedDate}
          maxAllowedDate={maxAllowedDate}
          isDateUnavailable={isDateUnavailable}
          showResetDatesBtn={showResetDatesBtn}
        />
      )}
    />
  );
};
