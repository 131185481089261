import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import {
  IPriceLineItem,
  ISummaryLineItem,
  PlatformComponentProps,
  CoreUiComponents,
  IFintechLineItem,
} from "@b2bportal/core-types";
import { Loading } from "@components/ui";
import clsx from "clsx";
import defaultStyles from "./styles.module.scss";

export interface PriceBreakdownComponentProps extends PlatformComponentProps {
  fintechLineItems?: IFintechLineItem[];
  priceLineItems: IPriceLineItem[];
  summaryLineItems: ISummaryLineItem[];
  currencyString?: string;
  isLoading?: boolean;
  title?: string;
}

const COMPONENT_KEY = CoreUiComponents.PriceBreakdown;

export const PriceLineItem = ({
  lineTitle,
  baseAmount,
  taxesAndFees,
  icon,
  products,
  className,
}: IPriceLineItem) => {
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [_, cn] = useModuleBEM(styles, COMPONENT_KEY);
  return (
    <div className={clsx(cn("line-item"), className)}>
      {lineTitle && (
        <div className={cn("line-item-title")}>
          {icon && <div>{icon}</div>}
          {lineTitle}
        </div>
      )}
      <div className={cn("line-item-detail", { baseAmount: true })}>
        <div className={cn("line-item-label")}>{baseAmount.label}</div>
        <div className={cn("line-item-value")}>{baseAmount.value}</div>
      </div>
      <div className={cn("line-item-detail", { taxes: true })}>
        <div className={cn("line-item-label")}>{taxesAndFees.label}</div>
        <div className={cn("line-item-value")}>{taxesAndFees.value}</div>
      </div>
      {products?.map((product, index) => (
        <div
          key={index}
          className={cn("line-item-detail", { [product.type]: true })}
        >
          <div className={cn("line-item-label")}>
            {product.icon && <div>{product.icon}</div>}
            {product.label}
          </div>
          <div className={cn("line-item-value")}>{product.value}</div>
        </div>
      ))}
    </div>
  );
};

export const SummaryLineItem = ({
  label,
  value,
  type,
  className,
}: ISummaryLineItem) => {
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [_, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <div className={clsx(cn("summary-item", { [type]: true }), className)}>
      <div className={cn("summary-item-label")}>{label}</div>
      <div className={cn("summary-item-value")}>{value}</div>
    </div>
  );
};

export const PriceBreakdown = ({
  priceLineItems,
  summaryLineItems,
  className,
  currencyString,
  isLoading = false,
  children,
  title,
}: PriceBreakdownComponentProps) => {
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <div className={clsx(block, className)}>
      {title ? <div className={cn("title")}>{title}</div> : null}
      {priceLineItems.map((priceLine, index) => (
        <PriceLineItem key={index} {...priceLine} />
      ))}
      {summaryLineItems.map((summaryLine, index) => (
        <SummaryLineItem key={index} {...summaryLine} />
      ))}
      {currencyString ? (
        <div className={cn("description")}>{currencyString}</div>
      ) : null}
      {isLoading ? (
        <div className={cn("loading")}>
          <Loading />
        </div>
      ) : null}
      {children}
    </div>
  );
};
