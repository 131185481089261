import { Fare, FareSliceOutbound, Flights } from "@b2bportal/air-shopping-api";
import { IFlightListData } from "@b2bportal/core-types";
import dayjs from "dayjs";

// Sort by Stops first and then sort by departure time within stops grouping
export const orderByStopsThenDeparture = <T extends Fare | FareSliceOutbound>(
  filteredFlights: IFlightListData<T>[],
  flights: Flights
) => {
  return filteredFlights.sort((a, b) => {
    if (flights.slices[a.sliceId].stops === flights.slices[b.sliceId].stops) {
      return dayjs(flights.slices[a.sliceId].departure).diff(
        dayjs(flights.slices[b.sliceId].departure)
      );
    } else {
      return flights.slices[a.sliceId].stops - flights.slices[b.sliceId].stops;
    }
  });
};

export const orderByRecommendedV2 = (
  filteredFlights: IFlightListData<Fare | FareSliceOutbound>[],
  flights: Flights
) => {
  return filteredFlights.sort(
    (a, b) =>
      flights.slices[b.sliceId].highestScore.value -
      flights.slices[a.sliceId].highestScore.value
  );
};

export const orderByRecommendedV3 = <T extends Fare | FareSliceOutbound>(
  filteredFlights: IFlightListData<T>[],
  flights: Flights,
  isReturn: boolean
) => {
  const fareSlicesWithTags = Object.keys({ ...flights?.fareSlices })
    .filter(
      (key) =>
        flights.fareSlices[key].tags?.isBest ||
        flights.fareSlices[key].tags?.isBestQuality ||
        flights.fareSlices[key].tags?.isCheapest ||
        flights.fareSlices[key].tags?.isFastest
    )
    .reduce((res, fare) => {
      const slice = flights.fareSlices[fare].slice;
      res[slice] = flights.fareSlices[fare];
      return res;
    }, {});

  return filteredFlights.sort((a, b) => {
    const aFlightHasTags = flights.slices[a.sliceId].id in fareSlicesWithTags;
    const bFlightHasTags = flights.slices[b.sliceId].id in fareSlicesWithTags;
    if (aFlightHasTags && bFlightHasTags) {
      return (
        (flights.slices[b.sliceId].lowestPrice ?? Infinity) -
        (flights.slices[a.sliceId].lowestPrice ?? Infinity)
      );
    } else if (aFlightHasTags) {
      return -1;
    } else if (bFlightHasTags) {
      return 1;
    } else {
      return (
        flights.slices[b.sliceId].highestScore.value -
        flights.slices[a.sliceId].highestScore.value
      );
    }
  });
};

export const orderByPriceV2 = <T extends Fare | FareSliceOutbound>(
  filteredFlights: IFlightListData<T>[]
) => {
  return filteredFlights.sort(
    (a, b) =>
      Math.min(...a.fares.map((f: any) => f.amount.fiat.value)) -
      Math.min(...b.fares.map((f: any) => f.amount.fiat.value))
  );
};

export const orderByDepartureTimeV2 = <T extends Fare | FareSliceOutbound>(
  filteredFlights: IFlightListData<T>[],
  flights: Flights
) => {
  return filteredFlights.sort((a, b) =>
    dayjs(flights.slices[a.sliceId].departure).diff(
      dayjs(flights.slices[b.sliceId].departure)
    )
  );
};

export const orderByArrivalTimeV2 = <T extends Fare | FareSliceOutbound>(
  filteredFlights: IFlightListData<T>[],
  flights: Flights
) => {
  return filteredFlights.sort((a, b) =>
    dayjs(flights.slices[a.sliceId].arrival).diff(
      dayjs(flights.slices[b.sliceId].arrival)
    )
  );
};

export const orderByStopsV2 = <T extends Fare | FareSliceOutbound>(
  filteredFlights: IFlightListData<T>[],
  flights: Flights
) => {
  return filteredFlights.sort(
    (a, b) => flights.slices[a.sliceId].stops - flights.slices[b.sliceId].stops
  );
};

export const orderByDurationV2 = <T extends Fare | FareSliceOutbound>(
  filteredFlights: IFlightListData<T>[],
  flights: Flights
) => {
  return filteredFlights.sort((a, b) => {
    const aMin = dayjs(flights.slices[a.sliceId].arrival).diff(
      dayjs(flights.slices[a.sliceId].departure),
      "minute",
      true
    );
    const bMin = dayjs(flights.slices[b.sliceId].arrival).diff(
      dayjs(flights.slices[b.sliceId].departure),
      "minute",
      true
    );

    return aMin - bMin;
  });
};

// Flight Shop V3
