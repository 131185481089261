import { useI18nContext } from "@hopper-b2b/i18n";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { BackButton, HeaderUI, MobileHeaderTitle } from "@lloyds/ui-connected";
import { useMemo } from "react";
import { LLOYDS_URL_PARAMS } from "@lloyds/utils";
import { useSearchParams } from "react-router-dom-v5-compat";

export const MyTripHeader = () => {
  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();
  const [searchParams] = useSearchParams();

  const isBackable = useMemo(() => {
    const showBackButton =
      searchParams.get(LLOYDS_URL_PARAMS.SHOW_BACK_BUTTON) === "true";
    if (showBackButton) {
      return true;
    }
    return false;
  }, [searchParams]);

  return (
    <HeaderUI
      leftItem={isBackable && <BackButton />}
      centerItem={
        matchesMobile ? (
          <MobileHeaderTitle title={t("mytrips.headerTitle")} />
        ) : null
      }
    />
  );
};
