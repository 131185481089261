import type { Brand } from "@b2bportal/lloyds-auth-api";

import AirplaneArriveLloyds from "./lloyds/airplane-arrive.svg";
import AirplaneArriveHalifax from "./halifax/airplane-arrive.svg";
import AirplaneArriveBankOfScotland from "./bankofscotland/airplane-arrive.svg";
import AirplaneArriveMBNA from "./mbna/airplane-arrive.svg";

import AirplaneDepartLloyds from "./lloyds/airplane-depart.svg";
import AirplaneDepartHalifax from "./halifax/airplane-depart.svg";
import AirplaneDepartBankOfScotland from "./bankofscotland/airplane-depart.svg";
import AirplaneDepartMBNA from "./mbna/airplane-depart.svg";

import AirplaneFintechLloyds from "./lloyds/airplane-fintech.svg";
import AirplaneFintechHalifax from "./halifax/airplane-fintech.svg";
import AirplaneFintechBankOfScotland from "./bankofscotland/airplane-fintech.svg";
import AirplaneFintechMBNA from "./mbna/airplane-fintech.svg";

import AirplaneLloyds from "./lloyds/airplane.svg";
import AirplaneHalifax from "./halifax/airplane.svg";
import AirplaneBankOfScotland from "./bankofscotland/airplane.svg";
import AirplaneMBNA from "./mbna/airplane.svg";

import AlertLloyds from "./lloyds/alert.svg";
import AlertHalifax from "./halifax/alert.svg";
import AlertBankOfScotland from "./bankofscotland/alert.svg";
import AlertMBNA from "./mbna/alert.svg";

import AvatarLloyds from "./lloyds/avatar.svg";
import AvatarHalifax from "./halifax/avatar.svg";
import AvatarBankOfScotland from "./bankofscotland/avatar.svg";
import AvatarMBNA from "./mbna/avatar.svg";

import BedLloyds from "./lloyds/bed.svg";
import BedHalifax from "./halifax/bed.svg";
import BedBankOfScotland from "./bankofscotland/bed.svg";
import BedMBNA from "./mbna/bed.svg";

import BellLloyds from "./lloyds/bell.svg";
import BellHalifax from "./halifax/bell.svg";
import BellBankOfScotland from "./bankofscotland/bell.svg";
import BellMBNA from "./mbna/bell.svg";

import BlackLockLloyds from "./lloyds/black-lock.svg";
import BlackLockHalifax from "./halifax/black-lock.svg";
import BlackLockBankOfScotland from "./bankofscotland/black-lock.svg";
import BlackLockMBNA from "./mbna/black-lock.svg";

import BriefcaseLloyds from "./lloyds/briefcase.svg";
import BriefcaseHalifax from "./halifax/briefcase.svg";
import BriefcaseBankOfScotland from "./bankofscotland/briefcase.svg";
import BriefcaseMBNA from "./mbna/briefcase.svg";

import BunnySuitcaseLloyds from "./lloyds/bunny-suitcase.svg";
import BunnySuitcaseHalifax from "./halifax/bunny-suitcase.svg";
import BunnySuitcaseBankOfScotland from "./bankofscotland/bunny-suitcase.svg";
import BunnySuitcaseMBNA from "./mbna/bunny-suitcase.svg";

import CalendarLloyds from "./lloyds/calendar.svg";
import CalendarHalifax from "./halifax/calendar.svg";
import CalendarBankOfScotland from "./bankofscotland/calendar.svg";
import CalendarMBNA from "./mbna/calendar.svg";

import CalendarEndLloyds from "./lloyds/calendar.svg";
import CalendarEndHalifax from "./halifax/calendar-end.svg";
import CalendarEndBankOfScotland from "./bankofscotland/calendar-end.svg";
import CalendarEndMBNA from "./mbna/calendar-end.svg";

import CalendarStartLloyds from "./lloyds/calendar.svg";
import CalendarStartHalifax from "./halifax/calendar-start.svg";
import CalendarStartBankOfScotland from "./bankofscotland/calendar-start.svg";
import CalendarStartMBNA from "./mbna/calendar-start.svg";

import CardLloyds from "./lloyds/card.svg";
import CardHalifax from "./halifax/card.svg";
import CardBankOfScotland from "./bankofscotland/card.svg";
import CardMBNA from "./mbna/card.svg";

import CarrotCoinLloyds from "./lloyds/carrot-coin.svg";
import CarrotCoinHalifax from "./halifax/carrot-coin.svg";
import CarrotCoinBankOfScotland from "./bankofscotland/carrot-coin.svg";
import CarrotCoinMBNA from "./mbna/carrot-coin.svg";

import CfarLloyds from "./lloyds/cfar.svg";
import CfarHalifax from "./halifax/cfar.svg";
import CfarBankOfScotland from "./bankofscotland/cfar.svg";
import CfarMBNA from "./mbna/cfar.svg";

import CfarWhiteLloyds from "./lloyds/cfar-white.svg";
import CfarWhiteHalifax from "./lloyds/cfar-white.svg";
import CfarWhiteBankOfScotland from "./lloyds/cfar-white.svg";
import CfarWhiteMBNA from "./lloyds/cfar-white.svg";

import CheckLloyds from "./lloyds/check.svg";
import CheckHalifax from "./halifax/check.svg";
import CheckBankOfScotland from "./bankofscotland/check.svg";
import CheckMBNA from "./mbna/check.svg";

import CheckmarkFilledLloyds from "./lloyds/checkmark-filled.svg";
import CheckmarkFilledHalifax from "./halifax/checkmark-filled.svg";
import CheckmarkFilledBankOfScotland from "./bankofscotland/checkmark-filled.svg";
import CheckmarkFilledMBNA from "./mbna/checkmark-filled.svg";

import CheckmarkSimpleLloyds from "./lloyds/checkmark-simple.svg";
import CheckmarkSimpleHalifax from "./halifax/checkmark-simple.svg";
import CheckmarkSimpleBankOfScotland from "./bankofscotland/checkmark-simple.svg";
import CheckmarkSimpleMBNA from "./mbna/checkmark-simple.svg";

import CheckmarkLloyds from "./lloyds/checkmark.svg";
import CheckmarkHalifax from "./halifax/checkmark.svg";
import CheckmarkBankOfScotland from "./bankofscotland/checkmark.svg";
import CheckmarkMBNA from "./mbna/checkmark.svg";

import ChevronLeftLloyds from "./lloyds/chevron-left.svg";
import ChevronLeftHalifax from "./halifax/chevron-left.svg";
import ChevronLeftBankOfScotland from "./bankofscotland/chevron-left.svg";
import ChevronLeftMBNA from "./mbna/chevron-left.svg";

import ChevronLloyds from "./lloyds/chevron.svg";
import ChevronHalifax from "./halifax/chevron.svg";
import ChevronBankOfScotland from "./bankofscotland/chevron.svg";
import ChevronMBNA from "./mbna/chevron.svg";

import ChevronDownLloyds from "./lloyds/chevron-down.svg";
import ChevronDownHalifax from "./halifax/chevron-down.svg";
import ChevronDownBankOfScotland from "./bankofscotland/chevron-down.svg";
import ChevronDownMBNA from "./mbna/chevron-down.svg";

import CircleQuestionMarkLloyds from "./lloyds/circle-question-mark.svg";
import CircleQuestionMarkHalifax from "./halifax/circle-question-mark.svg";
import CircleQuestionMarkBankOfScotland from "./bankofscotland/circle-question-mark.svg";
import CircleQuestionMarkMBNA from "./mbna/circle-question-mark.svg";

import CircleSlashLloyds from "./lloyds/circle-slash.svg";
import CircleSlashHalifax from "./halifax/circle-slash.svg";
import CircleSlashBankOfScotland from "./bankofscotland/circle-slash.svg";
import CircleSlashMBNA from "./mbna/circle-slash.svg";

import ClockLloyds from "./lloyds/clock.svg";
import ClockHalifax from "./halifax/clock.svg";
import ClockBankOfScotland from "./bankofscotland/clock.svg";
import ClockMBNA from "./mbna/clock.svg";

import CloseLloyds from "./lloyds/close.svg";
import CloseHalifax from "./halifax/close.svg";
import CloseBankOfScotland from "./bankofscotland/close.svg";
import CloseMBNA from "./mbna/close.svg";

import CopyLloyds from "./lloyds/copy.svg";
import CopyHalifax from "./halifax/copy.svg";
import CopyBankOfScotland from "./bankofscotland/copy.svg";
import CopyMBNA from "./mbna/copy.svg";

import ErrorLloyds from "./lloyds/error.svg";
import ErrorHalifax from "./halifax/error.svg";
import ErrorBankOfScotland from "./bankofscotland/error.svg";
import ErrorMBNA from "./mbna/error.svg";

import FilterLloyds from "./lloyds/filter.svg";
import FilterHalifax from "./halifax/filter.svg";
import FilterBankOfScotland from "./bankofscotland/filter.svg";
import FilterMBNA from "./mbna/filter.svg";

import FlightDelayLloyds from "./lloyds/flight-delay.svg";
import FlightDelayHalifax from "./halifax/flight-delay.svg";
import FlightDelayBankOfScotland from "./bankofscotland/flight-delay.svg";
import FlightDelayMBNA from "./mbna/flight-delay.svg";

import FlightsLloyds from "./lloyds/flights.svg";
import FlightsHalifax from "./halifax/flights.svg";
import FlightsBankOfScotland from "./bankofscotland/flights.svg";
import FlightsMBNA from "./mbna/flights.svg";

import GrayGuestLloyds from "./lloyds/gray-guest.svg";
import GrayGuestHalifax from "./halifax/gray-guest.svg";
import GrayGuestBankOfScotland from "./bankofscotland/gray-guest.svg";
import GrayGuestMBNA from "./mbna/gray-guest.svg";

import GraySheildXLloyds from "./lloyds/gray-sheild-x.svg";
import GraySheildXHalifax from "./halifax/gray-sheild-x.svg";
import GraySheildXBankOfScotland from "./bankofscotland/gray-sheild-x.svg";
import GraySheildXMBNA from "./mbna/gray-sheild-x.svg";

import GreenBoltLloyds from "./lloyds/green-bolt.svg";
import GreenBoltHalifax from "./halifax/green-bolt.svg";
import GreenBoltBankOfScotland from "./bankofscotland/green-bolt.svg";
import GreenBoltMBNA from "./mbna/green-bolt.svg";

import GreenShieldCheckLloyds from "./lloyds/green-shield-check.svg";
import GreenShieldCheckHalifax from "./halifax/green-shield-check.svg";
import GreenShieldCheckBankOfScotland from "./bankofscotland/green-shield-check.svg";
import GreenShieldCheckMBNA from "./mbna/green-shield-check.svg";

import GuestLloyds from "./lloyds/guest.svg";
import GuestHalifax from "./halifax/guest.svg";
import GuestBankOfScotland from "./bankofscotland/guest.svg";
import GuestMBNA from "./mbna/guest.svg";

import HeadsetLloyds from "./lloyds/headset.svg";
import HeadsetHalifax from "./halifax/headset.svg";
import HeadsetBankOfScotland from "./bankofscotland/headset.svg";
import HeadsetMBNA from "./mbna/headset.svg";

import HopperBunnyHappyLloyds from "./lloyds/hopper-bunny-happy.svg";
import HopperBunnyHappyHalifax from "./halifax/hopper-bunny-happy.svg";
import HopperBunnyHappyBankOfScotland from "./bankofscotland/hopper-bunny-happy.svg";
import HopperBunnyHappyMBNA from "./mbna/hopper-bunny-happy.svg";

import HotelsLloyds from "./lloyds/hotels.svg";
import HotelsHalifax from "./halifax/hotels.svg";
import HotelsBankOfScotland from "./bankofscotland/hotels.svg";
import HotelsMBNA from "./mbna/hotels.svg";

import InfoQuestionLloyds from "./lloyds/info-question.svg";
import InfoQuestionHalifax from "./halifax/info-question.svg";
import InfoQuestionBankOfScotland from "./bankofscotland/info-question.svg";
import InfoQuestionMBNA from "./mbna/info-question.svg";

import InfoWhiteLloyds from "./lloyds/info-white.svg";
import InfoWhiteHalifax from "./halifax/info-white.svg";
import InfoWhiteBankOfScotland from "./bankofscotland/info-white.svg";
import InfoWhiteMBNA from "./mbna/info-white.svg";

import InfoLloyds from "./lloyds/info.svg";
import InfoHalifax from "./halifax/info.svg";
import InfoBankOfScotland from "./bankofscotland/info.svg";
import InfoMBNA from "./mbna/info.svg";

import LocationMarkerLloyds from "./lloyds/location-marker.svg";
import LocationMarkerHalifax from "./halifax/location-marker.svg";
import LocationMarkerBankOfScotland from "./bankofscotland/location-marker.svg";
import LocationMarkerMBNA from "./mbna/location-marker.svg";

import LockLloyds from "./lloyds/lock.svg";
import LockHalifax from "./halifax/lock.svg";
import LockBankOfScotland from "./bankofscotland/lock.svg";
import LockMBNA from "./mbna/lock.svg";

import LogoWhiteLloyds from "./lloyds/logo-white.svg";
import LogoWhiteHalifax from "./halifax/logo-white.svg";
import LogoWhiteBankOfScotland from "./bankofscotland/logo-white.svg";
import LogoWhiteMBNA from "./mbna/logo-white.svg";

import Logo_smallLloyds from "./lloyds/logo_small.svg";
import Logo_smallHalifax from "./halifax/logo_small.svg";
import Logo_smallBankOfScotland from "./bankofscotland/logo_small.svg";
import Logo_smallMBNA from "./mbna/logo_small.svg";

import MissedConnectionLloyds from "./lloyds/missed-connection.svg";
import MissedConnectionHalifax from "./halifax/missed-connection.svg";
import MissedConnectionBankOfScotland from "./bankofscotland/missed-connection.svg";
import MissedConnectionMBNA from "./mbna/missed-connection.svg";

import OnewayLloyds from "./lloyds/oneway.svg";
import OnewayHalifax from "./halifax/oneway.svg";
import OnewayBankOfScotland from "./bankofscotland/oneway.svg";
import OnewayMBNA from "./mbna/oneway.svg";

import PassengerLloyds from "./lloyds/passenger.svg";
import PassengerHalifax from "./halifax/passenger.svg";
import PassengerBankOfScotland from "./bankofscotland/passenger.svg";
import PassengerMBNA from "./mbna/passenger.svg";

import Plus_smallLloyds from "./lloyds/plus_small.svg";
import Plus_smallHalifax from "./halifax/plus_small.svg";
import Plus_smallBankOfScotland from "./bankofscotland/plus_small.svg";
import Plus_smallMBNA from "./mbna/plus_small.svg";

import PriceFreezeLloyds from "./lloyds/price-freeze.svg";
import PriceFreezeHalifax from "./halifax/price-freeze.svg";
import PriceFreezeBankOfScotland from "./bankofscotland/price-freeze.svg";
import PriceFreezeMBNA from "./mbna/price-freeze.svg";

import RadioSelectedLloyds from "./lloyds/radio-selected.svg";
import RadioSelectedHalifax from "./halifax/radio-selected.svg";
import RadioSelectedBankOfScotland from "./bankofscotland/radio-selected.svg";
import RadioSelectedMBNA from "./mbna/radio-selected.svg";

import RadioLloyds from "./lloyds/radio.svg";
import RadioHalifax from "./halifax/radio.svg";
import RadioBankOfScotland from "./bankofscotland/radio.svg";
import RadioMBNA from "./mbna/radio.svg";

import ReceiptLloyds from "./lloyds/receipt.svg";
import ReceiptHalifax from "./halifax/receipt.svg";
import ReceiptBankOfScotland from "./bankofscotland/receipt.svg";
import ReceiptMBNA from "./mbna/receipt.svg";

import RoundtripLloyds from "./lloyds/roundtrip.svg";
import RoundtripHalifax from "./halifax/roundtrip.svg";
import RoundtripBankOfScotland from "./bankofscotland/roundtrip.svg";
import RoundtripMBNA from "./mbna/roundtrip.svg";

import ShieldLloyds from "./lloyds/shield.svg";
import ShieldHalifax from "./halifax/shield.svg";
import ShieldBankOfScotland from "./bankofscotland/shield.svg";
import ShieldMBNA from "./mbna/shield.svg";

import StarLloyds from "./lloyds/star.svg";
import StarHalifax from "./halifax/star.svg";
import StarBankOfScotland from "./bankofscotland/star.svg";
import StarMBNA from "./mbna/star.svg";

import TicketLloyds from "./lloyds/ticket.svg";
import TicketHalifax from "./halifax/ticket.svg";
import TicketBankOfScotland from "./bankofscotland/ticket.svg";
import TicketMBNA from "./mbna/ticket.svg";

import WalletIconLloyds from "./lloyds/wallet-icon.svg";
import WalletIconHalifax from "./halifax/wallet-icon.svg";
import WalletIconBankOfScotland from "./bankofscotland/wallet-icon.svg";
import WalletIconMBNA from "./mbna/wallet-icon.svg";

import WalletLloyds from "./lloyds/wallet.svg";
import WalletHalifax from "./halifax/wallet.svg";
import WalletBankOfScotland from "./bankofscotland/wallet.svg";
import WalletMBNA from "./mbna/wallet.svg";

import WarningTriangleLloyds from "./lloyds/warning-triangle.svg";
import WarningTriangleHalifax from "./halifax/warning-triangle.svg";
import WarningTriangleBankOfScotland from "./bankofscotland/warning-triangle.svg";
import WarningTriangleMBNA from "./mbna/warning-triangle.svg";

import PurchaseLloyds from "./lloyds/purchase.svg";
import PurchaseHalifax from "./lloyds/purchase.svg";
import PurchaseBankOfScotland from "./lloyds/purchase.svg";
import PurchaseMBNA from "./lloyds/purchase.svg";

import UnavailableLloyds from "./lloyds/unavailable.svg";
import UnavailableHalifax from "./lloyds/unavailable.svg";
import UnavailableBankOfScotland from "./lloyds/unavailable.svg";
import UnavailableMBNA from "./lloyds/unavailable.svg";

import PriceDropProtectionLloyds from "./lloyds/price-drop-protection.svg";
import PriceDropProtectionHalifax from "./lloyds/price-drop-protection.svg";
import PriceDropProtectionBankOfScotland from "./lloyds/price-drop-protection.svg";
import PriceDropProtectionMBNA from "./lloyds/price-drop-protection.svg";

import WarningLloyds from "./lloyds/warning.svg";
import WarningHalifax from "./halifax/warning.svg";
import WarningBankOfScotland from "./bankofscotland/warning.svg";
import WarningMBNA from "./mbna/warning.svg";

import SwitchLloyds from "./lloyds/switch.svg";
import SwitchHalifax from "./lloyds/switch.svg";
import SwitchBankOfScotland from "./lloyds/switch.svg";
import SwitchMBNA from "./lloyds/switch.svg";

import SearchLloyds from "./lloyds/search.svg";
import SearchHalifax from "./lloyds/search.svg";
import SearchBankOfScotland from "./lloyds/search.svg";
import SearchMBNA from "./lloyds/search.svg";

import BackWhite from "./lloyds/arrow-back.svg";
import type { IIconSet } from "@hopper-b2b/utilities";

export { ReactComponent as ChevronLeft } from "./lloyds/chevron-left.svg";
export { ReactComponent as ChevronDown } from "./lloyds/chevron-down.svg";
export { ReactComponent as ChevronRight } from "./lloyds/chevron.svg";
export { ReactComponent as CheckFilled } from "./lloyds/checkmark-filled.svg";
export { ReactComponent as Unavailable } from "./lloyds/unavailable.svg";
export { ReactComponent as Purchase } from "./lloyds/purchase.svg";
export { ReactComponent as Filters } from "./lloyds/filter.svg";
export { ReactComponent as Close } from "./lloyds/close.svg";
export { ReactComponent as Info } from "./lloyds/info.svg";
export { ReactComponent as Airplane } from "./lloyds/airplane.svg";
export { ReactComponent as Calendar } from "./lloyds/calendar.svg";
export { ReactComponent as Plus } from "./lloyds/plus_small.svg";
export { ReactComponent as Check } from "./lloyds/check.svg";
export { ReactComponent as Warning } from "./lloyds/warning.svg";
export { ReactComponent as GenericDetail } from "./shared/flight-generic-fare-detail.svg";
export { ReactComponent as DisruptionProtection } from "./lloyds/green-shield-check.svg";
export { ReactComponent as DiagonalPlane } from "./shared/return.svg";
export { ReactComponent as Hotel } from "./shared/hotel.svg";
export { ReactComponent as Conversation } from "./shared/conversation.svg";
export { ReactComponent as CancelForAnyReason } from "./lloyds/money-circle-arrow.svg";
export { ReactComponent as Informational } from "./lloyds/informational.svg";
export { ReactComponent as InformationalBrand } from "./lloyds/informational-brand.svg";
export { ReactComponent as ClockFace } from "./lloyds/clock-face.svg";
export { ReactComponent as Dollar } from "./lloyds/dollar.svg";
export { ReactComponent as TenantLogo } from "./lloyds/tenantLogo.svg";
export { ReactComponent as BritishPound } from "./lloyds/british-pound.svg";
export { ReactComponent as CheckmarkSuccess } from "./lloyds/checkmark-success.svg";
export { ReactComponent as Traveller } from "./shared/traveller.svg";
export { ReactComponent as Credits } from "./lloyds/credits.svg";
export { ReactComponent as Coins } from "./lloyds/coins.svg";
export { ReactComponent as Flight } from "./lloyds/flights.svg";

type BrandIcon = { [key in Brand]: string };

export const Icons: { [key in keyof IIconSet]: BrandIcon } = {
  airplaneArrive: {
    Lloyds: AirplaneArriveLloyds,
    Halifax: AirplaneArriveHalifax,
    BankOfScotland: AirplaneArriveBankOfScotland,
    MBNA: AirplaneArriveMBNA,
  },
  airplaneDepart: {
    Lloyds: AirplaneDepartLloyds,
    Halifax: AirplaneDepartHalifax,
    BankOfScotland: AirplaneDepartBankOfScotland,
    MBNA: AirplaneDepartMBNA,
  },
  airplaneFintech: {
    Lloyds: AirplaneFintechLloyds,
    Halifax: AirplaneFintechHalifax,
    BankOfScotland: AirplaneFintechBankOfScotland,
    MBNA: AirplaneFintechMBNA,
  },
  airplane: {
    Lloyds: AirplaneLloyds,
    Halifax: AirplaneHalifax,
    BankOfScotland: AirplaneBankOfScotland,
    MBNA: AirplaneMBNA,
  },
  alert: {
    Lloyds: AlertLloyds,
    Halifax: AlertHalifax,
    BankOfScotland: AlertBankOfScotland,
    MBNA: AlertMBNA,
  },
  avatar: {
    Lloyds: AvatarLloyds,
    Halifax: AvatarHalifax,
    BankOfScotland: AvatarBankOfScotland,
    MBNA: AvatarMBNA,
  },
  backWhite: {
    Lloyds: BackWhite,
    Halifax: BackWhite,
    BankOfScotland: BackWhite,
    MBNA: BackWhite,
  },
  bed: {
    Lloyds: BedLloyds,
    Halifax: BedHalifax,
    BankOfScotland: BedBankOfScotland,
    MBNA: BedMBNA,
  },
  bell: {
    Lloyds: BellLloyds,
    Halifax: BellHalifax,
    BankOfScotland: BellBankOfScotland,
    MBNA: BellMBNA,
  },
  blackLock: {
    Lloyds: BlackLockLloyds,
    Halifax: BlackLockHalifax,
    BankOfScotland: BlackLockBankOfScotland,
    MBNA: BlackLockMBNA,
  },
  briefcase: {
    Lloyds: BriefcaseLloyds,
    Halifax: BriefcaseHalifax,
    BankOfScotland: BriefcaseBankOfScotland,
    MBNA: BriefcaseMBNA,
  },
  bunnyWithSuitcase: {
    Lloyds: BunnySuitcaseLloyds,
    Halifax: BunnySuitcaseHalifax,
    BankOfScotland: BunnySuitcaseBankOfScotland,
    MBNA: BunnySuitcaseMBNA,
  },
  calendar: {
    Lloyds: CalendarLloyds,
    Halifax: CalendarHalifax,
    BankOfScotland: CalendarBankOfScotland,
    MBNA: CalendarMBNA,
  },
  calendarEndIcon: {
    Lloyds: CalendarEndLloyds,
    Halifax: CalendarEndHalifax,
    BankOfScotland: CalendarEndBankOfScotland,
    MBNA: CalendarEndMBNA,
  },
  calendarStartIcon: {
    Lloyds: CalendarStartLloyds,
    Halifax: CalendarStartHalifax,
    BankOfScotland: CalendarStartBankOfScotland,
    MBNA: CalendarStartMBNA,
  },
  card: {
    Lloyds: CardLloyds,
    Halifax: CardHalifax,
    BankOfScotland: CardBankOfScotland,
    MBNA: CardMBNA,
  },
  carrotCoin: {
    Lloyds: CarrotCoinLloyds,
    Halifax: CarrotCoinHalifax,
    BankOfScotland: CarrotCoinBankOfScotland,
    MBNA: CarrotCoinMBNA,
  },
  cfar: {
    Lloyds: CfarLloyds,
    Halifax: CfarHalifax,
    BankOfScotland: CfarBankOfScotland,
    MBNA: CfarMBNA,
  },
  cfarWhite: {
    Lloyds: CfarWhiteLloyds,
    Halifax: CfarWhiteHalifax,
    BankOfScotland: CfarWhiteBankOfScotland,
    MBNA: CfarWhiteMBNA,
  },
  check: {
    Lloyds: CheckLloyds,
    Halifax: CheckHalifax,
    BankOfScotland: CheckBankOfScotland,
    MBNA: CheckMBNA,
  },
  checkmarkFilled: {
    Lloyds: CheckmarkFilledLloyds,
    Halifax: CheckmarkFilledHalifax,
    BankOfScotland: CheckmarkFilledBankOfScotland,
    MBNA: CheckmarkFilledMBNA,
  },
  checkmarkSimple: {
    Lloyds: CheckmarkSimpleLloyds,
    Halifax: CheckmarkSimpleHalifax,
    BankOfScotland: CheckmarkSimpleBankOfScotland,
    MBNA: CheckmarkSimpleMBNA,
  },
  checkmark: {
    Lloyds: CheckmarkLloyds,
    Halifax: CheckmarkHalifax,
    BankOfScotland: CheckmarkBankOfScotland,
    MBNA: CheckmarkMBNA,
  },
  chevronLeft: {
    Lloyds: ChevronLeftLloyds,
    Halifax: ChevronLeftHalifax,
    BankOfScotland: ChevronLeftBankOfScotland,
    MBNA: ChevronLeftMBNA,
  },
  chevron: {
    Lloyds: ChevronLloyds,
    Halifax: ChevronHalifax,
    BankOfScotland: ChevronBankOfScotland,
    MBNA: ChevronMBNA,
  },
  chevronDown: {
    Lloyds: ChevronDownLloyds,
    Halifax: ChevronDownHalifax,
    BankOfScotland: ChevronDownBankOfScotland,
    MBNA: ChevronDownMBNA,
  },
  circleQuestionMark: {
    Lloyds: CircleQuestionMarkLloyds,
    Halifax: CircleQuestionMarkHalifax,
    BankOfScotland: CircleQuestionMarkBankOfScotland,
    MBNA: CircleQuestionMarkMBNA,
  },
  circleSlash: {
    Lloyds: CircleSlashLloyds,
    Halifax: CircleSlashHalifax,
    BankOfScotland: CircleSlashBankOfScotland,
    MBNA: CircleSlashMBNA,
  },
  clock: {
    Lloyds: ClockLloyds,
    Halifax: ClockHalifax,
    BankOfScotland: ClockBankOfScotland,
    MBNA: ClockMBNA,
  },
  close: {
    Lloyds: CloseLloyds,
    Halifax: CloseHalifax,
    BankOfScotland: CloseBankOfScotland,
    MBNA: CloseMBNA,
  },
  copy: {
    Lloyds: CopyLloyds,
    Halifax: CopyHalifax,
    BankOfScotland: CopyBankOfScotland,
    MBNA: CopyMBNA,
  },
  error: {
    Lloyds: ErrorLloyds,
    Halifax: ErrorHalifax,
    BankOfScotland: ErrorBankOfScotland,
    MBNA: ErrorMBNA,
  },
  filter: {
    Lloyds: FilterLloyds,
    Halifax: FilterHalifax,
    BankOfScotland: FilterBankOfScotland,
    MBNA: FilterMBNA,
  },
  flightDelay: {
    Lloyds: FlightDelayLloyds,
    Halifax: FlightDelayHalifax,
    BankOfScotland: FlightDelayBankOfScotland,
    MBNA: FlightDelayMBNA,
  },
  flights: {
    Lloyds: FlightsLloyds,
    Halifax: FlightsHalifax,
    BankOfScotland: FlightsBankOfScotland,
    MBNA: FlightsMBNA,
  },
  grayGuest: {
    Lloyds: GrayGuestLloyds,
    Halifax: GrayGuestHalifax,
    BankOfScotland: GrayGuestBankOfScotland,
    MBNA: GrayGuestMBNA,
  },
  grayShieldX: {
    Lloyds: GraySheildXLloyds,
    Halifax: GraySheildXHalifax,
    BankOfScotland: GraySheildXBankOfScotland,
    MBNA: GraySheildXMBNA,
  },
  vipIcon: {
    Lloyds: GreenBoltLloyds,
    Halifax: GreenBoltHalifax,
    BankOfScotland: GreenBoltBankOfScotland,
    MBNA: GreenBoltMBNA,
  },
  greenShieldCheck: {
    Lloyds: GreenShieldCheckLloyds,
    Halifax: GreenShieldCheckHalifax,
    BankOfScotland: GreenShieldCheckBankOfScotland,
    MBNA: GreenShieldCheckMBNA,
  },
  guest: {
    Lloyds: GuestLloyds,
    Halifax: GuestHalifax,
    BankOfScotland: GuestBankOfScotland,
    MBNA: GuestMBNA,
  },
  headset: {
    Lloyds: HeadsetLloyds,
    Halifax: HeadsetHalifax,
    BankOfScotland: HeadsetBankOfScotland,
    MBNA: HeadsetMBNA,
  },
  hopperBunnyHappy: {
    Lloyds: HopperBunnyHappyLloyds,
    Halifax: HopperBunnyHappyHalifax,
    BankOfScotland: HopperBunnyHappyBankOfScotland,
    MBNA: HopperBunnyHappyMBNA,
  },
  hotels: {
    Lloyds: HotelsLloyds,
    Halifax: HotelsHalifax,
    BankOfScotland: HotelsBankOfScotland,
    MBNA: HotelsMBNA,
  },
  infoQuestion: {
    Lloyds: InfoQuestionLloyds,
    Halifax: InfoQuestionHalifax,
    BankOfScotland: InfoQuestionBankOfScotland,
    MBNA: InfoQuestionMBNA,
  },
  infoWhite: {
    Lloyds: InfoWhiteLloyds,
    Halifax: InfoWhiteHalifax,
    BankOfScotland: InfoWhiteBankOfScotland,
    MBNA: InfoWhiteMBNA,
  },
  info: {
    Lloyds: InfoLloyds,
    Halifax: InfoHalifax,
    BankOfScotland: InfoBankOfScotland,
    MBNA: InfoMBNA,
  },
  locationMarker: {
    Lloyds: LocationMarkerLloyds,
    Halifax: LocationMarkerHalifax,
    BankOfScotland: LocationMarkerBankOfScotland,
    MBNA: LocationMarkerMBNA,
  },
  lock: {
    Lloyds: LockLloyds,
    Halifax: LockHalifax,
    BankOfScotland: LockBankOfScotland,
    MBNA: LockMBNA,
  },
  logoWhite: {
    Lloyds: LogoWhiteLloyds,
    Halifax: LogoWhiteHalifax,
    BankOfScotland: LogoWhiteBankOfScotland,
    MBNA: LogoWhiteMBNA,
  },
  logoSmall: {
    Lloyds: Logo_smallLloyds,
    Halifax: Logo_smallHalifax,
    BankOfScotland: Logo_smallBankOfScotland,
    MBNA: Logo_smallMBNA,
  },
  missedConnection: {
    Lloyds: MissedConnectionLloyds,
    Halifax: MissedConnectionHalifax,
    BankOfScotland: MissedConnectionBankOfScotland,
    MBNA: MissedConnectionMBNA,
  },
  oneway: {
    Lloyds: OnewayLloyds,
    Halifax: OnewayHalifax,
    BankOfScotland: OnewayBankOfScotland,
    MBNA: OnewayMBNA,
  },
  passenger: {
    Lloyds: PassengerLloyds,
    Halifax: PassengerHalifax,
    BankOfScotland: PassengerBankOfScotland,
    MBNA: PassengerMBNA,
  },
  plus_small: {
    Lloyds: Plus_smallLloyds,
    Halifax: Plus_smallHalifax,
    BankOfScotland: Plus_smallBankOfScotland,
    MBNA: Plus_smallMBNA,
  },
  priceFreeze: {
    Lloyds: PriceFreezeLloyds,
    Halifax: PriceFreezeHalifax,
    BankOfScotland: PriceFreezeBankOfScotland,
    MBNA: PriceFreezeMBNA,
  },
  radioSelected: {
    Lloyds: RadioSelectedLloyds,
    Halifax: RadioSelectedHalifax,
    BankOfScotland: RadioSelectedBankOfScotland,
    MBNA: RadioSelectedMBNA,
  },
  radio: {
    Lloyds: RadioLloyds,
    Halifax: RadioHalifax,
    BankOfScotland: RadioBankOfScotland,
    MBNA: RadioMBNA,
  },
  receipt: {
    Lloyds: ReceiptLloyds,
    Halifax: ReceiptHalifax,
    BankOfScotland: ReceiptBankOfScotland,
    MBNA: ReceiptMBNA,
  },
  roundtrip: {
    Lloyds: RoundtripLloyds,
    Halifax: RoundtripHalifax,
    BankOfScotland: RoundtripBankOfScotland,
    MBNA: RoundtripMBNA,
  },
  shield: {
    Lloyds: ShieldLloyds,
    Halifax: ShieldHalifax,
    BankOfScotland: ShieldBankOfScotland,
    MBNA: ShieldMBNA,
  },
  star: {
    Lloyds: StarLloyds,
    Halifax: StarHalifax,
    BankOfScotland: StarBankOfScotland,
    MBNA: StarMBNA,
  },
  ticket: {
    Lloyds: TicketLloyds,
    Halifax: TicketHalifax,
    BankOfScotland: TicketBankOfScotland,
    MBNA: TicketMBNA,
  },
  walletIcon: {
    Lloyds: WalletIconLloyds,
    Halifax: WalletIconHalifax,
    BankOfScotland: WalletIconBankOfScotland,
    MBNA: WalletIconMBNA,
  },
  wallet: {
    Lloyds: WalletLloyds,
    Halifax: WalletHalifax,
    BankOfScotland: WalletBankOfScotland,
    MBNA: WalletMBNA,
  },
  warningTriangle: {
    Lloyds: WarningTriangleLloyds,
    Halifax: WarningTriangleHalifax,
    BankOfScotland: WarningTriangleBankOfScotland,
    MBNA: WarningTriangleMBNA,
  },
  warning: {
    Lloyds: WarningLloyds,
    Halifax: WarningHalifax,
    BankOfScotland: WarningBankOfScotland,
    MBNA: WarningMBNA,
  },
  purchase: {
    Lloyds: PurchaseLloyds,
    Halifax: PurchaseHalifax,
    BankOfScotland: PurchaseBankOfScotland,
    MBNA: PurchaseMBNA,
  },
  unavailable: {
    Lloyds: UnavailableLloyds,
    Halifax: UnavailableHalifax,
    BankOfScotland: UnavailableBankOfScotland,
    MBNA: UnavailableMBNA,
  },
  priceDropProtection: {
    Lloyds: PriceDropProtectionLloyds,
    Halifax: PriceDropProtectionHalifax,
    BankOfScotland: PriceDropProtectionBankOfScotland,
    MBNA: PriceDropProtectionMBNA,
  },
  switch: {
    Lloyds: SwitchLloyds,
    Halifax: SwitchHalifax,
    BankOfScotland: SwitchBankOfScotland,
    MBNA: SwitchMBNA,
  },
  search: {
    Lloyds: SearchLloyds,
    Halifax: SearchHalifax,
    BankOfScotland: SearchBankOfScotland,
    MBNA: SearchMBNA,
  },
};
