import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  airDisruptionApi,
  DisruptionOffer,
  DisruptionOffersRequest,
  DisruptionOffersResponseEnum,
} from "@b2bportal/air-disruption-api";

export const fetchDisruptionOffers = createAsyncThunk<
  DisruptionOffer[],
  DisruptionOffersRequest
>(
  "disruption/fetchDisruptionOffers",
  async (request: DisruptionOffersRequest, thunkAPI) => {
    try {
      const res = await airDisruptionApi(axios).apiV0DisruptionOffersPost(
        request
      );
      if (
        res.data.DisruptionOffersResponse ===
        DisruptionOffersResponseEnum.Available
      ) {
        return res.data.offers;
      } else {
        return [];
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
