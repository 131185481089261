import {
  CategorizedResponse,
  LocationQueryEnum,
  Suggestion,
} from "@b2bportal/lodging-api";
import { fetchLodgingLocationAutocomplete } from "@hopper-b2b/api";
import { IApiConfig } from "@hopper-b2b/types";

export const fetchLocations = async (
  apiConfig: IApiConfig | undefined,
  search: string,
  callback: (newOptions: Suggestion[]) => void
): Promise<void> => {
  const response = await fetchLodgingLocationAutocomplete(
    { l: search, LocationQuery: LocationQueryEnum.Label },
    apiConfig
  );

  const options: Suggestion[] = [];
  response?.reduce<Suggestion[]>(
    (acc: Suggestion[], categoryRes: CategorizedResponse) => {
      acc.push(...categoryRes.results);

      return acc;
    },
    options
  );

  callback(options);
};
