import { ActionReducerMapBuilder, AsyncThunk, Draft } from "@reduxjs/toolkit";
import {
  BaseThunkConfig,
  GetThunkConfig,
  GetThunkResponse,
} from "./thunk-types";
import {
  CallState,
  ExternalResource,
  ExternalResourceNotCalled,
} from "@b2bportal/core-types";
import { processErrorForThunk, ThunkErrorType } from "./errors";

/**
 * Handles the lifecycle of an `AsyncThunk` and maps it to a
 * `ExternalResource` stored on the `State`.
 *
 * @see handleAsyncThunkCacheable for handling a `CacheableExternalResource`
 *
 * @template State The type of the state managed by the reducer.
 * @template Thunk The type of the `AsyncThunk`.
 *
 * @param builder The builder used to add cases to the reducer.
 * @param thunk The `AsyncThunk` to handle.
 * @param update A function to update the `State` given the `ExternalResource`
 *    from the thunk.
 */
export const handleAsyncThunk = <
  State,
  Thunk extends AsyncThunk<unknown, unknown, BaseThunkConfig>
>(
  builder: ActionReducerMapBuilder<State>,
  thunk: Thunk,
  update: (
    state: Draft<State>,
    resource: Exclude<
      ExternalResource<
        GetThunkResponse<Thunk>,
        ThunkErrorType<GetThunkConfig<Thunk>["rejectValue"]>
      >,
      ExternalResourceNotCalled
    >
  ) => void
) => {
  builder
    .addCase(thunk.pending, (state) => {
      update(state, {
        state: CallState.InProcess,
      });
    })
    .addCase(thunk.rejected, (state, action) => {
      update(state, {
        state: CallState.Failed,
        error: processErrorForThunk(action),
      });
    })
    .addCase(thunk.fulfilled, (state, action) => {
      update(state, {
        state: CallState.Success,
        data: action.payload as GetThunkResponse<Thunk>,
      });
    });
};
