import { Suggestion } from "@b2bportal/lodging-api";
import { trackEvent } from "@hopper-b2b/api";
import { ActionButton } from "@hopper-b2b/ui-core";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  buildSearchParams,
  placeLabelAndSublabelToUrl,
} from "@hopper-b2b/lodging-utils";
import { GuestsSelection, TripCategory } from "@hopper-b2b/types";
import { Grid } from "@material-ui/core";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIsRoomsEnabled } from "../../context";
import { CalendarPickerButton } from "../common/CalendarPickerButton";
import { GuestPicker } from "./GuestPicker";
import { HotelLocationAutoComplete } from "./HotelLocationAutocomplete";
import { PATH_HOTELS_BOOK_AVAILABILITY } from "@hopper-b2b/utilities";
import { useTenantIcons } from "@hopper-b2b/utilities";
import LocationSvg from "../../../assets/icons/Location.svg";

export type HotelSearchFormHandlers = {
  onDestinationChange?: (destination: Suggestion) => void;
  onCheckingDateChange?: (date: Date) => void;
  onCheckoutDateChange?: (date: Date | undefined) => void;
  onGuestCountChange?: (guestCount: GuestsSelection) => void;
};
export type HotelSearchProps = {
  changeHandlers?: HotelSearchFormHandlers;
  enableRooms?: boolean;
  initialAdultGuests?: number;
  initialChildGuests?: number[];
  initialRooms?: number;
  initialLocation?: Suggestion;
  initialCheckinDate?: string;
  initialCheckoutDate?: string;
  dateFormat?: string;
  onSearch?: (path: string) => void;
  onOpen?: () => void;
  minAllowedDate?: Date;
  maxAllowedDate?: Date;
};

export const HotelSearch = ({
  changeHandlers,
  enableRooms: enableRoomsProps = true,
  initialAdultGuests = 1,
  initialChildGuests = [],
  initialRooms = 1,
  initialLocation,
  onSearch,
  onOpen,
  initialCheckinDate,
  initialCheckoutDate,
  dateFormat,
  minAllowedDate,
  maxAllowedDate,
}: HotelSearchProps) => {
  const { t } = useI18nContext();

  const enableRoomsFlag = useIsRoomsEnabled();
  const enableRooms = enableRoomsProps ?? enableRoomsFlag;

  const [destination, setDestination] = useState<Suggestion | undefined>(
    initialLocation
  );
  const [checkinDate, setCheckinDate] = useState<string | undefined>(
    initialCheckinDate
  );
  const [checkoutDate, setCheckoutDate] = useState<string | undefined>(
    initialCheckoutDate
  );
  const [guestCount, setGuestCount] = useState<GuestsSelection>({
    adults: initialAdultGuests,
    children: initialChildGuests,
    rooms: initialRooms,
  });

  useEffect(() => {
    setDestination(initialLocation);
  }, [initialLocation]);

  const destinationCode = useMemo(() => {
    if (destination) {
      const { label, subLabel } = destination;
      return placeLabelAndSublabelToUrl(label, subLabel);
    }
    return undefined;
  }, [destination]);

  const isSearchReady = useMemo(
    () => destination && checkinDate && checkoutDate,
    [checkinDate, checkoutDate, destination]
  );

  const searchUrl = useMemo(() => {
    const searchParams = buildSearchParams({
      fromDate: checkinDate,
      untilDate: checkoutDate,
      adultsCount: guestCount.adults,
      childrenCount: guestCount.children,
      roomsCount: guestCount.rooms,
      enableRooms,
    });

    return `${PATH_HOTELS_BOOK_AVAILABILITY}${destinationCode}?${searchParams.toString()}`;
  }, [
    guestCount.adults,
    guestCount.children,
    guestCount.rooms,
    checkinDate,
    checkoutDate,
    destinationCode,
    enableRooms,
  ]);

  const getTrackingProperties = useCallback(
    ({
      checkin = checkinDate,
      checkout = checkoutDate,
      guest = guestCount,
      dest = destination,
    }) => ({
      ...{
        properties: {
          adults_count: guest.adults,
          children_count: guest.children.length,
          check_in_date: dayjs(checkin).format("YYYY-MM-DD"),
          check_out_date: dayjs(checkout).format("YYYY-MM-DD"),
          advance: dayjs(checkin).diff(dayjs(), "day") || null,
          los: dayjs(checkout).diff(dayjs(checkin), "day") || null,
        },
      },
      ...{
        properties: dest?.trackingPropertiesV2.properties,
        encryptedProperties: [dest?.trackingPropertiesV2.encryptedProperties],
      },
    }),
    [checkinDate, checkoutDate, guestCount, destination]
  );

  const handleSearch = useCallback(() => {
    onSearch ? onSearch?.(searchUrl) : (window.location.href = searchUrl);
  }, [onSearch, searchUrl]);

  const handleDestinationChange = useCallback(
    (dest: Suggestion) => {
      setDestination(dest);

      changeHandlers?.onDestinationChange?.(dest);
    },
    [changeHandlers]
  );

  const handleCheckinDateChange = useCallback(
    (date: Date) => {
      const trackingProperties = {};
      if (date) {
        setCheckinDate(date.toString());
        trackingProperties["checkin"] = date.toString();
        changeHandlers?.onCheckingDateChange?.(date);
        if (checkoutDate) {
          setCheckoutDate(undefined);
          trackingProperties["checkout"] = undefined;
          changeHandlers?.onCheckoutDateChange?.(undefined);
        }
        trackEvent({
          eventName: "hotel_selected_calendar_date",
          ...getTrackingProperties(trackingProperties),
        });
      }
    },
    [changeHandlers, checkoutDate, getTrackingProperties]
  );

  const handleCheckoutDateChange = useCallback(
    (date: Date) => {
      if (date) {
        setCheckoutDate(date.toString());
        changeHandlers?.onCheckoutDateChange?.(date);
        trackEvent({
          eventName: "hotel_selected_calendar_date",
          ...getTrackingProperties({ checkout: date.toString() }),
        });
      }
    },
    [changeHandlers, getTrackingProperties]
  );

  const updateGuestCount = useCallback(
    (guestCount: GuestsSelection) => {
      setGuestCount(guestCount);

      changeHandlers?.onGuestCountChange?.(guestCount);
    },
    [changeHandlers]
  );

  const onCalendarClick = useCallback(() => {
    trackEvent({
      eventName: "hotel_viewed_calendar",
      ...getTrackingProperties({}),
    });
  }, [getTrackingProperties]);

  const onComplete = useCallback(() => {
    trackEvent({
      eventName: "hotel_tapped_select_dates",
      ...getTrackingProperties({}),
    });
  }, [getTrackingProperties]);

  const { locationAutocompleteMarker } = useTenantIcons();

  return (
    <Grid container spacing={2} direction="row" alignItems="stretch">
      <Grid item xs={12} lg={3}>
        <HotelLocationAutoComplete
          id="destination"
          label={t("whereAreYouStaying")}
          onChange={handleDestinationChange}
          onOpen={onOpen}
          defaultValue={initialLocation}
          icon={
            <img
              src={locationAutocompleteMarker ?? LocationSvg}
              alt=""
              className="autocomplete-start-icon"
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4} className="hotel-search-container">
        <CalendarPickerButton
          departureDate={checkinDate ? dayjs(checkinDate).toDate() : null}
          returnDate={checkoutDate ? dayjs(checkoutDate).toDate() : null}
          tripCategory={TripCategory.ROUND_TRIP}
          setDepartureDate={handleCheckinDateChange}
          setReturnDate={handleCheckoutDateChange}
          startLabel={t("checkin") ?? undefined}
          endLabel={t("checkout") ?? undefined}
          headerTitle={t("hotelCalendarHeaderTitle")}
          onCalendarClick={onCalendarClick}
          onComplete={onComplete}
          dateFormat={dateFormat}
          minAllowedDate={minAllowedDate}
          maxAllowedDate={maxAllowedDate}
        />
      </Grid>
      <Grid item xs={12} sm={3} lg={3}>
        <GuestPicker
          initialGuestCount={guestCount}
          updateGuestCount={updateGuestCount}
          enableRooms={enableRooms}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={3}
        lg={2}
        className="hotel-search-button-container"
      >
        <ActionButton
          onClick={handleSearch}
          fullWidth
          disabled={!isSearchReady}
          size="large"
          message={t("searchButton")}
        />
      </Grid>
    </Grid>
  );
};
