import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  airCfarApi,
  type GetAirCfarOffersRequestV2,
  type GetAirCfarOffersResponseV2,
} from "@b2bportal/air-cfar-api";

// eslint-disable-next-line @nx/enforce-module-boundaries
import { trackEvent } from "@hopper-b2b/api";

import { FlightsCfarTrackingEvent } from "@b2bportal/core-types";

export const fetchCfarOffersV2 = createAsyncThunk<
  GetAirCfarOffersResponseV2,
  GetAirCfarOffersRequestV2
>(
  "cfar/fetchCfarOffersV2",
  async (request: GetAirCfarOffersRequestV2, thunkAPI) => {
    try {
      const response = await airCfarApi(axios).apiV2CfarAirOfferGetPost(
        request
      );
      if (response.data.offers != null && response.data.offers.length === 0) {
        trackEvent({
          eventName: FlightsCfarTrackingEvent.SKIP_CFAR,
          properties: response.data.trackingProperties.properties,
          encryptedProperties: response.data.trackingProperties
            .encryptedProperties
            ? [response.data.trackingProperties.encryptedProperties]
            : undefined,
        });
      }
      return response.data;
    } catch (error) {
      console.error("Error fetching CFAR V2", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
