import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  CallState,
  DisruptionOfferQuoteSelected,
  DisruptionOfferDeclined,
} from "@b2bportal/core-types";
import {
  DisruptionOffer,
  DisruptionOffersRequest,
} from "@b2bportal/air-disruption-api";
import { fetchDisruptionOffers } from "../thunks";

// State for offer fetching and selection in prebooking funnel
export interface DisruptionOffersState {
  fetchDisruptionOffersCallState: CallState;
  disruptionOffers: DisruptionOffer[];
  selectedDisruptionOffer?:
    | DisruptionOfferQuoteSelected
    | DisruptionOfferDeclined;
  lastRequest?: DisruptionOffersRequest;
}

export const initialDisruptionOffersState: DisruptionOffersState = {
  fetchDisruptionOffersCallState: CallState.NotCalled,
  disruptionOffers: [],
  selectedDisruptionOffer: undefined,
};

export const disruptionOffersSlice = createSlice({
  name: "disruptionOffers",
  initialState: initialDisruptionOffersState,
  reducers: {
    setSelectedDisruptionOffer: (
      state,
      action: PayloadAction<
        DisruptionOfferQuoteSelected | DisruptionOfferDeclined
      >
    ) => {
      state.selectedDisruptionOffer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDisruptionOffers.pending, (state, action) => {
        state.lastRequest = action.meta.arg;
        state.fetchDisruptionOffersCallState = CallState.InProcess;
      })
      .addCase(fetchDisruptionOffers.rejected, (state, _) => {
        state.fetchDisruptionOffersCallState = CallState.Failed;
        state.lastRequest = undefined;
      })
      .addCase(fetchDisruptionOffers.fulfilled, (state, action) => {
        state.fetchDisruptionOffersCallState = CallState.Success;
        state.disruptionOffers = action.payload;
        state.selectedDisruptionOffer = undefined;
      });
  },
});

export const DisruptionOffersActions = disruptionOffersSlice.actions;
export const DisruptionOffersReducer = disruptionOffersSlice.reducer;
