import axios from "axios";

import { priceFreezeApi } from "@b2bportal/price-freeze-api";
import { isHotelPriceFreezeVoucher } from "@b2bportal/core-hotels-price-freeze";
import type { HotelsPriceFreezeExercisePartialMachineContext } from "../types";

export const fetchPriceFreezeVoucher = async (
  context: HotelsPriceFreezeExercisePartialMachineContext
) => {
  const priceFreezeId = context.priceFreeze.priceFreezeId;
  const res = await priceFreezeApi(axios).apiV3PricefreezeVoucherListPost();

  return res.data.vouchers
    .filter(isHotelPriceFreezeVoucher)
    .find((voucher) => voucher.priceFreezeId === priceFreezeId);
};
