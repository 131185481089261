import type { Payment } from "@b2bportal/purchase-api";
import type {
  CardPaymentEvents,
  CartFulfillEvents,
  CartQuoteEvents,
  CartUpdateEvents,
  ContactEvents,
  PassengerEvents,
} from "@hopper-b2b/checkout";
import type { ProductType, WalletOffer } from "@hopper-b2b/types";

export enum Event {
  // Common Events
  NEXT = "NEXT",
  PREVIOUS = "PREVIOUS",
  CHANGE = "CHANGE",
  GO_IDLE = "GO_IDLE",
  RESET_CONTEXT = "RESET_CONTEXT",

  //  Passenger Events
  SELECT_PASSENGER = "SELECT_PASSENGER",
  ADD_PASSENGER = "ADD_PASSENGER",
  UPDATE_PASSENGER = "UPDATE_PASSENGER",
  DELETE_PASSENGER = "DELETE_PASSENGER",
  OPEN_PASSENGER_FORM = "OPEN_PASSENGER_FORM",
  OPEN_PASSENGER_PICKER = "OPEN_PASSENGER_PICKER",
  OPEN_PASSPORT_FORM = "OPEN_PASSPORT_FORM",
  SET_CURRENT_PASSENGER = "SET_CURRENT_PASSENGER",
  CLEAR_PASSENGER_INFORMATION_ERROR = "CLEAR_PASSENGER_INFORMATION_ERROR",

  // Contact Events
  SET_CONTACT_INFO = "SET_CONTACT_INFO",
  CLEAR_CONTACT_ERROR = "CLEAR_CONTACT_ERROR",

  // ScheduleQuote
  CLEAR_SCHEDULE_QUOTE_ERROR = "CLEAR_SCHEDULE_QUOTE_ERROR",

  // Payment Events
  OPEN_PAYMENT_FORM = "OPEN_PAYMENT_FORM",
  CLOSE_PAYMENT_FORM = "CLOSE_PAYMENT_FORM",
  SET_APPLIED_BALANCE = "SET_APPLIED_BALANCE",
  VERIFY_PAYMENT_METHOD = "VERIFY_PAYMENT_METHOD",
  DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD",
  SET_PAYMENT_METHOD_ID = "SET_PAYMENT_METHOD_ID",
  SET_SELECTED_PAYMENT_METHOD_ID = "SET_SELECTED_PAYMENT_METHOD_ID",
  CLEAR_PAYMENT_ERROR = "CLEAR_PAYMENT_ERROR",

  // Review
  GO_TO_PASSENGER_SELECT = "GO_TO_PASSENGER_SELECT",
  GO_TO_CONTACT_INFORMATION = "GO_TO_CONTACT_INFORMATION",

  // ScheduleFulfill
  CLEAR_SCHEDULE_FULFILL_ERROR = "CLEAR_SCHEDULE_FULFILL_ERROR",

  // Confirmation
  GO_HOME = "GO_HOME",

  // Wallet offers
  REMOVE_SELECTED_OFFER = "REMOVE_SELECTED_OFFER",
  SET_SELECTED_OFFER = "SET_SELECTED_OFFER",
  SET_WALLET_CREDITS = "SET_WALLET_CREDITS",
  REMOVE_WALLET_CREDITS = "REMOVE_WALLET_CREDITS",
  SET_WALLET_CREDIT_BALANCE = "SET_WALLET_CREDIT_BALANCE",
  REMOVE_WALLET_CREDIT_BALANCE = "REMOVE_WALLET_CREDIT_BALANCE",

  // Transition to state from query params
  GO_TO_CART_QUOTE = "GO_TO_CART_QUOTE",
  GO_TO_CART_FULFILL = "GO_TO_CART_FULFILL",
  GO_TO_PAYMENT = "GO_TO_PAYMENT",
  GO_TO_REVIEW = "GO_TO_REVIEW",
  GO_TO_BOOKING_CONFIRMATION = "GO_TO_BOOKING_CONFIRMATION",
  GO_TO_CARD_PAYMENT = "GO_TO_CARD_PAYMENT",
  GO_TO_SEAT_SELECTION = "GO_TO_SEAT_SELECTION",
}

// COMMON EVENTS
export interface NextEvent {
  type: Event.NEXT;
  productType: ProductType;
}
export interface PreviousEvent {
  type: Event.PREVIOUS;
}
export interface ResetContextEvent {
  type: Event.RESET_CONTEXT;
}

// GO EVENTS

export interface GoIdleEvent {
  type: Event.GO_IDLE;
}
export interface GoHome {
  type: Event.GO_HOME;
}

export interface GoToContactInformation {
  type: Event.GO_TO_CONTACT_INFORMATION;
  productType: ProductType;
}
export interface GoToPassengerSelect {
  type: Event.GO_TO_PASSENGER_SELECT;
}
export interface GoToCardPayment {
  type: Event.GO_TO_CARD_PAYMENT;
}
export interface GoToSeatSelection {
  type: Event.GO_TO_SEAT_SELECTION;
}
export interface GoToReview {
  type: Event.GO_TO_REVIEW;
}
export interface GoToBookingConfirmation {
  type: Event.GO_TO_BOOKING_CONFIRMATION;
}
export interface GoToCartQuote {
  type: Event.GO_TO_CART_QUOTE;
}
export interface GoToCartFulfill {
  type: Event.GO_TO_CART_FULFILL;
}

// CONTACT EVENTS
export interface ClearContactError {
  type: Event.CLEAR_CONTACT_ERROR;
}

// PAYMENT EVENTS
export interface VerifyPaymentMethodEvent {
  type: Event.VERIFY_PAYMENT_METHOD;
  token: string;
}

export interface DeletePaymentMethodEvent {
  type: Event.DELETE_PAYMENT_METHOD;
  paymentMethod: {
    paymentId: string;
  };
}

export interface SetPaymentMethodIdEvent {
  type: Event.SET_PAYMENT_METHOD_ID;
  data: {
    selectedPaymentMethodId: number | string;
    paymentMethods: Payment[];
  };
}

export interface SetSelectedPaymentMethodIdEvent {
  type: Event.SET_SELECTED_PAYMENT_METHOD_ID;
  paymentMethod: {
    paymentId: string | undefined;
  };
}

// CLEAR EVENTS
export interface ClearPaymentError {
  type: Event.CLEAR_PAYMENT_ERROR;
}
export interface ClearScheduleQuoteError {
  type: Event.CLEAR_SCHEDULE_QUOTE_ERROR;
}

export interface ClearScheduleFulfillError {
  type: Event.CLEAR_SCHEDULE_FULFILL_ERROR;
}

export interface RemoveSelectedOffer {
  type: Event.REMOVE_SELECTED_OFFER;
  offerId: string;
}

export interface SetSelectedOffer {
  type: Event.SET_SELECTED_OFFER;
  offerId: string;
}

// WALLET EVENTS
export interface SetWalletCreditsEvent {
  type: Event.SET_WALLET_CREDITS;
  credits: WalletOffer;
}
export interface RemoveWalletCreditsEvent {
  type: Event.REMOVE_WALLET_CREDITS;
}
export interface SetWalletCreditBalanceEvent {
  type: Event.SET_WALLET_CREDIT_BALANCE;
  credits: WalletOffer;
}

export interface RemoveWalletCreditBalanceEvent {
  type: Event.REMOVE_WALLET_CREDIT_BALANCE;
  credits: WalletOffer;
}

export type TEvent =
  // Base Events
  | ContactEvents
  | PassengerEvents
  | CardPaymentEvents
  | CartQuoteEvents
  | CartFulfillEvents
  | CartUpdateEvents
  // Wallet events
  | RemoveSelectedOffer
  | SetSelectedOffer
  | SetWalletCreditBalanceEvent
  | RemoveWalletCreditBalanceEvent
  // Common Events
  | NextEvent
  | PreviousEvent
  | ResetContextEvent
  | GoIdleEvent
  | GoHome
  | GoToContactInformation
  | GoToPassengerSelect
  | GoToCardPayment
  | GoToSeatSelection
  | GoToReview
  | GoToBookingConfirmation
  | GoToCartQuote
  | GoToCartFulfill
  | ClearContactError
  | VerifyPaymentMethodEvent
  | DeletePaymentMethodEvent
  | SetPaymentMethodIdEvent
  | SetSelectedPaymentMethodIdEvent
  | SetWalletCreditsEvent
  | RemoveWalletCreditsEvent
  | ClearPaymentError
  | ClearScheduleQuoteError
  | ClearScheduleFulfillError;
