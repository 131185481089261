import { IconComponent, IconName } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { IconButton } from "@material-ui/core";
import clsx from "clsx";
import { MutableRefObject, useEffect, useState } from "react";

import { ChatInput } from "../ChatInput";

import styles from "./ChatInputContainer.module.scss";

export interface IChatInputContainerProps {
  conversationId: string;
  inputRef: MutableRefObject<HTMLInputElement>;
  sendMessage: (message: string) => Promise<void>;
}

const ChatInputContainer = (props: IChatInputContainerProps): JSX.Element => {
  const { conversationId, inputRef, sendMessage } = props;
  const { t } = useI18nContext();
  const [disabled, setDisabled] = useState(true);
  const [value, setValue] = useState("");

  async function handleSendMessage() {
    const message = value.trim();

    if (conversationId && !disabled && message) {
      setDisabled(true);
      setValue("");

      try {
        await sendMessage(message);
      } catch {
        setValue(message);
      } finally {
        setDisabled(false);
      }
    }
  }

  useEffect(() => {
    if (conversationId) {
      setDisabled(false);
    }
  }, [conversationId]);

  return (
    <div className={clsx("chat-input-container", styles.inputContainer)}>
      <ChatInput
        classes={{
          disabled: styles.inputDisabled,
          input: styles.input,
          notchedOutline: styles.inputOutline,
          root: styles.inputRoot,
        }}
        disabled={!conversationId}
        onChange={setValue}
        onEnter={handleSendMessage}
        placeholder={t("chat.inputPlaceholder")}
        inputRef={inputRef}
        parentValue={value}
      />
      <IconButton
        className={clsx("send-button", styles.sendButton)}
        disabled={!conversationId || disabled || !value.trim()}
        onClick={handleSendMessage}
        size="medium"
      >
        <IconComponent
          className={clsx("send-button-icon", styles.sendButtonIcon)}
          name={IconName.SendMessage}
        />
      </IconButton>
    </div>
  );
};

export default ChatInputContainer;
