import { BookingType, PhoneSupportAvailability } from "@hopper-b2b/types";
import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export function isWithinServiceHours(startHour: number, endHour: number) {
  const currentUtcHour = dayjs().utc().hour();

  return currentUtcHour >= startHour && currentUtcHour < endHour;
}

export function formatChatMessageTimestamp(timestamp: string, locale: string) {
  return formatDateTime(
    dayjs(timestamp).toDate(),
    locale,
    DateTimeFormatStyle.ShortTime
  );
}

export function formatPhoneHours(hours: number) {
  const utcHoursOffset = dayjs().utcOffset() / 60;
  const localHours = (hours + utcHoursOffset) % 24;

  return dayjs(`${localHours}`, "H").format("hA");
}

export function getLocalTimeZoneCode() {
  const localNow = new Date().toLocaleTimeString(undefined, {
    timeZoneName: "short",
  });

  // Example: "12:00:00 PM EDT"
  // Destructure with variables: [timeStr, meridiem, timeZoneCode] = ["12:00:00", "PM", "EDT"]
  const [, , timeZoneCode = ""] = localNow.split(" ");

  return timeZoneCode;
}

export function getPhoneServiceHours(
  phoneAvailability?: PhoneSupportAvailability,
  bookingType?: BookingType
) {
  let serviceStartUtc = 0;
  let serviceEndUtc = 24;

  if (phoneAvailability) {
    const { air, general, ground, hotel } = phoneAvailability;

    if (!bookingType && general) {
      ({ serviceStartUtc, serviceEndUtc } = general);
    } else if (bookingType === BookingType.Flight && air) {
      ({ serviceStartUtc, serviceEndUtc } = air);
    } else if (bookingType === BookingType.Ground && ground) {
      ({ serviceStartUtc, serviceEndUtc } = ground);
    } else if (bookingType === BookingType.Lodging && hotel) {
      ({ serviceStartUtc, serviceEndUtc } = hotel);
    }
  }

  return [serviceStartUtc, serviceEndUtc];
}
