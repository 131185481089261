import { createContext, useContext, PropsWithChildren } from "react";

export interface ComponentMap {
  [componentName: string]: object;
}

export interface CoreLibrary {
  [libraryName: string]: ComponentMap;
}

export interface StyleContextValue {
  context?: CoreLibrary;
}

const InternalStyleContext = createContext<StyleContextValue | undefined>(
  undefined
);

export function useStyleContext(): StyleContextValue {
  const ctx = useContext(InternalStyleContext);
  if (ctx == null) {
    throw new Error(`must be used within a StyleContextProvider`);
  }
  return ctx;
}

export const useStyles = (
  componentlib: string,
  componentName: string,
  defaultStyles?: object
): Record<string, string> => {
  const ctx = useStyleContext();
  const tenantStyle = ctx.context?.[componentlib]?.[componentName];
  if (tenantStyle != null) {
    return Object.keys(tenantStyle).reduce(
      (mergedStyles, key) => {
        mergedStyles[key] = `${defaultStyles?.[key] ?? ""} ${
          tenantStyle[key] ?? ""
        }`;
        return mergedStyles;
      },
      { ...defaultStyles }
    );
  } else {
    return { ...defaultStyles };
  }
};

export const StyleContextProvider = ({
  children,
  context,
}: PropsWithChildren<StyleContextValue>) => {
  return (
    <InternalStyleContext.Provider value={{ context }}>
      {children}
    </InternalStyleContext.Provider>
  );
};
