import { resolvePath } from "react-router-dom-v5-compat";

/**
 * @see https://v5.reactrouter.com/web/api/BrowserRouter/basename-string
 * A properly formatted path should have a leading slash, but no trailing slash.
 * Helps match the regexp that react-router uses internally
 */
export function resolvePaths(paths: string[]) {
  return paths.map((path) => resolvePath(path).pathname);
}
