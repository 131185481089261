import { fetchTravelers } from "@hopper-b2b/api";
import type { ListTravelersResponse } from "@b2bportal/traveler-api/lib/api";
import { TravelerErrorModalTypes } from "@checkout/types";
import { apiConfig } from "@hopper-b2b/utilities";

export const fetchTravelersService = () =>
  new Promise((resolve, reject) => {
    fetchTravelers(apiConfig)
      .then((response: ListTravelersResponse) => {
        if (response?.travelers) {
          resolve(response?.travelers);
        } else {
          reject({
            type: TravelerErrorModalTypes.FetchTravelers,
          });
        }
      })
      .catch((e) => {
        reject({
          type: TravelerErrorModalTypes.FetchTravelers,
          data: e,
        });
      });
  });
