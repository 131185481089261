import clsx from "clsx";
import { trackEvent } from "@hopper-b2b/api";
import { Trans } from "@hopper-b2b/i18n";
import {
  CfarTrackingEvents,
  CfarTrackingPageSelected,
  ChfarTrackingEvents,
  ChfarTrackingPageSelected,
  DisruptionTrackingEvents,
  FintechProductType,
} from "@hopper-b2b/types";
import { ReactNode, useCallback, useMemo, useState } from "react";

import {
  ButtonWrap,
  IconComponent,
  IconName,
  MobilePopoverCard,
} from "../../index";
import { TermsAndConditions } from "../TermsAndConditions";
import "./styles.scss";

export interface IDetailHalfSheet {
  open: boolean;
  onClose: () => void;
  description: ReactNode;
  productType?: FintechProductType;
  header?: string;
  title?: string;
  descriptionAdditional?: boolean;
  className?: string;
  contentClassName?: string;
}

export const DetailHalfSheet = ({
  open = false,
  onClose,
  productType,
  header,
  title = "",
  description,
  descriptionAdditional,
  className,
  contentClassName,
}: IDetailHalfSheet) => {
  const [openTerms, setOpenTerms] = useState(false);

  const handleOpenTerms = useCallback(() => {
    setOpenTerms(true);
    switch (productType) {
      case FintechProductType.CfarPurchase:
      case FintechProductType.CfarExercise:
        trackEvent({
          eventName: CfarTrackingEvents.TAP_TERMS_CONDITIONS,
          properties: {
            page_selected: FintechProductType.CfarPurchase
              ? CfarTrackingPageSelected.OFFER
              : CfarTrackingPageSelected.EXERCISE,
          },
        });
        break;
      case FintechProductType.Disruption:
        trackEvent({
          eventName: DisruptionTrackingEvents.TAP_DELAY_TERMS,
          properties: undefined,
        });
        break;
      case FintechProductType.MissedConnection:
        trackEvent({
          eventName: DisruptionTrackingEvents.TAP_MISSED_CONNECTION_TERMS,
          properties: undefined,
        });
        break;
      case FintechProductType.Chfar:
        trackEvent({
          eventName: ChfarTrackingEvents.TAP_TERMS_CONDITIONS,
          properties: {
            page_selected: ChfarTrackingPageSelected.OFFER,
          },
        });
        break;
    }
  }, [productType]);

  const additionalInfo = useMemo(() => {
    let translationKey = "seeTermsAndConditions";
    switch (productType) {
      case FintechProductType.CfarPurchase:
      case FintechProductType.CfarExercise:
        translationKey = "cfarOffers.learnMoreDescription2";
        break;
      case FintechProductType.Chfar:
        translationKey = "chfarOffer.learnMoreDescription2";
        break;
      case FintechProductType.Disruption:
      case FintechProductType.MissedConnection:
        translationKey = "disruptionInfoModal.descriptionAdditional";
        break;
    }

    return (
      <Trans
        i18nKey={translationKey}
        components={[
          <ButtonWrap className="half-sheet-terms" onClick={handleOpenTerms} />,
        ]}
      />
    );
  }, [productType]);

  return (
    <>
      <MobilePopoverCard
        centered
        open={open}
        onClose={onClose}
        fullScreen={false}
        className={clsx("half-sheet", className, productType)}
        contentClassName={clsx(
          "half-sheet-content-container",
          contentClassName
        )}
        headerElement={header}
        topLeftButton={
          <button className="half-sheet-close" onClick={onClose}>
            <IconComponent
              className="half-sheet-close-icon"
              name={IconName.Close}
            />
          </button>
        }
      >
        <div className="half-sheet-content">
          <div className="half-sheet-title">{title}</div>
          <div className="half-sheet-description">
            <p className="half-sheet-segment">{description}</p>
            {descriptionAdditional ? (
              <p className="half-sheet-segment">{additionalInfo}</p>
            ) : null}
          </div>
        </div>
      </MobilePopoverCard>
      {!!productType && (
        <TermsAndConditions
          productType={productType}
          open={openTerms}
          onClose={() => setOpenTerms(false)}
        />
      )}
    </>
  );
};
