import { CheckoutQueryParams, ParentState } from "@checkout/types";
import {
  PATH_FLIGHTS_BOOK,
  PATH_HOME,
  PATH_HOTELS_BOOK,
} from "@hopper-b2b/utilities";
import { matchPath, useLocation } from "react-router-dom-v5-compat";

export const useIsBookingConfirmationPath = () => {
  const { pathname, search } = useLocation();

  const checkoutState = new URLSearchParams(search).get(
    CheckoutQueryParams.checkoutState
  );

  return (
    (!!matchPath(`${PATH_HOME}${PATH_FLIGHTS_BOOK}*`, pathname) ||
      !!matchPath(`${PATH_HOME}${PATH_HOTELS_BOOK}*`, pathname)) &&
    checkoutState === ParentState.bookingConfirmation
  );
};
