import {
  HotelStarRating,
  Lodging,
  LodgingMediaAsset,
  LodgingMediaAssetEnum,
  MediaCategory,
  TripAdvisorReviews,
} from "@b2bportal/lodging-api";
import { usePriceFormatterContext } from "@hopper-b2b/contexts";
import { getLang, useI18nContext } from "@hopper-b2b/i18n";
import {
  getDarkModePreferred,
  isDarkModeClassApplied,
} from "@hopper-b2b/themes";
import {
  B2BButton,
  ButtonWrap,
  IconComponent,
  IconName,
  Slot,
} from "@hopper-b2b/ui";
import {
  useDeviceTypes,
  useFeatureFlagsContext,
  useTenantIcons,
} from "@hopper-b2b/utilities";
import clsx from "clsx";
import GoogleMap, {
  onGoogleApiLoadedProps,
  type Map,
  type MapOptions,
} from "google-maps-react-markers";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Supercluster from "supercluster";
import useSupercluster from "use-supercluster";
import { getTotalNights, ICentroid } from "../../reducer";
import { darkMapStyles, mapStyles } from "./mapStyles";
import { ClusterMarker, Markers } from "./markers";
import "./styles.scss";
import { EmptyState } from "../EmptyState";

export interface BasicLodgingInfo {
  name: string;
  media: Array<LodgingMediaAsset>;
  starRating: HotelStarRating;
  tripAdvisor?: TripAdvisorReviews;
  totalPrice: string;
}

export const DEFAULT_ZOOM = 13;

export const LodgingMap = ({
  loading,
  lodgings,
  selectedId,
  hoveredId,
  centroid,
  initialZoom,
  onMapChange,
  onSearchArea,
  onMarkerClick,
  onCloseQuickView,
  onBackClick,
  mobileCardCentroidInView,
  googleMapsApiKey,
}: {
  loading: boolean;
  lodgings: Lodging[];
  selectedId: string | null;
  hoveredId: string | null;
  centroid: ICentroid;
  initialZoom: number;
  onMapChange: ({ zoom, centroid }) => void;
  onSearchArea: (bounds: [number, number, number, number]) => void;
  onMarkerClick: (id: string) => void;
  onCloseQuickView?: () => void;
  onBackClick: () => void;
  mobileCardCentroidInView: ICentroid | null;
  googleMapsApiKey: string;
}) => {
  const mapRef = useRef<Map>(null);
  const mapsRef = useRef<any>(null);

  const [mapLoaded, setMapLoaded] = useState(false);

  const [bounds, setBounds] = useState<[number, number, number, number]>();
  const [zoom, setZoom] = useState(initialZoom);
  const { t } = useI18nContext();
  const nights = useSelector(getTotalNights);
  const { formatPrice } = usePriceFormatterContext();
  const icons = useTenantIcons();
  const { matchesMobile } = useDeviceTypes();
  const featureFlags = useFeatureFlagsContext();
  const isHorizontalScrollEnabled =
    Boolean(featureFlags.showHorizontalScrollListInMobileMap) && matchesMobile;
  // Is darkmode on as a feature
  const isDarkModeEnabled = featureFlags.enableDarkMode;
  // Does the user prefer Darkmode and is it enabled
  const isDarkMode =
    (getDarkModePreferred() || isDarkModeClassApplied()) && isDarkModeEnabled;

  const points: Array<Supercluster.PointFeature<any>> = useMemo(() => {
    return lodgings.map((lodging) => ({
      type: "Feature" as const,
      properties: {
        cluster: false,
        lodgingId: lodging.lodging.id,
        lodgingName: lodging.lodging.name,
        price: lodging?.price?.nightlyPrice,
        available: lodging?.available,
      },
      geometry: {
        type: "Point" as const,
        coordinates: [
          lodging?.lodging.location.coordinates.lon,
          lodging?.lodging.location.coordinates.lat,
        ],
      },
    }));
  }, [lodgings]);

  // The User's preference could change, need to watch for that
  const styles = useMemo(
    () => (isDarkMode ? darkMapStyles : mapStyles),
    [isDarkMode]
  );

  const { clusters, supercluster } = useSupercluster({
    points,
    zoom,
    bounds,
    options: { radius: 95, maxZoom: 16 },
  });

  const onSearchAreaClick = useCallback(() => {
    onSearchArea(bounds);
  }, [bounds, onSearchArea]);

  const handleMapPanOffset = useCallback(() => {
    if (isHorizontalScrollEnabled) {
      // Move the center of map to be 20% above middle, fallback to 100px
      const panByY = mapRef?.current?.getDiv()?.offsetHeight * 0.2 || 100;
      // Changes the center of the map by x, y pixels
      mapRef?.current?.panBy(0, panByY);
    }
  }, [isHorizontalScrollEnabled]);

  const onMoveMapTo = useCallback(
    (lat: number, lng: number, z?: number) => {
      if (z != null) {
        mapRef?.current?.setZoom(z);
      }
      // Moves the center of the map to lat, lng
      mapRef?.current?.panTo({
        lat: lat,
        lng: lng,
      });
      handleMapPanOffset();
    },
    [handleMapPanOffset]
  );

  const onOpenQuickView = useCallback((offsetY: number) => {
    mapRef?.current?.panBy(0, offsetY);
  }, []);

  const getBasicLodingInfoFromId = useCallback(
    (lodgingId: string): BasicLodgingInfo => {
      return lodgings
        .filter((lodging: Lodging) => lodging.lodging.id === lodgingId)
        .map((lodging: Lodging) => {
          const tripAdvisorInfo = lodging.lodging.tripAdvisorReviews
            ? {
                tripAdvisor: lodging.lodging.tripAdvisorReviews,
                tripAdvisorText: t("tripAdvisorReviewCount", {
                  count: lodging.lodging.tripAdvisorReviews.reviewsCount,
                }),
              }
            : null;
          return {
            name: lodging.lodging.name,
            media: lodging.lodging.media.filter((asset) => {
              return (
                asset.LodgingMediaAsset !== LodgingMediaAssetEnum.Video &&
                (asset.category === MediaCategory.Primary ||
                  asset.category === MediaCategory.Exterior)
              );
            }),
            starRating: lodging.lodging.starRating,
            totalPrice: t("priceForNightsWithTaxesAndFees", {
              price: lodging.price ? formatPrice(lodging.price.totalPrice) : "",
              count: nights,
            }),
            ...tripAdvisorInfo,
          };
        })[0];
    },
    [formatPrice, lodgings, nights, t]
  );

  const onClick = useCallback(
    (id: string, lat: number, lng: number) => {
      onMoveMapTo(lat, lng);
      onMarkerClick(id);
    },
    [onMoveMapTo, onMarkerClick]
  );

  const onCloseMarker = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      onCloseQuickView?.();
    },
    [onCloseQuickView]
  );

  const onChange = useCallback(
    ({ zoom, bounds, center }) => {
      if (bounds) {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        setBounds([sw.lng(), sw.lat(), ne.lng(), ne.lat()]);
      }
      setZoom(zoom);
      const newCentroid = {
        lat: center[1],
        lng: center[0],
      };
      onMapChange({ zoom, centroid: newCentroid });
    },
    [onMapChange]
  );

  const handleAPILoaded = useCallback(
    ({ map, maps }: onGoogleApiLoadedProps) => {
      setMapLoaded(true);

      mapRef.current = map;
      mapsRef.current = maps;

      if (map.bounds) {
        const ne = map.bounds.getNorthEast();
        const sw = map.bounds.getSouthWest();
        setBounds([sw.lng(), sw.lat(), ne.lng(), ne.lat()]);
      }
      setZoom(map.zoom);
      onMoveMapTo(centroid.lat, centroid.lng);
    },
    [centroid.lat, centroid.lng, onMoveMapTo]
  );

  useEffect(() => {
    if (!mapLoaded) return;

    mapRef?.current?.setOptions({
      zoomControlOptions: {
        position: isHorizontalScrollEnabled
          ? mapsRef?.current?.ControlPosition.TOP_RIGHT
          : mapsRef?.current?.ControlPosition.RIGHT_BOTTOM,
      },
    });
  }, [isHorizontalScrollEnabled, mapLoaded]);

  const expansionZoom = useCallback(
    (clusterId: number) => {
      try {
        return supercluster?.getClusterExpansionZoom(clusterId);
      } catch (e) {
        return DEFAULT_ZOOM;
      }
    },
    [supercluster]
  );

  const mapPoints = useMemo(() => {
    if (!clusters.length || loading) {
      return null;
    }
    return clusters?.map((cluster) => {
      const [longitude, latitude]: number[] = cluster.geometry.coordinates;

      if (cluster.properties.cluster) {
        try {
          const clusterId = Number(cluster.id);
          const pointsInCluster = supercluster.getLeaves(clusterId, Infinity);
          const isOveredInLodgingList = pointsInCluster.find(
            ({ properties: { lodgingId } }) => lodgingId === hoveredId
          );
          return (
            <ClusterMarker
              key={`cluster-${latitude}-${longitude}`}
              cluster={cluster}
              lat={latitude}
              lng={longitude}
              expansionZoom={expansionZoom(clusterId)}
              isOveredInLodgingList={isOveredInLodgingList}
              onClick={onMoveMapTo}
            />
          );
        } catch (e) {
          console.log("Cluster is off map");
        }
      } else {
        return (
          <Markers
            {...getBasicLodingInfoFromId(cluster.properties.lodgingId)}
            available={cluster.properties.available}
            lodgingId={cluster.properties.lodgingId}
            lodgingName={cluster.properties.lodgingName}
            selected={selectedId === cluster.properties.lodgingId}
            isOveredInLodgingList={hoveredId === cluster.properties.lodgingId}
            onClick={onClick}
            key={`marker-${cluster?.properties?.lodgingId}`}
            lat={latitude}
            lng={longitude}
            price={cluster.properties.price ?? 0}
            onClose={onCloseMarker}
            onOpenQuickView={onOpenQuickView}
          />
        );
      }
    });
  }, [
    clusters,
    expansionZoom,
    getBasicLodingInfoFromId,
    loading,
    onCloseMarker,
    onClick,
    onMoveMapTo,
    onOpenQuickView,
    hoveredId,
    selectedId,
    supercluster,
  ]);

  const mapOptions: MapOptions = useMemo(
    () => ({
      clickableIcons: false,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      styles,
    }),
    [styles]
  );

  useEffect(() => {
    if (mapRef?.current && !isNaN(centroid.lat) && !isNaN(centroid.lng)) {
      mapRef?.current?.panTo(centroid);
    }
  }, [centroid]);

  // Pans google map to hotel that is currently in view in the mobile horizontal scroll view
  useEffect(() => {
    if (mobileCardCentroidInView) {
      mapRef?.current?.panTo(mobileCardCentroidInView);
      handleMapPanOffset();
    }
  }, [handleMapPanOffset, mobileCardCentroidInView]);

  return (
    <>
      <GoogleMap
        apiKey={googleMapsApiKey}
        defaultCenter={centroid}
        defaultZoom={zoom}
        onChange={onChange}
        externalApiParams={{ language: getLang() }}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={handleAPILoaded}
        errorContent={
          <Slot
            id="lodging-availability-empty-state"
            component={<EmptyState />}
          />
        }
      >
        {points.length ? mapPoints : null}
      </GoogleMap>
      <div className="Map-SearchArea">
        <Slot
          id="lodging-map-search-area-button"
          className={clsx("btn-search-area", { loading: loading })}
          variant="b2b-shop-filter"
          disabled={loading}
          aria-busy={loading}
          onClick={onSearchAreaClick}
          component={
            <B2BButton
              className={clsx("btn-search-area", { loading: loading })}
              variant="b2b-shop-filter"
              disabled={loading}
              aria-busy={loading}
              onClick={onSearchAreaClick}
            >
              {icons.reload ? (
                <img src={icons.reload} alt={t("searchThisArea")} />
              ) : (
                <IconComponent name={IconName.Reload} />
              )}
              {t("searchThisArea")}
            </B2BButton>
          }
        />
      </div>
      {isHorizontalScrollEnabled ? (
        <Slot
          id="lodging-map-back-button"
          className="Map-back-icon"
          onClick={onBackClick}
          component={
            icons.backWhite && (
              <ButtonWrap className="Map-back-icon" onClick={onBackClick}>
                <img src={icons.backWhite} alt={t("backIconAltText")} />
              </ButtonWrap>
            )
          }
        />
      ) : null}
    </>
  );
};
