import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export const getDurationFromMins = (mins: number) => {
  const durationObj = dayjs.duration(mins, "minutes");
  return [
    { value: durationObj.days(), key: "days" },
    { value: durationObj.hours(), key: "hours" },
    { value: durationObj.minutes(), key: "minutes" },
  ];
};
