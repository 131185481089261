import { IMonthBucket, SerializableMonthBucket } from "@b2bportal/core-types";

export const serializableMonthBuckets = (
  months: IMonthBucket[]
): SerializableMonthBucket[] => {
  // transform months to serializable format for redux store
  const serializableMonths = months.map((month) => {
    return {
      ...month,
      dates: month.dates.map((date) => ({
        ...date,
        date: date.date.toISOString(),
      })),
    };
  });
  return serializableMonths;
};

export const unserializableMonthBucket = (
  months: SerializableMonthBucket[]
): IMonthBucket[] => {
  return months.map((month) => {
    return {
      ...month,
      dates: month.dates.map((date) => ({
        ...date,
        date: new Date(date.date),
      })),
    };
  });
};
