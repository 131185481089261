import { Env, PortalConfig } from "../../types";
import { buildThemeFromBasicConfig } from "./theme";
import { Branding } from "@hopper-b2b/types";

const getEnv = (environment: string): Env => {
  const env = Env[environment];
  if (env) {
    return env;
  }
  console.warn(
    `${env} is not a valid environment. Local environment use instead`
  );
  return Env.local;
};

const config: Partial<Omit<PortalConfig, "branding">> & {
  branding: Partial<Branding>;
} = {
  pages: {
    routes: [],
  },
  bootConfig: {
    env: getEnv(import.meta.env.VITE_ENV),
    processEnv: getEnv(process.env.NODE_ENV),
  },

  slots: {},
  theme: buildThemeFromBasicConfig({
    variables: {},
    images: {},
    icons: {},
  }),
  featureFlags: {
    enablePricePrediction: true,
  },
  branding: {
    default: "en",
  },
};

export default config;
