import { OutlinedInput, OutlinedInputProps } from "@material-ui/core";
import clsx from "clsx";
import { debounce, defer } from "lodash-es";
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import styles from "./ChatInput.module.scss";

export interface IChatInputProps
  extends Omit<OutlinedInputProps, "defaultValue" | "onChange" | "onKeyDown"> {
  // overwrites onChange to have value as param instead of change event
  onChange: (value: string) => void;
  onEnter?: () => void;
  parentValue: string;
}

const ChatInput = (props: IChatInputProps): JSX.Element => {
  const { className, onChange, onEnter, parentValue, ...muiProps } = props;
  const [value, setValue] = useState("");

  /**
   * Updates the parent when there's a pause of 100ms while typing
   */
  const debouncedOnChange = useMemo(
    () => debounce((value: string) => onChange(value), 150, { maxWait: 300 }),
    [onChange]
  );

  const handleInputChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value: newValue } = ev.target;

      debouncedOnChange(newValue);
      setValue(newValue);
    },
    [debouncedOnChange]
  );

  const handleInputKeyDown = useMemo(() => {
    if (onEnter) {
      return (ev: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
          ev.preventDefault();
          debouncedOnChange.flush();
          defer(onEnter);
        }
      };
    }

    return undefined;
  }, [debouncedOnChange, onEnter]);

  useEffect(() => {
    if (parentValue !== value) {
      setValue(parentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentValue]);

  return (
    <OutlinedInput
      {...muiProps}
      className={clsx("chat-input", className, styles.chatInput)}
      notched={false}
      onChange={handleInputChange}
      onKeyDown={handleInputKeyDown}
      value={value}
    />
  );
};

export default ChatInput;
