import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import {
  Carousel,
  DefaultLodgingPlaceholder,
  HeroImageGallery,
} from "@hopper-b2b/ui";
import { getImageUrlsArray, useDeviceTypes } from "@hopper-b2b/utilities";
import Skeleton from "react-loading-skeleton";
import { useI18nContext } from "@hopper-b2b/i18n";
import { LodgingMediaAsset } from "@b2bportal/lodging-api";
import { ModalGallery } from "./components/ModalGallery";
import { getSelectedLodging } from "../../reducer/selectors";
import "./styles.scss";

export const ImageGallery = ({
  viewRoomOnClick,
  media,
  room = false,
}: {
  viewRoomOnClick: () => void;
  media: LodgingMediaAsset[];
  room?: boolean;
}) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const [showExpandedGallery, setShowExpandedGallery] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(null);
  const [, setCurrentImageIndex] = useState(0);
  const selectedLodging = useSelector(getSelectedLodging);

  const onImageClick = useCallback(
    (event) => {
      const selectedIndex = media.indexOf(
        media.find((m) => m.url === event.target.src)
      );
      if (room) {
        setCarouselIndex(selectedIndex);
        setCurrentImageIndex(selectedIndex);
      } else {
        setCurrentImageIndex(0);
      }
      setShowExpandedGallery(true);
    },
    [setCurrentImageIndex, setShowExpandedGallery]
  );

  if (!selectedLodging) return null;

  return (
    <div className="lodging-hero-image-container">
      {/* Hero Section */}
      {matchesMobile ? (
        <MobileHeroSection media={media} onImageClick={onImageClick} />
      ) : (
        <HeroImageGallery
          media={media}
          className="lodging-desktop-hero-gallery"
          onImageClick={(index) => {
            setCurrentImageIndex(index);
            setShowExpandedGallery(true);
          }}
          onSeeMoreClick={(index) => {
            setCurrentImageIndex(index);
            setShowExpandedGallery(true);
          }}
          seeMoreLabel={t("openGalleryButton")}
          placeholder={<DefaultLodgingPlaceholder />}
        />
      )}

      {/* Modal Image Gallery Grid */}
      <ModalGallery
        open={showExpandedGallery}
        onClose={() => setShowExpandedGallery(false)}
        viewRoomOnClick={() => {
          setShowExpandedGallery(false);
          viewRoomOnClick();
        }}
        imageUrlsArray={media ? getImageUrlsArray(media) : []}
        media={media}
        room={room}
        carouselIndex={carouselIndex}
        handleSetCarouselIndex={setCarouselIndex}
      />
    </div>
  );
};

const MobileHeroSection = ({
  onImageClick,
  media,
}: {
  onImageClick: (event: React.MouseEvent) => void;
  media: LodgingMediaAsset[];
}) => {
  const loading = false; //TODO: pull from redux
  const imageUrlsArray = media ? getImageUrlsArray(media) : [];

  if (!media || loading) {
    return (
      // TODO: Adjust styling in loading ticket
      <div className={clsx("availability-carousel", { skeleton: loading })}>
        <Skeleton
          className={clsx("lodging-availability-info-skeleton", "carousel")}
        />
      </div>
    );
  }

  return (
    <div className="lodging-mobile-carousel">
      <Carousel
        imageUrlsArray={imageUrlsArray}
        onClick={onImageClick}
        hideArrows={false}
      />
    </div>
  );
};
