import clsx from "clsx";

import AgentAvatar from "../../assets/agent-avatar-chat-bubble.svg";
import styles from "./ChatAvatar.module.scss";

export interface IChatAvatarProps {
  className?: string;
}

export const ChatAvatar = ({ className }: IChatAvatarProps) => {
  return (
    <div className={clsx(styles.avatar, className)}>
      <img src={AgentAvatar} alt="Virtual Assistant" />
    </div>
  );
};
