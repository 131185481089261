import { ActionButton, type ActionButtonProps } from "@lloyds/ui-core";
import type { DialogProps } from "@material-ui/core";
import { LbgModal } from "../LbgModal";

export type ConfirmationDialogProps = DialogProps & {
  title: string;
  description: string;
  acceptButtonProps: ActionButtonProps;
  cancelButtonProps: ActionButtonProps;
  onClose: () => void;
};

export const ConfirmationDialog = ({
  id,
  open,
  onClose,
  title,
  description,
  acceptButtonProps,
  cancelButtonProps,
}: ConfirmationDialogProps) => (
  <LbgModal
    open={open}
    onClose={onClose}
    aria-labelledby={`${id}-title`}
    aria-describedby={`${id}-description`}
    title={title}
    subtitle={description}
    primaryBottomButton={<ActionButton fullWidth {...acceptButtonProps} />}
    secondaryBottomButton={
      <ActionButton defaultStyle="h4r-secondary" {...cancelButtonProps} />
    }
  />
);
