import { combineReducers } from "@reduxjs/toolkit";
import type { AirCfarOffer } from "@b2bportal/air-cfar-api";
import { cfarOffersReducer } from "../features/offers/store/slice";

import { cfarExerciseReducer } from "../features/exercise/store";

// State used for `CfarV1` machine in libs/checkout
export interface CfarStateForCheckout {
  selectedOffers: AirCfarOffer[];
}

export const cfarRootReducer = combineReducers({
  cfarOffers: cfarOffersReducer,
  exercise: cfarExerciseReducer,
});

export type CfarRootState = ReturnType<typeof cfarRootReducer>;

export interface CfarAppState {
  cfar: CfarRootState;
}

/**
 * @deprecated  use `CfarAppState` instead
 */
export type CfarState = CfarAppState;
