import { GoogleMapsApiKey, lodgingApi } from "@b2bportal/lodging-api";
import { axiosInstance } from "@hopper-b2b/api";
import { useCallback, useEffect, useState } from "react";

export const useGoogleMapsApiKey = () => {
  const [googleMapsApiKey, setGoogleMapsApiKey] = useState("");

  const fetchGoogleMapsApiKeyAPI = async (): Promise<GoogleMapsApiKey> => {
    return new Promise((resolve, reject) => {
      try {
        lodgingApi(axiosInstance)
          .apiV0HotelsGoogleMapsApiKeyPost()
          .then((res) => resolve(res.data));
      } catch (_e) {
        return { key: undefined };
      }
    });
  };

  const handleFetch = useCallback(async () => {
    const { key: tenantGoogleMapsApiKey } = await fetchGoogleMapsApiKeyAPI();
    if (tenantGoogleMapsApiKey) {
      setGoogleMapsApiKey(tenantGoogleMapsApiKey);
    } else {
      setGoogleMapsApiKey(import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY);
    }
  }, []);

  useEffect(() => {
    // TODO(Nate): Running into referer issues, will bring back once sorted out
    // if (import.meta.env.PROD) {
    //   handleFetch();
    // } else {
    //   console.warn(
    //     "By default, you are using the shared Google Maps API Key, when running locally.\n  If you'd like to use your own, update useMapApiKey.ts\n"
    //   );
    //   setGoogleMapsApiKey(import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY);
    // }
    setGoogleMapsApiKey(import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY);
  }, [handleFetch]);

  return googleMapsApiKey;
};
