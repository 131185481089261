import { useI18nContext } from "@hopper-b2b/i18n";
import type { RadioOption } from "@hopper-b2b/types";
import {
  type IB2BTextFieldProps,
  type IPhoneInputFieldProps,
  uniquePhoneCodes,
} from "@hopper-b2b/ui";
import { formatPhoneNumber, useDeviceTypes } from "@hopper-b2b/utilities";
import { Grid } from "@material-ui/core";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { SelectField } from "../SelectField";
import { TextInputField } from "../TextInputField";

export type PhoneInputFieldProps = IPhoneInputFieldProps &
  Omit<IB2BTextFieldProps, "onChange">;

export const PhoneInputField = memo(
  ({
    onChange,
    onBlur,
    errorHelper,
    disabled,
    defaultValue,
    countryCode,
    inputRef,
  }: PhoneInputFieldProps) => {
    const { brand, t } = useI18nContext();
    const { matchesMobile } = useDeviceTypes();

    const [baseNumber, setBaseNumber] = useState(
      defaultValue?.toString() || ""
    );
    const [countryDialCode, setCountryDialCode] = useState(
      countryCode ?? brand.preferredAreaCode
    );

    const countryCodeOptions: RadioOption[] = useMemo(
      () =>
        uniquePhoneCodes.map((countryCode) => ({
          value: `+${countryCode}`,
          label: `+${countryCode}`,
        })),
      []
    );

    const formattedPhoneNumber = useMemo(
      () => formatPhoneNumber(baseNumber, countryDialCode),
      [baseNumber, countryDialCode]
    );

    const handleCountryCodeChange = useCallback(
      (value: string | string[]): void => {
        const code = typeof value === "string" ? value : value?.[0];
        setCountryDialCode(code);
        onChange(baseNumber, code);
      },
      [baseNumber, onChange]
    );

    const handleBaseNumberChange = useCallback(
      (value) => {
        const num = value?.replace(/\D/g, "");
        setBaseNumber(num);
        onChange?.(num, countryDialCode);
      },
      [onChange, countryDialCode]
    );

    useEffect(() => {
      if (defaultValue) {
        setBaseNumber(defaultValue?.toString());
      }
    }, [defaultValue]);

    useEffect(() => {
      if (countryCode) {
        setCountryDialCode(countryCode);
      }
    }, [countryCode]);

    return (
      <Grid container spacing={4} direction={matchesMobile ? "column" : "row"}>
        <Grid item xs lg={4}>
          <SelectField
            label={t("countryCode")}
            placeholder={t("countryCodeSelectPlaceholder")}
            selected={countryDialCode}
            setOption={handleCountryCodeChange}
            options={countryCodeOptions}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs lg={8}>
          <TextInputField
            disabled={disabled}
            errorHelper={errorHelper}
            id="phone-input-field"
            inputRef={inputRef}
            label={t("phoneNumber")}
            onBlur={onBlur}
            onChange={handleBaseNumberChange}
            required
            type="tel"
            value={formattedPhoneNumber}
          />
        </Grid>
      </Grid>
    );
  }
);
