import axios from "axios";

import {
  type FindProductToExerciseResponseNotAvailable,
  priceFreezeApi,
} from "@b2bportal/price-freeze-api";
import type { HotelsPriceFreezeExercisePartialMachineContext } from "../types";

class FrozenProductUnavailableError extends Error {
  reason: FindProductToExerciseResponseNotAvailable["reason"];
  FindProductToExerciseResponse: FindProductToExerciseResponseNotAvailable["FindProductToExerciseResponse"];

  constructor(
    message: string,
    data: FindProductToExerciseResponseNotAvailable
  ) {
    super(message);
    this.name = "FrozenProductToExerciseError";
    this.reason = data.reason;
    this.FindProductToExerciseResponse = data.FindProductToExerciseResponse;
  }
}

export const fetchFrozenProductToExercise = async (
  context: HotelsPriceFreezeExercisePartialMachineContext
) => {
  const priceFreezeId = context.priceFreeze.priceFreezeId;

  const res = await priceFreezeApi(
    axios
  ).apiV3PricefreezeVoucherExerciseFindPost({ priceFreezeId });

  if (res.data.FindProductToExerciseResponse === "NotAvailable") {
    throw new FrozenProductUnavailableError(
      "No product match found.",
      res.data
    );
  }

  if (res.data.matchingProduct.MatchingProduct !== "Hotel") {
    throw new Error("Matched product not hotel.");
  }

  return res.data.matchingProduct;
};
