import type { Suggestion } from "@b2bportal/lodging-api";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { type GuestsSelection, TripCategory } from "@hopper-b2b/types";
import { URL_DATE_FORMAT, useOnMount } from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import dayjs from "dayjs";
import { useCallback } from "react";
import { CalendarPicker } from "./CalendarPicker";
import { ReactComponent as HotelIcon } from "./hotel.svg";
import type { LloydsCalendarPickerProps } from "./types";

type LloydsHotelCalendarPickerProps = Omit<
  LloydsCalendarPickerProps,
  "tripCategory"
> &
  Pick<Partial<LloydsCalendarPickerProps>, "tripCategory"> & {
    guestCount?: GuestsSelection;
    destination?: Suggestion;
  };

export const HotelCalendarPicker = ({
  endDate: checkoutDate,
  startDate: checkinDate,
  guestCount,
  destination,
  setStartDate: setCheckinDate,
  setEndDate: setCheckoutDate,
  onComplete,
}: LloydsHotelCalendarPickerProps) => {
  const { t } = useI18nContext();

  const getTrackingProperties = useCallback(
    ({
      checkin = checkinDate,
      checkout = checkoutDate,
      guest = guestCount,
      dest = destination,
    }) => ({
      ...{
        properties: {
          adults_count: guest?.adults,
          children_count: guest?.children.length,
          check_in_date: dayjs(checkin).format(URL_DATE_FORMAT),
          check_out_date: dayjs(checkout).format(URL_DATE_FORMAT),
          advance: dayjs(checkin).diff(dayjs(), "day") || null,
          los: dayjs(checkout).diff(dayjs(checkin), "day") || null,
        },
      },
      ...{
        properties: dest?.trackingPropertiesV2.properties,
        encryptedProperties: dest?.trackingPropertiesV2.encryptedProperties
          ? [dest?.trackingPropertiesV2.encryptedProperties]
          : [],
      },
    }),
    [checkinDate, checkoutDate, guestCount, destination]
  );
  useOnMount(() => {
    trackEvent({
      eventName: "hotel_viewed_calendar",
      ...getTrackingProperties({}),
    });
  });

  const handleCheckinDateChange = useCallback(
    (date: Date) => {
      const formatedDate = dayjs(date).format(URL_DATE_FORMAT);
      const trackingProperties = {};
      if (date) {
        setCheckinDate(formatedDate);
        trackingProperties["checkin"] = formatedDate;
        if (checkoutDate) {
          setCheckoutDate(null);
          trackingProperties["checkout"] = undefined;
        }
        trackEvent({
          eventName: "hotel_selected_calendar_date",
          ...getTrackingProperties(trackingProperties),
        });
      }
    },
    [checkoutDate, setCheckinDate, setCheckoutDate, getTrackingProperties]
  );

  const handleCheckoutDateChange = useCallback(
    (date: Date) => {
      const formatedDate = dayjs(date).format(URL_DATE_FORMAT);
      if (date) {
        setCheckoutDate(formatedDate);
        trackEvent({
          eventName: "hotel_selected_calendar_date",
          ...getTrackingProperties({ checkout: formatedDate }),
        });
      }
    },
    [getTrackingProperties, setCheckoutDate]
  );

  const onCompleteInternal = useCallback(() => {
    trackEvent({
      eventName: "hotel_tapped_select_dates",
      ...getTrackingProperties({}),
    });
    onComplete();
  }, [getTrackingProperties, onComplete]);

  return (
    <CalendarPicker
      tripCategory={TripCategory.ROUND_TRIP}
      startDate={checkinDate ? dayjs(checkinDate).toDate() : null}
      endDate={checkoutDate ? dayjs(checkoutDate).toDate() : null}
      endDateLabel={t("checkout")}
      warningText={
        !checkinDate ? t("selectCheckinDate") : t("selectCheckoutDate")
      }
      startDateIcon={<HotelIconComponent ready={!!checkinDate} />}
      endDateIcon={<HotelIconComponent ready={!!checkoutDate} />}
      setStartDate={handleCheckinDateChange}
      setEndDate={handleCheckoutDateChange}
      startDateLabel={t("checkin")}
      onComplete={onCompleteInternal}
      disableHighContrast={true}
    />
  );
};
const HotelIconComponent = ({ ready }: { ready?: boolean }) => {
  return (
    <Box>
      <HotelIcon
        style={{
          paddingRight: "10px",
          color: ready ? "var(--primary)" : "var(--primary-text)",
          height: "24px",
          width: "34px",
          fill: ready ? "var(--primary)" : "var(--primary-text)",
        }}
      />
    </Box>
  );
};
