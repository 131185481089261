import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import { ChatAvailability } from "@hopper-b2b/types";
import { IconName } from "@hopper-b2b/ui";
import { SupportContext } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useContext } from "react";

import {
  formatPhoneHours,
  getLocalTimeZoneCode,
  getPhoneServiceHours,
  isWithinServiceHours,
} from "../../utils";
import { ContactSupportOption } from "../ContactSupportOption";

import styles from "./ContactSupportDetails.module.scss";
import { useChatContext } from "../../hooks";

const ContactSupportDetails = (): JSX.Element => {
  const { chatProvider, conversationId, supportId } = useChatContext();
  const { generalSupportChat } = useContext(SupportContext);
  const { brand, t } = useI18nContext();
  const {
    contactSupport: {
      chatAvailability,
      displaySupportNumber,
      phoneAvailability,
      supportNumber,
    },
    urls: { helpCenterBaseUrl },
  } = brand;
  const showChatOption = chatAvailability !== ChatAvailability.None;
  const [startUtc, endUtc] = getPhoneServiceHours(phoneAvailability);
  const enableCallOption = isWithinServiceHours(startUtc, endUtc);

  const startGeneralSupportChat = () => {
    generalSupportChat({
      conversationId,
      source: chatProvider.chatTransferSource,
    });
  };

  /**
   * Gets the i18n context for the call option subtitle copy
   */
  const getCallOptionSubtitleContext = () => {
    if (enableCallOption) {
      if (startUtc === 0 && endUtc === 24) return "";
      return "limited";
    }

    return "unavailable";
  };

  return (
    <Box
      className={clsx(
        "contact-support-details-container",
        styles.contactSupportDetails
      )}
    >
      <Typography variant="h1">{t("chat.contactSupport.title")}</Typography>
      <Typography variant="body1">
        {t("chat.contactSupport.subtitle")}
      </Typography>
      <Box
        className={clsx(
          "support-id-container",
          styles.supportIdContainer,
          styles.detailsSection
        )}
      >
        <Typography variant="subtitle1">
          {t("chat.contactSupport.supportIdLabel")}
        </Typography>
        {supportId ? (
          <Typography variant="h2">{supportId}</Typography>
        ) : (
          <Skeleton height={30} variant="text" width={100} />
        )}
      </Box>
      <Box
        className={clsx(
          "contact-options-container",
          styles.contactOptionsContainer,
          styles.detailsSection
        )}
      >
        {showChatOption && (
          <ContactSupportOption
            icon={IconName.SupportChat}
            onClick={startGeneralSupportChat}
            subtitle={t("chat.contactSupport.chatOptionSubtitle")}
            title={t("chat.contactSupport.chatOptionTitle")}
          />
        )}
        <ContactSupportOption
          caption={displaySupportNumber}
          disabled={!enableCallOption}
          icon={IconName.SupportPhone}
          onClick={() => window.open(`tel:${supportNumber}`)}
          subtitle={t("chat.contactSupport.callOptionSubtitle", {
            context: getCallOptionSubtitleContext(),
            startHour: formatPhoneHours(startUtc),
            endHour: formatPhoneHours(endUtc),
            timezone: getLocalTimeZoneCode(),
          })}
          title={t("chat.contactSupport.callOptionTitle")}
        />
      </Box>
      {helpCenterBaseUrl && (
        <Box
          className={clsx(
            "faq-link-container",
            styles.faqLinkContainer,
            styles.detailsSection
          )}
        >
          <Trans
            i18nKey={"chat.additionalInfo"}
            components={[
              <span />,
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                className={clsx("help-link", styles.helpLink)}
                href={helpCenterBaseUrl}
                rel="noopener noreferrer"
                target="_blank"
              />,
            ]}
          />
        </Box>
      )}
    </Box>
  );
};

export default ContactSupportDetails;
