import {
  FeatureFlags,
  FeatureFlagsProvider,
  type TabType,
  Tabs,
} from "@hopper-b2b/common-search";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useAirEnabled, useLodgingEnabled } from "@lloyds/theming";
import {
  PATH_FLIGHTS_SEARCH,
  PATH_HOME,
  PATH_HOTELS_SEARCH,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { useMemo } from "react";
import styles from "./TabbedSearch.module.scss";
import { DesktopFlightSearch } from "../FlightSearch";
import { DesktopHotelSearch } from "../HotelSearch";
import { SearchButton } from "./SearchButton";
import { Box, Typography } from "@material-ui/core";
import { IconNameEnum } from "@b2bportal/core-themes";
import { Icon } from "@b2bportal/core-ui";
import clsx from "clsx";

export const HOTELS_TAB_VALUE = "hotel-tab" as const;
export const FLIGHTS_TAB_VALUE = "flights-tab" as const;

const SearchTitle = ({
  title,
  subtitle,
  isMobile,
}: {
  title: string;
  subtitle: string;
  isMobile: boolean;
}) => (
  <>
    <Typography
      variant="subtitle1"
      style={{ color: "var(--white)" }}
      className={
        isMobile ? styles.mobileSearchTitle : styles.desktopSearchTitle
      }
    >
      {title}
    </Typography>
    <Typography
      variant="subtitle1"
      style={{ color: "var(--white)" }}
      className={
        isMobile ? styles.mobileSearchSubtitle : styles.desktopSearchSubtitle
      }
    >
      {subtitle}
    </Typography>
  </>
);

export const TabbedSearch = () => {
  const { t } = useI18nContext();

  const enableAir = useAirEnabled();
  const enableLodging = useLodgingEnabled();

  const { matchesMobile } = useDeviceTypes();

  const tabs: TabType[] = useMemo(
    () =>
      [
        enableLodging
          ? {
              value: HOTELS_TAB_VALUE,
              label: t?.("hotels"),
              icon: (
                <Icon iconName={IconNameEnum.hotel} className={styles.icon} />
              ),
              panel: matchesMobile ? (
                <SearchButton
                  path={`${PATH_HOME}${PATH_HOTELS_SEARCH}`}
                  placeholder={t("hotelSearchPrompt")}
                />
              ) : (
                <DesktopHotelSearch />
              ),
            }
          : undefined,
        enableAir
          ? {
              value: FLIGHTS_TAB_VALUE,
              label: t?.("flights"),
              icon: (
                <Icon iconName={IconNameEnum.flight} className={styles.icon} />
              ),
              panel: matchesMobile ? (
                <SearchButton
                  path={`${PATH_HOME}${PATH_FLIGHTS_SEARCH}`}
                  placeholder={t("flightSearchPrompt")}
                />
              ) : (
                <DesktopFlightSearch />
              ),
            }
          : undefined,
      ].filter(Boolean),
    [enableAir, enableLodging, matchesMobile, t]
  );

  return (
    <FeatureFlagsProvider
      flags={{
        [FeatureFlags.AIR]: true,
        [FeatureFlags.LODGING]: true,
        [FeatureFlags.CALENDAR_PREDICTION]: true,
        [FeatureFlags.ROOMS]: false,
      }}
    >
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        width="100%"
        p={4}
      >
        <SearchTitle
          title={t("homepageSearchTitle")}
          subtitle={t("homepageSearchSubtitle")}
          isMobile={matchesMobile}
        />
        <Tabs
          tabClassName={clsx(
            styles.tab,
            matchesMobile ? styles.mobileTab : styles.desktopTab
          )}
          tabPanelClassName={styles.tabPanel}
          tabs={tabs}
          containerClassName={styles.tabContainer}
        />
      </Box>
    </FeatureFlagsProvider>
  );
};
