import {
  CreateSessionResponseEnum,
  GetSessionInfoResponseEnum,
} from "@b2bportal/auth-api";
import { getSessionInfo, impersonateUserAsAgent } from "@hopper-b2b/api";
import { IndeterminateLoader } from "@hopper-b2b/ui";
import { PATH_HOME } from "@hopper-b2b/utilities";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom-v5-compat";

import { getSessionInfoSelector, updateSession } from "../../store";

interface AgentImpersonationProps {
  /**
   * The route to redirect to after the impersonation is successful
   * @default PATH_HOME ("/")
   */
  redirectPath?: string;
  refreshHeaderKey?: string;
  refreshStorageKey?: string;
}

type ImpersonationUrlParams = {
  tenantId?: string;
  userId?: string;
};

/**
 * @see https://hopper-jira.atlassian.net/wiki/spaces/CXPROD/pages/6695223378/Agent+Impersonation for usage guide
 * @param {AgentImpersonationProps} props
 * @return {JSX.Element}
 */
const AgentImpersonation = (props: AgentImpersonationProps): JSX.Element => {
  const {
    redirectPath = PATH_HOME,
    refreshHeaderKey,
    refreshStorageKey,
  } = props;
  const dispatch = useDispatch();
  const { tenantId = "", userId = "" } = useParams<ImpersonationUrlParams>();
  const sessionInfo = useSelector(getSessionInfoSelector);
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState("");

  const impersonateUser = useCallback(
    async (tenantId: string, userId: string) => {
      if (refreshStorageKey) {
        // deleteRefreshToken
        localStorage.removeItem(refreshStorageKey);
      }

      try {
        const impersonateRes = await impersonateUserAsAgent(
          userId,
          tenantId,
          refreshHeaderKey,
          refreshStorageKey
        );

        if (
          impersonateRes.CreateSessionResponse ===
          CreateSessionResponseEnum.CreateSessionSuccess
        ) {
          const userSessionRes = await getSessionInfo();

          if (
            userSessionRes.GetSessionInfoResponse ===
            GetSessionInfoResponseEnum.Session
          ) {
            dispatch(updateSession(userSessionRes.sessionInfo));
          }
        }
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setIsDone(true);
      }
    },
    [dispatch, refreshHeaderKey, refreshStorageKey]
  );

  useEffect(() => {
    if (tenantId && userId && !sessionInfo) {
      impersonateUser(tenantId, userId);
    }
  }, [impersonateUser, tenantId, sessionInfo, userId]);

  if (!isDone) {
    return <IndeterminateLoader />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return <Navigate to={redirectPath} />;
};

export default AgentImpersonation;
