import { ChatMessage, SenderRole, Status } from "@b2bportal/chat-api";
import { Slot } from "@hopper-b2b/ui";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";

import { ChatProvider } from "../../types";
import { ChatAvatar } from "../ChatAvatar";
import { ChatContextProvider } from "./ChatContextProvider";
import { ChatDivider } from "../ChatDivider";
import { ChatInputContainer } from "../ChatInputContainer";
import { ChatIntroMessage } from "../ChatIntroMessage";
import { ChatMessageComponent } from "../ChatMessage";
import { ChatWindowBottomSheet } from "../ChatWindowBottomSheet";
import { ChatWindowHeader } from "../ChatWindowHeader";
import { TypingIndicator } from "../TypingIndicator";

import messageStyles from "../ChatMessage/ChatMessage.module.scss";
import styles from "./ChatWindow.module.scss";

export interface IChatWindowProps {
  chatProvider: ChatProvider;
  /**
   * Ends the current chat session and hides the chat window
   */
  closeChat: () => void;
  /**
   * Hides the chat window but keeps the chat session active
   */
  minimizeChat: () => void;
  mobile?: boolean;
  open: boolean;
  getTripDetailsPath: (tripId: string) => string;
}

const ChatWindow: React.FC<IChatWindowProps> = ({
  chatProvider,
  closeChat,
  minimizeChat,
  mobile = false,
  open,
  getTripDetailsPath,
}) => {
  const chatWindowRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatInputRef = useRef<HTMLInputElement>();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [isAgentTyping, setIsAgentTyping] = useState(false);
  const [conversationIsLoaded, setConversationIsLoaded] = useState(false);
  const [conversationIsLoading, setConversationIsLoading] = useState(false);

  const showPendingMessage = (text: string) => {
    setMessages((currentMessages) => [
      ...currentMessages,
      {
        id: String(currentMessages.length + 1),
        senderRole: SenderRole.User,
        content: text,
        timestamp: new Date().toString(),
        deepLink: null,
        smartReplies: null,
        status: Status.Pending,
      },
    ]);
  };

  /**
   * Updates the status of the user's last message and adds the agent's reply to
   * the message list (if there was one)
   */
  const handleAgentReply = (
    userMessageStatus: Status,
    agentReply?: ChatMessage
  ) => {
    setMessages((currentMessages) => {
      // replace Array.prototype.findLastIndex once supported
      let lastUserMsgIdx = currentMessages.length - 1;
      for (; lastUserMsgIdx >= 0; lastUserMsgIdx -= 1) {
        const { senderRole } = currentMessages[lastUserMsgIdx];

        if (senderRole === SenderRole.User) break;
      }

      if (lastUserMsgIdx !== -1) {
        currentMessages[lastUserMsgIdx].status = userMessageStatus;
      }

      if (agentReply) {
        return [...currentMessages, agentReply];
      }

      return currentMessages;
    });
  };

  const onSendMessage = async (text: string) => {
    setIsAgentTyping(true);
    showPendingMessage(text);

    try {
      const reply = await chatProvider.sendMessage(conversationId, text);
      handleAgentReply(Status.Sent, reply);
    } catch (error) {
      console.error("An error occurred while sending the message:", error);
      handleAgentReply(Status.Failed);

      // bubble up error to ChatInputContainer to handle error
      throw error;
    } finally {
      setIsAgentTyping(false);
    }
  };

  useEffect(() => {
    const onCreateConversation = async () => {
      setConversationIsLoading(true);
      setIsAgentTyping(true);

      const reply = await chatProvider.createConversation();

      setConversationId(reply.id);
      setConversationIsLoaded(true);
      setConversationIsLoading(false);
      setIsAgentTyping(false);
      setMessages([reply.messages[0]]);
    };

    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

    if (!conversationIsLoaded && !conversationIsLoading) {
      onCreateConversation();
    }
  }, [chatProvider, conversationIsLoaded, conversationIsLoading, messages]);

  useEffect(() => {
    if (open) {
      chatInputRef.current?.focus();
    }
  }, [open]);

  return (
    <ChatContextProvider
      chatContext={{
        chatProvider,
        conversationId,
        setConversationId,
        closeChatWindow: closeChat,
        minimizeChatWindow: minimizeChat,
        getTripDetailsPath: getTripDetailsPath,
      }}
    >
      <div
        className={clsx(
          "chat-window",
          {
            [styles.mobileChatWindow]: mobile,
            [styles.open]: open,
          },
          styles.chatWindow
        )}
        ref={chatWindowRef}
      >
        <Slot
          id="chat-window-header"
          component={<ChatWindowHeader onMinimize={minimizeChat} />}
        />
        <div className={clsx("message-list", styles.messageList)}>
          {/* TODO incorporate https://day.js.org/docs/en/display/calendar-time if needed */}
          <ChatDivider value="Today" />
          <ChatIntroMessage />
          {messages.map((message) => (
            <ChatMessageComponent
              showMetadata
              key={message.id}
              message={message}
            />
          ))}
          {isAgentTyping && (
            // TODO replace with ChatMessageComponent
            <div className={clsx(messageStyles.messageContainer)}>
              <ChatAvatar className={messageStyles.messageAvatar} />
              <div className={clsx(messageStyles.messageContent)}>
                <TypingIndicator />
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <ChatInputContainer
          conversationId={conversationId}
          inputRef={chatInputRef}
          sendMessage={onSendMessage}
        />
        <ChatWindowBottomSheet chatWindowRef={chatWindowRef} />
      </div>
    </ChatContextProvider>
  );
};

export const ChatWindowContainer: React.FC<IChatWindowProps> = (props) => {
  return (
    <Box className={styles.floatingBox}>
      <ChatWindow {...props} />
    </Box>
  );
};

export default ChatWindow;
