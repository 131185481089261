import { Lodging } from "@b2bportal/lodging-api";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  parseLodgingParams,
  URL_PARAM_KEYS,
  ViewOption,
} from "@hopper-b2b/lodging-utils";
import { LodgingShopTrackingEvents } from "@hopper-b2b/types";
import { useTenantIcons } from "@hopper-b2b/utilities";
import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useSearchParams } from "react-router-dom-v5-compat";
import { LodgingMap } from "./components/LodgingMap";
import { ICentroid } from "./reducer";
import { useGoogleMapsApiKey } from "../../util/useMapApiKey";

export const AvailabilityMap = ({
  lodgings,
  loading,
  defaultHoveredId,
  isOverFiltered,
  onSearchArea,
  onBackClick,
  selectedHotelID,
  setSelectedHotelID,
  mobileCardCentroidInView,
}: {
  onSearchArea: (bounds: [number, number, number, number]) => void;
  lodgings: Lodging[];
  loading: boolean;
  defaultHoveredId?: string | null;
  isOverFiltered?: boolean;
  onBackClick: () => void;
  selectedHotelID: string | null;
  setSelectedHotelID: Dispatch<SetStateAction<string>>;
  mobileCardCentroidInView: ICentroid | null;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const fromUrl = useMemo(
    () => parseLodgingParams(searchParams),
    [searchParams]
  );

  const googleMapsApiKey = useGoogleMapsApiKey();

  const onCloseQuickView = () => {
    setSelectedHotelID(null);
  };

  const onMarkerClick = useCallback(
    (id: string) => {
      if (selectedHotelID === id) {
        setSelectedHotelID(null);
        return;
      }
      setSelectedHotelID(id);

      const element = document.getElementById(id);
      // TODO: there is a bug here where this function will not find the element because it has not been rendered in the dom
      // this is outside of the scope and probably would be a medium lift as we would need to virtualize the list and handle that
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    },
    [selectedHotelID, setSelectedHotelID]
  );
  const onMapChange = useCallback(
    ({ zoom, centroid }) => {
      setSearchParams(
        (previousParams) => {
          previousParams.set(
            URL_PARAM_KEYS.LAT_LNG,
            `${centroid.lat},${centroid.lng}`
          );
          previousParams.set(URL_PARAM_KEYS.ZOOM, zoom);
          return previousParams;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  useEffect(() => {
    trackEvent({
      eventName: LodgingShopTrackingEvents.hotel_viewed_map,
      properties: {},
    });
  }, []);

  return (
    <div className="Availability-map">
      {fromUrl.map.centroid && googleMapsApiKey !== "" ? (
        <LodgingMap
          loading={loading}
          lodgings={lodgings}
          hoveredId={defaultHoveredId}
          selectedId={selectedHotelID}
          centroid={fromUrl.map.centroid}
          initialZoom={fromUrl.map.zoom}
          onMapChange={onMapChange}
          onSearchArea={onSearchArea}
          onMarkerClick={onMarkerClick}
          onCloseQuickView={onCloseQuickView}
          onBackClick={onBackClick}
          mobileCardCentroidInView={mobileCardCentroidInView}
          googleMapsApiKey={googleMapsApiKey}
        />
      ) : undefined}
      {/**
       * We show the Skeleton over the map to allow Google Maps API to load in the background.
       * We only want to show the skeleton on very first search.
       */}
      {lodgings.length === 0 &&
      !fromUrl.map.centroid &&
      loading &&
      !isOverFiltered ? (
        <div className="Availability-map-skeleton">
          <Skeleton width="100%" height="100%" variant="rect" />
        </div>
      ) : null}
    </div>
  );
};

export default AvailabilityMap;

export const AvailabilityListMapToggle = ({
  view,
  onChange,
}: {
  view: ViewOption;
  onChange: (view: ViewOption) => void;
}) => {
  const { listIcon, mapIcon } = useTenantIcons();

  const { t } = useI18nContext();

  const label = useMemo(
    () => (view === ViewOption.MAP ? t("showList") : t("showMap")),
    [t, view]
  );

  const toggleListView = useCallback(() => {
    onChange(view === ViewOption.MAP ? ViewOption.LIST : ViewOption.MAP);
  }, [onChange, view]);
  return (
    <button
      className={clsx("Availability-view-toggle", view)}
      onClick={toggleListView}
    >
      <Typography>{label}</Typography>
      {
        <img
          alt={label}
          src={
            view === ViewOption.MAP
              ? listIcon
                ? listIcon
                : undefined
              : mapIcon
              ? mapIcon
              : undefined
          }
        />
      }
    </button>
  );
};
