import { useEffect, useState } from "react";
import type { BuildFlightShopQueryArgs } from "@components/flights";
import dayjs from "dayjs";
import { TripCategory } from "@hopper-b2b/types";
import type { IPassengerCounts, ITripTerminus } from "@b2bportal/core-types";
import { useSelector } from "react-redux";
import { FlightSearchSelectors } from "@b2bportal/core-flights";
import { useRecentFlightSearches } from "@lloyds/theming";

const FLIGHT_SEARCH_KEY = "flight-searches";
const DAYS_FORWARD = 14;

export const useFlightRecentSearches = () => {
  const [recentSearches, setRecentSearches] = useState<
    BuildFlightShopQueryArgs[]
  >([]);
  const searchState = useSelector(FlightSearchSelectors.getSearchState);
  const enable = useRecentFlightSearches();

  const { origin } = useSelector(FlightSearchSelectors.getSearchFormValues);

  useEffect(() => {
    const recentSearches = retrieveRecentSearches();
    setRecentSearches(recentSearches);
  }, []);

  return {
    recentSearches: enable ? recentSearches : [],
    filterByOrigin: buildSearchFilter("origin", undefined, searchState),
    filterByDestination: buildSearchFilter("destination", origin, searchState),
  };
};

const buildSearchFilter =
  (
    type: "origin" | "destination",
    origin?: ITripTerminus,
    omit?: BuildFlightShopQueryArgs
  ) =>
  (options: BuildFlightShopQueryArgs[], search: string) => {
    let internalArr = options;

    if (omit) {
      internalArr = internalArr.filter((op) => !isSameSearch(op, omit));
    }

    if (origin) {
      internalArr = internalArr.filter(
        (op) => op["origin"].label === origin.label
      );
    }

    return internalArr.filter((op) =>
      op[type].label.toLowerCase().includes(search.toLowerCase())
    );
  };

export const retrieveRecentSearches = () => {
  try {
    const recentSearches = window.localStorage.getItem(FLIGHT_SEARCH_KEY);

    if (!recentSearches) {
      return [];
    }

    const parsedRecentSearches = JSON.parse(recentSearches);

    if (!Array.isArray(parsedRecentSearches)) {
      console.warn(
        "Invalid data format for recent searches:",
        parsedRecentSearches
      );
      return [];
    } else {
      const mappedSearches = parsedRecentSearches.map(mapExpiredSearch);
      saveRecentSearches(mappedSearches);
      return mappedSearches;
    }
  } catch (error) {
    console.error(
      "Failed to retrieve recent searches from localStorage:",
      error
    );
    return [];
  }
};

export const saveRecentSearch = (params: BuildFlightShopQueryArgs) => {
  try {
    const recentSearches = retrieveRecentSearches();

    const isSaved = recentSearches.some((search) =>
      isSameSearch(search, params)
    );

    if (isSaved) {
      saveRecentSearches([
        params,
        ...recentSearches.filter((p) => !isSameSearch(p, params)),
      ]);
      return;
    }

    saveRecentSearches([params, ...recentSearches.slice(0, 4)]);
  } catch (error) {
    console.error("Failed to save recent search:", error);
  }
};

const saveRecentSearches = (searches: BuildFlightShopQueryArgs[]) => {
  try {
    window.localStorage.setItem(FLIGHT_SEARCH_KEY, JSON.stringify(searches));
  } catch (error) {
    console.error("Failed to save recent searches:", error);
  }
};

const mapExpiredSearch = (search: BuildFlightShopQueryArgs) => {
  const today = dayjs();
  const isPastToday = dayjs(search.departureDate).isBefore(today);

  if (!isPastToday) {
    return search;
  }

  if (search.tripCategory === TripCategory.ONE_WAY) {
    search.departureDate = today.add(DAYS_FORWARD, "days").format("YYYY-MM-DD");
    return search;
  } else {
    const diff = dayjs(search.returnDate).diff(
      dayjs(search.departureDate),
      "days"
    );

    search.departureDate = today.add(DAYS_FORWARD, "days").format("YYYY-MM-DD");
    search.returnDate = today
      .add(DAYS_FORWARD + diff, "days")
      .format("YYYY-MM-DD");
    return search;
  }
};

const isSameSearch = (
  obj1?: BuildFlightShopQueryArgs,
  obj2?: BuildFlightShopQueryArgs
) => {
  if (!obj1 || !obj2) return false;

  const isSameType = obj1.tripCategory === obj2.tripCategory;
  const isSameOrigin = obj1?.origin?.label === obj2?.origin?.label;
  const isSameDestination =
    obj1?.destination?.label === obj2?.destination?.label;
  const isSamePassengerCount =
    countPassengers(obj1.passengerCounts) ===
    countPassengers(obj2.passengerCounts);

  if (
    !isSameType ||
    !isSameOrigin ||
    !isSameDestination ||
    !isSamePassengerCount
  ) {
    return false;
  }

  if (
    obj1.tripCategory === TripCategory.ROUND_TRIP &&
    obj2.tripCategory === TripCategory.ROUND_TRIP
  ) {
    return (
      obj1.departureDate === obj2.departureDate &&
      obj1.returnDate === obj2.returnDate
    );
  } else {
    return obj1.departureDate === obj2.departureDate;
  }
};

const countPassengers = (value: IPassengerCounts) =>
  value.adultsCount +
  value.childrenCount +
  value.infantsOnLapCount +
  value.infantsInSeatCount;
