import { assign, type DoneInvokeEvent } from "xstate";
import { CallState } from "@hopper-b2b/types";
import { setContextWithKey, getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";

import type {
  AddProductsEvent,
  RemoveProductsEvent,
  AddPaymentsEvent,
  RemovePaymentsEvent,
  SetCartUpdateError,
  ClearCartUpdateError,
} from "./events";
import type { CartContext } from "../context";

export const actions = {
  // Fired in parent machine
  addProducts: assign((ctx: CartContext, event: AddProductsEvent) =>
    setContextWithKey(
      ctx,
      `${ParentState.cartUpdate}.addProducts`,
      event.products
    )
  ),
  addPayments: assign((ctx: CartContext, event: AddPaymentsEvent) =>
    setContextWithKey(
      ctx,
      `${ParentState.cartUpdate}.addPayments`,
      event.payments
    )
  ),
  removeProducts: assign((ctx: CartContext, event: RemoveProductsEvent) =>
    setContextWithKey(
      ctx,
      `${ParentState.cartUpdate}.removeProducts`,
      event.products
    )
  ),
  removePayments: assign((ctx: CartContext, event: RemovePaymentsEvent) =>
    setContextWithKey(
      ctx,
      `${ParentState.cartUpdate}.removePayments`,
      event.payments
    )
  ),
  clearCartUpdate: assign((ctx: CartContext, _event: RemovePaymentsEvent) =>
    setContextWithKey(ctx, ParentState.cartUpdate, {
      addProducts: [],
      removeProducts: [],
      removePayments: [],
      addPayments: [],
      error: undefined,
    })
  ),
  clearCartUpdateError: assign(
    (ctx: CartContext, _event: ClearCartUpdateError) =>
      setContextWithKey(ctx, `${ParentState.cartUpdate}.error`, undefined)
  ),
  setCartUpdateError: assign(
    (ctx: CartContext, event: DoneInvokeEvent<SetCartUpdateError>) => {
      return setContextWithKey(
        ctx,
        `${ParentState.cartUpdate}.error`,
        event.data
      );
    }
  ),
  setCartUpdateCallStateInProcess: assign((ctx: CartContext, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.cartUpdate}.callState`,
      CallState.InProcess
    )
  ),
  setCartUpdateCallStateSuccess: assign((ctx: CartContext, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.cartUpdate}.callState`,
      CallState.Success
    )
  ),
  setCartUpdateCallStateFailed: assign((ctx: CartContext, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.cartUpdate}.callState`,
      CallState.Failed
    )
  ),
  setCartUpdateCallStateNotCalled: assign((ctx: CartContext, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.cartUpdate}.callState`,
      CallState.NotCalled
    )
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);
