import { v4 as uuidv4 } from "uuid";

export const getSessionId = () => {
  const sessionIdKey = "sessionId";
  const sessionId = sessionStorage.getItem(sessionIdKey)
    ? sessionStorage.getItem(sessionIdKey)
    : uuidv4();
  sessionStorage.setItem(sessionIdKey, sessionId);

  return sessionId;
};
