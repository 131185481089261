import { FlightsFareSlice } from "@b2bportal/air-shopping-api";
import {
  FareDetails,
  FlightShopCardType,
  TripDetails,
  TrackingEventEntryPoint,
} from "@hopper-b2b/types";

import { Box } from "@material-ui/core";
import clsx from "clsx";
import { TFunction } from "i18next";

import { FareDetailsCard as MiniFareDetailsCard } from "../uber/FareDetailsCard";
import "./styles.scss";
import { Slot } from "../Slots";
import { useEnableFlightDetailsV2 } from "@hopper-b2b/utilities";

/**
 * Flight Details Card
 * @function FlightDetailsCard
 * @param classes
 *
 */
export interface IFlightDetailsCardProps {
  className?: string;
  inExchange?: boolean;
  tripDetails: TripDetails;
  selectedFareId?: string;
  isOutgoing: boolean;
  onClick?: () => void;
  onFareClick?: (fareId: string) => void;
  onAlgomerchClick?: (label: string) => void;
  // TODO: removed for storybook
  // fareNotice?: IFareNotice[];
  fareNotice?: any;
  isMobile?: boolean;
  rewardsKey?: string;
  plusDays?: number;
  getEmptyRestrictionsText: (
    fareRating: number | undefined,
    translate: TFunction
  ) => string;
  header?: JSX.Element;
  isMixedCabinClass?: boolean;
  selectedFareClassFilters: Array<number>;
  cardType?: FlightShopCardType;
  selectedOutgoingFareSlice?: FlightsFareSlice;
  setMobileSelectedFareId?: (fareId: string) => void;
  setExpandedFlight: (expandedFlight: string) => void;
  getFlightShopFareSlice: (fareId: string) => FlightsFareSlice;
}

export const FlightDetailsCard = ({
  className,
  inExchange,
  onClick,
  tripDetails,
  selectedFareId,
  isOutgoing,
  onFareClick,
  onAlgomerchClick,
  isMobile,
  fareNotice,
  rewardsKey,
  plusDays,
  getEmptyRestrictionsText = () => "",
  header,
  isMixedCabinClass,
  selectedFareClassFilters,
  cardType,
  selectedOutgoingFareSlice,
  setMobileSelectedFareId,
  getFlightShopFareSlice,
  setExpandedFlight,
}: IFlightDetailsCardProps) => {
  const enableFlightDetailsV2 = useEnableFlightDetailsV2();
  const fare = tripDetails.fareDetails.find(
    (fare) => fare.id === selectedFareId
  );
  const selectedFare = fare ? fare : tripDetails.fareDetails[0];

  const onFareDetailsClicked = (fare: FareDetails) => {
    if (onFareClick) {
      onFareClick(fare.id);
    }
  };

  const selectedFareSlice =
    selectedFare.slices[
      selectedFare.slices.findIndex((slice) =>
        isOutgoing ? slice.outgoing : !slice.outgoing
      )
    ];

  return (
    <Box
      className={clsx("flight-details-card", className, {
        mini: cardType === FlightShopCardType.MINI,
        desktop: !isMobile,
        mobile: isMobile,
        "single-column-flight-details-card": enableFlightDetailsV2,
      })}
      onClick={onClick}
    >
      {/* this slot is desktop only and affects only trip selection Outbound/return */}
      {enableFlightDetailsV2 ? (
        <Slot
          id="flight-with-fare-details-summary"
          isOutgoing={isOutgoing}
          tripDetails={tripDetails}
          onFareClicked={(fare: any) => onFareDetailsClicked(fare)}
          fareSlice={selectedFareSlice}
          setExpandedFlight={setExpandedFlight}
          entryPoint={
            isOutgoing
              ? TrackingEventEntryPoint.Outbound
              : TrackingEventEntryPoint.Return
          }
        />
      ) : (
        <>
          <Box className="flight-details">
            <Slot
              id="flight-details-summary"
              className="flight-summary"
              entryPoint={
                isOutgoing
                  ? TrackingEventEntryPoint.Outbound
                  : TrackingEventEntryPoint.Return
              }
              isOutgoing={isOutgoing}
              showTitle={false}
              header={header}
              totalDurationMinutes={
                tripDetails.slices[isOutgoing ? 0 : 1].totalDurationMinutes
              }
              departureTime={
                isOutgoing
                  ? tripDetails.slices[0].departureTime
                  : tripDetails.slices[tripDetails.slices.length - 1]
                      .departureTime
              }
              segments={
                isOutgoing
                  ? tripDetails.slices[0].segmentDetails
                  : tripDetails.slices[tripDetails.slices.length - 1]
                      .segmentDetails
              }
              fareClass={selectedFareSlice.fareShelf?.shortBrandName || ""}
              planeInfo={
                selectedFareSlice.amenitiesUtas?.amenities?.aircraft.info
                  .displayText || ""
              }
              plusDays={plusDays}
              fareSlice={selectedFareSlice}
              isMixedCabinClass={isMixedCabinClass}
              renderAirlineIconSection={false}
            />
          </Box>
          <Box m={!isMobile ? 3 : undefined} className="fare-details-container">
            <Box className="fare-details">
              {cardType === FlightShopCardType.MINI && isMobile ? (
                <MiniFareDetailsCard
                  className={clsx("flight-fare-details", className)}
                  fareDetails={tripDetails.fareDetails}
                  inExchange={inExchange}
                  selectedFare={selectedFare.id}
                  isOutgoing={isOutgoing}
                  fareNotice={fareNotice}
                  onAlgomerchClick={onAlgomerchClick}
                  isMobile={isMobile}
                  rewardsKey={rewardsKey}
                  getEmptyRestrictionsText={getEmptyRestrictionsText}
                  selectedFareClassFilters={selectedFareClassFilters}
                  selectedOutgoingFareSlice={selectedOutgoingFareSlice}
                  onFareSelect={onFareDetailsClicked}
                  setClickedFareId={setMobileSelectedFareId}
                  getFlightShopFareSlice={getFlightShopFareSlice}
                />
              ) : (
                <Slot
                  id="fare-details-card"
                  className={clsx("flight-fare-details", className)}
                  fareDetails={tripDetails.fareDetails}
                  onClick={(fare: any) => onFareDetailsClicked(fare)}
                  selectedFare={selectedFare.id}
                  isOutgoing={isOutgoing}
                  fareNotice={fareNotice}
                  onAlgomerchClick={onAlgomerchClick}
                  isMobile={isMobile}
                  rewardsKey={rewardsKey}
                  getEmptyRestrictionsText={getEmptyRestrictionsText}
                  selectedFareClassFilters={selectedFareClassFilters}
                  selectedOutgoingFareSlice={selectedOutgoingFareSlice}
                  getFlightShopFareSlice={getFlightShopFareSlice}
                  tripDetails={tripDetails}
                  setExpandedFlight={setExpandedFlight}
                />
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default FlightDetailsCard;
