import { axiosInstance } from "@hopper-b2b/api";
import { type TokenizeCardErrors } from "@hopper-b2b/types";
import { type AxiosError } from "axios";
import {
  type SpreedlyCreatePaymentMethodErrorResponse,
  type SpreedlyCreatePaymentMethodRequest,
  type SpreedlyCreatePaymentMethodResponse,
} from "../types";
import { isSpreedlyCreatePaymentMethodError } from "../utils";

const SPREEDLY_CORE_URL = "https://core.spreedly.com";
const SPREEDLY_PAYMENT_METHOD_URI = "/v1/payment_methods.json";

export type PostSpreedlyPaymentMethodArgs = {
  key: string;
  data: SpreedlyCreatePaymentMethodRequest;
  onSuccess: (token: string, last4: string) => void;
  onError: (error) => void;
  onFieldErrors: (errors: TokenizeCardErrors[]) => void;
};

export const postSpreedlyPaymentMethod = async ({
  key,
  data,
  onSuccess,
  onError,
  onFieldErrors,
}: PostSpreedlyPaymentMethodArgs): Promise<void> => {
  try {
    const res = await axiosInstance.post<SpreedlyCreatePaymentMethodResponse>(
      `${SPREEDLY_CORE_URL}${SPREEDLY_PAYMENT_METHOD_URI}?environment_key=${key}`,
      data
    );

    const paymentMethod = res?.data?.transaction?.payment_method;
    return onSuccess(paymentMethod.token, paymentMethod.last_four_digits);
  } catch (e: AxiosError<SpreedlyCreatePaymentMethodErrorResponse> | any) {
    if (
      isSpreedlyCreatePaymentMethodError(e) &&
      e.response.data.errors.length > 0
    ) {
      return onFieldErrors(e.response.data.errors);
    } else {
      return onError(e);
    }
  }
};
