import { IAirlineOptions } from "@b2bportal/core-types";
import { CheckboxGroup } from "@components/ui";

interface IAirlineFilterProps {
  allAirlines: IAirlineOptions[];
  airlineFilter: string[];
  onAirlineFilterChange: (val: string[]) => void;
}

export const FilterAirline = (props: IAirlineFilterProps) => {
  const { allAirlines, airlineFilter, onAirlineFilterChange } = props;

  return (
    <CheckboxGroup
      options={allAirlines}
      checkedOptions={airlineFilter}
      selectedOptions={airlineFilter}
      onOptionsChange={onAirlineFilterChange}
    />
  );
};
