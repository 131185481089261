import { TripCategory } from "@b2bportal/core-types";
import { useI18nContext } from "@hopper-b2b/i18n";
import type { RadioOption } from "@hopper-b2b/types";
import { SelectField } from "../SelectField";
import { ReactComponent as TripCategoryIcon } from "./tripCatIcon.svg";
import "./styles.scss";

export type TripCategoryPickerProps = {
  tripCategory: TripCategory;
  setTripCategory: (tripCat: TripCategory) => void;
};

export const TripCategoryPicker = ({
  tripCategory,
  setTripCategory,
}: TripCategoryPickerProps) => {
  const { t } = useI18nContext();

  const tripOptions: RadioOption[] = [
    { label: t("flightRoundTrip"), value: TripCategory.ROUND_TRIP },
    { label: t("flightOneWay"), value: TripCategory.ONE_WAY },
  ];
  return (
    <SelectField
      options={tripOptions}
      setOption={(tripCat) => setTripCategory(tripCat as TripCategory)}
      selected={tripCategory}
      placeholder={"Trip type"}
      startAdornment={<TripCategoryIcon className={"tripCatIcon"} />}
      required
      hideEndAdornment
    />
  );
};
