import { createSelector } from "@reduxjs/toolkit";
import { selectCfarState } from "../../../store/selectors";

export const selectCfarExerciseState = createSelector(
  selectCfarState,
  ({ exercise }) => exercise
);

export const selectQuoteFetchState = createSelector(
  selectCfarExerciseState,
  ({ quoteFetchState }) => quoteFetchState
);

export const selectSuccessQuoteFetchState = createSelector(
  selectQuoteFetchState,
  ({ data }) =>
    data?.AirCfarExerciseQuoteResponse === "QuoteSuccess" ? data : undefined
);

export const selectQuoteFetchTrackingProperties = createSelector(
  selectSuccessQuoteFetchState,
  (state) => state?.trackingProperties
);

export const selectFulfillQuoteFetchState = createSelector(
  selectCfarExerciseState,
  ({ fulfillQuoteFetchState }) => fulfillQuoteFetchState
);
