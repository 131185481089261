import { FLIGHTS_PATH } from "@b2bportal/core-utilities";

export const PATH_FINTECH_CUSTOMIZE_RELATIVE = "/customize";
export const PATH_DISRUPTION_RELATIVE = "/customize/disruption";
export const PATH_CFAR_RELATIVE = "/customize/cfar";

export const PATH_FINTECH_CUSTOMIZE_PAGE =
  FLIGHTS_PATH + PATH_FINTECH_CUSTOMIZE_RELATIVE;
export const PATH_DISRUPTION_PAGE = FLIGHTS_PATH + PATH_DISRUPTION_RELATIVE;
export const PATH_CFAR_PAGE = FLIGHTS_PATH + PATH_CFAR_RELATIVE;

export const VIEW_TERMS_LINK = "http://travel.lloydsbank.co.uk/terms";
