import { IconComponent, IconName } from "@hopper-b2b/ui";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import clsx, { ClassValue } from "clsx";
import { ReactNode } from "react";

import styles from "./ContactSupportOption.module.scss";

export interface IContactSupportOptionProps {
  caption?: ReactNode;
  className?: ClassValue;
  disabled?: boolean;
  icon: IconName;
  onClick: () => void;
  subtitle: ReactNode;
  title: ReactNode;
}

const ContactSupportOption = (
  props: IContactSupportOptionProps
): JSX.Element => {
  const {
    caption,
    className,
    disabled = false,
    icon,
    onClick,
    subtitle,
    title,
  } = props;

  return (
    <ButtonBase
      className={clsx("contact-option-btn", className, styles.contactOptionBtn)}
      disabled={disabled}
      onClick={onClick}
    >
      <Box>
        <IconComponent
          className={clsx("contact-option-icon", styles.contactOptionIcon)}
          name={icon}
        />
      </Box>
      <Box
        className={clsx("contact-option-details", styles.contactOptionDetails)}
      >
        <Box
          className={clsx(
            "contact-option-title-row",
            styles.contactOptionTitleRow
          )}
        >
          <Typography
            className={clsx("contact-option-title", styles.contactOptionTitle)}
            component="span"
            display="inline"
            variant="body1"
          >
            {title}
          </Typography>
          {caption && (
            <Typography
              className={clsx(
                "contact-option-title-caption",
                styles.contactOptionTitleCaption
              )}
              component="span"
              variant="caption"
            >
              {caption}
            </Typography>
          )}
        </Box>
        <Typography
          className={clsx(
            "contact-option-subtitle",
            styles.contactOptionSubtitle
          )}
          variant="subtitle1"
        >
          {subtitle}
        </Typography>
      </Box>
      {disabled ? null : (
        <Box>
          <IconComponent
            className={clsx("contact-option-icon", styles.contactOptionIcon)}
            name={IconName.SupportChevronRight}
          />
        </Box>
      )}
    </ButtonBase>
  );
};

export default ContactSupportOption;
