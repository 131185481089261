import { SerializedError } from "@reduxjs/toolkit";

export enum ThunkErrorEnum {
  Rejected = "rejected",
  Aborted = "aborted",
  Unknown = "unknown",
}

export const processErrorForThunk = <RejectValue>(action: {
  payload?: RejectValue;
  error: SerializedError;
  meta: { aborted: boolean };
}): ThunkErrorType<RejectValue> => {
  if (action.payload != null) {
    return { type: ThunkErrorEnum.Rejected, error: action.payload };
  } else if (action.meta.aborted) {
    return { type: ThunkErrorEnum.Aborted, error: action.error };
  } else {
    return { type: ThunkErrorEnum.Unknown, error: action.error };
  }
};

interface BaseThunkError<Type extends ThunkErrorEnum, ErrorType> {
  type: Type;
  error: ErrorType;
}

export type ThunkErrorType<RejectValue> =
  | BaseThunkError<ThunkErrorEnum.Rejected, RejectValue>
  | BaseThunkError<ThunkErrorEnum.Aborted, SerializedError>
  | BaseThunkError<ThunkErrorEnum.Unknown, SerializedError>;
