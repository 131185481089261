import type { SessionData } from "@b2bportal/lloyds-auth-api";
import { LoadingScreen } from "@lloyds/ui-connected";
import { B2B_PORTAL_AUTH_REDIRECT_TO } from "@hopper-b2b/types";
import { PATH_AUTH_INVALID_SESSION, PATH_HOME } from "@hopper-b2b/utilities";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import { type StartSessionRequest, startSession } from "../../api";
import { UserSessionContext } from "../../context";

export const SESSION_TOKEN_PARAM = "token";
export const REDIRECT_URI_PARAM = "redirect_uri";
export const INITIATOR_PARAM = "initiator";

const doStartSession = async (
  { token, initiator }: StartSessionRequest,
  onSuccess: (response: SessionData) => void,
  onFailure: () => void
) => {
  try {
    if (!token) {
      throw Error("Invalid url params in startSession");
    }

    const response = await startSession({
      token,
      initiator,
    });
    if (response && response.accessToken) {
      onSuccess(response);
    } else {
      throw Error("Invalid response from startSession");
    }
  } catch (err) {
    console.error("START SESSION ERROR", { err });
    onFailure();
  }
};

export const StartSessionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { updateSessionInfo } = useContext(UserSessionContext);

  const searchParams = useMemo(
    () => new URLSearchParams(location?.search),
    [location]
  );

  const decodedToken = useMemo(() => {
    const token = searchParams.get(SESSION_TOKEN_PARAM);
    return token ? decodeURIComponent(token) : token;
  }, [searchParams]);

  // initiator represents the redirect_uri used when generating an auth code
  const decodedInitiator = useMemo(() => {
    const initiator = searchParams.get(INITIATOR_PARAM);
    return initiator ? decodeURIComponent(initiator) : undefined;
  }, [searchParams]);

  const decodedRedirectUri = useMemo(() => {
    const redirectUri = searchParams.get(REDIRECT_URI_PARAM) || PATH_HOME;
    return decodeURIComponent(redirectUri);
  }, [searchParams]);

  const redirectToSessionStorageUrl = useCallback(
    (url: string) => {
      navigate(url);
      sessionStorage.removeItem(B2B_PORTAL_AUTH_REDIRECT_TO);
    },
    [navigate]
  );

  const onStartSessionSuccess = useCallback(
    (response: SessionData) => {
      updateSessionInfo(response);

      const redirectTo =
        decodedRedirectUri ||
        sessionStorage.getItem(B2B_PORTAL_AUTH_REDIRECT_TO);
      if (redirectTo) redirectToSessionStorageUrl(redirectTo);
      else navigate(PATH_HOME);
    },
    [
      decodedRedirectUri,
      navigate,
      redirectToSessionStorageUrl,
      updateSessionInfo,
    ]
  );

  const onStartSessionFailure = useCallback(() => {
    navigate(`${PATH_HOME}${PATH_AUTH_INVALID_SESSION}`);
  }, [navigate]);

  useEffect(() => {
    doStartSession(
      { token: decodedToken, initiator: decodedInitiator },
      onStartSessionSuccess,
      onStartSessionFailure
    );
  }, [
    decodedInitiator,
    decodedToken,
    onStartSessionFailure,
    onStartSessionSuccess,
  ]);

  return <LoadingScreen />;
};
