export const CORE_DISRUPTION_NAME = "core-disruption";

export enum CoreDisruptionComponent {
  DisruptionOfferSection = "DisruptionOfferSection",
  DisruptionOfferSectionWrapper = "DisruptionOfferSectionWrapper",
  DisruptionUniversalExercise = "DisruptionUniversalExercise",
  DisruptionExerciseContainer = "DisruptionExerciseContainer",
  DisruptionExerciseButton = "DisruptionExerciseButton",
  DisruptionExerciseFooter = "DisruptionExerciseFooter",
  DisruptionExerciseHeader = "DisruptionExerciseHeader",
  DisruptionExerciseMoreInfo = "DisruptionExerciseMoreInfo",
  DisruptionExerciseLandingPage = "DisruptionExerciseLandingPage",
  DisruptionExerciseOptionsCard = "DisruptionExerciseOptionsCard",
  DisruptionExercisePage = "DisruptionExercisePage",
  DisruptionExercisePolicyDetails = "DisruptionExercisePolicyDetails",
  DisruptionExercisePrimaryButton = "DisruptionExercisePrimaryButton",
  DisruptionExerciseRefundConfirmationPage = "DisruptionExerciseRefundConfirmationPage",
  DisruptionExerciseRefundInformationCard = "DisruptionExerciseRefundInformationCard",
  DisruptionExerciseRefundReviewContainer = "DisruptionExerciseRefundReviewContainer",
  DisruptionExerciseRefundReviewPage = "DisruptionExerciseRefundReviewPage",
  DisruptionExerciseRefundSummaryCard = "DisruptionExerciseRefundSummaryCard",
  DisruptionExerciseSelection = "DisruptionExerciseSelection",
  DisruptionExerciseSummaryCard = "DisruptionExerciseSummaryCard",
  DisruptionInfoBanner = "DisruptionInfoBanner",
  DisruptionExpandableContainer = "DisruptionExpandableContainer",
  DisruptionExerciseLearnMoreContent = "DisruptionExerciseLearnMoreContent",
  DisruptionExercisePopup = "DisruptionExercisePopup",
  DisruptionExercisePageContent = "DisruptionExercisePageContent",
  DisruptionExerciseBulletPoint = "DisruptionExerciseBulletPoint",
  DisruptionExerciseInformationCard = "DisruptionExerciseInformationCard",
  HowItWorksDialog = "HowItWorksDialog",
  FlightDisruptionAlertBanner = "FlightDisruptionAlertBanner",
  DisruptionContactSupport = "DisruptionContactSupport",
  DisruptionExerciseNotEligiblePage = "DisruptionExerciseNotEligiblePage",
  DisruptionExerciseLoading = "DisruptionExerciseLoading",
  TextWithExternalLink = "TextWithExternalLink",
  ExternalLinkContainer = "ExternalLinkContainer",
  DisruptionExerciseRebookOnboardingPage = "DisruptionExerciseRebookOnboardingPage",
  DisruptionExerciseRebookSegmentSelectPage = "DisruptionExerciseRebookSegmentSelectPage",
  DisruptionExerciseSegmentSelectCard = "DisruptionExerciseSegmentSelectCard",
  DisruptionExerciseFlightInfoRow = "DisruptionExerciseFlightInfoRow",
  DisruptionExerciseRebookSelectFlightPage = "DisruptionExerciseRebookSelectFlightPage",
  DisruptionExerciseFlightSliceCard = "DisruptionExerciseFlightSliceCard",
  DisruptionExerciseRebookConfirmationPage = "DisruptionExerciseRebookConfirmationPage",
  DisruptionExerciseRebookCheckoutPage = "DisruptionExerciseRebookCheckoutPage",
  DisruptionExerciseCheckoutComponent = "DisruptionExerciseCheckoutComponent",
  DisruptionExerciseShopFilter = "DisruptionExerciseShopFilter",
  DisruptionExerciseRebookFlightShopPage = "DisruptionExerciseRebookFlightShopPage",
  DisruptionExerciseNoFlightsAvailable = "DisruptionExerciseNoFlightsAvailable",
  DisruptionExerciseCheckbox = "DisruptionExerciseCheckbox",
  DisruptionExerciseFlightCard = "DisruptionExerciseFlightCard",
  DisruptionFintechMarketplaceProduct = "DisruptionFintechMarketplaceProduct",
}
