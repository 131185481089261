import { useI18nContext } from "@hopper-b2b/i18n";
import { IconComponent, IconName } from "@hopper-b2b/ui";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import clsx, { ClassValue } from "clsx";

import { useChatContext } from "../../hooks";
import { BottomSheetContentEnum } from "../../types";
import { ChatAvatar } from "../ChatAvatar";

import styles from "./ChatWindowHeader.module.scss";

export interface IChatWindowHeaderProps {
  className?: ClassValue;
  onMinimize: () => void;
}

const ChatWindowHeader = (props: IChatWindowHeaderProps): JSX.Element => {
  const { className, onMinimize } = props;
  const { closeChatWindow, conversationId, setBottomSheetContent } =
    useChatContext();
  const { t } = useI18nContext();

  function handleClose() {
    if (conversationId) {
      setBottomSheetContent(BottomSheetContentEnum.CSATFormResolved);
    } else {
      closeChatWindow();
    }
  }

  return (
    <Box
      className={clsx(className, "chat-window-header", styles.chatWindowHeader)}
    >
      <Box
        className={clsx("agent-avatar-container", styles.agentAvatarContainer)}
      >
        <ChatAvatar />
      </Box>
      <Box
        className={clsx(
          "agent-details-container",
          styles.agentDetailsContainer
        )}
      >
        <Typography
          className={clsx("agent-details-title", styles.agentDetailsTitle)}
          variant="body1"
        >
          {t("chat.header.title")}
        </Typography>
        <Typography
          className={clsx(
            "agent-details-subtitle",
            styles.agentDetailsSubtitle
          )}
          variant="subtitle2"
        >
          {t("chat.header.subtitle")}
        </Typography>
      </Box>
      <Box className={clsx("action-container", styles.actionContainer)}>
        <Tooltip title={t("chat.header.minimize")}>
          <IconButton
            className={clsx("minimize-icon-button", styles.minimizeIconButton)}
            onClick={onMinimize}
            size="medium"
          >
            <IconComponent
              className={clsx("minimize-icon", styles.minimizeIcon)}
              name={IconName.ChevronDown}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("chat.header.close")}>
          <IconButton
            className={clsx("close-icon-button", styles.closeIconButton)}
            onClick={handleClose}
            size="medium"
          >
            <IconComponent
              className={clsx("close-icon", styles.closeIcon)}
              name={IconName.Close}
            />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default ChatWindowHeader;
