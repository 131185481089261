import { UsageType } from "@b2bportal/card-api";
import { useI18nContext, useI18nCountry } from "@hopper-b2b/i18n";
import {
  postSpreedlyPaymentMethod,
  type SpreedlyCreatePaymentMethodRequest,
  type SpreedlyCreditCard,
} from "@hopper-b2b/spreedly-js";
import type { TokenizeCardErrors } from "@hopper-b2b/types";
import { FloatingBox, type ICheckoutPaymentFormProps } from "@hopper-b2b/ui";
import {
  getExpiryDateError,
  getRequiredFieldError,
  getZipError,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { ActionButton } from "@lloyds/ui-core";
import { config } from "@lloyds/utils";
import { Box, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import { SelectField } from "../SelectField";
import { NumberInputField, TextInputField } from "../TextInputField";
import styles from "./CheckoutPaymentForm.module.scss";

interface CheckoutPaymentFormProps {
  showSavePaymentOption?: boolean;
}

export const CheckoutPaymentForm = ({
  loading: paymentsLoading,
  saveLabel,
  onSubmit,
  onClose,
  onError,
  showSavePaymentOption = true,
}: Pick<
  ICheckoutPaymentFormProps,
  "loading" | "saveLabel" | "onSubmit" | "onError" | "onClose"
> &
  CheckoutPaymentFormProps) => {
  const { matchesMobile } = useDeviceTypes();

  const { t, brand } = useI18nContext();
  const { getAllCountryNames } = useI18nCountry();

  const [loading, setLoading] = useState(paymentsLoading);

  const [number, setNumber] = useState("");
  const [numberValidationError, setNumberValidationError] = useState(null);

  const [fullName, setFullName] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const [cvv, setCvv] = useState("");
  const [cvvValidationError, setCvvValidationError] = useState(null);

  const [country, setCountry] = useState(brand?.preferredCountryCode ?? "GB");
  const [zip, setZip] = useState("");

  const [savePaymentMethod, setSavePaymentMethod] = useState<boolean>(true);

  // Adapted from: https://stackoverflow.com/questions/45259196/javascript-regex-credit-card-expiry-date-auto-format
  const formattedExpiryDate = useMemo(
    () =>
      expiryDate
        .replace(/^([1-9]\/|[2-9])$/g, "0$1/")
        .replace(/^(0[1-9]|1[0-2])$/g, "$1/")
        .replace(/^([0-1])([3-9])$/g, "0$1/$2")
        .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, "$1/$2")
        .replace(/^([0]+)\/|[0]+$/g, "0")
        .replace(/[^\d/]|^[/]*$/g, "")
        .replace(/\/\//g, "/")
        .substring(0, 7),
    [expiryDate]
  );

  const numberError: string = useMemo(
    () =>
      numberValidationError ??
      (getRequiredFieldError(number) && t("requiredField")),
    [number, numberValidationError, t]
  );

  const fullNameError: string = useMemo(
    () => getRequiredFieldError(fullName) && t("requiredField"),
    [fullName, t]
  );

  const expiryDateError: string = useMemo(
    () => getExpiryDateError({ expiryDate: formattedExpiryDate }),
    [formattedExpiryDate]
  );

  const cvvError: string = useMemo(
    () =>
      cvvValidationError ?? (getRequiredFieldError(cvv) && t("requiredField")),
    [cvv, cvvValidationError, t]
  );

  const countryError: string = useMemo(
    () => getRequiredFieldError(country) && t("requiredField"),
    [country, t]
  );

  const zipError: string = useMemo(() => {
    if (getRequiredFieldError(zip)) {
      return t("zipErrorRequired");
    } else {
      const error = getZipError(zip, country);
      return error && t(error as string);
    }
  }, [zip, t, country]);

  const formFilled = useMemo(
    () =>
      !!(
        number !== "" &&
        fullName !== "" &&
        formattedExpiryDate !== "" &&
        cvv !== "" &&
        country !== "" &&
        zip !== ""
      ),
    [number, fullName, formattedExpiryDate, cvv, country, zip]
  );

  const errorExists = useMemo(
    () =>
      !!numberError ||
      !!fullNameError ||
      !!expiryDateError ||
      !!cvvError ||
      !!countryError ||
      !!zipError,
    [
      numberError,
      fullNameError,
      expiryDateError,
      cvvError,
      countryError,
      zipError,
    ]
  );

  const paymentData: SpreedlyCreatePaymentMethodRequest = useMemo(() => {
    const firstSpaceIndex = fullName.indexOf(" ");
    const firstName = fullName.substring(0, firstSpaceIndex);
    const otherNames = fullName.substring(firstSpaceIndex + 1);

    const dateParts = formattedExpiryDate.split("/");
    const month = dateParts && dateParts.length > 0 ? dateParts[0] : "";
    const year = dateParts && dateParts.length > 1 ? dateParts[1] : "";

    return {
      payment_method: {
        credit_card: {
          first_name: firstName,
          last_name: otherNames,
          number,
          verification_value: cvv,
          month,
          year,
          country,
          zip,
        } as SpreedlyCreditCard,
      },
    };
  }, [fullName, number, cvv, formattedExpiryDate, country, zip]);

  const onSuccess = useCallback(
    (token: string, last4: string) => {
      onSubmit(
        token,
        last4,
        savePaymentMethod ? UsageType.MultiUsage : UsageType.SingleUsage
      );
    },
    [onSubmit, savePaymentMethod]
  );

  const onFieldErrors = useCallback(
    (fieldErrors: TokenizeCardErrors[]) => {
      const unhandledErrors = [];

      fieldErrors.forEach((fieldError: TokenizeCardErrors) => {
        switch (fieldError.attribute) {
          case "card_type":
          case "number":
            setNumberValidationError(t("paymentForm.invalidCardError"));
            break;
          case "verification_value":
            setCvvValidationError(t("paymentForm.invalidCVV"));
            break;
          default:
            unhandledErrors.push(fieldError);
            break;
        }
      });

      if (unhandledErrors && unhandledErrors.length > 0) {
        onError(unhandledErrors);
      }
    },
    [onError, t]
  );

  const submitPaymentForm = useCallback(() => {
    if (loading) return;

    setLoading(true);

    postSpreedlyPaymentMethod({
      key: config.spreedlyKey,
      data: paymentData,
      onSuccess,
      onError,
      onFieldErrors,
    }).finally(() => {
      setLoading(false);
    });
  }, [loading, paymentData, onSuccess, onError, onFieldErrors]);

  const onToggleSaveMethod = useCallback(() => {
    setSavePaymentMethod(!savePaymentMethod);
  }, [setSavePaymentMethod, savePaymentMethod]);

  const onChangeNumber = useCallback((value: string) => {
    setNumberValidationError(null);
    setNumber(value);
  }, []);

  const onChangeCvv = useCallback((value: string) => {
    setCvvValidationError(null);
    setCvv(value);
  }, []);

  const countryOptions = useMemo(() => {
    const options = Array.from(Object.entries(getAllCountryNames())).map(
      ([code, label]) => ({
        value: code,
        label,
      })
    );
    // Replace with toSorted once we migrate to es2023+
    options.sort((a, b) => a.label.localeCompare(b.label));
    return options;
  }, [getAllCountryNames]);

  return (
    <Grid container spacing={4} direction="column">
      <Grid item>
        <Grid
          container
          spacing={4}
          direction={matchesMobile ? "column" : "row"}
        >
          <Grid item xs lg={6}>
            <NumberInputField
              required
              label={t("paymentForm.cardNumber")}
              value={number}
              helperText={numberError}
              onChange={onChangeNumber}
              disabled={loading}
            />
          </Grid>
          <Grid item xs lg={6}>
            <TextInputField
              required
              label={t("paymentForm.nameOnCard")}
              value={fullName}
              helperText={fullNameError}
              onChange={setFullName}
              disabled={loading}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={4}
          direction={matchesMobile ? "column" : "row"}
        >
          <Grid item xs lg={3}>
            <NumberInputField
              required
              label={t("paymentForm.expiryDate")}
              value={formattedExpiryDate}
              helperText={
                expiryDateError &&
                t(
                  getExpiryDateError({
                    expiryDate: formattedExpiryDate,
                  }) as string
                )
              }
              onChange={setExpiryDate}
              disabled={loading}
            />
          </Grid>
          <Grid item xs lg={2}>
            <NumberInputField
              required
              label={t("paymentForm.cvv")}
              value={cvv}
              helperText={cvvError}
              onChange={onChangeCvv}
              disabled={loading}
            />
          </Grid>

          <Grid item xs lg={4}>
            <SelectField
              required
              label={t("paymentForm.country")}
              placeholder={t("paymentForm.countrySelectPlaceholder")}
              selected={country}
              helperText={countryError}
              options={countryOptions}
              setOption={(value: string | string[]) =>
                setCountry(typeof value === "string" ? value : value[0])
              }
              disabled={loading}
            />
          </Grid>
          <Grid item xs lg={3}>
            <NumberInputField
              required
              label={t("paymentForm.zipCode")}
              value={zip}
              helperText={zipError}
              onChange={setZip}
              disabled={loading}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {showSavePaymentOption ? (
            <FormControlLabel
              className={styles.checkBoxFormLabel}
              label={t("savePaymentMethod")}
              control={
                <Checkbox
                  color="primary"
                  checked={savePaymentMethod}
                  onChange={onToggleSaveMethod}
                />
              }
            />
          ) : undefined}
          {!matchesMobile ? (
            <Box display="flex" flexDirection="row" gridGap={"8px"}>
              <ActionButton
                size="small"
                defaultStyle="h4r-secondary"
                message={t("cancel")}
                onClick={onClose}
              />
              <ActionButton
                message={saveLabel || t("continue")}
                onClick={submitPaymentForm}
                disabled={!formFilled || errorExists || loading}
                size="small"
              />
            </Box>
          ) : null}
        </Box>
      </Grid>
      {matchesMobile ? (
        <Grid item>
          <FloatingBox>
            <ActionButton
              message={saveLabel || t("continue")}
              onClick={submitPaymentForm}
              disabled={!formFilled || errorExists || loading}
            />
          </FloatingBox>
        </Grid>
      ) : null}
    </Grid>
  );
};
