import type {
  CoreThemeConfig,
  PriceFreezeColorConfig,
} from "@b2bportal/core-themes";

const coreColors = {
  primary: "rgba(1, 61, 88, 1.0)",
  secondary: "#e04846",
  "text-primary": "#141414",
  "text-secondary": "rgba(103, 104, 104, 1.0)",
  border: "#CECFCD",
  "divider-color": "var(--border)",
};

export const defaultThemeConfig: CoreThemeConfig = {
  // Font family is currently only non css variable that refers to a color
  "font-family": ["Trip Sans", "sans-serif"].join(","),
  ...coreColors,

  /** === Common === */
  "popup-background-color": "#fff",
  "link-primary": "rgba(1, 61, 88, 1.0)",
  "warning-error-color-transparent": "rgba(255, 241, 208, 1.0)",
  "warning-error-color": "rgba(249, 198, 6, 1.0)",
  "red-error-color": "rgba(204, 36, 39, 1.0)",
  "red-error-color-transparent": "rgba(252, 213, 209, 1.0)",
  /** Button */
  "button-primary": "#fff",
  "button-primary-background": "#0276B1",
  "button-primary-hover-background": "#0276B1",
  "button-primary-hover": "#fff",
  "button-primary-disabled": "#fff",
  "button-primary-disabled-background": "#d7d7d7",
  "button-secondary": "var(--primary)",
  "button-secondary-background": "#fff",
  "button-secondary-border": "var(--primary)",
  "button-tertiary": "var(--text-primary)",
  "button-tertiary-background": "var(--button-secondary-background)",
  "button-tertiary-hover-background": "var(--button-secondary-background)",
  "button-tertiary-hover": "var(--button-secondary)",
  "button-tertiary-hover-border": "var(--button-secondary-border)",
  "button-tertiary-disabled": "var(--button-primary-disabled)",
  "button-tertiary-border": "var(--button-secondary-border)",
  "button-tertiary-padding": "0.5rem 1rem",
  "button-tertiary-disabled-background": "#d7d7d7",
  "pill-background": "#EDEDED",
  "pill-text-color": "var(--text-primary)",
  "pill-border-radius": "20px",
  "pill-success-background": "#0F9842",
  "pill-success-text-color": "#fff",
  "pill-error-background": "#FD2C2C",
  "pill-error-text-color": "#fff",

  /** === Homepage === */
  "homepage-title-primary": "var(--primary)",

  /** === Loading page modal === */
  "loading-icon-track-color": "transparent",
  "loading-icon-border-color": "rgba(2, 118, 177, 1.0)",
  "loading-background-color": "var(--primary)",
  "loading-text-color": "#fff",

  /** ========= UI ======== */
  /** ========= Slider ======== */
  "slider-background-color": "rgba(216, 216, 216, 1.0)",
  "slider-range-background-color": "var(--primary)",
  "slider-thumb-background-color": "var(--primary)",
  "slider-thumb-value-background-color": "var(--primary)",
  "slider-thumb-value-color": "#fff",

  /** ========= Page ======== */
  "page-header-background-color": "#fff",
  "page-header-text-color": "var(--text-primary)",
  "page-footer-background-color": "#fff",
  "page-footer-text-color": "var(--text-primary)",

  /** ========= Checkbox ======== */
  "checkbox-border-color": "var(--border)",
  "checkbox-checked-border-color": "var(--primary)",
  "checkbox-checked-background-color": "var(--primary)",
  "checkbox-checked-icon-color": "#fff",

  /** ========= Radio Button ======== */
  "radio-button-border-color": "var(--border)",
  "radio-button-selected-color": "var(--text-primary)",

  /** ========= Input ======== */
  "input-background-color": "#fff",
  "input-border-color": "var(--border)",
  "input-error-color": "red",
  "input-error-border-color": "red",

  /** ========= Carousel ======= */
  "carousel-lodging-placeholder": "#EFEFEF",
  "carousel-arrow-background": "#FFFFFF",
  "carousel-dots-container-background": "rgba(34,34,34, 0.5)",
  "carousel-dot": "#F4F5F6",
  "carousel-dot-focused": "#FFFFFF",

  /** ========= Card ======= */
  "core-card-color-border": "var(--border)",
  "core-card-color-surface": "var(--background-color)",

  /** ========= Flights ======== */
  "flight-list-search-background": "#fff",
  "algomerch-tag-background": "rgba(0, 163, 207, 0.10)",
  "algomerch-tag-text": "#013D58",
  "danger-text": "#CC2427",
  "fare-list-background": "#FBFBFB",

  "segment-icon-color": "#CECFCD",
  "segment-layover-color": "#F4F4F4",
  "flight-fare-notice": "rgba(1, 61, 88, 1.0)",
  "flight-fare-option-border": "#EFEFEF",
  "flight-fare-option-border-selected": "#013D58",
  "flight-fare-option-selected": "#013D58",

  "flight-fare-details-more-button-background": "#EFEFEF",

  "flight-loading-background-color": "var(--primary)",
  "flight-loading-text-color": "#fff",

  "flight-card-summary-icon-color": "rgb(224, 224, 224)",
  "flight-card-summary-border-color": "#5e5e5e",

  "review-page-header-color": "#fff",
  "review-page-header-background-color": "var(--primary)",
  "inactive-button-icon-color": "#f6f6f6",

  "flight-list-grid-filter-selected-background-color": "var(--primary)",
  "flight-list-grid-filter-selected-color": "#fff",
  "flight-price-breakdown-background-color": "#fff",

  "flight-itinerary-confirmation-number": "rgb(0, 129, 64)",
  "flight-itinerary-confirmation-number-not-available": "rgb(204, 36, 39)",

  /** ========= Flight Search Bar ======== */
  "flight-search-bar-text-primary": "var(--text-primary)",

  /** ========= Calendar ======== */
  "calendar-labels-background-color": "#DDD",
  "date-selected-text-color": "#FFFFFF",
  "date-hover-text-color": "#FFFFFF",
  "bucket-0-text-color": "#FFFFFF",
  "bucket-1-text-color": "#FFFFFF",
  "bucket-2-text-color": "#FFFFFF",
  "bucket-3-text-color": "#FFFFFF",
  "bucket-0-color": "rgba(0, 129, 64, 1.0)",
  "bucket-1-color": "rgba(240, 160, 42, 1.0)",
  "bucket-2-color": "rgba(227, 102, 36, 1.0)",
  "bucket-3-color": "rgba(204, 36, 39, 1.0)",
  "white-font-color": "rgba(255, 255, 255, 1.0)",
  "grey-font-color": "rgba(80, 80, 80, 1.0)",
  "date-hover-color": "rgba(0, 118, 193, 1.0)",
  "date-selected-color": "rgba(0, 118, 193, 1.0)",
  "date-range-limit-color": "rgba(0, 118, 193, 1.0)",
  "date-disabled-color": "rgba(216, 216, 216, 1.0)",
  "date-selected-background-color": "#fff",

  /** ========= Trips ======== */
  "trips-loading-background-color": "var(--loading-background-color)",
  "trips-loading-text-color": "var(--loading-text-color)",

  /** ========= Hotel Card ======== */
  "discount-tag-background": "var(--primary)",
  "discount-tag-text-color": "#fff",
  "discount-tag-border-radius": "20px",

  /** ========= Map markers ======== */
  "map-marker-text": "var(--text-primary)",
  "map-marker-surface": "var(--background-color)",
  "map-marker-hover-text": "#ffffff",
  "map-marker-hover-surface": "var(--primary)",
};

export const defaultPriceFreezeThemeConfig: PriceFreezeColorConfig = {
  "price-freeze-icon-background-color": "#F2F2F2",
  "price-freeze-icon-color": "#000",
  "price-freeze-voucher-pill-active-color": "#C9F2E3",
  "price-freeze-voucher-pill-expired-color": "#FFCCCC",
  "price-freeze-voucher-pill-cancelled-color": "#FFCCCC",
  "price-freeze-voucher-pill-exercised-color": "#E0E0E0",
  "price-freeze-voucher-pill-pending-color": "#FEE39A",
  "price-freeze-voucher-pill-refunded-color": "#E0E0E0",
  "price-freeze-savings-banner-color": "#EAFAF4",
  "price-freeze-savings-banner-accent-color": "#00AA6C",
};
