import { combineReducers } from "@reduxjs/toolkit";
import flightExchangeReducer, {
  type FlightExchangeState,
} from "../features/exchange/store";
import flightPostBookingReducer, {
  type FlightPostBookingState,
} from "../features/postbooking/store/slice";
import flightSearchReducer, {
  type FlightSearchState,
} from "../features/search/store/slice";
import flightShopReducer, {
  type FlightsShopState,
} from "../features/shop/store/slice";

export interface FlightState {
  flights: {
    flightExchange: FlightExchangeState;
    flightPostBooking: FlightPostBookingState;
    flightSearch: FlightSearchState;
    flightShop: FlightsShopState;
  };
}

export const flightsRootReducer = combineReducers({
  flightExchange: flightExchangeReducer,
  flightPostBooking: flightPostBookingReducer,
  flightShop: flightShopReducer,
  flightSearch: flightSearchReducer,
});
