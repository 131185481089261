import { CipherText } from "@b2bportal/air-disruption-api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum DisruptionExerciseProgress {
  NotStarted = "NotStarted",
  LandingPage = "LandingPage",
  RebookSelected = "RebookSelected",
  RefundSelected = "RefundSelected",
  FlightSelect = "FlightSelect",
  FlightSearch = "FlightSearch",
  Review = "Review",
  ConfirmationPage = "ConfirmationPage",
  Pending = "Pending",
  Completed = "Completed",
  RefundReview = "RefundReview",
  RefundConfirmation = "RefundConfirmation",
  RebookReview = "RebookReview",
  RebookConfirmation = "RebookConfirmation",
  ContactInfo = "ContactInfo",
  Loading = "Loading",
  NotEligible = "NotEligible",
  SegmentSelect = "SegmentSelect",
}

export interface DisruptionExerciseState {
  disruptionExerciseProgress: DisruptionExerciseProgress;
  token?: CipherText;
}

export const initialDisruptionExerciseState: DisruptionExerciseState = {
  disruptionExerciseProgress: DisruptionExerciseProgress.NotStarted,
  token: undefined,
};

export enum DisruptionContractStatus {
  REDEEM = "Redeem",
  ELIGIBLE = "Eligible",
  CONCLUDE = "Conclude",
  CANCEL = "Cancel",
  PURCHASE = "Purchase",
  // future-proof contract statuses
  ANY_EXERCISE_ELIGIBLE = "AnyExerciseEligible",
  EXERCISE_NOT_ELIGIBLE = "ExerciseNotEligible",
  REBOOK_ONLY_ELIGIBLE = "RebookOnlyEligible",
  ALREADY_EXERCISED = "AlreadyExercised",
}

export enum EventCovered {
  DELAYS = "Delays",
  MISSED_CONNECTIONS = "MissedConnections",
  CANCELLATIONS = "Cancellations",
}

export const disruptionExerciseSlice = createSlice({
  name: "disruptionExerciseState",
  initialState: initialDisruptionExerciseState,
  reducers: {
    setDisruptionExerciseProgress: (
      state,
      action: PayloadAction<DisruptionExerciseProgress>
    ) => {
      state.disruptionExerciseProgress = action.payload;
    },
    setDisruptionExerciseToken: (state, action: PayloadAction<CipherText>) => {
      state.token = action.payload;
    },
    resetDisruptionExerciseState: (state) => {
      state.disruptionExerciseProgress = DisruptionExerciseProgress.NotStarted;
    },
  },
});

export const DisruptionExerciseActions = disruptionExerciseSlice.actions;
export const DisruptionExerciseReducer = disruptionExerciseSlice.reducer;

// to tie into legacy functions
export const setDisruptionExerciseProgress =
  DisruptionExerciseActions.setDisruptionExerciseProgress;
export const setDisruptionExerciseToken =
  DisruptionExerciseActions.setDisruptionExerciseToken;
export const resetDisruptionExerciseState =
  DisruptionExerciseActions.resetDisruptionExerciseState;
