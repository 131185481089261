import { CalendarBuckets } from "@b2bportal/air-shopping-api";
import { FlightState } from "@b2bportal/core-flights";
import { flightsShoppingApi } from "@overrides/utilities";
import {
  IMonthBucket,
  ITripTerminus,
  SerializableMonthBucket,
  TripCategory,
  TripFilter,
} from "@b2bportal/core-types";
import { serializableMonthBuckets } from "@b2bportal/core-utilities";
import { IBucketedDate, IDateBucket } from "@hopper-b2b/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

interface FetchCalendarAction {
  origin: ITripTerminus;
  destination: ITripTerminus;
  tripCategory: TripCategory;
}
export const fetchCalendar = createAsyncThunk<
  {
    departureMonths: SerializableMonthBucket[];
    priceTags: string[];
  },
  FetchCalendarAction,
  { state: FlightState; rejectValue: { error: string } }
>(
  "flightsShop/fetchCalendar",
  async (action: FetchCalendarAction, thunkAPI) => {
    const state = thunkAPI.getState();

    const { origin, destination, tripCategory } = action;
    const { pricePrediction } = state.flights.flightSearch;

    if (origin == null || destination == null) {
      return thunkAPI.rejectWithValue({
        error: "Origin or destination is not set",
      });
    }

    /** If the price prediction is already set, return it **/
    if (
      pricePrediction.priceTags.length > 0 &&
      pricePrediction.departureMonths.length > 0 &&
      pricePrediction.origin === origin &&
      pricePrediction.destination === destination &&
      pricePrediction.tripCategory === tripCategory
    ) {
      return {
        departureMonths: pricePrediction.departureMonths,
        priceTags: pricePrediction.priceTags,
        origin,
        destination,
        tripCategory,
      };
    }

    const {
      id: { code: destinationCode },
    } = destination;
    const {
      id: { code: originCode },
    } = origin;

    const requestBody: CalendarBuckets = {
      route: { origin: originCode, destination: destinationCode },
      tripType: tripCategory,
      filter: { TripFilter: TripFilter.NO_FILTER },
    };

    try {
      const res = await flightsShoppingApi.apiV0CalendarPost(requestBody);

      const calendarReport = res.data;
      const departureDateBuckets =
        calendarReport.departureDateReport.departureDateBuckets;

      const departureMonths = serializableMonthBuckets(
        transformDateBuckets(departureDateBuckets)
      );

      const priceTags = departureDateBuckets.map(
        (dateBucket: IDateBucket) => dateBucket.legend
      );
      return { departureMonths, priceTags };
    } catch (e) {
      return thunkAPI.rejectWithValue({ error: String(e) });
    }
  }
);

export const transformDateBuckets = (
  dateBuckets: IDateBucket[]
): IMonthBucket[] => {
  const processedMonths = dateBuckets.reduce(
    (months, { dates }, bucketIndex) => {
      const reducedMonths = dates.reduce(
        (monthBuckets: IMonthBucket[], dateString: string) => {
          const date = dayjs(dateString).toDate();
          const bucketedDate: IBucketedDate = { bucket: bucketIndex, date };
          const currentMonthIndex = dayjs(date).month();
          let existingMonthBucket = monthBuckets.find(({ monthIndex }) => {
            return monthIndex === currentMonthIndex;
          });

          if (typeof existingMonthBucket === "undefined") {
            existingMonthBucket = { monthIndex: currentMonthIndex, dates: [] };
            monthBuckets.push(existingMonthBucket);
          }

          existingMonthBucket.dates.push(bucketedDate);

          return monthBuckets;
        },
        months
      );

      return reducedMonths;
    },
    [] as IMonthBucket[]
  );
  const sortedMonths = processedMonths.map((processedMonth) => {
    const sortedDates = processedMonth.dates.sort(
      (a: IBucketedDate, b: IBucketedDate) => dayjs(a.date).diff(b.date)
    );
    return { monthIndex: processedMonth.monthIndex, dates: sortedDates };
  });

  return sortedMonths;
};
