import { CipherText, FulfillRequest } from "@b2bportal/purchase-api";
import { scheduleFulfill } from "@hopper-b2b/api";
import {
  CartFulfillError,
  CartFulfillErrorModal,
  CartFulfillState,
} from "../../../../types";
import { CartContext } from "../../context";
import { getFullFillRequestParams } from "../selectors";

export const scheduleFulfillService = async (
  context: CartContext,
  _event: unknown
) => {
  const fulfillRequest: FulfillRequest = getFullFillRequestParams({
    context,
  });
  const cartFulfillError: Omit<CartFulfillError, "type"> = {
    failedAt: CartFulfillState.schedule,
  };

  const options = {
    headers: {
      Accept:
        "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8",
      "Color-Depth": window.screen.colorDepth,
      "Java-Enabled": false, // navigator.javaEnabled() is deprecated. Most modern web browsers no longer support Java applets.
      "JavaScript-Enabled": true,
      "Screen-Width": window.innerWidth,
      "Screen-Height": window.innerHeight,
      "Timezone-Offset": new Date().getTimezoneOffset(),
    },
  };

  try {
    const result: CipherText = await scheduleFulfill(fulfillRequest, options);

    if (result.value) {
      return result;
    } else {
      throw ""; // Will be caught in the catch
    }
  } catch (e) {
    throw {
      ...cartFulfillError,
      type: CartFulfillErrorModal.NoCipherText,
    };
  }
};
