import clsx from "clsx";
import { useState } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import { IconComponent, IconName } from "@hopper-b2b/ui";
import { Box, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";

import styles from "./ChatRating.module.scss";

export interface IChatRatingProps {
  onChange: (value: number) => void;
  showSubtitle?: boolean;
}

const ChatRating = (props: IChatRatingProps): JSX.Element => {
  const { onChange, showSubtitle = false } = props;
  const { t } = useI18nContext();
  const [rating, setRating] = useState(0);

  function handleRatingChange(ev, value) {
    onChange(value);
    setRating(value);
  }

  return (
    <Box className={clsx("chat-rating-container", styles.chatRatingContainer)}>
      <Typography
        className={clsx("rating-title", styles.ratingTitle)}
        variant="h1"
      >
        {t("chat.csatForm.rating.title")}
      </Typography>
      {showSubtitle && (
        <Typography
          className={clsx("rating-subtitle", styles.ratingSubtitle)}
          variant="subtitle1"
        >
          {t("chat.csatForm.rating.subtitle")}
        </Typography>
      )}
      <Box className={clsx("rating-container", styles.ratingContainer)}>
        <Rating
          classes={{
            icon: styles.ratingIcon,
            root: styles.ratingRow,
          }}
          emptyIcon={
            <div>
              <IconComponent
                className={clsx(
                  "star-icon empty-icon",
                  styles.starIcon,
                  styles.emptyIcon
                )}
                name={IconName.StarRoundedEmpty}
              />
            </div>
          }
          icon={
            <div>
              <IconComponent
                className={clsx(
                  "star-icon filled-icon",
                  styles.starIcon,
                  styles.filledIcon
                )}
                name={IconName.StarRoundedFilled}
              />
            </div>
          }
          name="chat-rating"
          onChange={handleRatingChange}
          value={rating}
        />
        {rating ? (
          <Typography
            className={clsx("rating-label", styles.ratingLabel)}
            variant="subtitle1"
          >
            {t("chat.csatForm.rating.label", { context: rating })}
          </Typography>
        ) : (
          <Box className={clsx("rating-label-range", styles.ratingLabelRange)}>
            <Typography
              className={clsx("rating-label", styles.ratingLabel)}
              variant="subtitle1"
            >
              {t("chat.csatForm.rating.label", { context: 1 })}
            </Typography>
            <Typography
              className={clsx("rating-label", styles.ratingLabel)}
              variant="subtitle1"
            >
              {t("chat.csatForm.rating.label", { context: 5 })}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ChatRating;
