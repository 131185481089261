import type { Lodgings, Suggestion, Place } from "@b2bportal/lodging-api";
import { fetchLocations } from "@hopper-b2b/common-search";
import { Autocomplete, type AutocompleteProps } from "./Autocomplete";
import { useTenantIcons } from "@hopper-b2b/utilities";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as Hotel } from "./hotel.svg";
import { ReactComponent as RecentSearch } from "./recentSearch.svg";
import { DateTimeFormatStyle, useI18nContext } from "@hopper-b2b/i18n";
import type { HotelSearchQueryArgs } from "@lloyds/utils";
import { useCallback } from "react";
import { trackEvent } from "@hopper-b2b/api";
import dayjs from "dayjs";
import styles from "./HotelAutocomplete.module.scss";
import clsx from "clsx";

enum SuggestionTypeEnum {
  Country = "country",
  City = "locality",
  Lodging = "lodging",
  Other = "other",
  Results = "result",
  RecentSearches = "recentSearch",
}

export interface HotelLocationAutoCompleteProps
  extends Omit<
    AutocompleteProps<Suggestion>,
    "fetch" | "groupBy" | "getOptionLabel" | "sortOptions"
  > {
  defaultValue?: Suggestion;
  recentSearches?: HotelSearchQueryArgs[];
  filterRecentSearches?: (
    searches: HotelSearchQueryArgs[],
    search: string
  ) => HotelSearchQueryArgs[];
  onRecentSearchClick?: (value: HotelSearchQueryArgs) => void;
}

export const HotelLocationAutoComplete = ({
  id,
  defaultValue,
  label,
  recentSearches,
  onRecentSearchClick,
  filterRecentSearches,
  onChange,
  onOpen,
  className,
  placeholder,
  closeIcon,
}: HotelLocationAutoCompleteProps) => {
  const icons = useTenantIcons();
  const { t, formatDateTime } = useI18nContext();

  const formatDate = useCallback(
    (search: HotelSearchQueryArgs) => {
      const formattedFrom = formatDateTime(
        dayjs(search.fromDate).toDate(),
        DateTimeFormatStyle.Custom({ day: "numeric", month: "short" })
      );

      const formattedUntil = formatDateTime(
        dayjs(search.untilDate).toDate(),
        DateTimeFormatStyle.Custom({ day: "numeric", month: "short" })
      );
      return `${formattedFrom} - ${formattedUntil}`;
    },
    [formatDateTime]
  );

  const onChangeInternal = useCallback(
    (value?: Suggestion | HotelSearchQueryArgs) => {
      if (!value) {
        onChange();
        return;
      }
      if ("fromDate" in value) {
        trackEvent({
          eventName: "tapped_search_suggestion",
          properties: {
            location_type: "recent",
            search_term: getOptionLabel(value),
          },
        });
        return onRecentSearchClick(value);
      }

      if (value?.trackingPropertiesV2) {
        trackEvent({
          eventName: "tapped_search_suggestion",
          properties: value.trackingPropertiesV2.properties,
          encryptedProperties: value.trackingPropertiesV2.encryptedProperties
            ? [value.trackingPropertiesV2.encryptedProperties]
            : [],
        });
      }
      onChange(value);
    },
    [onChange, onRecentSearchClick]
  );

  const getOptionSublabel = useCallback(
    (value?: Suggestion | HotelSearchQueryArgs) => {
      if (value && "fromDate" in value) {
        const totalPassengerCount =
          value.adultsCount + value.childrenCount.length;

        return `${formatDate(value)} • ${t("searchFilter.pickerTraveler", {
          count: totalPassengerCount,
        })}`;
      }
      return value?.subLabel;
    },
    [t, formatDate]
  );

  return (
    <>
      <label htmlFor={id} id={`${id}-label`} style={{ display: "none" }}>
        {t("searchDestination")}
      </label>
      <Autocomplete
        id={id}
        key={defaultValue ? "destination-loaded" : "loading-destination"}
        paperClassName={"hotel"}
        className={clsx(className, "hotel-autocomplete")}
        defaultValue={defaultValue}
        label={label}
        placeholder={placeholder}
        closeIcon={closeIcon}
        icon={<img src={icons.locationMarker} alt="" />}
        onOpen={onOpen}
        sortOptions={sortLocations}
        onChange={onChangeInternal}
        fetch={fetchLocations}
        getOptionLabel={getOptionLabel}
        getOptionSublabel={getOptionSublabel}
        getOptionLabelIcon={getOptionLabelIcon}
        groupBy={groupPlaceType}
        staticOptions={recentSearches}
        filterStaticOptions={filterRecentSearches}
        inputPropsClassName={styles.cursor}
      />
    </>
  );
};

const getPlaceType = (value: HotelSearchQueryArgs) => {
  if ("fromDate" in value) {
    return SuggestionTypeEnum.RecentSearches;
  }
  const lodging = value?.id as Lodgings;
  const place = lodging?.lodgingSelection as Place;
  return place?.placeTypes?.[0] ?? "";
};
const groupPlaceType = (value?: HotelSearchQueryArgs): string => {
  const type = getPlaceType(value);
  switch (type) {
    case SuggestionTypeEnum.RecentSearches:
      return type;
    default:
      return SuggestionTypeEnum.Results;
  }
};

const getOptionLabel = (value?: Suggestion) => {
  return value?.label;
};

const getOptionLabelIcon = (value: Suggestion) => {
  switch (getPlaceType(value)) {
    case SuggestionTypeEnum.Lodging:
      return <Hotel />;
    case SuggestionTypeEnum.RecentSearches:
      return <RecentSearch />;
    default:
      return <Location />;
  }
};

const sortLocations = (options?: Suggestion[]): Suggestion[] => {
  const groupEntries = {
    [SuggestionTypeEnum.RecentSearches]: [],
    [SuggestionTypeEnum.Country]: [],
    [SuggestionTypeEnum.City]: [],
    [SuggestionTypeEnum.Lodging]: [],
    [SuggestionTypeEnum.Other]: [],
  };

  options?.forEach((suggestion: Suggestion) => {
    const group: string = getPlaceType(suggestion);
    groupEntries[group]
      ? groupEntries[group].push(suggestion)
      : groupEntries[SuggestionTypeEnum.Other].push(suggestion);
  });

  return [
    ...groupEntries[SuggestionTypeEnum.RecentSearches],
    ...groupEntries[SuggestionTypeEnum.Country],
    ...groupEntries[SuggestionTypeEnum.City],
    ...groupEntries[SuggestionTypeEnum.Lodging],
    ...groupEntries[SuggestionTypeEnum.Other],
  ];
};
