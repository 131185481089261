import {
  CardPaymentGuards as BaseCardPaymentGuards,
  CartGuards as BaseCartGuards,
  ContactInformationGuards as BaseContactInformationGuards,
  PassengerGuards as BasePassengerGuards,
  ReviewGuards as BaseReviewGuards,
  WalletGuards as BaseWalletGuards,
  getObjectKeysAsObject,
} from "@hopper-b2b/checkout";

export const Guards = {
  ...BaseCartGuards,
  ...BaseContactInformationGuards,
  ...BasePassengerGuards,
  ...BaseReviewGuards,
  ...BaseCardPaymentGuards,
  ...BaseWalletGuards,
};

export const GuardTypes = getObjectKeysAsObject(Guards);
