import {
  SingleFlightItineraryRequest,
  SingleFlightItineraryResponse,
} from "@b2bportal/air-booking-api";
import { flightsBookingApi } from "@overrides/utilities";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { FlightPostBookingState } from "../slice";

export const fetchItinerary = createAsyncThunk<
  SingleFlightItineraryResponse,
  SingleFlightItineraryRequest,
  { state: FlightPostBookingState; rejectValue: { error: string } }
>("flights/fetchItinerary", async (request, thunkAPI) => {
  try {
    const response = await flightsBookingApi.apiV0ItineraryFlightSinglePut(
      request
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: String(error) });
  }
});
