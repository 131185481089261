import { ActionLink, IconComponent, IconName } from "@hopper-b2b/ui";
import { Box, SwipeableDrawer } from "@material-ui/core";
import clsx from "clsx";
import { noop } from "lodash-es";
import { MutableRefObject, ReactNode, useMemo } from "react";

import { useChatContext } from "../../hooks";
import { BottomSheetContentEnum } from "../../types";
import { CSATForm } from "../CSATForm";
import { ContactSupportDetails } from "../ContactSupportDetails";

import styles from "./ChatWindowBottomSheet.module.scss";

export interface IChatWindowBottomSheetProps {
  chatWindowRef: MutableRefObject<HTMLDivElement>;
}

const ChatWindowBottomSheet = (
  props: IChatWindowBottomSheetProps
): JSX.Element => {
  const { chatWindowRef } = props;
  const { bottomSheetContent, closeChatWindow, setBottomSheetContent } =
    useChatContext();

  const ModalContent = useMemo<ReactNode>(() => {
    switch (bottomSheetContent) {
      case BottomSheetContentEnum.CSATFormResolved:
        return <CSATForm onConversationEnd={closeChatWindow} />;
      case BottomSheetContentEnum.CSATFormUnresolved:
        return (
          <CSATForm
            onConversationEnd={() =>
              setBottomSheetContent(BottomSheetContentEnum.ContactSupport)
            }
          />
        );
      case BottomSheetContentEnum.ContactSupport:
        return <ContactSupportDetails />;
      case BottomSheetContentEnum.Closed:
      default:
        return null;
    }
  }, [bottomSheetContent, closeChatWindow, setBottomSheetContent]);

  function closeBottomSheet() {
    setBottomSheetContent(BottomSheetContentEnum.Closed);
  }

  return (
    <SwipeableDrawer
      disableDiscovery
      disablePortal
      disableSwipeToOpen
      BackdropProps={{
        className: styles.bottomSheetBackdrop,
      }}
      ModalProps={{
        container: chatWindowRef.current,
      }}
      anchor="bottom"
      classes={{
        paper: styles.bottomSheetPaper,
        root: styles.chatWindowBottomSheet,
      }}
      onClose={closeBottomSheet}
      onOpen={noop}
      open={bottomSheetContent !== BottomSheetContentEnum.Closed}
    >
      <Box
        className={clsx("bottom-sheet-dragbar", styles.bottomSheetDragbar)}
      />
      <Box className={clsx("bottom-sheet-header", styles.bottomSheetHeader)}>
        <ActionLink
          className={styles.closeButton}
          content={
            <IconComponent
              ariaLabel="Close button icon"
              className="close-button-icon"
              name={IconName.Close}
            />
          }
          onClick={closeBottomSheet}
        />
      </Box>
      {ModalContent}
    </SwipeableDrawer>
  );
};

export default ChatWindowBottomSheet;
