import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useEffect, useId, useMemo, useState } from "react";
import {
  Amenity,
  CancellationPolicyEnum,
  RoomInfoProducts,
  RoomProduct,
} from "@b2bportal/lodging-api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { ActionButton, ActionLink, Carousel, Slot } from "@hopper-b2b/ui";
import {
  useDeviceTypes,
  useRenderExtraHotelAmenities,
  useTenantIcons,
} from "@hopper-b2b/utilities";
import { usePriceFormatterContext } from "@hopper-b2b/contexts";
import { RenderExtraHotelAmenitiesVariant } from "@hopper-b2b/types";
import { ImageGallery } from "../ImageGallery";
import { AmenitiesList } from "../AmenitiesList";
import { DetailsAndAmenitiesModal } from "./components";
import "./styles.scss";

export type RoomCardProps = {
  room: RoomInfoProducts;
  onRateSelected: (roomId: string, rateId: string) => void;
  onSelectedRoomChange: (roomId: string) => void;
  currSelectedRoom: string;
  nights: number;
  onRateChange: (roomId: string, rateId: string) => void;
};

export const RoomCard = ({
  room,
  onRateSelected,
  onSelectedRoomChange,
  currSelectedRoom,
  nights,
  onRateChange,
}: RoomCardProps) => {
  const [selectedId, setSelectedId] = useState(room.products[0].rateId.value);
  const [openDetailsAndAmenities, setOpenDetailsAndAmenities] = useState(false);
  const icons = useTenantIcons();
  const { t } = useI18nContext();
  const { formatPrice } = usePriceFormatterContext();
  const renderExtraHotelAmenities = useRenderExtraHotelAmenities();
  const amenities = useMemo(() => room?.roomInfo.amenities, [room]);

  const onChange = useCallback(
    (selectedRate: RoomProduct) => {
      const selectedRateId = selectedRate.rateId.value;
      setSelectedId(selectedRateId);
      if (room.roomInfo.roomId !== currSelectedRoom) {
        onSelectedRoomChange(room.roomInfo.roomId);
      }
      onRateChange(room.roomInfo.roomId, selectedRateId);
    },
    [currSelectedRoom, onRateChange, onSelectedRoomChange, room.roomInfo.roomId]
  );

  const onClickAmenities = useCallback(() => {
    setOpenDetailsAndAmenities(true);
    onSelectedRoomChange(room.roomInfo.roomId);
  }, [onSelectedRoomChange, room.roomInfo.roomId]);

  useEffect(() => {
    setSelectedId(room.products[0].rateId.value);
  }, [room.products]);

  const { matchesMobile: isMobile } = useDeviceTypes();

  const selectedProduct = useMemo(() => {
    return room.products.find((prod) => {
      return prod.rateId.value === selectedId;
    });
  }, [room.products, selectedId]);

  const onProductSelected = useCallback(() => {
    onRateSelected(room.roomInfo.roomId, selectedId);
  }, [onRateSelected, room.roomInfo.roomId, selectedId]);

  const roomMediaUrls = room.roomInfo.media.map((asset) => asset.url);

  const renderAmenitiesList = useCallback(() => {
    switch (renderExtraHotelAmenities) {
      case RenderExtraHotelAmenitiesVariant.BreakfastOnly:
        return (
          <AmenitiesList
            amenities={amenities.filter(
              (amenity) => amenity.amenity === Amenity.FreeBreakfast
            )}
            className="RoomCard-amenities"
          />
        );
      case RenderExtraHotelAmenitiesVariant.ExtraAmenities:
        return (
          <AmenitiesList
            amenities={amenities.length > 3 ? amenities.slice(0, 3) : amenities}
            className="RoomCard-amenities"
          />
        );
      default:
        return null;
    }
  }, [renderExtraHotelAmenities]);

  const renderAmenities = useMemo(
    () =>
      (amenities.length > 0 &&
        renderExtraHotelAmenities ===
          RenderExtraHotelAmenitiesVariant.ExtraAmenities) ||
      (renderExtraHotelAmenities ===
        RenderExtraHotelAmenitiesVariant.BreakfastOnly &&
        amenities.some((amenity) => amenity.amenity === Amenity.FreeBreakfast)),
    [amenities, renderExtraHotelAmenities]
  );

  const hiddenAmenitiesCount = useMemo(() => {
    switch (renderExtraHotelAmenities) {
      case RenderExtraHotelAmenitiesVariant.BreakfastOnly:
        return renderAmenities ? amenities.length - 1 : 0;
      case RenderExtraHotelAmenitiesVariant.ExtraAmenities:
        return amenities.length > 3 ? amenities.length - 3 : 0;
      default:
        return 0;
    }
  }, [renderExtraHotelAmenities, renderAmenities]);

  const viewMoreText = useMemo(
    () =>
      renderAmenities && hiddenAmenitiesCount
        ? t("viewExtraAmenities", { count: hiddenAmenitiesCount })
        : t("viewDetailsAndAmenities"),
    [renderAmenities, amenities, hiddenAmenitiesCount]
  );

  return (
    <div
      className={clsx("RoomCard", { mobile: isMobile })}
      id={room.roomInfo.roomId}
    >
      <div>
        <div className={clsx("RoomCard-carousel")}>
          <Slot
            id="hotel-room-details-gallery"
            Gallery={
              <ImageGallery
                viewRoomOnClick={onProductSelected}
                media={room?.roomInfo?.media}
                room
              />
            }
            component={<Carousel imageUrlsArray={roomMediaUrls} />}
          />
        </div>
        <div className="RoomCard-info">
          <Typography variant="h6" gutterBottom>
            {room.roomInfo.name}
          </Typography>
          <Box
            className={clsx("RoomCard-quickInfo-container", {
              "render-amenities": renderAmenities,
            })}
          >
            {room.roomInfo.maxAdults ? (
              <Typography noWrap className="RoomCard-quickInfo">
                <img src={icons.grayGuest} alt="" />
                {t("roomFitsGuests", {
                  count: room.roomInfo.maxAdults,
                })}
              </Typography>
            ) : null}
            <Typography noWrap className="RoomCard-quickInfo">
              <img src={icons.bed} alt="" />
              {room.roomInfo.beds.description}
            </Typography>
          </Box>
          {renderAmenities ? renderAmenitiesList() : null}
          {/* <div>
            <HotelLoyaltyChip
              loyaltyCategory={room.products[0].loyaltyProgramCode}
            />
          </div> */}
          <ActionLink
            className="RoomCard-amenities-text"
            content={viewMoreText}
            onClick={onClickAmenities}
          />
        </div>
        <Slot
          id="hotel-room-details-select"
          room={room}
          onChange={onChange}
          nights={nights}
          selectedId={selectedId}
          DetailsModal={
            <Slot
              id="hotel-room-details-modal"
              open={openDetailsAndAmenities}
              onClose={() => setOpenDetailsAndAmenities(false)}
              {...room.roomInfo}
              component={
                <DetailsAndAmenitiesModal
                  open={openDetailsAndAmenities}
                  onClose={() => setOpenDetailsAndAmenities(false)}
                  continueRoomBooking={() => setOpenDetailsAndAmenities(false)}
                  ctaLabel={t("close.button")}
                  {...room.roomInfo}
                />
              }
            />
          }
          component={
            <fieldset id={room.roomInfo.roomId}>
              {room.products.map((product: RoomProduct) => (
                <React.Fragment
                  key={`RoomCard-product-${product.rateId.value}`}
                >
                  <RoomProductDetails
                    key={`RoomCard-product-${product.rateId.value}`}
                    room={room}
                    nights={nights}
                    selected={
                      currSelectedRoom === room.roomInfo.roomId &&
                      selectedId === product.rateId.value
                    }
                    product={product}
                    onChange={onChange}
                  />
                  <DetailsAndAmenitiesModal
                    open={openDetailsAndAmenities}
                    onClose={() => setOpenDetailsAndAmenities(false)}
                    continueRoomBooking={() =>
                      onRateSelected(room.roomInfo.roomId, product.rateId.value)
                    }
                    {...room.roomInfo}
                  />
                </React.Fragment>
              ))}
            </fieldset>
          }
        />
      </div>
      <div className="room-card-button-container">
        <Slot
          id="hotel-room-details-cta"
          onClick={onProductSelected}
          selectedProduct={selectedProduct}
          component={
            <ActionButton
              onClick={onProductSelected}
              disabled={currSelectedRoom !== room.roomInfo.roomId}
              aria-disabled={currSelectedRoom !== room.roomInfo.roomId}
              fullWidth
              size="medium"
              message={t("reserveForPricePerNight", {
                price: formatPrice(
                  selectedProduct?.nightlyDiscountAware
                    .priceWithUnmanagedDiscounts
                ),
              })}
            />
          }
        />
      </div>
    </div>
  );
};

type RoomProductDetailsProps = Pick<RoomCardProps, "room" | "nights"> & {
  selected: boolean;
  product: RoomProduct;
  onChange: (product: RoomProduct) => void;
};

const RoomProductDetails = ({
  room,
  nights,
  selected,
  product,
  onChange,
}: RoomProductDetailsProps) => {
  const inputId = useId();

  const icons = useTenantIcons();

  const { t } = useI18nContext();
  const { formatPrice } = usePriceFormatterContext();

  const isNonRefundable =
    product.cancellationPolicy.CancellationPolicy ===
    CancellationPolicyEnum.NonRefundable;

  return (
    <div
      className="RoomCard-product"
      key={`RoomCard-product-${product.rateId.value}`}
      onClick={() => onChange(product)}
    >
      <input
        type="radio"
        name={room.roomInfo.roomId}
        aria-describedby={inputId}
        value={product.rateId.value}
        checked={selected}
        onChange={() => onChange(product)}
      />
      <div id={inputId}>
        <Typography variant="h6">
          <I18nMarkup
            tKey={"pricePerNight"}
            replacements={{
              price: formatPrice(
                product.nightlyDiscountAware.priceWithUnmanagedDiscounts
              ),
            }}
          />
        </Typography>
        <Typography className="RoomCard-product-total" gutterBottom>
          {t("priceForNightsWithTaxesAndFees", {
            price: formatPrice(
              product.totalDiscountAware.priceWithUnmanagedDiscounts
            ),
            count: nights,
          })}
        </Typography>
        <Typography
          className={clsx(
            "RoomCard-product-text",
            isNonRefundable ? "nonrefundable" : "refundable"
          )}
          component={"span"}
          gutterBottom
        >
          <img
            className="RoomCard-product-icon"
            src={isNonRefundable ? icons.grayShieldX : icons.greenShieldCheck}
            alt=""
          />
          <Typography>{product.cancellationPolicy.primaryText}</Typography>
        </Typography>
      </div>
    </div>
  );
};
