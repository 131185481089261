import { useI18nContext } from "@hopper-b2b/i18n";
import type { PhoneNumberValidatorEnum } from "@hopper-b2b/types";
import {
  isEmailValid,
  isPhoneNumberValid,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { Box, Grid, Typography } from "@material-ui/core";
import { useMemo } from "react";
import { PhoneInputField } from "../PhoneInputField";
import { TextInputField } from "../TextInputField";
import styles from "./ContactInfoForm.module.scss";

interface IContactInfoFormProps {
  phone: string;
  countryCode: string;
  email: string;
  onPhoneNumberChanged: (
    number: string,
    countryDialCode: string,
    customIsValid?: (number: string) => PhoneNumberValidatorEnum
  ) => void;
  onEmailChanged: (email: string) => void;
  disabled?: boolean;
}

export const ContactInfoForm = ({
  phone,
  countryCode,
  email,
  onPhoneNumberChanged,
  onEmailChanged,
  disabled,
}: IContactInfoFormProps) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const phoneErrorMessage = useMemo(
    () => (!isPhoneNumberValid(phone) ? t("contactInfoPhoneError") : undefined),
    [phone, t]
  );
  const emailErrorMessage = useMemo(
    () => (!isEmailValid(email) ? t("contactInfoEmailError") : undefined),
    [email, t]
  );

  return (
    <>
      <Box mb={3}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item>
            <Typography className={styles.title} variant="h3" gutterBottom>
              {t("lodging.checkout.contactInfo.title")}
            </Typography>
            <Typography
              variant="body1"
              className="subtitle"
              color="textSecondary"
            >
              {t("lodging.checkout.contactInfo.subtitle")}
            </Typography>
          </Grid>
          <Grid item xs />
        </Grid>
      </Box>
      <Grid container spacing={4} direction={matchesMobile ? "column" : "row"}>
        <Grid item xs lg={6}>
          <PhoneInputField
            label={t("phoneNumber")}
            required
            defaultValue={phone}
            countryCode={countryCode}
            errorHelper={phoneErrorMessage}
            onChange={onPhoneNumberChanged}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs lg={6}>
          <TextInputField
            id="contact-email-input-field"
            className="email-input-field"
            label={t("email")}
            required
            value={email}
            errorHelper={emailErrorMessage}
            onChange={onEmailChanged}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </>
  );
};
