import {
  AmenityStatus,
  type Lodging,
  type Suggestion,
  type WalletOfferApplicabilityResponse,
} from "@b2bportal/lodging-api";
import { type SortOption, type ViewOption } from "@hopper-b2b/lodging-utils";
import { actions, actionTypes } from "../actions";
import { calculatePriceRangeFilter } from "@overrides/lodging-utilities";

export type PriceRange = {
  min: number;
  max: number;
  lowest: number;
  highest: number;
};

export type LoadingLodgings = null | "loading-from-place" | "loading-from-map";

export type LodgingsFilterBoundaries = {
  amenities: Map<string, { amenity: AmenityStatus; count: number }>;
};

export interface ILodgingFilterState {
  starRating: number[];
  userRating: number;
  amenities: string[];
  filterAmenitiesAnd: boolean;
  freeCancellation: boolean;
  priceRange: PriceRange;
}

export interface ICentroid {
  lat: number;
  lng: number;
}

export interface ILodgingAvailabilityState {
  loading?: LoadingLodgings;
  fromDate?: string;
  untilDate?: string;
  place?: Suggestion;
  lodgings?: Lodging[];
  centroid?: ICentroid;
  guests?: { adults: number; children: number[] };
  rooms?: number;
  filters?: ILodgingFilterState;
  offers?: WalletOfferApplicabilityResponse;
  sort?: SortOption;
  view?: ViewOption;
  isFirstRender: boolean;
}

export function reducer(
  state: ILodgingAvailabilityState = { isFirstRender: true },
  action: actions.LodgingAvailabilityActions
): ILodgingAvailabilityState {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case actionTypes.SET_AVAILABILITY_LODGINGS:
      return {
        ...state,
        ...action.stayValues,
        lodgings: action.lodgings,
        place: action.place,
        filters: {
          ...state.filters,
          priceRange: calculatePriceRangeFilter(
            [...state.lodgings, ...action.lodgings],
            state.filters.priceRange
          ),
        },
        offers: action.offers,
        loading: null,
      };
    case actionTypes.SET_STAY_VALUES:
      return {
        ...state,
        fromDate: action.stayValues.fromDate,
        untilDate: action.stayValues.untilDate,
      };
    case actionTypes.ADD_AVAILABILITY_LODGINGS:
      return {
        ...state,
        lodgings: action.prepend
          ? [...action.lodgings, ...state.lodgings]
          : [...state.lodgings, ...action.lodgings],
        filters: {
          ...state.filters,
          priceRange: calculatePriceRangeFilter(
            [...state.lodgings, ...action.lodgings],
            state.filters.priceRange
          ),
        },
        offers: {
          // Set as the intial best overall lodging, might not be the best.
          bestOfferOverall: state.offers?.bestOfferOverall,
          offersByProductId: {
            ...state.offers?.offersByProductId,
            ...action.offers?.offersByProductId,
          },
          opaqueProductMap: {
            ...state.offers?.opaqueProductMap,
            ...action.offers?.opaqueProductMap,
          },
        },
      };
    case actionTypes.SET_FILTER_FREE_CANCELLATION:
      return {
        ...state,
        filters: {
          ...state.filters,
          freeCancellation: action.value,
        },
      };
    case actionTypes.SET_FILTER_STAR_RATING:
      return {
        ...state,
        filters: {
          ...state.filters,
          starRating: action.value,
        },
      };
    case actionTypes.SET_FILTER_USER_RATING:
      return {
        ...state,
        filters: {
          ...state.filters,
          userRating: action.value,
        },
      };
    case actionTypes.SET_FILTER_AMENITIES:
      return {
        ...state,
        filters: {
          ...state.filters,
          amenities: action.value,
        },
      };
    case actionTypes.SET_FILTER_AMENITIES_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          filterAmenitiesAnd: action.value,
        },
      };
    case actionTypes.SET_FILTER_PRICE:
      return {
        ...state,
        filters: {
          ...state.filters,
          priceRange: {
            ...state.filters.priceRange,
            min: action.value.min,
            max: action.value.max,
          },
        },
      };
    case actionTypes.RESET_FILTERS:
      return {
        ...state,
        filters: {
          userRating: 0,
          starRating: [],
          amenities: [],
          freeCancellation: false,
          filterAmenitiesAnd: true,
          priceRange: {
            ...state.filters.priceRange,
            min: state.filters.priceRange.lowest,
            max: state.filters.priceRange.highest,
          },
        },
      };
    case actionTypes.SET_SORT:
      return {
        ...state,
        sort: action.value,
      };
    case actionTypes.SET_VIEW:
      return {
        ...state,
        view: action.value,
      };
    case actionTypes.SET_PLACE:
      return {
        ...state,
        place: action.value,
      };
    case actionTypes.SET_GUESTS:
      return {
        ...state,
        guests: action.value,
      };
    case actionTypes.SET_ROOMS:
      return {
        ...state,
        rooms: action.value,
      };
    case actionTypes.SET_CENTROID:
      return {
        ...state,
        centroid: action.value,
      };
    case actionTypes.SET_IS_FIRST_RENDER:
      return {
        ...state,
        isFirstRender: false,
      };
    default:
      return state;
  }
}

export * from "./selectors";
