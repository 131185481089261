import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import {
  CoreUiComponents,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import { B2BTextField } from "./B2BTextField";
import { ReactNode } from "react";
import defaultStyles from "./styles.module.scss";
import clsx from "clsx";

export interface TextFieldComponentProps extends PlatformComponentProps {
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  placeholder?: string;
  label?: string | ReactNode;
  defaultValue?: string | number;
  required?: boolean;
  error?: boolean;
  errorHelper?: string;
  type?: string;
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  id?: string;
}

export const TextField = ({ className, ...props }: TextFieldComponentProps) => {
  const COMPONENT_KEY = CoreUiComponents.TextField;
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return <B2BTextField className={clsx(block, className)} {...props} />;
};
