export enum FlightsCfarTrackingEvent {
  VIEW_CFAR = "view_cfar",
  SKIP_CFAR = "skip_cfar",
  CHOOSE_CFAR = "choose_cfar",
  CFAR_EXERCISE_QUOTE_OUTCOMES = "cfar_exercise_quote_outcomes",
  CFAR_EXERCISE_QUOTE = "cfar_exercise_quote",
  CFAR_EXERCISE_QUOTE_FAIL = "cfar_exercise_quote_fail",
  CFAR_EXERCISE_COMPLETE = "cfar_exercise_complete",
  CFAR_EXERCISE_COMPLETE_FAIL = "cfar_exercise_complete_fail",

  ENTER_CFAR_EXERCISE_FLOW = "enter_cfar_exercise_flow",
  CFAR_POLICY_EXERCISED = "cfar_policy_exercised",
  VIEWED_CFAR_EXERCISE_ERROR = "viewed_cfar_exercise_error",
}
