import { getObjectKeysAsObject } from "@checkout/helpers";
import { fetchTravelersService } from "./fetchTravelersService";
import { updateTravelerService } from "./updateTravelerService";
import { deleteTravelerService } from "./deleteTravelerService";
import { trackEditTravelers } from "@checkout/states/common/PassengerInformation/services/trackEditTravelers";
import { trackNewTraveler } from "@checkout/states/common/PassengerInformation/services/trackNewTraveler";

export const services = {
  deleteTravelerService,
  fetchTravelersService,
  updateTravelerService,
  trackEditTravelers,
  trackNewTraveler,
};

export const ServiceTypes = getObjectKeysAsObject(services);
