export enum CartQuoteEventType {
  PREVIOUS = "PREVIOUS",

  RETRY_QUOTE = "RETRY_QUOTE",
  OPEN_PASSENGER_PICKER = "OPEN_PASSENGER_PICKER",
  CLEAR_CART_QUOTE_ERROR = "CLEAR_CART_QUOTE_ERROR",
  SET_CART_LOCKED = "SET_CART_LOCKED",
}

export interface RetryQuote {
  type: CartQuoteEventType.RETRY_QUOTE;
}

export interface OpenPassengerPicker {
  type: CartQuoteEventType.OPEN_PASSENGER_PICKER;
}

export interface ClearCartQuoteError {
  type: CartQuoteEventType.CLEAR_CART_QUOTE_ERROR;
}

export interface SetCartLocked {
  type: CartQuoteEventType.SET_CART_LOCKED;
  cartLocked: boolean;
}

export interface PreviousCartQuote {
  type: CartQuoteEventType.PREVIOUS;
}

export type CartQuoteEvents =
  | RetryQuote
  | OpenPassengerPicker
  | ClearCartQuoteError
  | SetCartLocked
  | PreviousCartQuote;
