import { FlightError } from "@b2bportal/air-booking-api";
import { useCheckoutSend } from "@checkout/context";
import { CartQuoteEventType } from "@checkout/states";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useCallback, useMemo } from "react";
import type { TEvent } from "../events";

export const useFlightTravelerErrorProps = (clearError: () => void) => {
  const { t } = useI18nContext();
  const send = useCheckoutSend<TEvent>();

  const handleBackToTravelers = useCallback(() => {
    clearError();
    send(CartQuoteEventType.OPEN_PASSENGER_PICKER);
  }, [clearError, send]);

  const baseErrorProps = useMemo(
    () => ({
      primaryButtonText: t("checkoutError.updateTraveler"),
      primaryOnClick: handleBackToTravelers,
    }),
    [handleBackToTravelers, t]
  );

  return useCallback(
    (flightError: FlightError) => {
      switch (flightError) {
        case FlightError.CheckInMinimumAgeNotMet:
          return {
            title: t("checkoutError.checkInMinimumAgeNotMetTitle"),
            subtitle: t("checkoutError.checkInMinimumAgeNotMetSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.ExpiredPassport:
          return {
            title: t("checkoutError.expiredPassportTitle"),
            subtitle: t("checkoutError.expiredPassportSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.IllegalLapInfantKTN:
          return {
            title: t("checkoutError.illegalLapInfantKTNTitle"),
            subtitle: t("checkoutError.illegalLapInfantKTNSubtitle"), // todo: we don't have the pax name
            ...baseErrorProps,
          };
        case FlightError.IllegalLapInfantRedress:
          return {
            title: t("checkoutError.illegalLapInfantRedressTitle"),
            subtitle: t("checkoutError.illegalLapInfantRedressSubtitle"), // todo: we don't have the pax name
            ...baseErrorProps,
          };
        case FlightError.InvalidCustomer:
          return {
            title: t("checkoutError.invalidCustomerTitle"),
            subtitle: t("checkoutError.invalidCustomerSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.InvalidPassengers:
          return {
            title: t("checkoutError.invalidPassenger"),
            subtitle: t("checkoutError.invalidPassengerSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.InvalidPassportNumber:
          return {
            title: t("checkoutError.invalidPassportNumberTitle"),
            subtitle: t("checkoutError.invalidPassportNumberSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.LapInfantTooOld:
          return {
            title: t("checkoutError.lapInfantTooOldTitle"),
            subtitle: t("checkoutError.lapInfantTooOldSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.LapInfantsUnsupported:
          return {
            title: t("checkoutError.lapInfantsUnsupportedTitle"),
            subtitle: t("checkoutError.lapInfantsUnsupportedSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.MalformedKnownTravelerNumber:
          return {
            title: t("checkoutError.malformedKnownTravelerNumberTitle"),
            subtitle: t("checkoutError.malformedKnownTravelerNumberSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.MalformedRedressNumber:
          return {
            title: t("checkoutError.malformedRedressNumberTitle"),
            subtitle: t("checkoutError.malformedRedressNumberSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.MissingNationality:
          return {
            title: t("checkoutError.missingNationalityTitle"),
            subtitle: t("checkoutError.missingNationalitySubtitle"),
            ...baseErrorProps,
          };
        case FlightError.MissingPassport:
          return {
            title: t("checkoutError.missingPassportTitle"),
            subtitle: t("checkoutError.missingPassportSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.MissingPassportNumber:
          return {
            title: t("checkoutError.missingPassportNumberTitle"),
            subtitle: t("checkoutError.missingPassportNumberSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.NoAdultPassenger:
          return {
            title: t("checkoutError.noAdultPassengerTitle"),
            subtitle: t("checkoutError.noAdultPassengerSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.SeatedInfantsUnsupported:
          return {
            title: t("checkoutError.seatedInfantsUnsupportedTitle"),
            subtitle: t("checkoutError.seatedInfantsUnsupportedSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.TooManyLapInfants:
          return {
            title: t("checkoutError.tooManyLapInfantsTitle"),
            subtitle: t("checkoutError.tooManyLapInfantsSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.TooManyPassengers:
          return {
            title: t("checkoutError.tooManyPassengersTitle"),
            subtitle: t("checkoutError.tooManyPassengersSubtitle"),
            ...baseErrorProps,
          };
        default:
          return undefined;
      }
    },
    [baseErrorProps, t]
  );
};
