import React from "react";
import { IconNameEnum, useTenantIcons } from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import { useUiStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./Icon.module.scss";
import clsx from "clsx";

export interface IconComponentProp
  extends React.SVGAttributes<HTMLOrSVGElement> {
  iconName: IconNameEnum;
  className?: string;
  useCurrentColor?: boolean;
  alt?: string;
}

export const Icon = ({
  iconName,
  className,
  useCurrentColor = true,
  ...props
}: IconComponentProp) => {
  const styles = useUiStyles(CoreUiComponents.Icon, defaultStyles);
  const [block] = useModuleBEM(styles, CoreUiComponents.Icon, {
    currentColor: useCurrentColor,
  });

  const icons = useTenantIcons();
  const IconComponent = icons[iconName];
  // Render the IconComponent if it exists, otherwise render null
  return IconComponent ? (
    <IconComponent className={clsx(block, className)} {...props} />
  ) : null;
};
