import { useI18nContext } from "@hopper-b2b/i18n";
import { TripCategory } from "@hopper-b2b/types";
import { DatePickerButton } from "@hopper-b2b/ui";
import { LbgModal } from "../LbgModal";
import dayjs from "dayjs";
import styles from "./CalendarPickerButton.module.scss";
import clsx from "clsx";
import type { ComponentType } from "react";
import type { LloydsCalendarPickerProps } from "../CalendarPicker/types";
import type { IMonthBucket } from "@b2bportal/core-types";
import { PriceRangeTags } from "../PriceRangeTags";

export type ICalendarPickerButtonProps = {
  endDate: string | null;
  startDate: string | null;
  onComplete?: () => void;
  onClose?: () => void;
  disabled?: boolean;
  tripCategory?: TripCategory;
  setStartDate: (value: string | null) => void;
  setEndDate: (value: string | null) => void;
  startLabel?: string;
  endLabel?: string;
  openDatesModal?: boolean;
  buttonClass?: string;
  classes?: string[];
  CalendarComponent: ComponentType<LloydsCalendarPickerProps>;
  priceTags?: string[];
  departureMonths?: IMonthBucket[];
};

export const CalendarPickerButton = ({
  tripCategory = TripCategory.ROUND_TRIP,
  startDate,
  endDate,
  startLabel,
  endLabel,
  disabled,
  setStartDate,
  setEndDate,
  buttonClass,
  classes = [],
  CalendarComponent,
  onComplete,
  onClose,
  priceTags,
  departureMonths,
}: ICalendarPickerButtonProps) => {
  const { t } = useI18nContext();

  return (
    <DatePickerButton
      buttonClass={clsx(styles.lloydsPickerButton, buttonClass)}
      classes={classes}
      startLabel={startLabel || t?.("departure")}
      startDate={startDate ? dayjs(startDate).toDate() : null}
      endLabel={endLabel || t?.("return")}
      endDate={endDate ? dayjs(endDate).toDate() : null}
      disabled={disabled}
      dateFormat={"ddd, MMM D"}
      hideEndDate={tripCategory === TripCategory.ONE_WAY}
      renderCalendarPopup={({ open, closePopup }) => (
        <LbgModal
          open={open}
          onClose={() => {
            closePopup();
            onClose?.();
          }}
          title={t("calendarTitle")}
          additionalInfoHeader={<PriceRangeTags priceTags={priceTags} />}
          diableContentPadding
        >
          <CalendarComponent
            tripCategory={tripCategory}
            onComplete={() => {
              closePopup();
              onComplete?.();
            }}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            priceTags={priceTags}
            months={departureMonths}
          />
        </LbgModal>
      )}
    />
  );
};
