import { useI18nContext } from "@hopper-b2b/i18n";
import { Prices } from "@hopper-b2b/types";
import { createContext, useContext } from "react";

export type FormatPriceFn = (price: Prices) => string;

export interface IPriceFormatterContextProps {
  formatPrice?: FormatPriceFn;
}

/**
 * Context to override the default price formatting. Components that use it will
 * by default format prices using the `formatFiatCurrency` function from the
 * `i18n` context. If you want to override this behavior, you can include it
 * in your app and provide a custom `formatPrice` function.
 */
export const PriceFormatterContext = createContext<IPriceFormatterContextProps>(
  {}
);

export const usePriceFormatterContext = () => {
  const ctx = useContext(PriceFormatterContext);
  const { formatFiatCurrency } = useI18nContext();
  return {
    formatPrice: (prices: Prices, options: Intl.NumberFormatOptions = {}) =>
      prices && prices?.fiat && ctx.formatPrice
        ? ctx.formatPrice(prices)
        : formatFiatCurrency(prices?.fiat, options),
  };
};
