import { axiosInstance } from "@hopper-b2b/api";
import {
  lloydsWalletApi,
  type GetWalletSummaryResponseSuccess,
  GetWalletSummaryResponseEnum,
  type GetCreditHistoryResponseSuccess,
  GetCreditHistoryResponseEnum,
} from "@b2bportal/lloyds-wallet-api";

export const fetchWalletSummary =
  async (): Promise<GetWalletSummaryResponseSuccess> =>
    new Promise((resolve, reject) => {
      lloydsWalletApi(axiosInstance)
        .apiV0WalletSummaryGet()
        .then((res) => {
          switch (res.data.GetWalletSummaryResponse) {
            case GetWalletSummaryResponseEnum.Success: {
              resolve(res.data);
              break;
            }
            case GetWalletSummaryResponseEnum.Failure:
            default:
              reject(res.data);
              break;
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

export const fetchCreditHistory =
  async (): Promise<GetCreditHistoryResponseSuccess> =>
    new Promise((resolve, reject) => {
      lloydsWalletApi(axiosInstance)
        .apiV0WalletCreditHistoryGet()
        .then((res) => {
          switch (res.data.GetCreditHistoryResponse) {
            case GetCreditHistoryResponseEnum.Success: {
              resolve(res.data);
              break;
            }
            case GetCreditHistoryResponseEnum.Failure:
            default:
              reject(res.data);
              break;
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
