import { Brand } from "@b2bportal/lloyds-auth-api";
import BankOfScotlandFavicon from "../assets/favicon/bank_of_scotland_favicon.ico";
import HalifaxFavicon from "../assets/favicon/halifax_favicon.ico";
import LloydsFavicon from "../assets/favicon/lloyds_favicon.ico";
import MBNAFavicon from "../assets/favicon/mbna_favicon.ico";

const faviconMap = {
  [Brand.Lloyds]: LloydsFavicon,
  [Brand.Halifax]: HalifaxFavicon,
  [Brand.BankOfScotland]: BankOfScotlandFavicon,
  [Brand.MBNA]: MBNAFavicon,
};

export const getFaviconFromBrand = (brand: Brand = Brand.Lloyds) =>
  faviconMap[brand];
