import clsx from "clsx";
import styles from "./Spinner.module.scss";

export const Spinner = ({
  color = "primary",
}: {
  color: "primary" | "secondary" | "disabled";
}) => {
  const colorStyle = styles[color];
  return <div className={clsx(styles.spinner, colorStyle)}></div>;
};
