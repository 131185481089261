import clsx from "clsx";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Icon } from "@components/ui";
import {
  IconNameEnum,
  useModuleBEM,
  useUiStyles,
} from "@b2bportal/core-themes";
import {
  CoreUiComponents,
  PlatformComponentProps,
  StarRatingValue,
} from "@b2bportal/core-types";
import defaultStyles from "./styles.module.scss";

export interface StateRatingComponentProps extends PlatformComponentProps {
  /*
   * Number [0-5]
   */
  starRating: StarRatingValue;
}
const COMPONENT_KEY = CoreUiComponents.StarRating;

export const StarRating = ({
  className,
  starRating,
}: StateRatingComponentProps) => {
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [block] = useModuleBEM(styles, COMPONENT_KEY);

  const { t } = useI18nContext();
  if (starRating == null) return null;
  return (
    <div className={clsx(block, className)}>
      <Icon className={styles.StarIcon} iconName={IconNameEnum.starFilled} />
      {t("stars", { count: starRating })}
    </div>
  );
};
