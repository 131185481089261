import { ITimeRange } from "@b2bportal/core-types";
import { useState } from "react";
import { debounce } from "lodash-es";

export const useFilterTime = ({
  initialDepartureTimeRange,
  initialArrivalTimeRange,
  onTimeRangeChange,
}: {
  initialDepartureTimeRange: ITimeRange;
  initialArrivalTimeRange: ITimeRange;
  onTimeRangeChange: (
    departureTimeRange: ITimeRange,
    arrivalTimeRange: ITimeRange
  ) => void;
}) => {
  const [departureTimeRangeInternal, setDepartureTimeRangeInternal] = useState(
    initialDepartureTimeRange
  );
  const [arrivalTimeRangeInternal, setArrivalTimeRangeInternal] = useState(
    initialArrivalTimeRange
  );

  const handleTimeRangeChange = (
    departureTimeRange: ITimeRange,
    arrivalTimeRange: ITimeRange
  ) => {
    onTimeRangeChange(departureTimeRange, arrivalTimeRange);
  };

  const debouncedAction = debounce(handleTimeRangeChange, 300);
  const [stateDebounceDispatchAction] = useState(() =>
    debounce(debouncedAction, 300, {
      leading: false,
      trailing: true,
    })
  );

  const handleChange = (
    departureTimeRange: ITimeRange,
    arrivalTimeRange: ITimeRange
  ) => {
    stateDebounceDispatchAction(departureTimeRange, arrivalTimeRange);
    setDepartureTimeRangeInternal(departureTimeRange);
    setArrivalTimeRangeInternal(arrivalTimeRange);
  };
  return {
    departureTimeRangeInternal,
    arrivalTimeRangeInternal,
    handleTimeRangeChange: handleChange,
  };
};
