import type { PurchaseErrorEnum } from "@b2bportal/purchase-api";
import {
  CartFulfillErrorModal,
  CartFulfillEventType,
  cartFulfillSelectors,
  useCheckoutSend,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import type { ProductType } from "@hopper-b2b/types";
import {
  LbgErrorModal,
  type LbgErrorModalErrorProps,
} from "@lloyds/ui-connected";
import { useCallback, useEffect, useMemo } from "react";
import { Event, type TEvent } from "../../events";
import { useBaseCartErrorProps, usePurchaseErrorProps } from "../../utilities";

export type CartFulfillErrorDialogProps = {
  productType: ProductType;
  onError?: (error?: string) => void;
};

export const CartFulfillErrorDialog = ({
  productType,
  onError,
}: CartFulfillErrorDialogProps) => {
  const send = useCheckoutSend<TEvent>();

  const errorState = useSelector(cartFulfillSelectors.getCartFulfillError);

  const cartFulfillErrorFailureReasons: (PurchaseErrorEnum | string)[] =
    useSelector(cartFulfillSelectors.getCartFulfillErrorFailureReasons);

  const clearError = useCallback(
    () => send(CartFulfillEventType.CLEAR_CART_FULFILL_ERROR),
    [send]
  );

  const goBack = useCallback(() => send(Event.PREVIOUS), [send]);

  // TODO: Do we need to care about all other Errors? Should we sort/prioritize?
  const firstPurchaseError = useMemo(() => {
    const purchaseErrors = errorState?.data;

    return purchaseErrors?.[0];
  }, [errorState?.data]);

  const baseErrorProps = useBaseCartErrorProps(clearError, goBack);

  const purchaseErrorProps = usePurchaseErrorProps(
    baseErrorProps,
    clearError,
    firstPurchaseError
  );

  const failureReason = useMemo(
    () =>
      cartFulfillErrorFailureReasons &&
      cartFulfillErrorFailureReasons.length > 0
        ? cartFulfillErrorFailureReasons.join("-")
        : "Unknown",
    [cartFulfillErrorFailureReasons]
  );

  useEffect(() => {
    if (errorState) {
      onError?.(failureReason);
    }
  });

  const errorProps: LbgErrorModalErrorProps = useMemo(() => {
    if (!errorState) return undefined;

    switch (errorState.type) {
      case CartFulfillErrorModal.FailedPolling:
      case CartFulfillErrorModal.FailedPollingNoErrorCode:
        return { ...baseErrorProps, ...purchaseErrorProps };
      default:
        return baseErrorProps;
    }
  }, [baseErrorProps, errorState, purchaseErrorProps]);

  return (
    <LbgErrorModal
      open={!!errorState}
      productType={productType}
      {...errorProps}
    />
  );
};
