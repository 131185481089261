import { NavigationFlow } from "@b2bportal/core-types";
import { createContext, useContext, useState, ReactNode } from "react";

export interface FlowContextType<T extends string> {
  flow: NavigationFlow<T>;
  setFlow: (flow: NavigationFlow<T>) => void;
  currentStep: T;
  /**
   * For internal use only by FlowContext
   * @type {(step: T) => void}
   * @memberof FlowContextType
   */
  setCurrentStep: (step: T) => void;
}

const FlowContext = createContext<FlowContextType<any> | undefined>(undefined);

interface FlowProviderProps<T extends string> {
  children: ReactNode;
  flow: NavigationFlow<T>;
}

export const FlowProvider = <T extends string>({
  children,
  flow: flowFromProps,
}: FlowProviderProps<T>): JSX.Element => {
  const [flow, setFlow] = useState<NavigationFlow<T>>(flowFromProps);
  const [currentStep, setCurrentStep] = useState<NavigationFlow<T>>(
    flow?.steps?.[0]
  );

  return (
    <FlowContext.Provider
      value={{ flow, setFlow, currentStep, setCurrentStep }}
    >
      {children}
    </FlowContext.Provider>
  );
};

export const useNavigationFlow = <T extends string>() => {
  const context = useContext(FlowContext) as FlowContextType<T> | undefined;
  if (!context) {
    throw new Error("useFlow must be used within a FlowProvider");
  }
  return context;
};
