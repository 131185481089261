import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Tab, Typography } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import clsx from "clsx";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { FlightSearch, HotelSearch } from "..";
import { ReactComponent as FlightsSvg } from "../../../assets/icons/Flights.svg";
import { ReactComponent as HotelsSvg } from "../../../assets/icons/Hotels.svg";
import {
  useIsAirEnabled,
  useIsLodgingEnabled,
  useIsRoomsEnabled,
} from "../../context";
import styles from "./Tabs.module.scss";

export const HOTELS_TAB_VALUE = "hotel-tab" as const;
export const FLIGHTS_TAB_VALUE = "flights-tab" as const;

export type TabValue = typeof HOTELS_TAB_VALUE | typeof FLIGHTS_TAB_VALUE;

export type TabType = {
  value: TabValue;
  label: string;
  icon?: React.ReactElement;
  panel: ReactNode;
};

export type TabsProps = {
  tabListClassName?: string;
  tabPanelClassName?: string;
  tabClassName?: string;
  containerClassName?: string;
  tabs?: TabType[];
  initalHotelAdultGuests?: number;
  initialTab?: TabValue;
  onSearch?: (path: string) => void;
};

export const Tabs = ({
  tabListClassName,
  tabPanelClassName,
  tabClassName,
  containerClassName,
  tabs,
  initalHotelAdultGuests,
  initialTab,
  onSearch,
}: TabsProps) => {
  const { t } = useI18nContext();

  const enableAir = useIsAirEnabled();
  const enableLodging = useIsLodgingEnabled();
  const enableRooms = useIsRoomsEnabled();

  const defaultTabPanelClassName = useMemo(() => styles.tabPanel, []);

  const definitiveInitialTab = useMemo(() => {
    if (!enableLodging || initialTab === FLIGHTS_TAB_VALUE) {
      return FLIGHTS_TAB_VALUE;
    }

    return HOTELS_TAB_VALUE;
  }, [enableLodging, initialTab]);

  const [activeTab, setActiveTab] = useState<TabValue>(definitiveInitialTab);

  useEffect(() => {
    setActiveTab(definitiveInitialTab);
  }, [definitiveInitialTab]);

  const getTrackingEventName = useCallback((tabValue: TabValue) => {
    switch (tabValue) {
      case HOTELS_TAB_VALUE:
        return "hotel_tapped_entry";
      case FLIGHTS_TAB_VALUE:
        return "air_tapped_entry";
      default:
        return "";
    }
  }, []);

  const handleTabChange = useCallback(
    (_e: React.ChangeEvent<unknown>, newValue: TabValue) => {
      setActiveTab(newValue);
      trackEvent({
        eventName: getTrackingEventName(newValue),
        properties: undefined,
      });
    },
    [getTrackingEventName]
  );

  const hotelOnOpen = useCallback(() => {
    trackEvent({
      eventName: "hotel_viewed_search",
      properties: undefined,
    });
  }, []);

  const activeTabs: TabType[] = useMemo(
    () =>
      tabs ??
      [
        enableLodging
          ? {
              value: HOTELS_TAB_VALUE,
              label: t?.("hotels"),
              icon: <HotelsSvg />,
              panel: (
                <HotelSearch
                  onSearch={onSearch}
                  initialAdultGuests={initalHotelAdultGuests}
                  onOpen={hotelOnOpen}
                  enableRooms={enableRooms}
                />
              ),
            }
          : undefined,
        enableAir
          ? {
              value: FLIGHTS_TAB_VALUE,
              label: t?.("flights"),
              icon: <FlightsSvg />,
              panel: <FlightSearch onSearch={onSearch} />,
            }
          : undefined,
      ].filter(Boolean),
    [
      tabs,
      enableAir,
      enableLodging,
      enableRooms,
      hotelOnOpen,
      initalHotelAdultGuests,
      onSearch,
      t,
    ]
  );

  return (
    <div
      className={clsx(
        containerClassName,
        styles.searchContainer,
        "common-search-root"
      )}
    >
      <TabContext value={activeTab}>
        <TabList
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          className={tabListClassName}
        >
          {activeTabs.map((tab: TabType) => (
            <Tab
              key={tab.value}
              data-testid={tab.value}
              className={clsx(tabClassName, styles.tab)}
              value={tab.value}
              label={<Typography>{tab.label}</Typography>}
              icon={tab.icon}
            />
          ))}
        </TabList>
        {activeTabs.map((tab: TabType) => (
          <TabPanel
            key={tab.value}
            className={tabPanelClassName ?? defaultTabPanelClassName}
            value={tab.value}
          >
            {tab.panel}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};
