import { getObjectKeysAsObject } from "../../../helpers";
import type { PartialParentWithTravelerContext } from "./context";
import { ParentState } from "../../../index";
import type { SelectTravelerEvent } from "./events";

export const guards = {
  haveAnyTravelersLoaded: (ctx: PartialParentWithTravelerContext) => {
    const userTravelers = ctx[ParentState.travelerInformation].travelers;
    return !!(userTravelers?.length && userTravelers.length > 0);
  },
  hasAnySelectedTravelers: (ctx: PartialParentWithTravelerContext) => {
    const selectedTravelerIds =
      ctx[ParentState.travelerInformation].selectedTravelerIds;
    return selectedTravelerIds.length > 0;
  },
  willHaveSelectedTravelers: (
    ctx: PartialParentWithTravelerContext,
    event: SelectTravelerEvent
  ) => {
    const selectedTravelerIds =
      ctx[ParentState.travelerInformation].selectedTravelerIds;
    return !(
      selectedTravelerIds.length === 1 &&
      selectedTravelerIds[0] === event.travelerId
    );
  },
  hasCurrentTraveler: (ctx: PartialParentWithTravelerContext) => {
    const currentUserTraveler =
      ctx[ParentState.travelerInformation].currentTraveler?.id;
    return !!currentUserTraveler;
  },
};

export const GuardTypes = getObjectKeysAsObject(guards);
