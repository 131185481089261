import { CoreDesignSystemConfig } from "./core-design-system";

export interface CoreColorConfig extends CoreDesignSystemConfig {
  // Common
  primary?: string;
  secondary?: string;
  "background-color"?: string;
  "popup-background-color"?: string;

  // Text colors
  "text-primary"?: string;
  "text-secondary"?: string;

  // Link colors
  "link-primary"?: string;

  // Button colors
  "button-primary"?: string;
  "button-primary-background"?: string;
  "button-primary-hover-background"?: string;
  "button-primary-hover"?: string;
  "button-primary-disabled"?: string;
  "button-primary-disabled-background"?: string;
  "button-secondary"?: string;
  "button-secondary-background"?: string;
  "button-secondary-border"?: string;
  "inactive-button-icon-color"?: string;

  // Border
  border?: string;
  "divider-color"?: string;

  // Loading
  "loading-icon-track-color"?: string;
  "loading-icon-border-color"?: string;

  // UI
  "slider-background-color"?: string;
  "slider-range-background-color"?: string;
  "slider-thumb-background-color"?: string;
  "slider-thumb-value-background-color"?: string;
  "slider-thumb-value-color"?: string;
  "page-header-background-color"?: string;
  "page-header-text-color"?: string;
  "page-footer-background-color"?: string;
  "page-footer-text-color"?: string;
  "checkbox-border-color"?: string;
  "checkbox-checked-border-color"?: string;
  "checkbox-checked-background-color"?: string;
  "checkbox-checked-icon-color"?: string;
  "input-background-color"?: string;
  "input-border-color"?: string;
  "input-error-color"?: string;
  "input-error-border-color"?: string;
  "pill-background"?: string;
  "pill-text-color"?: string;
  "pill-success-background"?: string;
  "pill-success-text-color"?: string;
  "pill-error-background"?: string;
  "pill-error-text-color"?: string;

  "map-marker-text"?: string;
  "map-marker-surface"?: string;
  "map-marker-hover-text"?: string;
  "map-marker-hover-surface"?: string;

  // Flights
  "algomerch-tag-background"?: string;
  "algomerch-tag-text"?: string;
  "segment-icon-color"?: string;
  "segment-layover-color"?: string;
  "flight-fare-notice-text-color"?: string;
  "flight-fare-option-border"?: string;
  "flight-fare-option-border-selected"?: string;
  "flight-fare-option-selected"?: string;
  "flight-fare-details-more-button-background"?: string;
  "flight-loading-background-color"?: string;
  "flight-loading-text-color"?: string;
  "flight-card-border-color"?: string;
  "flight-card-summary-icon-color"?: string;
  "flight-card-summary-border-color"?: string;
  "flight-card-summary-divider"?: string;
  "review-page-header-color"?: string;
  "review-page-header-background-color"?: string;
  "flight-list-search-background"?: string;
  "fare-list-background"?: string;
  "flight-list-grid-filter-selected-background-color"?: string;
  "flight-list-grid-filter-selected-color"?: string;

  // Flight itinerary
  "flight-itinerary-confirmation-number"?: string;
  "flight-itinerary-confirmation-number-not-available"?: string;

  // Flights search bar
  "flight-search-bar-text-primary"?: string;

  // Calendar colors
  "calendar-labels-background-color"?: string;
  "date-selected-text-color"?: string;
  "date-hover-text-color"?: string;
  "bucket-0-text-color"?: string;
  "bucket-1-text-color"?: string;
  "bucket-2-text-color"?: string;
  "bucket-3-text-color"?: string;
  "bucket-0-color"?: string;
  "bucket-1-color"?: string;
  "bucket-2-color"?: string;
  "bucket-3-color"?: string;
  "date-hover-color"?: string;
  "date-selected-color"?: string;
  "date-range-limit-color"?: string;
  "date-disabled-color"?: string;
  "date-selected-background-color"?: string;

  // Trips
  "trips-loading-background-color"?: string;
  "trips-loading-text-color"?: string;
}

export interface PriceFreezeColorConfig extends CoreDesignSystemConfig {
  "price-freeze-icon-background-color"?: string;
  "price-freeze-voucher-pill-active-color"?: string;
  "price-freeze-voucher-pill-expired-color"?: string;
  "price-freeze-voucher-pill-cancelled-color"?: string;
  "price-freeze-voucher-pill-exercised-color"?: string;
  "price-freeze-voucher-pill-pending-color"?: string;
  "price-freeze-voucher-pill-refunded-color"?: string;
  "price-freeze-icon-color"?: string;
  "price-freeze-savings-banner-color"?: string;
  "price-freeze-savings-banner-accent-color"?: string;
}

export interface CoreThemeConfig
  extends CoreColorConfig,
    PriceFreezeColorConfig {
  "font-family"?: string;
}

export const installCoreCssConfig = (config: CoreThemeConfig): void =>
  Object.keys(config).forEach((key) => {
    document.body.style.setProperty(`--${key}`, (config as any)[key]);
  });
