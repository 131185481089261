import clsx from "clsx";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";

import styles from "./ChatIntroMessage.module.scss";

export interface IChatIntroMessageProps {
  faqLink?: string;
}

const ChatIntroMessage = (props: IChatIntroMessageProps) => {
  const { brand, t } = useI18nContext();
  const { helpCenterBaseUrl } = brand.urls;

  return (
    <div className={clsx(styles.virtualAssistantInfo)}>
      <div className={clsx(styles.connectionInfo)}>
        <span className={clsx(styles.aiIcon)}>
          {t("chat.chatDescriptionBadge")}
        </span>
        <span className={clsx(styles.connectionText)}>
          {t("chat.chatDescription")}
        </span>
      </div>
      {helpCenterBaseUrl && (
        <div className={clsx(styles.additionalInfo)}>
          <Trans
            i18nKey="chat.additionalInfo"
            components={[
              <span />,
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={helpCenterBaseUrl}
                className={clsx("help-link", styles.helpLink)}
                target="_blank"
                rel="noopener noreferrer"
              />,
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default ChatIntroMessage;
