import { HotelStarRating } from "@b2bportal/lodging-api";

export const toStarRating = (value: HotelStarRating) => {
  switch (value) {
    case HotelStarRating.One:
      return 1;
    case HotelStarRating.Two:
      return 2;
    case HotelStarRating.Three:
      return 3;
    case HotelStarRating.Four:
      return 4;
    case HotelStarRating.Five:
      return 5;
    case HotelStarRating.NotAvailable:
    default:
      return 0;
  }
};
