import {
  Platform,
  ResponseEnum,
  ShopFilter,
  ShopSummaryRequest,
} from "@b2bportal/air-shopping-api";
import { flightsShoppingApi, getDeviceType } from "@overrides/utilities";
import { TripCategory } from "@b2bportal/core-types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { FlightState } from "@b2bportal/core-flights";
import { ShopSummaryResponseV4 } from "@b2bportal/air-shopping-api/lib/api";

export const fetchFlights = createAsyncThunk<
  ShopSummaryResponseV4,
  void,
  { state: FlightState; rejectValue: { error: string } }
>("flightsShop/fetchFlights", async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const {
    passengerCounts: {
      adultsCount,
      infantsInSeatCount,
      infantsOnLapCount,
      childrenCount,
    },
    origin,
    destination,
    departureDate,
    returnDate,
    tripCategory,
  } = state.flights.flightSearch;
  const { isDesktopAndUp } = getDeviceType();
  try {
    if (origin == null || destination == null || departureDate == null) {
      return thunkAPI.rejectWithValue({ error: "Missing required fields" });
    }
    const requestBody: ShopSummaryRequest = {
      passengers: {
        ADT: Number(adultsCount),
        ...(infantsInSeatCount ? { INS: Number(infantsInSeatCount) } : {}),
        ...(infantsOnLapCount ? { INF: Number(infantsOnLapCount) } : {}),
        ...(childrenCount ? { CNN: Number(childrenCount) } : {}),
      },
      route: {
        origin: origin.id.code,
        destination: destination.id.code,
      },
      tripFilter: ShopFilter.NoFilter,
      platform: isDesktopAndUp ? Platform.Desktop : Platform.Mobile,
      departureDate,
      ...(tripCategory === TripCategory.ROUND_TRIP
        ? { returnDate: returnDate }
        : {}),
    };

    const res = await flightsShoppingApi.apiV4ShopSummaryPost(requestBody);

    const data = res.data;

    switch (data.Response) {
      case ResponseEnum.Success: {
        return data.value;
      }
      case ResponseEnum.Failure: {
        return thunkAPI.rejectWithValue({ error: data.errors.toString() });
      }
    }
  } catch (error) {
    // Reject the thunk with an error message
    return thunkAPI.rejectWithValue({ error: String(error) });
  }
});
