import { Slot } from "@hopper-b2b/ui";
import { Box } from "@material-ui/core";
import { BackButton, HeaderUI, MobileHeaderTitle } from "@lloyds/ui-connected";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom-v5-compat";
import {
  PATH_HOME,
  PATH_HOTELS_BOOK_AVAILABILITY,
  PATH_HOTELS_SEARCH,
} from "@hopper-b2b/utilities";
import { useCallback } from "react";

export const LodgingHeader = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onBack = useCallback(() => {
    if (pathname.includes(PATH_HOTELS_SEARCH)) {
      navigate(PATH_HOME);

      return;
    }
    if (pathname.includes(PATH_HOTELS_BOOK_AVAILABILITY)) {
      navigate(PATH_HOTELS_SEARCH);
      return;
    }

    navigate(-1);
  }, [navigate, pathname]);

  return (
    <HeaderUI
      leftItem={<BackButton onClick={onBack} />}
      centerItem={<LodgingHeaderCenterItem />}
      rightItem={
        <Box textAlign="end">
          <Slot id="header-extra-action" />
        </Box>
      }
    />
  );
};

const LodgingHeaderCenterItem = () => {
  return (
    <MobileHeaderTitle
      title={<Slot id="header-title" component={<HeaderTitle />} />}
      subtitle={<Slot id="header-subtitle" />}
    />
  );
};

const HeaderTitle = () => {
  const { t } = useI18nContext();

  return (
    <Routes>
      {/* Hotels */}
      <Route
        path={`${PATH_HOME}${PATH_HOTELS_SEARCH}`}
        element={t("lodging.search.header")}
      />
      <Route
        path={`${PATH_HOME}${PATH_HOTELS_BOOK_AVAILABILITY}*`}
        element={t("lodging.availability.headerTitle")}
      />
    </Routes>
  );
};
