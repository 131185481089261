import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom-v5-compat";
import { LLOYDS_URL_PARAMS } from "../paths";

export const useHighContrast = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setHighContrast = useCallback(
    (key: "true" | "false") => {
      searchParams.set(LLOYDS_URL_PARAMS.HIGH_CONTRAST, key);
      window.localStorage.setItem(LLOYDS_URL_PARAMS.HIGH_CONTRAST, key);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const isHighContrast = useMemo(() => {
    const param = searchParams.get(LLOYDS_URL_PARAMS.HIGH_CONTRAST);
    const savedItem = window.localStorage.getItem(
      LLOYDS_URL_PARAMS.HIGH_CONTRAST
    );

    if (param === "true" || savedItem === "true") {
      if (savedItem !== param) {
        setHighContrast("true");
      }
      return true;
    }

    if (savedItem !== param) {
      setHighContrast("false");
    }
    return false;
  }, [searchParams, setHighContrast]);

  const toggleHighContrast = useCallback(() => {
    if (isHighContrast) {
      setHighContrast("false");
    } else {
      setHighContrast("true");
    }
  }, [setHighContrast, isHighContrast]);

  return { isHighContrast, toggleHighContrast };
};
