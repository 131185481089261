import { getObjectKeysAsObject } from "../../../helpers";
import { CartState } from "../types";
import type { CartContext } from "../context";

export const guards = {
  hasNoAction: (ctx: CartContext, _event: unknown) =>
    !ctx[CartState.cartFulfill].pendingInteractive ||
    !ctx[CartState.cartFulfill].pendingInteractive.actions ||
    ctx[CartState.cartFulfill].pendingInteractive.actions.length === 0,

  hasNoUserAction: (ctx: CartContext, _event: unknown) =>
    !ctx[CartState.cartFulfill].userAction,
};

export const GuardTypes = getObjectKeysAsObject(guards);
