import {
  type AvailabilityResponse,
  LodgingSelectionEnum,
  type Suggestion,
  type Lodging,
} from "@b2bportal/lodging-api";
import { fetchPlace, fetchPlaceFromCoordinates } from "@hopper-b2b/api";
import {
  parseLodgingParams,
  unifyChildrenAges,
  URL_PARAM_KEYS,
  urlToPlaceQuery,
  ViewOption,
  SortOption,
  coordinatesToPlaceQuery,
} from "@hopper-b2b/lodging-utils";
import {
  LocationDescriptorEnum,
  LodgingShopTrackingEvents,
} from "@hopper-b2b/types";
import {
  useDeviceTypes,
  useRenderHotelMapAsDefaultView,
} from "@hopper-b2b/utilities";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom-v5-compat";
import {
  type AvailabilityByLocationQuery,
  type AvailabilityByLodgingQuery,
  type AvailabilityByPlaceQuery,
  fetchAvailabilityByLocation,
  fetchAvailabilityByLodgingId,
  fetchAvailabilityByPlaceAPI,
  fetchAvailabilityNextPage,
} from "../../../api/availability/fetchAvailability";
import { useTrackEvents } from "../../../tracking";
import {
  addAvailabilityLodgings,
  resetFilters,
  setAvailabilityLodgings,
  setCentroid,
  setGuests,
  setIsFirstRender,
  setPlace,
  setRooms,
  setSort,
  setStayValues,
} from "../actions/actions";
import {
  getIsFirstRender,
  getLodgings,
  getPlace,
  type ICentroid,
} from "./../reducer";
import {
  LODGING_BOOK_FULL_PATH,
  LODGING_SEARCH_FULL_PATH,
} from "../../../util/urlPaths";

interface StayValues {
  stayDates: {
    from: string;
    until: string;
  };
  guests: {
    adults: number;
    children: number[];
  };
  rooms: { numberOfRooms: number };
}

interface GetAvailabilityParams {
  place: Suggestion | undefined;
  queryStayValues: StayValues;
}

type SearchSubmit = {
  nextDestination: Suggestion;
  nextFromDate: string;
  nextUntilDate: string;
  nextAdultsCount: number;
  nextChildrenCount: number;
  nextChildrenAges: number[];
  nextRoomsCount: number;
};

const DEFAULT_ZOOM = 13;
// The maximum zoom allowed in Google Maps is 21, but you would only see a single street at that level.
const ZOOM_MAX = 16;

/**
 * Converts latitude to radians in a way that is compatible with the Mercator projection.
 *
 * Google Maps uses a Mercator projection. In a Mercator projection the lines of longitude
 * are equally spaced, but the lines of latitude are not. The distance between lines of
 * latitude increase as they go from the equator to the poles.
 *
 * @param lat - Latitude in degrees
 * @returns Latitude in radians adjusted for Mercator projection
 */
function latRad(lat: number): number {
  // Convert latitude from degrees to radians
  const sin = Math.sin((lat * Math.PI) / 180);
  // Apply the Mercator projection formula
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  // Clamp the resulting value to the range of -π/2 to π/2
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
}

/**
 * Calculate the zoom level for the map based on the lodging bounds
 * Adapted from https://stackoverflow.com/a/65046616
 *
 * Note that this doesn't account for pixel density that does affect Google zoom levels.
 * This should be fine as most devices are pretty similar currently.
 *
 * @param lodgings - The lodgings to calculate the zoom level for
 * @returns Zoom level for the map
 */
function getZoomLevel(lodgings: Lodging[]): number {
  if (!lodgings.length) return DEFAULT_ZOOM;

  let maxLat: number, minLat: number, maxLon: number, minLon: number;
  maxLat = minLat = lodgings[0].lodging.location.coordinates.lat;
  maxLon = minLon = lodgings[0].lodging.location.coordinates.lon;

  // Iterate through all lodgings to find the extreme latitude and longitude values
  for (const lodging of lodgings) {
    const { lat, lon } = lodging.lodging.location.coordinates;
    if (lat > maxLat) maxLat = lat;
    if (lat < minLat) minLat = lat;
    if (lon > maxLon) maxLon = lon;
    if (lon < minLon) minLon = lon;
  }

  // Calculate the difference in latitude and longitude, adjusted for the Mercator projection
  const latDif = Math.abs(latRad(maxLat) - latRad(minLat));
  const lngDif = Math.abs(maxLon - minLon);

  // Calculate the fractional differences relative to the Mercator projection limits
  const latFrac = latDif / Math.PI;
  const lngFrac = lngDif / 360;

  // Calculate zoom levels based on latitude and longitude differences
  const lngZoom = Math.log(1 / latFrac) / Math.log(2);
  const latZoom = Math.log(1 / lngFrac) / Math.log(2);

  return Math.round(Math.min(lngZoom, latZoom, ZOOM_MAX));
}

export const useAvailabilitySearch = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const trackEvent = useTrackEvents();
  const { matchesMobile } = useDeviceTypes();

  const isFirstRender = useSelector(getIsFirstRender);
  const lodgings = useSelector(getLodgings);
  const place = useSelector(getPlace);
  const showMapAsDefault = useRenderHotelMapAsDefaultView();

  const nextPage = useRef<string | undefined>();
  const [isFirstAvailabilityLoaded, setIsFirstAvailabilityLoaded] =
    useState(true);
  const [availabilityLoaded, setAvailabilityLoaded] = useState(
    lodgings.length > 0
  );

  const firstLoad = sessionStorage.getItem("firstLoad");

  const location = useMemo(() => params["location"] ?? "", [params]);
  const coordinates = useMemo(
    () => searchParams.get("latlng") ?? "",
    [searchParams]
  );
  const fromUrl = useMemo(
    () => parseLodgingParams(searchParams),
    [searchParams]
  );
  const queryStayValues = useMemo(
    () => ({
      stayDates: {
        from: fromUrl.fromDate,
        until: fromUrl.untilDate,
      },
      guests: {
        adults: fromUrl.adults,
        children: fromUrl.children,
      },
      rooms: { numberOfRooms: fromUrl.rooms },
    }),
    [fromUrl]
  );

  const setUrlCentroid = useCallback(
    ({ lat, lon, zoom }: { lat: number; lon: number; zoom?: number }) => {
      setSearchParams(
        (previousParams) => {
          previousParams.set(URL_PARAM_KEYS.LAT_LNG, `${lat},${lon}`);
          if (zoom) {
            previousParams.set(URL_PARAM_KEYS.ZOOM, String(zoom));
          }
          return previousParams;
        },
        { replace: true }
      );

      dispatch(
        setCentroid({
          lat,
          lng: lon,
        } as ICentroid)
      );
    },
    [dispatch, setSearchParams]
  );

  const onNextPage = useCallback(async () => {
    if (!nextPage.current) return;

    const routesToStopSearchingAt = [
      LODGING_BOOK_FULL_PATH,
      LODGING_SEARCH_FULL_PATH,
    ];

    // If we're in checkout or go back to search, stop fetching.
    // Tried using useLocation but it never updated as the url changed.
    if (routesToStopSearchingAt.includes(window?.location?.pathname)) return;

    const res = await fetchAvailabilityNextPage(nextPage.current);

    // Check again to ensure it wasn't cancelled
    if (!nextPage.current) return;

    if (res && res.nextPageToken) {
      dispatch(addAvailabilityLodgings(res.lodgings, res.offers));
      nextPage.current = res.nextPageToken;
      setTimeout(onNextPage, 200);
    }
  }, [dispatch]);

  const getAvailability = useCallback(
    async ({ place, queryStayValues }: GetAvailabilityParams) => {
      let query:
        | AvailabilityByLocationQuery
        | AvailabilityByPlaceQuery
        | undefined = undefined;

      if (place) {
        if (place.id.Id === "Lodgings") {
          const lodgingSelection = place.id.lodgingSelection;
          const platform = matchesMobile ? "Mobile" : "Desktop";

          if (
            lodgingSelection.LodgingSelection === LodgingSelectionEnum.Location
          ) {
            query = {
              ...queryStayValues,
              platform,
              lodgingSelection,
            };
          } else if (
            // Typescript cannot unify the type of lodgingSelection if I merge the two conditions, so I have to repeat the check.
            // That or I have to use a type guard or force the type with "as", but I don't think it's worth it.
            lodgingSelection.LodgingSelection === LodgingSelectionEnum.Place
          ) {
            query = {
              ...queryStayValues,
              platform,
              lodgingSelection,
            };
          } else {
            console.warn("Unsupported lodging selection", lodgingSelection);
          }
        } else {
          // Context: https://hopchat.slack.com/archives/C04E9SWLC3E/p1705605778766329
          console.warn("Unsupported place id", place.id.Id);
        }
      }
      if (!query) return;

      // Clear first
      nextPage.current = undefined;

      let nextPageToken = undefined;

      // Fetch Availabilities
      if (place) {
        setAvailabilityLoaded(false);
        const res = await fetchAvailabilityByPlaceAPI(
          query as AvailabilityByPlaceQuery
        );

        if (res) {
          nextPageToken = res.nextPageToken;
          dispatch(
            setAvailabilityLodgings(
              res.lodgings,
              {
                guests: queryStayValues.guests,
                rooms: queryStayValues.rooms.numberOfRooms,
                ...queryStayValues.stayDates,
              },
              place,
              res.offers
            )
          );

          if (res.centroid) {
            setUrlCentroid({
              lat: res.centroid.lat,
              lon: res.centroid.lon,
              zoom: getZoomLevel(res.lodgings),
            });
          }
        }
      }

      if (fromUrl.lodgingIds && fromUrl.lodgingIds.length > 0) {
        const spotlightQuery: AvailabilityByLodgingQuery = {
          ...queryStayValues,
          platform: matchesMobile ? "Mobile" : "Desktop",
          lodgingSelection: {
            lodgingIds: fromUrl.lodgingIds,
            LodgingSelection: LodgingSelectionEnum.LodgingIds,
          },
        };

        const spotlightRes: AvailabilityResponse =
          await fetchAvailabilityByLodgingId(spotlightQuery);

        if (spotlightRes) {
          dispatch(
            addAvailabilityLodgings(
              spotlightRes.lodgings,
              spotlightRes.offers,
              true
            )
          );
        }
      }

      setAvailabilityLoaded(true);
      if (nextPageToken) {
        nextPage.current = nextPageToken;
        setTimeout(onNextPage, 200);
      }
    },
    [dispatch, fromUrl.lodgingIds, matchesMobile, onNextPage, setUrlCentroid]
  );

  const getMapAvailability = useCallback(
    async (bounds: [number, number, number, number]) => {
      let query:
        | AvailabilityByLocationQuery
        | AvailabilityByPlaceQuery
        | undefined = undefined;

      if (bounds?.length > 0) {
        setAvailabilityLoaded(false);
        query = {
          ...queryStayValues,
          platform: matchesMobile ? "Mobile" : "Desktop",
          lodgingSelection: {
            descriptor: {
              northEast: { lat: bounds[3], lon: bounds[2] },
              southWest: { lat: bounds[1], lon: bounds[0] },
              LocationDescriptor: LocationDescriptorEnum.BoundingBox,
            },
            LodgingSelection: LodgingSelectionEnum.Location,
          },
        };

        const res = await fetchAvailabilityByLocation(
          query as AvailabilityByLocationQuery
        );

        if (res) {
          dispatch(
            setAvailabilityLodgings(
              res.lodgings,
              {
                guests: queryStayValues.guests,
                rooms: queryStayValues.rooms.numberOfRooms,
                ...queryStayValues.stayDates,
              },
              undefined,
              res.offers
            )
          );

          setAvailabilityLoaded(true);
          if (res && res.nextPageToken) {
            nextPage.current = res.nextPageToken;
            setTimeout(onNextPage, 200);
          }
        }

        if (res.centroid) {
          setUrlCentroid({
            lat: res.centroid.lat,
            lon: res.centroid.lon,
          });
        }
      }
    },
    [matchesMobile, setUrlCentroid, onNextPage, queryStayValues, dispatch]
  );

  const onSearch = useCallback(
    ({
      nextDestination,
      nextFromDate,
      nextUntilDate,
      nextAdultsCount,
      nextChildrenCount,
      nextChildrenAges,
      nextRoomsCount,
    }: SearchSubmit) => {
      setAvailabilityLoaded(false);

      const children = unifyChildrenAges(nextChildrenAges, nextChildrenCount);
      // Plan is to later set this isn the URL only instead of the store
      dispatch(resetFilters());
      dispatch(setSort(SortOption.MOST_RECOMMENDED));
      dispatch(setPlace(nextDestination));
      dispatch(
        setStayValues({
          fromDate: nextFromDate,
          untilDate: nextUntilDate,
        })
      );
      dispatch(setGuests({ adults: nextAdultsCount, children }));
      dispatch(setRooms(nextRoomsCount));

      setSearchParams(
        (previousParams) => {
          previousParams.set(URL_PARAM_KEYS.FROM_DATE, nextFromDate);
          previousParams.set(URL_PARAM_KEYS.UNTIL_DATE, nextUntilDate);
          previousParams.set(
            URL_PARAM_KEYS.ADULTS_COUNT,
            String(nextAdultsCount)
          );
          previousParams.set(
            URL_PARAM_KEYS.CHILDREN_COUNT,
            String(nextChildrenCount)
          );
          previousParams.set(
            URL_PARAM_KEYS.ROOMS_COUNT,
            nextRoomsCount.toString()
          );

          previousParams.delete(URL_PARAM_KEYS.CHILDREN_AGES);
          children.forEach((age) =>
            previousParams.append(URL_PARAM_KEYS.CHILDREN_AGES, age.toString())
          );

          previousParams.set(
            URL_PARAM_KEYS.VIEW,
            showMapAsDefault ? ViewOption.MAP : ViewOption.LIST
          );
          previousParams.delete(URL_PARAM_KEYS.ZOOM);
          previousParams.delete(URL_PARAM_KEYS.LAT_LNG);
          return previousParams;
        },
        { replace: true }
      );

      getAvailability({
        place: nextDestination,
        queryStayValues: {
          guests: {
            adults: nextAdultsCount,
            children,
          },
          rooms: {
            numberOfRooms: nextRoomsCount,
          },
          stayDates: {
            from: nextFromDate,
            until: nextUntilDate,
          },
        },
      });
    },
    [dispatch, setSearchParams, getAvailability, showMapAsDefault]
  );

  const onSearchMap = useCallback(
    (bounds: [number, number, number, number]) => {
      setAvailabilityLoaded(false);
      getMapAvailability(bounds);
    },
    [getMapAvailability]
  );

  const handleInitialFetch = useCallback(async () => {
    if (!isFirstRender) return;
    dispatch(setIsFirstRender());

    setSearchParams(
      (previousParams) => {
        // if a view is already set, don't change it, otherwise default to list
        const previousView = previousParams.get(URL_PARAM_KEYS.VIEW);
        const previousLatLng = previousParams.get(URL_PARAM_KEYS.LAT_LNG);
        if (previousLatLng) {
          previousParams.set(URL_PARAM_KEYS.LAT_LNG, previousLatLng);
        }
        previousParams.set(
          URL_PARAM_KEYS.VIEW,
          previousView === ViewOption.MAP ? ViewOption.MAP : ViewOption.LIST
        );
        previousParams.set(URL_PARAM_KEYS.ZOOM, String(13));
        return previousParams;
      },
      { replace: true }
    );

    const query = location
      ? { l: urlToPlaceQuery(location) }
      : coordinatesToPlaceQuery(coordinates);

    let place;
    if (location !== "undefined") {
      const query = urlToPlaceQuery(location);
      place = await fetchPlace(query);
    } else {
      const query = coordinatesToPlaceQuery(coordinates);
      place = await fetchPlaceFromCoordinates(query);
      // when searching off of coordinates, subLabel gives us a string that isn't helpful for
      // b2bportal, so setting to undefined to not render.
      // example subLabel: "<b><color name='#60B955'>From R$457/night</color></b> • 67 miles away""
      place.subLabel = undefined;
    }

    if (!place) {
      console.error(
        "No response from query: " + query + "cannot load initial search"
      );
      return;
    }

    dispatch(setPlace(place));

    getAvailability({
      place: place,
      queryStayValues,
    });
  }, [
    isFirstRender,
    dispatch,
    setSearchParams,
    location,
    coordinates,
    getAvailability,
    queryStayValues,
  ]);

  useEffect(() => {
    handleInitialFetch();
  }, [handleInitialFetch]);

  useEffect(() => {
    if (lodgings?.length > 0 && isFirstAvailabilityLoaded) {
      trackEvent(LodgingShopTrackingEvents.hotel_loaded_list_page);
      setIsFirstAvailabilityLoaded(false);
    }

    if (!matchesMobile) {
      trackEvent(LodgingShopTrackingEvents.hotel_viewed_map);
    }

    if (place?.label && !firstLoad) {
      trackEvent(LodgingShopTrackingEvents.hotel_entry, {
        landing_screen: "hotel_list",
      });
      sessionStorage.setItem("firstLoad", "true");
    }
  }, [
    trackEvent,
    place,
    firstLoad,
    matchesMobile,
    availabilityLoaded,
    isFirstAvailabilityLoaded,
    lodgings?.length,
  ]);

  return {
    loading: !availabilityLoaded,
    search: onSearch,
    searchMapArea: onSearchMap,
    lodgings: lodgings,
  };
};
