import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import * as RadixCheckbox from "@radix-ui/react-checkbox";
import defaultStyles from "./CheckboxButton.module.scss";
import React from "react";
import clsx from "clsx";

export interface CheckboxComponentProps
  extends RadixCheckbox.CheckboxProps,
    React.RefAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export const CheckboxButton = React.forwardRef<
  HTMLButtonElement,
  CheckboxComponentProps
>((props, forwardedRef) => {
  const COMPONENT_KEY = CoreUiComponents.CheckboxButton;
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <RadixCheckbox.Root
      {...props}
      ref={forwardedRef}
      className={clsx(block, props.className)}
    >
      {props.children}
    </RadixCheckbox.Root>
  );
});
