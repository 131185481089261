import {
  type Branding,
  ChatAvailability,
  ClientName,
  type ContactSupportConfig,
  FlightShopCardType,
  I18nNamespace,
} from "@hopper-b2b/types";
import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import { PATH_FAQ } from "@lloyds/utils";
import { resolvePath } from "react-router-dom-v5-compat";

const contactSupport: ContactSupportConfig = {
  chatAvailability: ChatAvailability.None,
  displaySupportNumber: "+44 123 456 789",
  phoneAvailability: {
    general: {
      // 8PM BST
      serviceEndUtc: 19,
      // 8AM BST
      serviceStartUtc: 7,
    },
  },
  supportNumber: "+44123456789",
};

export const branding: Branding = {
  default: "en-GB",
  contactSupport,
  merchantPayments: "",
  clientName: ClientName.LLOYDS,
  currencies: [
    {
      locale: "en-GB",
      code: "GBP",
      symbol: "£",
    },
  ],
  supportedLanguages: [{ key: "en-GB", name: "English", label: "English" }],
  flightShopCardType: FlightShopCardType.MINI,
  adaChat: {
    answerIds: {
      delayOrMissedConnection: "6418b6f2d98bdd47409e0705",
    },
  },
  [I18nNamespace.translation]: {
    "en-GB": {
      portalName: "Lloyds with Hopper",
      urls: {
        helpCenterBaseUrl: resolvePath(PATH_FAQ).pathname,
        termsAndConditions: "",
        privacyPolicy: "",
        disruptionTerms: "http://travel.lloydsbank.co.uk/terms",
        priceFreezeTerms:
          "https://www.lloyds.com/legal/en/document/?name=lloyds-travel---terms-and-conditions---flights-and-hotels&country=great-britain&lang=en",
        missedConnectionTerms:
          "https://www.lloyds.com/legal/en/document/?name=lloyds-travel---terms-and-conditions---flights-and-hotels&country=great-britain&lang=en",
      },
      preferredCountryCode: "GB",
      preferredAreaCode: "+44",
      calendarMonthFormatStyle: DateTimeFormatStyle.Custom({ month: "long" }),
      timeFormat: "h:mm A",
      customDateTimeFormats: {
        calendar: (date: Date) =>
          formatDateTime(
            new Date(date),
            // Intentionally set to "en" to render the date like "Fri, Jun 7" instead of "Fri, 7 Jun"
            "en",
            DateTimeFormatStyle.Custom({
              weekday: "short",
              month: "short",
              day: "numeric",
            })
          ),
        calendarDay: (date: Date) =>
          formatDateTime(
            new Date(date),
            "en-GB",
            DateTimeFormatStyle.Custom({
              weekday: "narrow",
            })
          ),
      },
    },
  },
};
