import {
  FulfillResponseEnum,
  type CipherText,
  type FulfillResponse,
} from "@b2bportal/purchase-api";
import { CallState } from "@hopper-b2b/types";
import { assign, type DoneInvokeEvent } from "xstate";
import { getObjectKeysAsObject, setContextWithKey } from "../../../helpers";
import type { CartFulfillError } from "../../../types";
import type { CartContext } from "../context";
import { CartState } from "../types";
import type { CartFulfillContext } from "./context";
import type { CompleteInteractiveSuccess } from "./events";
import {
  type InteractiveAuthResponse,
  InteractiveAuthResponseEnum,
} from "@b2bportal/card-api";

export const actions = {
  setCartFulfillCipherText: assign(
    (ctx: CartContext, event: DoneInvokeEvent<CipherText>) =>
      setContextWithKey(ctx, CartState.cartFulfill, {
        ...ctx[CartState.cartFulfill],
        fulfillCipherText: event.data,
      } as CartFulfillContext)
  ),
  setCartFulfillError: assign(
    (ctx: CartContext, event: DoneInvokeEvent<CartFulfillError>) =>
      setContextWithKey(ctx, CartState.cartFulfill, {
        ...ctx[CartState.cartFulfill],
        error: event.data,
      } as CartFulfillContext)
  ),
  setCartFulfillResult: assign(
    (ctx: CartContext, event: DoneInvokeEvent<FulfillResponse>) => {
      switch (event.data.FulfillResponse) {
        case FulfillResponseEnum.Success: {
          return setContextWithKey(ctx, CartState.cartFulfill, {
            ...ctx[CartState.cartFulfill],
            fulfilledPayments: event.data.payments,
            fulfilledProducts: event.data.products,
            fulfillRequestPayments: {},
            trackingProperties: event.data.trackingProperties,
          } as CartFulfillContext);
        }
        default:
          return ctx;
      }
    }
  ),
  clearCartFulfillError: assign((ctx: CartContext) =>
    setContextWithKey(ctx, CartState.cartFulfill, {
      ...ctx[CartState.cartFulfill],
      error: undefined,
    } as CartFulfillContext)
  ),
  setCartFulfillCallStateInProcess: assign((ctx: CartContext) =>
    setContextWithKey(ctx, CartState.cartFulfill, {
      ...ctx[CartState.cartFulfill],
      callState: CallState.InProcess,
    } as CartFulfillContext)
  ),
  setCartFulfillCallStateSuccess: assign((ctx: CartContext) =>
    setContextWithKey(ctx, CartState.cartFulfill, {
      ...ctx[CartState.cartFulfill],
      callState: CallState.Success,
    } as CartFulfillContext)
  ),
  setCartFulfillCallStateFailed: assign((ctx: CartContext) =>
    setContextWithKey(ctx, CartState.cartFulfill, {
      ...ctx[CartState.cartFulfill],
      callState: CallState.Failed,
    } as CartFulfillContext)
  ),
  setCartFulfillPendingInteractive: assign(
    (ctx: CartContext, event: DoneInvokeEvent<InteractiveAuthResponse>) => {
      switch (event.data.InteractiveAuthResponse) {
        case InteractiveAuthResponseEnum.PendingInteractivePaymentAuth: {
          return setContextWithKey(ctx, CartState.cartFulfill, {
            ...ctx[CartState.cartFulfill],
            pendingInteractive: event.data,
          } as CartFulfillContext);
        }
        default:
          return setContextWithKey(ctx, CartState.cartFulfill, {
            ...ctx[CartState.cartFulfill],
            pendingInteractive: undefined,
          } as CartFulfillContext);
      }
    }
  ),
  clearCartFulfillPendingInteractive: assign((ctx: CartContext) =>
    setContextWithKey(ctx, CartState.cartFulfill, {
      ...ctx[CartState.cartFulfill],
      pendingInteractive: undefined,
    } as CartFulfillContext)
  ),
  clearCartFulfillUserActions: assign((ctx: CartContext) =>
    setContextWithKey(ctx, CartState.cartFulfill, {
      ...ctx[CartState.cartFulfill],
      userAction: undefined,
    } as CartFulfillContext)
  ),
  setCartFulfillCompleteInteractive: assign(
    (ctx: CartContext, event: CompleteInteractiveSuccess) =>
      setContextWithKey(ctx, CartState.cartFulfill, {
        ...ctx[CartState.cartFulfill],
        completeInteractive: { results: [event.data] },
      } as CartFulfillContext)
  ),
  setUserAction: assign(
    (ctx: CartFulfillContext, event: DoneInvokeEvent<{ url: string }>) => {
      return setContextWithKey(ctx, CartState.cartFulfill, {
        ...ctx[CartState.cartFulfill],
        userAction: event,
      } as CartFulfillContext);
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);
