import { useCallback, useMemo } from "react";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { openExternalLink } from "@hopper-b2b/utilities";
import { FintechProductType } from "@hopper-b2b/types";
import {
  ButtonWrap,
  IconComponent,
  IconName,
  MobilePopoverCard,
} from "../../index";
import "./styles.scss";
import clsx from "clsx";

export interface ITermsAndConditions {
  open: boolean;
  onClose: () => void;
  productType: FintechProductType;
}

export const TermsAndConditions = (props: ITermsAndConditions) => {
  const { t, brand } = useI18nContext();

  const { open = false, onClose, productType: product } = props;

  const productType =
    product === FintechProductType.CfarExercise ||
    product === FintechProductType.CfarPurchase
      ? "cfar"
      : product;

  const redirectUrl = useMemo(
    () => brand.urls[`${productType}Terms`],
    [brand.urls, productType]
  );

  const redirect = useCallback(() => {
    if (redirectUrl) {
      openExternalLink(redirectUrl);
    }
  }, [redirectUrl]);

  // The following comment lets i18n plugin know which keys are being used for this component even when
  // it is using dynamic keys e.g. `${productType}Terms.content`
  //
  //! t("disruptionTerms.content")
  //! t("disruptionTerms.title")
  //! t("missedConnectionTerms.content")
  //! t("missedConnectionTerms.title")
  //! t("chfarTerms.content")
  //! t("chfarTerms.title")
  //! t("cfarTerms.content")
  //! t("cfarTerms.title")
  return (
    <MobilePopoverCard
      centered
      fullScreen
      open={open}
      className={clsx("terms", productType)}
      contentClassName="terms-container"
      headerElement={t("termsAndConditions")}
      topLeftButton={
        <button className="terms-close" onClick={onClose}>
          <IconComponent className="terms-close-icon" name={IconName.Close} />
        </button>
      }
    >
      <div className="terms-title">{t(`${productType}Terms.title`)}</div>
      <div className="terms-content">
        <I18nMarkup tKey={`${productType}Terms.content`} />
        {redirectUrl ? (
          <ButtonWrap className="terms-link" onClick={redirect}>
            {t("termsLink")}.
          </ButtonWrap>
        ) : null}
      </div>
    </MobilePopoverCard>
  );
};
