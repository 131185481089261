import { type AxiosError } from "axios";
import { type SpreedlyCreatePaymentMethodErrorResponse } from "../types";

export const isSpreedlyCreatePaymentMethodError = (
  e: AxiosError<SpreedlyCreatePaymentMethodErrorResponse> | any
): e is AxiosError<SpreedlyCreatePaymentMethodErrorResponse> => {
  return (
    (e as AxiosError<SpreedlyCreatePaymentMethodErrorResponse>)?.response?.data
      ?.errors !== undefined
  );
};
