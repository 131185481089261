import { ChatTransfer } from "@b2bportal/ivr-api";
import { getGenericSupportId } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { noop } from "lodash-es";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

import { BottomSheetContentEnum, IChatContext } from "../../types";

const initChatContext: IChatContext = {
  bottomSheetContent: BottomSheetContentEnum.Closed,
  chatProvider: null,
  closeChatWindow: noop,
  conversationId: "",
  getTripDetailsPath: null,
  minimizeChatWindow: noop,
  setBottomSheetContent: noop,
  setConversationId: noop,
  supportId: "",
};

export const ChatContext = createContext<IChatContext>(initChatContext);

export interface IChatContextProps {
  chatContext: Partial<IChatContext>;
  children: ReactNode;
}

/**
 * "Global" values accessible to all chat components
 */
export const ChatContextProvider = (props: IChatContextProps) => {
  const { chatContext, children } = props;
  const { language: locale } = useI18nContext();
  const [bottomSheetContent, setBottomSheetContent] = useState(
    BottomSheetContentEnum.Closed
  );
  const [supportId, setSupportId] = useState("");

  const fetchSupportId = useCallback(
    async (chatTransfer: ChatTransfer) => {
      setSupportId("");

      try {
        // TODO Add params to fetchSupportId and call getBookingSupportId instead to make product-specific
        const newSupportId = await getGenericSupportId(
          chatTransfer,
          locale,
          "general",
          "general"
        );

        if (newSupportId) {
          setSupportId(newSupportId);
        }
      } catch {
        setSupportId("");
      }
    },
    [locale]
  );

  useEffect(() => {
    const { chatProvider, conversationId } = chatContext;

    if (chatProvider && conversationId) {
      const chatTransfer: ChatTransfer = {
        conversationId,
        source: chatProvider.chatTransferSource,
      };

      fetchSupportId(chatTransfer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatContext.chatProvider, chatContext.conversationId]);

  return (
    <ChatContext.Provider
      value={{
        ...initChatContext,
        ...chatContext,
        bottomSheetContent,
        setBottomSheetContent,
        supportId,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
