import { Reducer, combineReducers } from "@reduxjs/toolkit";

import { reducer as availabilityReducer } from "../modules/availability";
import { reducer as shopReducer } from "../modules/shop";
import { reducer as walletReducer } from "../modules/wallet";
import { ILodgingAvailabilityState } from "../modules/availability/reducer";
import { ILodgingShopState } from "../modules/shop/reducer";
import { ILodgingWalletState } from "../modules/wallet/reducer";

export const appReducer = combineReducers({
  lodgingAvailability: availabilityReducer,
  lodgingShop: shopReducer,
  wallet: walletReducer,
});

export interface LodgingAppState {
  lodgingAvailability: ILodgingAvailabilityState;
  lodgingShop: ILodgingShopState;
  wallet: ILodgingWalletState;
}

export function rootReducer(state: LodgingAppState, action: any) {
  return appReducer(state, action);
}
