import { useI18nContext } from "@hopper-b2b/i18n";
import { PATH_WALLET } from "@hopper-b2b/utilities";
import { useSessionUserInfo } from "@lloyds/auth";
import { useWallet } from "@lloyds/wallet";
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { ReactComponent as WalletIcon } from "../../../../assets/wallet.svg";
import styles from "./HeaderWalletInfo.module.scss";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

export const HeaderWalletInfo = () => {
  const { t, formatFiatCurrency } = useI18nContext();
  const userInfo = useSessionUserInfo();
  const navigate = useNavigate();
  const { creditBalance, isLoadingWallet } = useWallet();

  const goToWallet = useCallback(() => {
    navigate(PATH_WALLET);
  }, [navigate]);

  return (
    userInfo &&
    (userInfo.firstName || userInfo.lastName) && (
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Grid item>
          <Box className={styles.walletIcon}>
            <IconButton onClick={goToWallet} aria-label={t("goToWallet")}>
              <WalletIcon height={19} width={24} />
            </IconButton>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" alignItems="flex-start" flexDirection="column">
            <Typography variant="subtitle1">{`Welcome, ${userInfo.firstName} ${userInfo.lastName}`}</Typography>
            {isLoadingWallet || !creditBalance?.value ? undefined : (
              <Typography variant="h5">
                {t("rewardBalance", {
                  amount: formatFiatCurrency(creditBalance),
                })}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    )
  );
};
