import { deleteTraveler } from "@hopper-b2b/api";
import type { DeleteTravelerRequest } from "@b2bportal/traveler-api/lib/api";
import { TravelerErrorModalTypes } from "@checkout/types";
import { apiConfig } from "@hopper-b2b/utilities";
import type { DeleteTravelerEvent } from "../events";
import { AxiosResponse } from "axios";

export const deleteTravelerService = (event: DeleteTravelerEvent) =>
  new Promise((resolve, reject) => {
    const body: DeleteTravelerRequest = {
      travelerId: event.travelerId,
    };
    deleteTraveler(body, apiConfig)
      .then((response: AxiosResponse<object>) => {
        resolve(response);
      })
      .catch((e) => {
        reject({
          type: TravelerErrorModalTypes.DeleteTraveler,
          data: e,
        });
      });
  });
