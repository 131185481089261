import type {
  HotelPriceFreezeFrozenProduct,
  HotelPriceFreezeVoucher,
  QuotedPriceFreezeOfferProduct,
  VoucherPriceFreezeOfferProduct,
} from "@b2bportal/core-types";
import type { HotelsPriceFreezeStateWithoutValue } from "./types";

// Offer - before purchase
export const getPriceFreezeOfferCap = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return state.context.priceFreeze.priceFreezeOffers[0]?.serviceDetails
    .totalServiceCap.fiat;
};

export const getPriceFreezeOffers = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return state.context.priceFreeze.priceFreezeOffers;
};

export const getSelectedPriceFreezeOffer = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return state.context.priceFreeze.selectedOffer;
};

export const getSelectedPriceFreezeOfferTotalPrice = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return state.context.priceFreeze.selectedOffer.serviceDetails.totalServiceFee
    .fiat;
};

export const getPriceFreezeOfferPricePerNight = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return state.context.priceFreeze.selectedOffer?.serviceDetails
    .perNightServiceFee.fiat;
};

// Quoted Frozen Product
export const getFrozenHotelProduct = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  const hotelPriceFreezeFrozenProducts =
    state.context.cartQuote.quoteBreakdown.products
      .map(({ product }) => product)
      .filter(
        (product): product is QuotedPriceFreezeOfferProduct =>
          product.type === "PriceFreeze"
      )
      .map((product) => product.value.offer.frozenProduct)
      .filter(
        (frozenProduct): frozenProduct is HotelPriceFreezeFrozenProduct =>
          frozenProduct.FrozenProduct === "Hotel"
      );

  return hotelPriceFreezeFrozenProducts.find((product) => !!product);
};

export const getFrozenProductShoppedPrice = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return getFrozenHotelProduct(state)?.shoppedPrice;
};

export const getFrozenProductShoppedPricePerNight = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return getFrozenHotelProduct(state)?.shoppedPricePerNight;
};

export const getFrozenProductQuotedPrice = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return getFrozenHotelProduct(state)?.quotedPrice;
};

export const getFrozenProductQuotedPricePerNight = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return getFrozenHotelProduct(state)?.quotedPricePerNight;
};

export const getCartQuoteCurrency = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  const price =
    getFrozenProductQuotedPrice(state) ?? getFrozenProductShoppedPrice(state);
  return {
    currencyCode: price.fiat.currencyCode,
    currencySymbol: price.fiat.currencySymbol,
  };
};

export const getShouldShowPriceChangeModal = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  const acknowledged = state.context.cartQuote.priceChange?.acknowledged;
  const hasPriceDifference = state.context.cartQuote.priceChange?.hasDifference;
  return hasPriceDifference && !acknowledged;
};

// Voucher - after purchase
export const getHotelPriceFreezeVoucher = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  const hotelPriceFreezeVouchers = state.context.cartFulfill.fulfilledProducts
    .filter(
      (product): product is VoucherPriceFreezeOfferProduct =>
        product.type === "PriceFreeze"
    )
    .map((product) => product.value.voucher)
    .filter(
      (product): product is HotelPriceFreezeVoucher =>
        product.frozenProduct.FrozenProduct === "Hotel"
    );

  return hotelPriceFreezeVouchers.find((product) => !!product);
};
