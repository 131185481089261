import {
  CoreUiComponents,
  MapCoordinates,
  MapState,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import GoogleMap, { Map as MapType } from "google-maps-react-markers";
import { useMap } from "@components/ui";
import { getLang } from "@hopper-b2b/i18n";
import { MutableRefObject } from "react";
import { MapMarkerComponentProps } from "./types";

export interface MapPointer<T> {
  data: T;
  label: string;
  id: string;
  coordinates: MapCoordinates;
}

export interface MapComponentProps<T> extends PlatformComponentProps {
  //Uncontrolled component
  defaultCentroid?: MapCoordinates;
  defaultZoom?: number;

  //Controlled component
  centroid?: MapCoordinates;
  zoom?: number;

  onMapChange?: (mapState: MapState) => void;
  zoomControl?: boolean;
  onMapReady?: (mapRef: MutableRefObject<MapType>) => void;
  points: Array<MapPointer<T>>;
  highlightedId?: string;
  MapMarker?: React.ComponentType<MapMarkerComponentProps<T>>;
  clusterLabel?: (count: number) => string;
}

const COMPONENT_KEY = CoreUiComponents.Map;

export const Map = <T,>(props: MapComponentProps<T>) => {
  const { children } = props;
  const { context, handlers } = useMap(props);
  return (
    <GoogleMap
      apiKey={import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY}
      defaultCenter={context.mapCenter}
      defaultZoom={context.mapZoom}
      onChange={handlers.onChange}
      externalApiParams={{ language: getLang() }}
      options={{
        clickableIcons: false,
        zoomControl: context.zoomControl,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: context.styles,
      }}
      yesIWantToUseGoogleMapApiInternals={true}
      onGoogleApiLoaded={handlers.handleAPILoaded}
      errorContent={null}
    >
      {context.mapPoints.map((props) => {
        return (
          <context.MapMarker<T> key={props.markerId} {...props}>
            {props.label}
          </context.MapMarker>
        );
      })}
      {children}
    </GoogleMap>
  );
};
