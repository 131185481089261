import {
  ChatTransfer,
  EncodeGenericConversationRequest,
  EncodeGenericConversationResponseEnum,
  ivrApi,
} from "@b2bportal/ivr-api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";

import { trackEvent } from "../tracking/trackEvent";
import { axiosInstance } from "../AxiosInterceptor";

async function getGenericSupportId(
  chatTransfer?: ChatTransfer,
  locale?: string,
  funnelType?: string,
  requestType?: string
) {
  const body: EncodeGenericConversationRequest = {
    chatTransfer,
    funnelType,
    locale,
    requestType,
  };

  try {
    const res = await ivrApi(axiosInstance).apiV0IvrEncodeGenericPost(body);

    if (
      res.data.EncodeGenericConversationResponse ===
      EncodeGenericConversationResponseEnum.Success
    ) {
      return res.data.encodedCode;
    }

    throw res.data;
  } catch {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
  }
}

export default getGenericSupportId;
