import clsx from "clsx";
import { type HotelStarRating } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { toStarRating } from "@hopper-b2b/utilities";
import styles from "./StarRating.module.scss";
import { IconComponent } from "../IconComponent";
import { IconName } from "../Icon";
import { Typography, TypographyVariant } from "@material-ui/core";
import { useMemo } from "react";

interface Props {
  starRating: HotelStarRating;
  className?: string;
  textVariant?: TypographyVariant;
  onlyStarRating?: boolean;
}

export function StarRating({
  starRating,
  className,
  textVariant,
  onlyStarRating,
}: Props) {
  const { t } = useI18nContext();

  const starsCount = useMemo(() => toStarRating(starRating), [starRating]);

  return starsCount && starsCount > 0 ? (
    <div className={clsx(className, styles.Container)}>
      <IconComponent name={IconName.StarFilled} />
      <Typography color="textSecondary" variant={textVariant ?? "caption"}>
        {onlyStarRating ? starsCount : t("stars", { count: starsCount })}
      </Typography>
    </div>
  ) : null;
}
