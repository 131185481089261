import { FeatureFlagsContext } from "@b2bportal/core-utilities";
import { Branding, ClientName, Translation } from "@hopper-b2b/types";
import { ThemeOptions } from "@material-ui/core/styles";
import { LabComponentsPropsList } from "@material-ui/lab/themeAugmentation";
import {
  CoreColorConfig,
  CoreLibrary,
  IconSet,
  ImageSet,
} from "@b2bportal/core-themes";

export type MUIThemeDefinition = ThemeOptions & Partial<LabComponentsPropsList>;

export enum Env {
  production = "production",
  staging = "staging",
  development = "development",
  local = "local",
}

export type Route = {
  path: string;
  component: React.ReactNode;
  identifier: string;
};

export type BootConfig = {
  env: Env;
  processEnv: Env;
  datadog?: {
    serviceName: string;
    clientToken: string;
    // TODO: This property might be interfered
    reactVersion: string;
  };
};

export type PortalConfig = {
  clientName: ClientName;
  bootConfig: BootConfig;
  theme: ConfigTheme;
  featureFlags: FeatureFlagsContext;
  branding?: Branding;
  translations?: Translation;
};

export type BasicConfig = {
  clientName: ClientName;
  bootConfig: Partial<BootConfig>;
  theme: ConfigTheme;
  featureFlags?: FeatureFlagsContext;
  branding: Branding;
  translations?: Translation;
};

export type ConfigTheme = {
  muiTheme?: MUIThemeDefinition;
  variables: CoreColorConfig;
  styleOverrides?: CoreLibrary;
  icons: Partial<IconSet>;
  images: Partial<ImageSet>;
};
