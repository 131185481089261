import { Status } from "@b2bportal/chat-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";

import { formatChatMessageTimestamp } from "../../utils";

import styles from "./ChatMessageMetadata.module.scss";

export interface IChatMessageMetadataProps {
  agent?: boolean;
  className?: string;
  status?: Status;
  timestamp: string;
}

const ChatMessageMetadata = (props: IChatMessageMetadataProps): JSX.Element => {
  const { agent = false, className, status, timestamp } = props;
  const { language: locale, t } = useI18nContext();
  let statusLC = "";
  let statusCopy = "";

  if (status) {
    statusLC = status.toLowerCase();
    statusCopy = t("chat.messageStatus", { context: statusLC });
  }

  return (
    <div
      className={clsx(styles.chatMessageMetadata, className, {
        [styles.agent]: agent,
        [styles.user]: !agent,
      })}
    >
      {agent && (
        <span className={styles.AIAgentName}>{t("chat.AIAgentName")}</span>
      )}
      {statusCopy && (
        <span
          className={clsx(
            "message-status",
            styles[statusLC],
            styles.messageStatus
          )}
        >
          {statusCopy}
        </span>
      )}
      <span
        className={clsx(styles.messageTimestamp, {
          [styles.showSeparator]: Boolean(agent || statusCopy),
        })}
      >
        {formatChatMessageTimestamp(timestamp, locale)}
      </span>
    </div>
  );
};

export default ChatMessageMetadata;
