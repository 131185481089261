export enum TenantFlightSteps {
  FINTECH_CUSTOMIZE = "FINTECH_CUSTOMIZE",
  DISRUPTION = "DISRUPTION",
  CFAR = "CFAR",
}
enum CoreFlightSteps {
  SEARCH = "SEARCH",
  DEPARTURE = "DEPARTURE",
  RETURN = "RETURN",
  REVIEW = "REVIEW",
  REVIEW_FARE = "REVIEW_FARE",
  BOOK = "BOOK",
}

export const LloydsFlightSteps = {
  ...CoreFlightSteps,
  ...TenantFlightSteps,
} as const;

// Type for the values of the combined enum
export type FlightSteps =
  (typeof LloydsFlightSteps)[keyof typeof LloydsFlightSteps];
