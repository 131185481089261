import { useI18nContext } from "@hopper-b2b/i18n";
import { IAirlineOptions } from "@b2bportal/core-types";
import { CheckboxGroup } from "@components/ui";

interface IAirlineFilterProps {
  allAirports: IAirlineOptions[];
  airportFilter: string[];
  onAirportFilterChange: (val: string[]) => void;
}

export const FilterAirport = (props: IAirlineFilterProps) => {
  const { allAirports, airportFilter, onAirportFilterChange } = props;
  const { t } = useI18nContext();

  return (
    <CheckboxGroup
      options={allAirports}
      checkedOptions={airportFilter}
      onOptionsChange={onAirportFilterChange}
    />
  );
};
