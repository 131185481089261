import { QueryStatus } from "@reduxjs/toolkit/query";
import { CallState } from "./stateTypes";
import { AxiosError } from "axios";
import { SerializedError } from "@reduxjs/toolkit";

interface IBaseExternalResource<State extends CallState> {
  state: State;
}

export interface ExternalResourceNotCalled
  extends IBaseExternalResource<CallState.NotCalled> {
  data?: undefined;
}
export interface ExternalResourceInProcess<Data>
  extends IBaseExternalResource<CallState.InProcess> {
  data?: Data;
}
export interface ExternalResourceSuccess<Data>
  extends IBaseExternalResource<CallState.Success> {
  data: Data;
}
export interface ExternalResourceFailure<Data, Error>
  extends IBaseExternalResource<CallState.Failed> {
  data?: Data;
  error: Error;
}

type BaseExternalResource<
  Data,
  Error = string,
  SupportCaching extends true | false = false
> =
  | ExternalResourceNotCalled
  | ExternalResourceInProcess<SupportCaching extends true ? Data : never>
  | ExternalResourceSuccess<Data>
  | ExternalResourceFailure<SupportCaching extends true ? Data : never, Error>;

export const unpackExternalResource = <
  Data,
  Error,
  SupportCaching extends true | false
>(
  resource: BaseExternalResource<Data, Error, SupportCaching>
) => {
  switch (resource.state) {
    case CallState.NotCalled:
      return {
        data: { data: undefined, cached: undefined },
        error: undefined,
        isLoading: false,
        isError: false as boolean,
      } as const;
    case CallState.Success:
      return {
        data: { data: resource.data, cached: undefined },
        error: undefined,
        isLoading: false,
        isError: false as boolean,
      } as const;
    case CallState.InProcess:
      return {
        data: { data: undefined, cached: resource.data },
        error: undefined,
        isLoading: true,
        isError: false as boolean,
      } as const;
    case CallState.Failed:
      return {
        data: { data: undefined, cached: resource.data },
        error: resource.error,
        isLoading: false,
        isError: true as boolean,
      } as const;
  }
};

/**
 * `ExternalResource` represents a resource that is fetchable from an external
 * source. It's a generic way of managing success, loading, and error states.
 *
 * @template Data The type of the data stored by the resource.
 * @template Error The type of the error stored by the resource.
 */
export type ExternalResource<Data, Error = string> = BaseExternalResource<
  Data,
  Error,
  false
>;

/**
 * `CacheableExternalResource` represents a resource that is fetchable from an
 * external source and can be cached. It's a generic way of managing success,
 * loading, and error states.
 *
 * In this context, "cached" means that the loading and error states can also
 * store data. This is useful when the data doesn't expire or has a long TTL.
 * For example, a list of flight itineraries. If the user attempted to refresh
 * the list, they should be able to see their current itineraries while the new
 * ones are being fetched. Additionally, if the fetch fails, the user should
 * still be able to see their current itineraries.
 *
 * @template Data The type of the data stored by the resource.
 * @template Error The type of the error stored by the resource.
 */
export type CacheableExternalResource<
  Data,
  Error = string
> = BaseExternalResource<Data, Error, true>;

export type MapExternalResource = {
  // Explicit support for the narrow types have to come first in the overload
  // list to avoid the compiler from inferring the broader types.
  <Data, NewData, Error>(
    resource: ExternalResource<Data, Error>,
    mapData: (data: Data) => NewData
  ): ExternalResource<NewData, Error>;
  <Data, NewData, Error>(
    resource: CacheableExternalResource<Data, Error>,
    mapData: (data: Data) => NewData
  ): CacheableExternalResource<NewData, Error>;

  // Handlers for the `BaseExternalResource` type.
  <Data, NewData, Error, SupportsCaching extends true | false>(
    resource: BaseExternalResource<Data, Error, SupportsCaching>,
    mapData: (data: Data) => NewData
  ): BaseExternalResource<NewData, Error, SupportsCaching>;
  <Data, NewData, Error, NewError, SupportsCaching extends true | false>(
    resource: BaseExternalResource<Data, Error, SupportsCaching>,
    mapData: (data: Data) => NewData,
    mapError: (error: Error) => NewError
  ): BaseExternalResource<NewData, NewError, SupportsCaching>;
};
export const mapExternalResource: MapExternalResource = (<
  Data,
  NewData,
  Error,
  SupportCaching extends true | false,
  NewError = Error
>(
  resource: BaseExternalResource<Data, Error, SupportCaching>,
  mapData: (data: Data) => NewData,
  mapError?: (error: Error) => NewError
): BaseExternalResource<NewData, NewError, SupportCaching> => {
  type CachedData<Data> = SupportCaching extends true ? Data : never;
  switch (resource.state) {
    case CallState.NotCalled:
      return resource;
    case CallState.InProcess:
      if (resource.data == null) {
        return { ...resource, data: undefined };
      }
      return {
        ...resource,
        data: mapData(resource.data) as CachedData<NewData>,
      };
    case CallState.Success:
      return {
        state: CallState.Success,
        data: mapData(resource.data),
      };
    case CallState.Failed: {
      const error =
        mapError?.(resource.error) ?? (resource.error as unknown as NewError);
      if (resource.data == null) {
        return { ...resource, data: undefined, error };
      }
      return {
        ...resource,
        data: mapData(resource.data) as CachedData<NewData>,
        error,
      };
    }
  }
}) as MapExternalResource;

export interface BaseResponse<
  Status extends QueryStatus,
  Data,
  Error = string
> {
  data?: Data;
  error?: Error;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  isUninitialized: boolean;
  status: Status;
}

export interface ResponseUninitialized<Data, Error = string>
  extends BaseResponse<QueryStatus.uninitialized, Data, Error> {
  error?: undefined;
  isLoading: false;
  isSuccess: false;
  isError: false;
  isUninitialized: true;
}

export interface ResponsePending<Data, Error = string>
  extends BaseResponse<QueryStatus.pending, Data, Error> {
  data?: Data;
  error?: undefined;
  isLoading: true;
  isSuccess: false;
  isError: false;
  isUninitialized: false;
}

export interface ResponseFulfilled<Data, Error>
  extends BaseResponse<QueryStatus.fulfilled, Data, Error> {
  data: Data;
  error: undefined;
  isLoading: false;
  isSuccess: true;
  isError: false;
  isUninitialized: false;
}

export interface ResponseRejected<Data, Error>
  extends BaseResponse<QueryStatus.rejected, Data, Error> {
  data?: Data;
  error: Error;
  isLoading: false;
  isSuccess: false;
  isError: true;
  isUninitialized: false;
}

export type Response<D, E = Error | unknown> =
  | ResponseUninitialized<D, E>
  | ResponsePending<D, E>
  | ResponseFulfilled<D, E>
  | ResponseRejected<D, E>;

export type RTKQueryResponse<D, E> = {
  data?: D;
  error?: E;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  isUninitialized: boolean;
  status: QueryStatus;
};
