import type { Fare } from "@b2bportal/air-shopping-api";
import type {
  DriverLicense,
  IFlightGridFares,
  PaymentErrorEnum,
  TripDetails,
  TripSummary,
} from "../apis";
import type { Maybe } from "../exchange-module";
import type { HotelBookErrorType } from "../hotels-module";

export enum CallState {
  NotCalled,
  InProcess,
  Success,
  Failed,
}

export enum TagColors {
  GREEN = "green",
  GREY = "grey",
  RED = "red",
  YELLOW = "yellow",
  ORANGE = "orange",
  BLUE = "blue",
  BLACK = "black",
}

export interface TagInfo {
  label: string;
  type: TagColors;
  tooltipCopy?: string;
  iconName?: string;
  onClick?: () => void;
}

export interface ICoordinates {
  lat: Double;
  lon: Double;
}

export type Double = number;

export type Integer = number;

export type CountryCode = string;

export type UserId = Uuid;

export type Uuid = string;

export type Float = number;

export type Date = string;
export type DateTime = string;

export type Timestamp = number;

type JsPrimitive = boolean | string | number | null;
type JsArray = JsValue[];
type JsValue = JsPrimitive | JsArray | JsObject;
export type JsObject = {
  [key: string]: JsValue;
};

// Representing Hopper custom HttpHelper URI object.
export type Uri = string;

// Representing Java URL object.
export type Url = string;

export type CardType = CardTypeConstants | string;

export enum CardTypeConstants {
  Visa = "visa",
  Master = "master",
  AmericanExpress = "american_express",
  DinersClub = "diners_club",
  Discover = "discover",
  Jcb = "jcb",
  Dankort = "dankort",
  Maestro = "maestro",
}

export const CardTypeCollection: { [key in CardType]: string } = {
  [CardTypeConstants.Visa]: "Visa",
  [CardTypeConstants.Master]: "Mastercard",
  [CardTypeConstants.AmericanExpress]: "American Express",
  [CardTypeConstants.DinersClub]: "Diners Club",
  [CardTypeConstants.Discover]: "Discover",
  [CardTypeConstants.Jcb]: "JCB",
  [CardTypeConstants.Dankort]: "Dankort",
  [CardTypeConstants.Maestro]: "Maestro",
};

export enum Currency {
  CAD = "CAD",
  USD = "USD",
  PEN = "PEN",
  EUR = "EUR",
  GBP = "GBP",
  AUD = "AUD",
  MXN = "MXN",
  NZD = "NZD",
  CNY = "CNY",
  JPY = "JPY",
  INR = "INR",
  COP = "COP",
  CLP = "CLP",
}

export interface TokenizeCardErrors {
  key: string;
  message: string;
  status: number;
  attribute: string;
}

export enum PassengerTypes {
  Adult = "ADT",
  InfantInSeat = "INS",
  InfantInLap = "INF",
  Child = "CNN",
}
export interface CardValidationErrors {
  cardType: CardType;
  validNumber: boolean;
  validCvv: boolean;
  numberLength: number;
  cvvLength: number;
}

export interface AirportRegion {
  regionType: RegionType;
  code: string;
}

export enum RegionType {
  Airport = "airport",
  City = "city",
}

export interface IPassenger {
  person: IPerson;
  lapInfant: boolean;
}

export interface IProxyPassenger {
  travellerId: PersonId;
  lapInfant: boolean;
}

export interface IPerson {
  id: PersonId;
  lastBookedWith?: DateTime;
  givenName: string;
  middleName?: string;
  surname: string;
  gender: Gender;
  dateOfBirth: LocalDate;
  phoneNumber?: string;
  emailAddress?: string;
  redressNumber?: string;
  knownTravelerNumber?: string;
  assistance: Assistance[];
  createdAt: DateTime;
  nationality?: INationality;
  passport?: IPassport;
  frequentFlyer: FrequentFlyerMap;
  hotelLoyalty: HotelLoyaltyMap;
  driverLicense?: DriverLicense;
}

export interface SessionInfo<T = UserInfo> {
  csrfToken?: string;
  sessionExpiration?: DateTime;
  userInfo: T;
  isFirstSession: boolean;
  isDelegatedSession?: string;
  userInfoResponse?: UserInfoResponse;
}

export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
}

export interface UserInfoResponse {
  name: string;
  email: string;
  userId: string;
  preferredName?: string;
  taxId: string;
  phoneNumber?: string;
  partnerExperiments: string;
}

export type FrequentFlyerMap = {
  [key in AirlineCode]: { value: FrequentFlyerNumber };
};

export type HotelLoyaltyMap = {
  [key in HotelLoyaltyProgramCode]: { value: HotelLoyaltyNumber };
};

export type PersonId = Uuid;

export enum Gender {
  M = "M",
  F = "F",
}

export type LocalDate = string;

export enum Assistance {
  BLND = "BLND", // blind passenger
  DEAF = "DEAF", // deaf passenger
  STCR = "STCR", // stretcher assistance
  WCHR = "WCHR", // Wheelchair/Passenger can walk up stairs
  WCHS = "WCHS", // Wheelchair/Passenger can walk to seat
  WCHC = "WCHC", // Wheelchair/Passenger must be carried
  WCBD = "WCBD", // Wheelchair with Dry Cell Battery
  WCBW = "WCBW", // Wheelchair with Wet Cell Battery
  WCLB = "WCLB", // Wheelchair with Lithium Battery
  WCMP = "WCMP", // Wheelchair, manual power
  WCOB = "WCOB", // Wheelchair, On-Board
}

export interface INationality {
  country: CountryCode;
}

export interface IPassport {
  countryOfIssue: CountryCode;
  number: string;
  expiration: LocalDate;
}

export type AirlineCode = string;

export type AirportCode = string;

export type FrequentFlyerProgramCode = string;

export type FrequentFlyerNumber = string;

export type HotelLoyaltyProgramCode = string;

export type HotelLoyaltyNumber = string;

export type FareId = string;

export type OpaqueFormat = string;

export type LocalDateTime = string;

export type AirlineLocator = string;

export type Duration = string;

export interface ISeatedPassengers {
  withLapInfants: IAdultWithLapInfant[];
  alone: IAssociatedPassenger[];
  contact: IAssociatedContact;
}

export interface IAssociatedContact {
  nameNumber: NameNumber;
  contact: IContact;
}

export interface IContact {
  phoneNumber: string;
  emailAddress: string;
}

export interface IAdultWithLapInfant {
  adult: IAssociatedPassenger;
  infant: IAssociatedPassenger;
}

export interface IAssociatedPassenger {
  nameNumber: NameNumber;
  type: PassengerType;
  person: IPerson;
}

export type PassengerType = string;

export enum PassengerTypeEnum {
  Adult = "Adult",
  Child = "Child",
  LapInfant = "LapInfant",
  SeatedInfant = "SeatedInfant",
}

export type NameNumber = string;

export type BigDecimal = number;

export type BookingSessionId = Uuid;

export type AuthServiceId = Uuid;

export interface ITokenInfo {
  createdAt: DateTime;
  sessionId: TokenInfoId;
  deviceId: DeviceId;
  ipAddress?: string;
}

export type TokenInfoId = string;

export type DeviceId = string;

export const B2B_PORTAL_JWT_SECRET_KEY = "b2bportal-jwt-secret";

export const B2B_PORTAL_AUTH_REDIRECT_TO = "b2bportal-auth-redirectTo";

export const B2B_PORTAL_AUTH_INVALID_SESSION = "b2bportal-auth-invalid-session";

export const B2B_PORTAL_CSRF_TOKEN = "H-Csrf-Token";

export const B2B_PORTAL_BANNER_CONTAINER_ID = "b2bportal-banner-container";

export const B2B_PORTAL_STARTSESSION_PATH = "/auth/startsession/";

export const B2B_PORTAL_UNAUTHORIZED_PATH = "/auth/invalidsession/";

export const B2B_PORTAL_ENDSESSION_PATH = "/auth/endsession/";

export const DISPLAY_COVID_BANNER = "display-covid-banner";
export interface Location {
  code: string;
  name?: string;
}

export interface IPriceRange {
  min: number;
  max: number;
}

export enum BaggageInfoEnum {
  Allowed = "Allowed",
  NotAllowed = "NotAllowed",
  Unknown = "Unknown",
}

export enum PenaltiesInfoEnum {
  ExternallyManaged = "ExternallyManaged",
  Known = "Known",
  Unknown = "Unknown",
}

export interface ILocationDescriptorBase {
  LocationDescriptor: LocationDescriptorEnum;
}

export enum LocationDescriptorEnum {
  BoundingBox = "BoundingBox",
  BoundingCircle = "BoundingCircle",
}

export interface Coordinates {
  lat: number;
  lon: number;
}
export interface BoundingBox extends ILocationDescriptorBase {
  northEast: Coordinates;
  southWest: Coordinates;
  LocationDescriptor: LocationDescriptorEnum.BoundingBox;
}

export interface BoundingCircle extends ILocationDescriptorBase {
  center: Coordinates;
  radius: number;
  LocationDescriptor: LocationDescriptorEnum.BoundingCircle;
}

export type LocationDescriptor = BoundingBox | BoundingCircle;

export interface IIdBase {
  Id: IdEnum;
}

export enum IdEnum {
  Flight = "Flight",
  HotelMarket = "HotelMarket",
  Lodgings = "Lodgings",
  Grounds = "Grounds",
}

export enum ArrowKeyEnum {
  UP = "ArrowUp",
  DOWN = "ArrowDown",
  LEFT = "ArrowLeft",
  RIGHT = "ArrowRight",
}

export enum ErrorModalType {
  PRICE_FREEZE_PURCHASE_SET_BOOK_PARAMS_FAILED = "price-freeze-purchase-set-book-params-failed",
  PRICE_FREEZE_PURCHASE_GENERIC_FAILED = "price-freeze-purchase-generic-failed",
  PRICE_FREEZE_EXERCISE_SET_BOOK_PARAMS_FAILED = "price-freeze-exercise-set-book-params-failed",
  FLIGHTS_PRICE_QUOTE_HAS_PRICE_DIFFERENCE = "flights-price-quote-has-price-difference",
  PRICE_FREEZE_EXERCISE_SIMILAR_FLIGHTS_AND_REFUND = "price-freeze-exercise-similar-flights-and-refund",
  PRICE_FREEZE_EXERCISE_REFUND = "price-freeze-exercise-refund",
  FLIGHTS_PRICE_QUOTE_HAS_NO_AVAILABILITY = "flights-price-quote-has-no-availability",
}

export interface ErrorTitles {
  agentSubtitle?: string;
  agentTitle?: string;
  icon?: string;
  /**
   * TODO: primaryButton and secondaryButton can be objects that have a text and onClickId,
   * so that we can map within BookingErrorModal with better extensibility
   */
  primaryButtonText?: string;
  secondaryButtonText?: string;
  subtitle?: string;
  mobileSubtitle?: string;
  /**
   * Note: it lets the consumer know that subtitle / mobileSubtitle needs to be injected as HTML
   */
  useHtml?: boolean;
  title: string;
  type?: ErrorModalType;
}

export enum ErrorType {
  BookError = "BookError",
  BookFailed = "BookFailed",
  CannotResume = "CannotResume",
  CaptureFailed = "CaptureFailed",
  CardDeclined = "CardDeclined",
  CheckInMinAgeNotMet = "CheckInMinimumAgeNotMet",
  DuplicateBooking = "DuplicateBooking",
  ExecutionTimeout = "ExecutionTimeout",
  FlightPaymentError = "FlightPaymentError",
  HardTimeout = "HardTimeout",
  Inactivity = "Inactivity",
  Incomplete = "Incomplete",
  InvalidGDSCharacter = "InvalidGDSCharacter",
  InvalidOffPoint = "InvalidOffPoint",
  InvalidPassengers = "InvalidPassengers",
  InvalidRequestError = "InvalidRequestError",
  InvalidScheduleChangeUA = "InvalidScheduleChangeUserAction",
  InvalidSegmentStatus = "InvalidSegmentStatus",
  MaxPriceIncreaseReached = "MaximumPriceIncreaseReached",
  MissingAirlineLocator = "MissingAirlineLocator",
  NoAvailability = "NoAvailability",
  NoProductsAvailable = "NoProductsAvailable",
  NoTicketlessResponse = "NoTicketlessResponse",
  NotApplicable = "N/A",
  NotTicketed = "NotTicketed",
  ParseError = "ParseError",
  PaymentError = "PaymentError",
  PrinterProfileAlreadyDesignated = "PrinterProfileAlreadyDesignated",
  ProviderError = "ProviderError",
  RMStepTimeout = "RemoteMachineStepTimeout",
  RMUncaughtException = "RemoteMachineUncaughtException",
  ReadRatesError = "ReadRatesError",
  RedemptionFailure = "RedemptionFailure",
  RequirementUnsupported = "RequirementUnsupported",
  SchedulingError = "SchedulingError",
  SegmentCancelledAfterTicketing = "SegmentCancelledAfterTicketing",
  SessionClosed = "SessionClosed",
  TicketReadError = "TicketReadError",
  Timeout = "Timeout",
  TipFetchFailed = "TipFetchFailed",
  TransientServiceError = "TransientServerError",
  UncategorizedError = "UncategorizedError",
  UncaughtException = "UncaughtException",
  UnconfirmedStatus = "UnconfirmedStatus",
  UnknownSabreAppError = "UnknownSabreAppError",
  VoidFailed = "VoidFailed",
  ZenDeskUserException = "ZenDeskUserException",
}

export type Error = ErrorType | HotelBookErrorType | PaymentErrorEnum | string;

export interface IFlightGridFareMap {
  [sliceId: string]: IFlightGridFares;
}

export interface IFlightListData {
  fares: Fare[];
  slice: string;
}

export interface IFlightNumberFilter {
  airlineCode: AirlineCode;
  flightNumber: string;
}

export interface IReturnFlightsMap {
  [outgoingSliceId: string]: {
    /* value is trip summary id */
    [returnSliceId: string]: string;
  };
}

export interface IShoppedTrip {
  outgoingFareId: Maybe<string>;
  outgoingFareRating?: number;
  outgoingSliceId: Maybe<string>;
  returnFareId?: Maybe<string>;
  returnFareRating?: number;
  returnSliceId?: Maybe<string>;
  tripId: Maybe<string>;
}

export interface ITripDetails {
  [tripId: string]: TripDetails;
}

export interface ITripSummariesById {
  [key: string]: TripSummary;
}

export interface ITraveler {
  id: PersonId;
  name: IName;
  dateOfBirth: string;
  // 'airTravelerDetails'?: IAirTravelerDetails; TODO: Uncomment when air traveler is used
  // 'hotelTravelerDetails'?: IHotelTravelerDetails; TODO: Uncomment when hotel traveler is used
  groundTravelerDetails?: IGroundTravelerDetails;
}

export interface IName {
  first: string;
  middle?: string;
  last: string;
}

export interface IGroundTravelerDetails {
  driverLicense: IDriverLicense;
}

export interface IDriverLicense {
  country: string;
  cityCode: string;
  street: string;
  postalCode: string;
}

export interface ITravelerStepErrors {
  phone?: boolean;
  email?: boolean;
  travelerSelect?: boolean;
}

export interface IApiConfig {
  experimentsApiPrefix: string;
  rewardsApiPrefix: string;
  takeoversApiPrefix: string;
  analyticsApiPrefix: string;
  fetchUserInfoPath?: string;
}

export type CaseClassValue<T = string> = {
  value: T;
};

export interface IKillSwitchResponse {
  isKillSwitchOn: boolean;
}
