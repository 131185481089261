import { ActionButton } from "@lloyds/ui-core";
import { useI18nContext } from "@hopper-b2b/i18n";

export interface FrequentFlyerButtonProps {
  handleAddAnotherMobile: () => void;
}

export const FrequentFlyerButton = ({
  handleAddAnotherMobile,
}: FrequentFlyerButtonProps) => {
  const { t } = useI18nContext();

  return (
    <ActionButton
      message={t("addAnotherProgram")}
      onClick={handleAddAnotherMobile}
      defaultStyle="h4r-secondary"
    />
  );
};
