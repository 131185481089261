import {
  FlightItineraryRequest,
  FlightItineraryResponse,
  FlightItineraryResponseResponseEnum,
} from "@b2bportal/air-booking-api";
import { flightsBookingApi } from "@overrides/utilities";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { FlightPostBookingState } from "../slice";

export const fetchItineraries = createAsyncThunk<
  FlightItineraryResponse,
  FlightItineraryRequest,
  { state: FlightPostBookingState; rejectValue: { error: string } }
>("flights/fetchItineraries", async (request, thunkAPI) => {
  try {
    const response = await flightsBookingApi.apiV0ItineraryFlightPut(request);
    const data = response.data;
    if (data.Response === FlightItineraryResponseResponseEnum.Success) {
      return data.value;
    }
    throw data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: String(error) });
  }
});
