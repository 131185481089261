import { useI18nContext } from "@hopper-b2b/i18n";
import styles from "./PaymentWallet.module.scss";
import { SelectionRow, SelectionType } from "@lloyds/ui-connected";
import { Divider, Typography } from "@material-ui/core";
import { useCallback, useMemo } from "react";
import {
  commonSelectors,
  getParentState,
  ParentState,
  useCheckoutSend,
  useCheckoutStateSelector as useSelector,
  WalletSelectors,
} from "@hopper-b2b/checkout";
import { Event, getWalletCredit, type TEvent } from "@lloyds/common-checkout";
import { Icon } from "@components/ui";
import { IconNameEnum } from "@b2bportal/core-themes";

export const PaymentWallet = () => {
  const { t, formatFiatCurrency } = useI18nContext();
  const send = useCheckoutSend<TEvent>();

  const selectedCredit = useSelector(getWalletCredit);
  const selectedOffer = useSelector(WalletSelectors.getWalletOffer);
  const offers = useSelector(WalletSelectors.getWalletOffers);

  const stateValue = useSelector(commonSelectors.getStateValue) as string;
  const parentState = getParentState(stateValue);
  const isCurrentState = parentState === ParentState.cardPayment;

  const walletOffers = useMemo(() => {
    return offers?.filter((o) => o.payment.value.paymentType === "Offer");
  }, [offers]);

  const creditOffer = useMemo(() => {
    return offers?.find((o) => o.payment.value.paymentType === "Credit");
  }, [offers]);

  const onSelect = useCallback(
    (id?: string) => {
      send({
        type:
          id === undefined
            ? Event.REMOVE_SELECTED_OFFER
            : Event.SET_SELECTED_OFFER,
        offerId: id,
      });
    },
    [send]
  );

  const onCreditsSelected = useCallback(() => {
    send({
      type: !selectedCredit
        ? Event.SET_WALLET_CREDITS
        : Event.REMOVE_WALLET_CREDITS,
      credits: creditOffer,
    });
  }, [send, selectedCredit, creditOffer]);

  const disabled = !isCurrentState;

  return (
    <>
      {walletOffers?.length > 0 ? (
        <div className={styles.travelOffersSection}>
          <Typography variant="h5">{t("applyOffer")}</Typography>
          <Typography variant="subtitle1">{t("selectOfferTitle")}</Typography>
          <div className={styles.offers}>
            {walletOffers.map((offer) => {
              const discount = offer.percentage
                ? `${offer.percentage * 100}%`
                : formatFiatCurrency(offer.amount);
              return (
                <SelectionRow
                  key={offer.id}
                  type={SelectionType.RADIO}
                  title={`${t("applyOffer")}:`}
                  description={
                    <Typography>
                      {t("wallet.discount", { amount: discount })}
                    </Typography>
                  }
                  selected={selectedOffer?.id === offer.id}
                  disabled={disabled}
                  onSelect={() => onSelect(offer.id)}
                  ariaLabel={t("selectVoucher")}
                />
              );
            })}
            <SelectionRow
              type={SelectionType.RADIO}
              title={t("declineOffer")}
              description={null}
              selected={!selectedOffer}
              disabled={disabled}
              onSelect={() => onSelect()}
              ariaLabel={t("declineVoucher")}
            />
          </div>
          <Divider />
        </div>
      ) : undefined}

      {creditOffer ? (
        <div className={styles.travelCreditsSection}>
          <Typography variant="h5">{t("applyCredits")}</Typography>
          <Typography variant="subtitle1">{t("selectCreditsTitle")}</Typography>
          <SelectionRow
            type={SelectionType.CHECK}
            title={`${t("applyCredits")}: ${formatFiatCurrency({
              value: creditOffer.amount.value * -1,
              currencyCode: creditOffer.amount.currencyCode,
            })}`}
            description={null}
            selected={!!selectedCredit}
            disabled={disabled}
            onSelect={onCreditsSelected}
            ariaLabel={t("selectCredit")}
          />
          <div className={styles.details}>
            <Icon iconName={IconNameEnum.credits} className={styles.icon} />
            <Typography>{t("selectCreditsInfo")}</Typography>
          </div>
          <Divider />
        </div>
      ) : undefined}
    </>
  );
};
