import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
  AirCfarExerciseFailureEnum,
  AirCfarExercisePollFulfillResponseEnum,
  AirCfarExerciseQuoteResponseEnum,
  AirCfarExerciseScheduleFulfillResponseEnum,
  type TrackingProperties,
} from "@b2bportal/air-cfar-api";

// eslint-disable-next-line @nx/enforce-module-boundaries
import { trackEvent } from "@hopper-b2b/api";

import { FlightsCfarTrackingEvent } from "@b2bportal/core-types";

import { cfarExerciseActions, cfarExerciseSelectors } from "./store";
import type { CfarAppState } from "../../store";

export const cfarExerciseTrackingMiddleware = createTrackingMiddleware();

function createTrackingMiddleware() {
  const trackingListenerMiddleware: CfarStateListenerMiddleware =
    createListenerMiddleware();

  startFetchQuoteListeners(trackingListenerMiddleware);
  startFulfillQuoteListeners(trackingListenerMiddleware);

  return trackingListenerMiddleware.middleware;
}

function startFetchQuoteListeners(
  trackingListenerMiddleware: CfarStateListenerMiddleware
) {
  trackingListenerMiddleware.startListening({
    actionCreator: cfarExerciseActions.fetchQuote.fulfilled,
    effect: async (action) => {
      const data = action.payload;

      switch (data.AirCfarExerciseQuoteResponse) {
        case AirCfarExerciseQuoteResponseEnum.QuoteFailure: {
          await trackEvent({
            eventName: FlightsCfarTrackingEvent.CFAR_EXERCISE_QUOTE_FAIL,
            properties: {
              cfar_exercise_fail_reason: data.failure.AirCfarExerciseFailure,
              ...normilizeTrackingProperties(data.trackingProperties),
            },
          });
          break;
        }
      }
    },
  });

  trackingListenerMiddleware.startListening({
    actionCreator: cfarExerciseActions.fetchQuote.rejected,
    effect: async () => {
      await trackEvent({
        eventName: FlightsCfarTrackingEvent.CFAR_EXERCISE_QUOTE_FAIL,
        properties: {
          cfar_exercise_fail_reason: AirCfarExerciseFailureEnum.Error,
        },
      });
    },
  });
}

function startFulfillQuoteListeners(
  trackingListenerMiddleware: CfarStateListenerMiddleware
) {
  trackingListenerMiddleware.startListening({
    actionCreator: cfarExerciseActions.fulfillQuote.fulfilled,
    effect: async (action, { getState }) => {
      const data = action.payload;

      const cfarExerciseQuoteTrackingProperties =
        cfarExerciseSelectors.selectQuoteFetchTrackingProperties(getState());

      if ("AirCfarExerciseScheduleFulfillResponse" in data) {
        switch (data.AirCfarExerciseScheduleFulfillResponse) {
          case AirCfarExerciseScheduleFulfillResponseEnum.ScheduleFulfillFailure:
            await trackEvent({
              eventName: FlightsCfarTrackingEvent.CFAR_EXERCISE_COMPLETE_FAIL,
              ...normilizeTrackingProperties(
                cfarExerciseQuoteTrackingProperties
              ),
            });
            break;
        }
      }

      if ("AirCfarExercisePollFulfillResponse" in data) {
        switch (data.AirCfarExercisePollFulfillResponse) {
          case AirCfarExercisePollFulfillResponseEnum.PollFulfillFailure: {
            await trackEvent({
              eventName: FlightsCfarTrackingEvent.CFAR_EXERCISE_COMPLETE_FAIL,
              properties: {
                cfar_exercise_fail_reason: data.failure.AirCfarExerciseFailure,
              },
            });
            break;
          }
          case AirCfarExercisePollFulfillResponseEnum.PollFulfillSuccess: {
            await trackEvent({
              eventName: FlightsCfarTrackingEvent.CFAR_EXERCISE_COMPLETE,
              ...normilizeTrackingProperties(
                cfarExerciseQuoteTrackingProperties
              ),
            });
            break;
          }
        }
      }
    },
  });

  trackingListenerMiddleware.startListening({
    actionCreator: cfarExerciseActions.fulfillQuote.rejected,
    effect: async () => {
      await trackEvent({
        eventName: FlightsCfarTrackingEvent.CFAR_EXERCISE_COMPLETE_FAIL,
        properties: {
          cfar_exercise_fail_reason: AirCfarExerciseFailureEnum.Error,
        },
      });
    },
  });
}

function normilizeTrackingProperties(
  trackingProperties: TrackingProperties | undefined
) {
  return {
    properties: trackingProperties?.properties ?? [],
    encryptedProperties: [trackingProperties?.encryptedProperties].flatMap(
      (x) => x ?? []
    ),
  };
}

type CfarStateListenerMiddleware = ReturnType<
  typeof createListenerMiddleware<CfarAppState>
>;
