import { CoreUiComponents, CORE_UI_NAME } from "@b2bportal/core-types";

import {
  checkboxGroupStyles,
  checkboxStyles,
  pageStyles,
  popoverStyles,
  sliderStyles,
  radioGroupStyles,
} from "./styles";

export const styleOverrides = {
  [CORE_UI_NAME]: {
    [CoreUiComponents.Page]: pageStyles,
    [CoreUiComponents.Checkbox]: checkboxStyles,
    [CoreUiComponents.CheckboxGroup]: checkboxGroupStyles,
    [CoreUiComponents.Slider]: sliderStyles,
    [CoreUiComponents.Popover]: popoverStyles,
    [CoreUiComponents.RadioItem]: radioGroupStyles,
  },
};
