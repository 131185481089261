import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { I18nMarkup, Trans, useI18nContext } from "@hopper-b2b/i18n";
import { useRenderLodgingStrikethroughPrice } from "@hopper-b2b/utilities";
import { usePriceFormatterContext } from "@hopper-b2b/contexts";
import { LodgingPrice } from "@b2bportal/lodging-api";
import { Slot } from "@hopper-b2b/ui";
import { getTotalNights } from "../../../../reducer";
import styles from "./Price.module.scss";

interface Props {
  price: LodgingPrice;
  className?: string;
  loading?: boolean;
}

export function Price({ price, className, loading = false }: Props) {
  const { t, brand, exists } = useI18nContext();

  const { formatPrice } = usePriceFormatterContext();
  const renderLodgingStrikethroughPrice = useRenderLodgingStrikethroughPrice();
  const nights = useSelector(getTotalNights);

  const finalPrice = useMemo(() => price.nightlyPrice, [price]);
  const originalPrice = useMemo(
    () => price.nightlyDiscountAware?.priceWithNoDiscounts,
    [price]
  );

  return price ? (
    <Typography
      variant="h2"
      className={clsx(styles.Price, className)}
      style={{ lineHeight: "100%" }}
    >
      {loading ? (
        <>
          <Skeleton width={116} variant="text" />
          <Skeleton width={116} variant="text" />
        </>
      ) : (
        <>
          <div className="price-per-night">
            {renderLodgingStrikethroughPrice &&
            originalPrice?.fiat?.value > finalPrice?.fiat?.value ? (
              <Slot
                id="lodging-card-original-price"
                originalPrice={originalPrice}
                finalPrice={finalPrice}
              />
            ) : null}
            <I18nMarkup
              tKey={"pricePerNight"}
              replacements={{
                price: formatPrice(finalPrice, {
                  maximumFractionDigits: brand?.lodgingCardPriceRounding || 0,
                  minimumFractionDigits: brand?.lodgingCardPriceRounding || 0,
                  roundingMode: "ceil",
                }),
              }}
            />
          </div>
          <Typography variant="body1">
            {/* exists() can't check for singular and plural keys, checking singular */}
            {exists("fromPriceForNightsTaxesIncluded_one") ? (
              <span className="total-price">
                <Trans
                  i18nKey={"fromPriceForNightsTaxesIncluded"}
                  values={{
                    price: formatPrice(price.totalPrice, {
                      maximumFractionDigits:
                        brand?.lodgingCardPriceRounding || 0,
                      minimumFractionDigits:
                        brand?.lodgingCardPriceRounding || 0,
                      roundingMode: "ceil",
                    }),
                    count: nights,
                  }}
                  components={[<strong className="value" />]}
                />
              </span>
            ) : (
              <>
                <span className="total-price">
                  <Trans
                    i18nKey="priceForNights"
                    values={{
                      price: formatPrice(price.totalPrice, {
                        maximumFractionDigits:
                          brand?.lodgingCardPriceRounding || 0,
                        minimumFractionDigits:
                          brand?.lodgingCardPriceRounding || 0,
                        roundingMode: "ceil",
                      }),
                      count: nights,
                    }}
                    components={[<strong className="value" />]}
                  />
                </span>
                <span className="taxes-and-fees">
                  {t("taxesAndFeesIncluded")}
                </span>
              </>
            )}
          </Typography>
        </>
      )}
    </Typography>
  ) : null;
}
