import { TripCategory } from "@b2bportal/core-types";
import {
  FLIGHTS_SEARCH_PATH,
  FLIGHTS_SHOP_DEPARTURE_PATH,
  FLIGHTS_SHOP_RETURN_PATH,
  FLIGHTS_SHOP_REVIEW_FARE_PATH,
  FLIGHTS_SHOP_REVIEW_PATH,
} from "@b2bportal/core-utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { PATH_CFAR_PAGE, PATH_DISRUPTION_PAGE } from "@lloyds/utils";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router";
import { useSearchParameters } from "@b2bportal/core-flights";
import { FilterDialog } from "@lloyds/air";
import { BackButton, HeaderUI, MobileHeaderTitle } from "@lloyds/ui-connected";
import { PATH_HOME } from "@hopper-b2b/utilities";
import { useNavigate } from "react-router-dom-v5-compat";

const placeLabelToCity = (label?: string) => {
  return label ? label.split(",")[0] : "";
};

export const FlightsHeader = () => {
  const { t } = useI18nContext();
  const { pathname } = useLocation();
  const searchState = useSearchParameters();
  const navigate = useNavigate();

  const title = useMemo(() => {
    switch (true) {
      case pathname.includes(FLIGHTS_SEARCH_PATH):
        return t("flight.search.header");
      case pathname.includes(FLIGHTS_SHOP_DEPARTURE_PATH):
      case pathname.includes(FLIGHTS_SHOP_RETURN_PATH):
        return searchState.tripCategory === TripCategory.ONE_WAY
          ? t("flight.shop.header.oneWay", {
              location: placeLabelToCity(searchState.destination?.label),
            })
          : t("flight.shop.header.roundTrip", {
              location: placeLabelToCity(searchState.destination?.label),
            });
      case pathname.includes(FLIGHTS_SHOP_REVIEW_FARE_PATH):
        return t("flightsPageTitles.fareDetails");
      case pathname.includes(FLIGHTS_SHOP_REVIEW_PATH):
        return t("flightsPageTitles.reviewItinerary");
      case pathname.includes(PATH_DISRUPTION_PAGE):
      case pathname.includes(PATH_CFAR_PAGE):
        return t("customizeYourTrip");
      default:
        return "";
    }
  }, [t, pathname, searchState]);

  const onBack = useCallback(() => {
    if (pathname.includes(FLIGHTS_SEARCH_PATH)) {
      navigate(PATH_HOME);
      return;
    } else if (pathname.includes(FLIGHTS_SHOP_DEPARTURE_PATH)) {
      navigate(FLIGHTS_SEARCH_PATH);
      return;
    }
    navigate(-1);
  }, [navigate, pathname]);

  return (
    <HeaderUI
      leftItem={<BackButton onClick={onBack} />}
      centerItem={<MobileHeaderTitle title={title} />}
      rightItem={<FlightsRightItem />}
    />
  );
};

const FlightsRightItem = () => {
  const { pathname } = useLocation();

  const isDeparturePage = useMemo(
    () => pathname.includes(FLIGHTS_SHOP_DEPARTURE_PATH),
    [pathname]
  );
  const isReturnPage = useMemo(
    () => pathname.includes(FLIGHTS_SHOP_RETURN_PATH),
    [pathname]
  );
  const shouldShowFilter = isDeparturePage || isReturnPage;

  return (
    shouldShowFilter && <FilterDialog sliceIndex={isDeparturePage ? 0 : 1} />
  );
};
