import { ChatPropertiesType } from "@b2bportal/chat-api";
import { BookingType, Uuid } from "@hopper-b2b/types";
import { SupportContext } from "@hopper-b2b/utilities";
import { Box, CircularProgress, GridProps } from "@material-ui/core";
import clsx from "clsx";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { Slot } from "../Slots";
import { SupportModalTextContent } from "./SupportModalTextContent";

import "./styles.scss";

export interface IContactSupportModalContentProps extends GridProps {
  /** Used for IVR encoding & lookup -  UUID for flight/ground, reservation Id for hotels/homes */
  bookingId: string;
  /** The UUID of the booking */
  bookingUuid: Uuid;
  bookingType: BookingType;
  requestType?: string;
  className?: string;
  getSupportId: (
    bookingId: string,
    bookingType: BookingType
  ) => Promise<string>;
  hasVipSupport?: boolean;
  icon?: ReactNode;
  showHelpLink?: boolean;
  subtitle?: string;
  testId?: string;
  title?: string;
  hotelCancellation?: boolean;
  onClose?: () => void;
  onContactSupport?: () => void;
  setFullScreen?: (value: boolean) => void;
}

export const ContactSupportModalContent = (
  props: IContactSupportModalContentProps
) => (
  <Slot
    id="contact-support-modal-content"
    {...props}
    component={<ContactSupportModalContentInternal {...props} />}
  />
);

const ContactSupportModalContentInternal = ({
  bookingId,
  bookingType,
  bookingUuid,
  className,
  getSupportId,
  hasVipSupport = false,
  icon,
  onClose,
  requestType = "General",
  showHelpLink,
  subtitle,
  testId = "",
  title,
}: IContactSupportModalContentProps): JSX.Element => {
  const [supportId, setSupportId] = useState("");
  const [showLoginDisclaimer, setShowLoginDisclaimer] = useState(false);
  const { productSupportChat } = useContext(SupportContext);

  useEffect(() => {
    async function fetchBookingData() {
      setSupportId("");

      try {
        const newSupportId = await getSupportId(bookingId, bookingType);

        if (newSupportId) {
          setSupportId(newSupportId);
        }
      } catch (e) {
        // Encoding non-cart bookings for anonymous users may require the user to login for a support ID
        setSupportId("-");
        setShowLoginDisclaimer(true);
      }
    }

    if (bookingId) {
      fetchBookingData();
    }
  }, [bookingId, bookingType, getSupportId]);

  const chatSupportAction = useMemo(() => {
    if (productSupportChat) {
      return () => {
        const chatPropertiesTypeMap: Record<BookingType, ChatPropertiesType> = {
          [BookingType.Flight]: ChatPropertiesType.Air,
          [BookingType.Ground]: ChatPropertiesType.Ground,
          [BookingType.Lodging]: ChatPropertiesType.Hotel,
        };
        const chatPropertiesType = chatPropertiesTypeMap[bookingType];

        onClose?.();
        productSupportChat(bookingUuid, chatPropertiesType, requestType);
      };
    } else {
      return undefined;
    }
  }, [bookingUuid, bookingType, onClose, productSupportChat, requestType]);

  return (
    <Box
      className={clsx("contact-support-modal-content", className)}
      data-testid={testId}
    >
      {supportId ? (
        <SupportModalTextContent
          bookingType={bookingType}
          icon={icon}
          hasVipSupport={hasVipSupport}
          showHelpLink={showHelpLink}
          supportId={supportId}
          subtitle={subtitle}
          title={title}
          showLoginDisclaimer={showLoginDisclaimer}
          chatSupportAction={chatSupportAction}
        />
      ) : (
        <Box className="loading-spinner-container">
          <CircularProgress color="primary" />
        </Box>
      )}
    </Box>
  );
};
