import { AirportRegion } from "@b2bportal/air-shopping-api";

export type Id = IIdFlight | IIdLodgings;

export enum IdEnum {
  Flight = "Flight",
  HotelMarket = "HotelMarket",
  Lodgings = "Lodgings",
  Grounds = "Grounds",
}

export interface IIdFlight {
  code: AirportRegion;
  Id: IdEnum.Flight;
}

export interface IIdLodgings {
  // Server returns this object as opaque base64 encoded string.
  // The real type of the decoded object is AppLodgingSelection.
  lodgingSelection: any;
  Id: IdEnum.Lodgings;
}
