import { TenantPackageSteps } from "@tenant/types";
import { FlowProvider, useNavigationFlow } from "../../common";

export enum CorePackageSteps {
  SEARCH = "SEARCH",
  HOTEL_AVAILABILITY = "HOTEL_AVAILABILITY",
  HOTEL_SHOP = "HOTEL_SHOP",
  FLIGHT_DEPARTURE = "FLIGHT_DEPARTURE",
  FLIGHT_RETURN = "FLIGHT_RETURN",
  // REVIEW = "REVIEW",
  // BOOK = "BOOK",
}

export const PackageSteps = {
  ...CorePackageSteps,
  ...TenantPackageSteps,
} as const;
export type PackageSteps = (typeof PackageSteps)[keyof typeof PackageSteps];

export const PackageNavigationFlowProvider = ({ children, navigationFlow }) => {
  return (
    <FlowProvider<PackageSteps> flow={navigationFlow}>{children}</FlowProvider>
  );
};

export const usePackageNavigationFlow = () => {
  const context = useNavigationFlow();
  if (!context) {
    throw new Error("useFlow must be used within a PackageNavigationFlow");
  }
  return context;
};
