import * as SelectPrimitive from "@radix-ui/react-select";
import { Icon } from "../Icon";
import {
  IconNameEnum,
  useModuleBEM,
  useUiStyles,
} from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import defaultStyles from "./Select.module.scss";
import React from "react";
import clsx from "clsx";

export interface SelectComponentProps extends SelectPrimitive.SelectProps {
  placeholder?: React.ReactNode;
  // Radix Content prop
  contentProps?: SelectPrimitive.SelectContentProps;
  className?: string;
  valueToDisplay?: string;
  dropdownIconName?: IconNameEnum;
}

export const Select = React.forwardRef<HTMLButtonElement, SelectComponentProps>(
  (
    {
      children,
      placeholder,
      valueToDisplay,
      contentProps,
      className,
      dropdownIconName,
      ...props
    },
    forwardedRef
  ) => {
    const COMPONENT_KEY = CoreUiComponents.Select;
    const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
    const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

    return (
      <SelectPrimitive.Root {...props}>
        <SelectPrimitive.Trigger
          ref={forwardedRef}
          className={clsx(cn("trigger"), className)}
        >
          <SelectPrimitive.Value
            className={cn("trigger-label")}
            placeholder={placeholder}
            aria-label={valueToDisplay}
          >
            {valueToDisplay}
          </SelectPrimitive.Value>

          <Icon
            iconName={dropdownIconName ?? IconNameEnum.dropdownTriangle}
            className={cn("trigger-icon")}
          />
        </SelectPrimitive.Trigger>
        <SelectPrimitive.Portal>
          <SelectPrimitive.Content
            className={cn("content")}
            position="popper"
            sideOffset={5}
            arrowPadding={5}
            {...contentProps}
          >
            <SelectPrimitive.ScrollUpButton className={cn("scroll-button")}>
              <Icon
                iconName={IconNameEnum.chevronUp}
                className={cn("scroll-icon")}
              />
            </SelectPrimitive.ScrollUpButton>
            <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
            <SelectPrimitive.ScrollDownButton className={cn("scroll-button")}>
              <Icon
                iconName={IconNameEnum.chevronDown}
                className={cn("scroll-icon")}
              />
            </SelectPrimitive.ScrollDownButton>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    );
  }
);

export const SelectItem = React.forwardRef<
  HTMLDivElement,
  SelectPrimitive.SelectItemProps
>(({ children, className, ...props }, forwardedRef) => {
  const COMPONENT_KEY = CoreUiComponents.Select;
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [_, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <SelectPrimitive.Item
      {...props}
      ref={forwardedRef}
      className={clsx(cn("item"), className)}
    >
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      <SelectPrimitive.ItemIndicator>
        <Icon
          iconName={IconNameEnum.check}
          className={cn("item-indicator-icon")}
        />
      </SelectPrimitive.ItemIndicator>
    </SelectPrimitive.Item>
  );
});
