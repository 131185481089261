import {
  ChatMessage,
  DeepLink,
  DeepLinkEnum,
  SenderRole,
} from "@b2bportal/chat-api";
import {
  SELF_SERVE_ACTION,
  SELF_SERVE_URL_PARAMS,
} from "@hopper-b2b/self-serve/src/utils";
import { PATH_TRIPS, useDeviceTypes } from "@hopper-b2b/utilities";
import clsx from "clsx";
import Markdown from "react-markdown";
import { useNavigate } from "react-router-dom-v5-compat";

import { useChatContext } from "../../hooks";
import { BottomSheetContentEnum } from "../../types";
import { ChatAvatar } from "../ChatAvatar";
import { ChatMessageMetadata } from "../ChatMessageMetadata";
import { DeepLinkMessage } from "../DeepLinkMessage";

import styles from "./ChatMessage.module.scss";

export interface IChatMessageComponentProps {
  message: ChatMessage;
  showMetadata?: boolean;
}

const ChatMessageComponent = (props: IChatMessageComponentProps) => {
  if (props.message.senderRole === SenderRole.Agent) {
    return AgentChatMessage(props);
  } else {
    return UserChatMessage(props);
  }
};

const AgentChatMessage = (props: IChatMessageComponentProps) => {
  const { message, showMetadata = false } = props;
  const {
    conversationId,
    getTripDetailsPath,
    minimizeChatWindow,
    setBottomSheetContent,
  } = useChatContext();

  const navigate = useNavigate();
  const { matchesMobile } = useDeviceTypes();

  const handleDeepLink = (deepLink: DeepLink) => {
    switch (deepLink.DeepLink) {
      case DeepLinkEnum.DeepLinkContactSupport:
        setBottomSheetContent(
          conversationId
            ? BottomSheetContentEnum.CSATFormUnresolved
            : BottomSheetContentEnum.ContactSupport
        );
        break;
      case DeepLinkEnum.DeepLinkTripDetails: {
        const tripsDetailsPath = getTripDetailsPath(
          deepLink.productInfo.itineraryUuid
        );
        navigate(tripsDetailsPath);
        if (matchesMobile) {
          minimizeChatWindow();
        }
        break;
      }
      case DeepLinkEnum.DeepLinkTripCancellation: {
        const tripsDetailsPath = getTripDetailsPath(
          deepLink.productInfo.itineraryUuid
        );
        const cancelQueryParam = `&${SELF_SERVE_URL_PARAMS.SELF_SERVE_ACTION}=${SELF_SERVE_ACTION.CANCEL}`;
        navigate(`${tripsDetailsPath}${cancelQueryParam}`);
        if (matchesMobile) {
          minimizeChatWindow();
        }
        break;
      }
      default:
        break;
    }
  };

  const LinkFormatter = ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );

  return (
    <div className={clsx(styles.messageContainer, styles.agent)}>
      <ChatAvatar className={styles.messageAvatar} />
      <div className={styles.messageContent}>
        <Markdown
          className={clsx(styles.message, styles.messageAgent)}
          components={{
            a: LinkFormatter,
          }}
        >
          {message.content}
        </Markdown>
        {props.message.deepLink && (
          <DeepLinkMessage
            deepLink={props.message.deepLink}
            handleDeepLink={handleDeepLink}
          />
        )}
      </div>
      {showMetadata && (
        <ChatMessageMetadata
          agent
          className={styles.messageMetadata}
          timestamp={message.timestamp}
        />
      )}
    </div>
  );
};

const UserChatMessage = (props: IChatMessageComponentProps) => {
  const { message, showMetadata = false } = props;
  const { content, status, timestamp } = message;
  const statusLC = status.toLowerCase();

  return (
    <div className={clsx(styles.messageContainer, styles.user)}>
      <div className={styles.messageContent}>
        <div
          className={clsx(styles.message, styles.messageUser, styles[statusLC])}
        >
          {content}
        </div>
      </div>
      {showMetadata && (
        <ChatMessageMetadata
          className={styles.messageMetadata}
          status={status}
          timestamp={timestamp}
        />
      )}
    </div>
  );
};

export default ChatMessageComponent;
