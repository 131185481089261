import {
  type UnknownAction,
  combineReducers,
  createAction,
} from "@reduxjs/toolkit";

import policyReducer from "./policySlice";
import searchReducer from "./searchSlice";
import shopReducer from "./shopSlice";

export * from "./policySelectors";
export * from "./searchSelectors";
export * from "./trackingSelectors";

export const resetExchange = createAction("exchange/reset");

const flightExchangeReducer = combineReducers({
  policy: policyReducer,
  search: searchReducer,
  shop: shopReducer,
});

/**
 * If the state given to it is undefined, it must return the initial state for this specific reducer.
 * source: https://redux.js.org/api/combinereducers
 */
export const initialState = flightExchangeReducer(undefined, { type: "" });

const flightExchangeReducerProxy = (state, action: UnknownAction) => {
  if (action.type === resetExchange.toString()) {
    return flightExchangeReducer(undefined, action);
  }

  return flightExchangeReducer(state, action);
};

export type FlightExchangeState = ReturnType<typeof flightExchangeReducer>;
export default flightExchangeReducerProxy;
