import {
  CipherText,
  FulfillRequest,
  purchaseApi,
} from "@b2bportal/purchase-api";
import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { axiosInstance } from "../AxiosInterceptor";
import { trackEvent } from "../tracking/trackEvent";
import type { RawAxiosRequestConfig } from "axios";

export const scheduleFulfill = async (
  req: FulfillRequest,
  options?: RawAxiosRequestConfig,
  apiConfig?: IApiConfig
): Promise<CipherText> => {
  try {
    const res = await purchaseApi(
      axiosInstance
    ).apiV1PurchaseFulfillSchedulePost(req, options);

    return res?.data;
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      apiConfig
    );
    throw e;
  }
};
