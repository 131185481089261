import {
  PaymentAuthAction,
  type PaymentAuthActionOpaqueValue,
} from "@b2bportal/card-api";
import {
  CartFulfillEventType,
  cartFulfillSelectors,
  ParentState,
  useCheckoutSend,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import { Environment } from "@hopper-b2b/utilities";
import { config } from "@lloyds/utils";
import { lazy, useCallback } from "react";
import type { TEvent } from "../../events";
import { WrapperComponent } from "../WrapperComponent";
import {
  CartFulfillErrorDialog,
  type CartFulfillErrorDialogProps,
} from "./CartFulfillErrorDialog";

const AdyenActionComponent = lazy(() => import("@hopper-b2b/adyen"));

export type CartFulfillProps = CartFulfillErrorDialogProps;

export const CartFulfill = ({ productType, onError }: CartFulfillProps) => (
  <WrapperComponent
    state={ParentState.cartFulfill}
    className="checkout-cart-fulfill"
    isLoading={true}
  >
    <CartFulfillContent />
    <CartFulfillErrorDialog productType={productType} onError={onError} />
  </WrapperComponent>
);

const CartFulfillContent = () => {
  const cartFulfillPendingInteractive = useSelector(
    cartFulfillSelectors.getCartFulfillPendingInteractive
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {cartFulfillPendingInteractive?.actions?.map(
        (authAction: PaymentAuthActionOpaqueValue, index: number) => (
          <PaymentAuthActionComponent key={index} authAction={authAction} />
        )
      )}
    </>
  );
};

type PaymentAuthActionComponentProps = {
  authAction: PaymentAuthActionOpaqueValue;
};

const PaymentAuthActionComponent = ({
  authAction: { value: actionValue },
}: PaymentAuthActionComponentProps) => {
  const send = useCheckoutSend<TEvent>();

  const handleAdyenError = useCallback(() => {
    send(CartFulfillEventType.COMPLETE_INTERACTIVE_ERROR);
  }, [send]);

  const handleAdyenSuccess = useCallback(
    (details: unknown) => {
      send({
        type: CartFulfillEventType.COMPLETE_INTERACTIVE_SUCCESS,
        data: {
          type: PaymentAuthAction.InteractivePayment3DSAdyenAuthAction,
          value: { ...actionValue, details },
        } as PaymentAuthActionOpaqueValue,
      });
    },
    [send, actionValue]
  );

  return (
    <AdyenActionComponent
      environment={config.env === Environment.production ? "live" : "test"}
      clientKey={config.adyenKey}
      action={actionValue.action}
      onError={handleAdyenError}
      onSuccess={handleAdyenSuccess}
    />
  );
};
