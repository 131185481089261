import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import { useI18nContext } from "@hopper-b2b/i18n";
import defaultStyles from "./styles.module.scss";
import { useMemo } from "react";
import { ReactComponent as TripAdvisorRating00 } from "./assets/tripadvisor-0-0.svg";
import { ReactComponent as TripAdvisorRating05 } from "./assets/tripadvisor-0-5.svg";
import { ReactComponent as TripAdvisorRating10 } from "./assets/tripadvisor-1-0.svg";
import { ReactComponent as TripAdvisorRating15 } from "./assets/tripadvisor-1-5.svg";
import { ReactComponent as TripAdvisorRating20 } from "./assets/tripadvisor-2-0.svg";
import { ReactComponent as TripAdvisorRating25 } from "./assets/tripadvisor-2-5.svg";
import { ReactComponent as TripAdvisorRating30 } from "./assets/tripadvisor-3-0.svg";
import { ReactComponent as TripAdvisorRating35 } from "./assets/tripadvisor-3-5.svg";
import { ReactComponent as TripAdvisorRating40 } from "./assets/tripadvisor-4-0.svg";
import { ReactComponent as TripAdvisorRating45 } from "./assets/tripadvisor-4-5.svg";
import { ReactComponent as TripAdvisorRating50 } from "./assets/tripadvisor-5-0.svg";

export interface TripadvisorRatingIconComponentProps {
  scoreOutOfFive: number;
}

const COMPONENT_KEY = CoreUiComponents.TripadvisorRatingIcon;
export const TripadvisorRatingIcon = ({
  scoreOutOfFive,
}: TripadvisorRatingIconComponentProps) => {
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { t } = useI18nContext();
  const scoreOneDecimalPlace = scoreOutOfFive.toFixed(1);
  const TripadvisorBadge = useMemo(() => {
    switch (scoreOneDecimalPlace) {
      case "0.5":
        return TripAdvisorRating05;
      case "1.0":
        return TripAdvisorRating10;
      case "1.5":
        return TripAdvisorRating15;
      case "2.0":
        return TripAdvisorRating20;
      case "2.5":
        return TripAdvisorRating25;
      case "3.0":
        return TripAdvisorRating30;
      case "3.5":
        return TripAdvisorRating35;
      case "4.0":
        return TripAdvisorRating40;
      case "4.5":
        return TripAdvisorRating45;
      case "5.0":
        return TripAdvisorRating50;
      default:
        return TripAdvisorRating00;
    }
  }, [scoreOneDecimalPlace]);

  return (
    <div className={block}>
      <TripadvisorBadge
        className={cn("image")}
        alt={t("tripAdvisorBadge", { scoreOneDecimalPlace })}
      />
    </div>
  );
};
