import type { Lodging } from "@b2bportal/lodging-api";
import type { PriceRange } from "@hopper-b2b/lodging-availability";

export const calculatePriceRangeFilter = (
  lodgings: Lodging[],
  {
    min: currentMin,
    max: currentMax,
    lowest: currentLowest,
    highest: currentHighest,
  }: PriceRange
): PriceRange => {
  if (lodgings.length === 0) {
    return {
      min: 0,
      max: 0,
      lowest: 0,
      highest: 0,
    };
  }
  const lodgingsSortedByPrice = lodgings.sort(
    (a, b) =>
      a?.price?.nightlyPriceTaxesAndFeesIncluded?.fiat.value -
      b?.price?.nightlyPriceTaxesAndFeesIncluded?.fiat.value
  );
  const lowestPrice = Math.floor(
    lodgingsSortedByPrice[0]?.price?.nightlyPriceTaxesAndFeesIncluded?.fiat
      .value || 0
  );
  const highestPrice = Math.ceil(
    lodgingsSortedByPrice[lodgingsSortedByPrice.length - 1]?.price
      ?.nightlyPriceTaxesAndFeesIncluded?.fiat.value || Infinity
  );

  return {
    min: currentMin === currentLowest ? lowestPrice : currentMin,
    max: currentMax === currentHighest ? highestPrice : currentMax,
    lowest: lowestPrice,
    highest: highestPrice,
  };
};
