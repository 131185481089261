import type { RequiredField } from "@hopper-b2b/utilities";
import type { State } from "xstate";
import type { CommonMachineContext } from "../../types";
import { ParentState } from "@checkout/types";

export type ParentContextWithWalletPayment = RequiredField<
  CommonMachineContext,
  ParentState.credits
>;

export type CommonStateType = State<ParentContextWithWalletPayment>;
export type CommonStateWithoutValue = Pick<CommonStateType, "context">;

export const getWalletCredit = (state: CommonStateWithoutValue) =>
  state.context[ParentState.credits]?.walletCredits;
