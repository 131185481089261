import defaultConfig from "./defaultConfig";
import { BasicConfig, PortalConfig } from "../../types";
import { buildThemeFromBasicConfig } from "./theme";

export const fromBasicConfig = (tenantConfig: BasicConfig): PortalConfig => {
  const config = {
    ...defaultConfig,
    clientName: tenantConfig.clientName,
    bootConfig: {
      ...defaultConfig.bootConfig,
      ...tenantConfig.bootConfig,
    },
    theme: {
      ...buildThemeFromBasicConfig(tenantConfig.theme),
    },
    featureFlags: {
      ...defaultConfig.featureFlags,
      ...tenantConfig.featureFlags,
    },
    branding: {
      ...defaultConfig.branding,
      ...tenantConfig.branding,
    },
    translations: tenantConfig.translations,
  };
  return config;
};
