import { CoreUiComponents } from "@b2bportal/core-types";
import * as SliderPrimitive from "@radix-ui/react-slider";
import defaultStyles from "./Slider.module.scss";
import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import React from "react";
import clsx from "clsx";

export interface SliderComponentProps
  extends SliderPrimitive.SliderProps,
    React.RefAttributes<HTMLSpanElement> {
  ariaLabel?: string;
  formatValueLabel?: (val: number) => JSX.Element | string;
  className?: string;
}

export const Slider = React.forwardRef<HTMLSpanElement, SliderComponentProps>(
  (
    {
      formatValueLabel,
      ariaLabel,
      className,
      ...sliderProps
    }: SliderComponentProps,
    forwardedRef
  ) => {
    const styles = useUiStyles(CoreUiComponents.Slider, defaultStyles);
    const [block, cn] = useModuleBEM(styles, CoreUiComponents.Slider);

    const sliderValue = sliderProps.value ?? sliderProps.defaultValue ?? [];

    return (
      <SliderPrimitive.Slider
        ref={forwardedRef}
        className={clsx(block, className)}
        {...sliderProps}
      >
        <SliderPrimitive.Track className={cn("track")}>
          <SliderPrimitive.Range className={cn("range")} />
        </SliderPrimitive.Track>
        {sliderValue.map((val, i) => (
          <SliderPrimitive.Thumb
            key={i}
            className={cn("thumb")}
            aria-label={ariaLabel}
          >
            {formatValueLabel ? (
              <span className={cn("thumb-value")}>{formatValueLabel(val)}</span>
            ) : null}
          </SliderPrimitive.Thumb>
        ))}
      </SliderPrimitive.Slider>
    );
  }
);
