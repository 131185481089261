import clsx from "clsx";

import messageStyles from "../ChatMessage/ChatMessage.module.scss";
import styles from "./TypingIndicator.module.scss";

export const TypingIndicator = () => {
  return (
    <div
      className={clsx(
        messageStyles.message,
        messageStyles.messageAgent,
        styles.typingIndicator
      )}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};
