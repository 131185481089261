import { fetchFlightLocations } from "@hopper-b2b/common-search";
import { Autocomplete, type AutocompleteProps } from "./Autocomplete";
import { IdEnum, type Suggestion } from "@b2bportal/air-shopping-api";
import { DateTimeFormatStyle, useI18nContext } from "@hopper-b2b/i18n";
import React, { useCallback } from "react";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as RecentSearch } from "./recentSearch.svg";
import type { BuildFlightShopQueryArgs } from "@components/flights";
import { trackEvent } from "@hopper-b2b/api";
import { TripCategory } from "@b2bportal/core-types";
import dayjs from "dayjs";
import { DiagonalPlane } from "@lloyds/theming";
import clsx from "clsx";

enum SuggestionTypeEnum {
  City = "city",
  Airport = "airport",
  RecentSearch = "recentSearch",
}

interface FlightLocationAutoCompleteProps
  extends Omit<
    AutocompleteProps<Suggestion>,
    | "fetch"
    | "groupBy"
    | "getOptionLabel"
    | "sortOptions"
    | "filterStaticOptions"
  > {
  defaultValue?: Suggestion;
  recentSearches?: BuildFlightShopQueryArgs[];
  filterRecentSearches?: (
    searches: BuildFlightShopQueryArgs[],
    search: string
  ) => BuildFlightShopQueryArgs[];
  onRecentSearchClick?: (value: BuildFlightShopQueryArgs) => void;
}

export const FlightLocationAutoComplete = React.forwardRef<
  HTMLInputElement,
  FlightLocationAutoCompleteProps
>(
  (
    {
      id,
      defaultValue,
      label,
      placeholder,
      icon,
      closeIcon,
      additionalSearchControl,
      recentSearches,
      onRecentSearchClick,
      filterRecentSearches,
      onChange,
      onOpen,
      className,
    }: FlightLocationAutoCompleteProps,
    forwardedRef
  ) => {
    const { t, formatDateTime } = useI18nContext();

    const formatDate = useCallback(
      (search: BuildFlightShopQueryArgs) => {
        const formattedDeparture = formatDateTime(
          dayjs(search.departureDate).toDate(),
          DateTimeFormatStyle.Custom({ day: "numeric", month: "short" })
        );

        if (search.tripCategory === TripCategory.ONE_WAY) {
          return formattedDeparture;
        }

        const formattedReturn = formatDateTime(
          dayjs(search.returnDate).toDate(),
          DateTimeFormatStyle.Custom({ day: "numeric", month: "short" })
        );
        return `${formattedDeparture} - ${formattedReturn}`;
      },
      [formatDateTime]
    );

    const getOptionSublabel = useCallback(
      (value?: Suggestion | BuildFlightShopQueryArgs) => {
        if (value && "origin" in value) {
          const categoryKey =
            value.tripCategory === TripCategory.ONE_WAY
              ? "oneWay"
              : "roundTrip";

          const totalPassengerCount =
            value.passengerCounts.adultsCount +
            value.passengerCounts.childrenCount +
            value.passengerCounts.infantsOnLapCount +
            value.passengerCounts.infantsInSeatCount;

          return `${t(categoryKey)} , ${formatDate(value)} • ${t(
            "searchFilter.pickerTraveler",
            { count: totalPassengerCount }
          )}`;
        }
        return undefined;
      },
      [t, formatDate]
    );

    const onChangeInternal = useCallback(
      (value?: Suggestion | BuildFlightShopQueryArgs) => {
        if (!value) {
          onChange();
          return;
        }
        if ("origin" in value) {
          trackEvent({
            eventName: "tapped_search_suggestion",
            properties: {
              location_type: "recent",
              search_term: getOptionLabel(value),
            },
          });
          return onRecentSearchClick(value);
        }

        if (value?.trackingPropertiesV2) {
          trackEvent({
            eventName: "tapped_search_suggestion",
            properties: value.trackingPropertiesV2.properties,
            encryptedProperties: value.trackingPropertiesV2.encryptedProperties
              ? [value.trackingPropertiesV2.encryptedProperties]
              : [],
          });
        }
        onChange(value);
      },
      [onChange, onRecentSearchClick]
    );

    return (
      <>
        <label htmlFor={id} id={`${id}-label`} style={{ display: "none" }}>
          {t(`${id}`)}
        </label>
        <Autocomplete
          id={id}
          key={defaultValue ? "destination-loaded" : "loading-destination"}
          className={clsx(className, "flight-autocomplete")}
          paperClassName={"flight"}
          defaultValue={defaultValue}
          label={label}
          icon={icon}
          placeholder={placeholder}
          closeIcon={closeIcon}
          onOpen={onOpen}
          onChange={onChangeInternal}
          staticOptions={recentSearches}
          filterStaticOptions={filterRecentSearches}
          getOptionLabel={getOptionLabel}
          getOptionLabelIcon={getOptionLabelIcon}
          getOptionSublabel={getOptionSublabel}
          groupBy={groupPlaceType}
          sortOptions={sortLocations}
          fetch={(apiConfig, search, callback) =>
            fetchFlightLocations(
              apiConfig,
              search,
              callback,
              additionalSearchControl
            )
          }
          ref={forwardedRef}
        />
      </>
    );
  }
);

const groupPlaceType = (
  value?: Suggestion | BuildFlightShopQueryArgs
): string => {
  if (!value) {
    return "";
  }
  if ("origin" in value) {
    return SuggestionTypeEnum.RecentSearch;
  }
  const flight = value?.id.Id === IdEnum.Flight ? value.id.code : null;
  const regionType = flight?.regionType;
  return regionType ?? "";
};

const getOptionLabelIcon = (value: Suggestion) => {
  switch (groupPlaceType(value)) {
    case SuggestionTypeEnum.City:
      return <Location />;
    case SuggestionTypeEnum.RecentSearch:
      return <RecentSearch />;
    case SuggestionTypeEnum.Airport:
      return <DiagonalPlane style={{ rotate: "90deg" }} />;
    default:
      return <Location />;
  }
};

const getOptionLabel = (value?: Suggestion | BuildFlightShopQueryArgs) => {
  if (!value) {
    return undefined;
  }

  if ("origin" in value) {
    return (
      value.origin.label.split(",")[0] +
      " to " +
      value.destination.label.split(",")[0]
    );
  }
  return value?.label;
};

const sortLocations = (
  options?: (Suggestion | BuildFlightShopQueryArgs)[]
): (Suggestion | BuildFlightShopQueryArgs)[] => {
  const groupEntries = {
    [SuggestionTypeEnum.RecentSearch]: [],
    [SuggestionTypeEnum.City]: [],
    [SuggestionTypeEnum.Airport]: [],
  };

  options?.forEach((suggestion: Suggestion | BuildFlightShopQueryArgs) => {
    const group: string = groupPlaceType(suggestion);
    groupEntries[group].push(suggestion);
  });

  return [
    ...groupEntries[SuggestionTypeEnum.RecentSearch],
    ...groupEntries[SuggestionTypeEnum.City],
    ...groupEntries[SuggestionTypeEnum.Airport],
  ];
};
