import { useCallback, useMemo } from "react";

const getClassnames = (
  element: string,
  modifiers?: { [key: string]: boolean }
): string => {
  if (!modifiers) return element;

  return [
    element,
    ...Object.entries(modifiers)
      .filter(([_, value]) => value)
      .map(([key]) => `${element}--${key}`),
  ].join(" ");
};

export const useBEM = (
  block: string,
  modifiers?: { [s: string]: boolean }
): [
  string,
  (element: string, elementModifiers?: { [s: string]: boolean }) => string
] => {
  const className = useMemo(
    () => getClassnames(block, modifiers),
    [block, modifiers]
  );

  const getElementClassName = useCallback(
    (
      element: string,
      elementModifiers?: { [key: string]: boolean }
    ): string => {
      return getClassnames(`${block}__${element}`, elementModifiers);
    },
    [block]
  );

  return [className, getElementClassName];
};

const getScssModuleClassnames = (
  styles: Record<string, string>,
  element: string,
  modifiers?: { [key: string]: boolean }
): string => {
  const baseClass = `${styles[element] || ""} ${element}`.trim();

  const modifierClasses = Object.entries(modifiers ?? {})
    .filter(([, value]) => value)
    .map(([key]) => {
      const modifierClass = `${element}--${key}`;
      return `${styles[modifierClass] || ""} ${modifierClass}`.trim();
    });

  return [baseClass, ...modifierClasses].join(" ").trim();
};

type ConditionalModifiers = { [key: string]: boolean };
/**
 * `ModifierArgs` allows us to express two arguments that are both optional.
 * So the caller can specify no arguments, one argument of either type, or both
 * in the specific supported order.
 */
type ModifierArgs = [...([] | [string[]]), ...([] | [ConditionalModifiers])];
const processModifierArgs = (
  args: ModifierArgs
): [string[] | undefined, ConditionalModifiers | undefined] => {
  if (args.length === 0) {
    return [undefined, undefined];
  } else if (args.length === 1) {
    if (Array.isArray(args[0])) {
      return [args[0], undefined];
    } else {
      return [undefined, args[0]];
    }
  } else {
    return args;
  }
};

const processModifiers = (args: ModifierArgs): ConditionalModifiers => {
  const [unconditionalModifiers, conditionalModifiers] =
    processModifierArgs(args);
  return {
    ...(unconditionalModifiers != null
      ? Object.fromEntries(
          unconditionalModifiers.map((mod) => [mod, true] as const)
        )
      : null),
    ...conditionalModifiers,
  };
};

export const useModuleBEM: UseModuleBEMFn = (
  styles: Record<string, string>,
  block: string,
  ...args: ModifierArgs
): UseModuleBEMReturnType => {
  const modifiers = processModifiers(args);
  const className = useMemo(
    () => getScssModuleClassnames(styles, block, modifiers),
    [styles, block, modifiers]
  );

  const getElementClassName = useCallback(
    (element: string, ...args: ModifierArgs): string =>
      getScssModuleClassnames(
        styles,
        `${block}__${element}`,
        processModifiers(args)
      ),
    [styles, block]
  );

  return [className, getElementClassName];
};

export type GetElementClassNameFn = {
  (element: string): string;
  (element: string, elementModifiers: string[]): string;
  (element: string, elementConditionalModifiers: ConditionalModifiers): string;
  (
    element: string,
    elementModifiers: string[],
    elementConditionalModifiers: ConditionalModifiers
  ): string;
};

type UseModuleBEMReturnType = [string, GetElementClassNameFn];
type UseModuleBEMFn = {
  (styles: Record<string, string>, block: string): UseModuleBEMReturnType;
  (
    styles: Record<string, string>,
    block: string,
    modifiers: string[]
  ): UseModuleBEMReturnType;
  (
    styles: Record<string, string>,
    block: string,
    conditionalModifiers: ConditionalModifiers
  ): UseModuleBEMReturnType;
  (
    styles: Record<string, string>,
    block: string,
    modifiers: string[],
    conditionalModifiers: ConditionalModifiers
  ): UseModuleBEMReturnType;
};
