import {
  commonSelectors,
  getParentState,
  MobileLayout,
  ParentState,
  useCheckoutSend,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionButton, MobileFloatingButton } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { LoadingScreen } from "@lloyds/ui-connected";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { type PropsWithChildren, useCallback, useMemo } from "react";
import { Event } from "../../events";
import "./styles.scss";

interface WrapperComponentProps extends PropsWithChildren {
  state: ParentState;
  className?: string;
  mobileClassName?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onEdit?: () => void;
  onContinue?: () => void;
  continueButtonTopContent?: JSX.Element;
  additionalButton?: JSX.Element;
}

export const WrapperComponent = ({
  state,
  children,
  className,
  mobileClassName,
  isLoading,
  disabled,
  onEdit,
  onContinue,
  continueButtonTopContent,
  additionalButton,
}: WrapperComponentProps) => {
  const { t } = useI18nContext();

  const { matchesMobile } = useDeviceTypes();

  const send = useCheckoutSend();
  const stateValue = useSelector(commonSelectors.getStateValue) as string;

  const parentState = getParentState(stateValue);

  const isCurrentState = useMemo(() => {
    switch (state) {
      case ParentState.passengerInformation:
        return (
          parentState === ParentState.passengerInformation ||
          parentState === ParentState.passport
        );
      default:
        return parentState === state;
    }
  }, [parentState, state]);

  const isHiddenSection =
    parentState === ParentState.cartQuote ||
    parentState === ParentState.wallet ||
    parentState === ParentState.cartUpdate ||
    parentState === ParentState.cartFulfill;

  const disableSection = !isHiddenSection && !isCurrentState;

  const showContinueButton =
    parentState !== ParentState.bookingConfirmation &&
    parentState !== ParentState.passport &&
    isCurrentState;

  const buttonText =
    parentState === ParentState.review ? t("confirmAndBook") : t("continue");

  const showPrevStep = useMemo(() => {
    switch (parentState) {
      case ParentState.contactInformation:
        return false;
      case ParentState.passengerInformation:
        return state === ParentState.contactInformation;
      case ParentState.cardPayment:
        return (
          state === ParentState.contactInformation ||
          state === ParentState.passengerInformation
        );
      case ParentState.review:
        return (
          state === ParentState.contactInformation ||
          state === ParentState.passengerInformation ||
          state === ParentState.cardPayment
        );
      default:
        return false;
    }
  }, [parentState, state]);

  const showSection =
    (matchesMobile && isCurrentState && !disableSection) || !matchesMobile;

  const onContinueInternal = useCallback(() => {
    if (onContinue) return onContinue();
    send({ type: Event.NEXT });
  }, [onContinue, send]);

  return (
    showSection && (
      <div
        className={clsx("checkout-wrapper-component", className, {
          disabled: disableSection || (!matchesMobile && isLoading),
          previous: showPrevStep,
          active: isCurrentState,
        })}
      >
        <WrapperContainer className={mobileClassName}>
          {isLoading && (matchesMobile || isHiddenSection) && <LoadingScreen />}
          {children}
          {showContinueButton ? (
            matchesMobile ? (
              <MobileFloatingButton
                onClick={onContinueInternal}
                disabled={disabled}
                topContent={continueButtonTopContent}
                wide
              >
                {buttonText}
              </MobileFloatingButton>
            ) : (
              <Box
                display={"flex"}
                flexDirection={"row"}
                gridGap={"12px"}
                pt={2}
              >
                {additionalButton}
                <ActionButton
                  fullWidth={false}
                  onClick={onContinueInternal}
                  disabled={disabled}
                  message={buttonText}
                  size={"small"}
                />
              </Box>
            )
          ) : null}
          {showPrevStep && onEdit && (
            <ActionButton
              fullWidth={false}
              className="checkout-section-edit-action"
              onClick={onEdit}
              message={t("edit")}
              defaultStyle="h4r-secondary"
              size="small"
            />
          )}
        </WrapperContainer>
      </div>
    )
  );
};

type WrapperContainerProps = {
  className?: string;
};

const WrapperContainer = ({
  children,
  className,
}: PropsWithChildren<WrapperContainerProps>) => {
  const { matchesMobile } = useDeviceTypes();

  return matchesMobile ? (
    <MobileLayout className={className} fullScreen>
      {children}
    </MobileLayout>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};
