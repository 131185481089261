import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import {
  CoreUiComponents,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import defaultStyles from "./CheckboxGroup.module.scss";
import { Checkbox } from "@components/ui";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import { FC, useState } from "react";
import { CheckedState } from "@radix-ui/react-checkbox";

export type ChildCheckboxComponentProps = {
  id: string;
  checked: boolean;
  onCheckedChange: (checkedState: CheckedState) => void;
  label: JSX.Element | string;
};

export interface CheckoxGroupComponentProps extends PlatformComponentProps {
  options: {
    value: string;
    label: JSX.Element | string;
  }[];
  checkedOptions: string[];
  onOptionClick?: (val: string) => void;
  onOptionsChange?: (val: string[]) => void;
  checkboxElement?: FC<ChildCheckboxComponentProps>;
}

const DefaultCheckbox = ({
  id,
  checked,
  onCheckedChange,
  label,
}: ChildCheckboxComponentProps) => {
  return (
    <>
      <Checkbox id={id} checked={checked} onCheckedChange={onCheckedChange} />
      <label htmlFor={id}>{label}</label>
    </>
  );
};

export const CheckboxGroup = (props: CheckoxGroupComponentProps) => {
  const COMPONENT_KEY = CoreUiComponents.CheckboxGroup;
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const [checkedOptionInternal, setCheckedOptions] = useState<string[]>(
    props.checkedOptions
  );

  const handleCheckboxChange = (value: string, checked: boolean) => {
    const updatedCheckedOptions = checked
      ? [...checkedOptionInternal, value]
      : checkedOptionInternal.filter((option) => option !== value);

    setCheckedOptions(updatedCheckedOptions);
    props.onOptionsChange?.(updatedCheckedOptions);
  };

  const CheckboxComponent = props.checkboxElement || DefaultCheckbox;

  return (
    <ul className={clsx(block, props.className)}>
      {props.options?.map((option) => {
        const id = `checkbox-${uuidv4()}`;
        const isChecked = checkedOptionInternal.includes(option.value);
        return (
          <li key={option.value} className={cn("option")}>
            <CheckboxComponent
              id={id}
              checked={isChecked}
              onCheckedChange={(checkState) => {
                handleCheckboxChange(option.value, !!checkState);
                props.onOptionClick?.(option.value);
              }}
              label={option.label}
            />
          </li>
        );
      })}
    </ul>
  );
};
