import { updateTraveler } from "@hopper-b2b/api";
import type {
  CreateOrUpdateTravelerRequest,
  CreateOrUpdateTravelerResponse,
} from "@b2bportal/traveler-api/lib/api";
import { TravelerErrorModalTypes } from "@checkout/types";
import { apiConfig } from "@hopper-b2b/utilities";
import type { UpdateTravelerEvent } from "../events";

export const updateTravelerService = (event: UpdateTravelerEvent) =>
  new Promise((resolve, reject) => {
    const body: CreateOrUpdateTravelerRequest = {
      traveler: { ...event.traveler },
    };
    updateTraveler(body, apiConfig)
      .then((response: CreateOrUpdateTravelerResponse) => {
        if (response?.traveler) {
          resolve(response?.traveler);
        } else {
          reject({
            type: TravelerErrorModalTypes.UpdateTraveler,
          });
        }
      })
      .catch((e) => {
        reject({
          type: TravelerErrorModalTypes.UpdateTraveler,
          data: e,
        });
      });
  });
