import clsx from "clsx";

import { DeepLink, DeepLinkEnum } from "@b2bportal/chat-api";
import { IconComponent, IconName } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";

import styles from "./DeepLinkMessage.module.scss";

export interface IDeepLinkMessageProps {
  deepLink: DeepLink;
  handleDeepLink: (deepLink: DeepLink) => void;
}

export const DeepLinkMessage = (props: IDeepLinkMessageProps) => {
  const { t } = useI18nContext();

  const handleClick = () => {
    props.handleDeepLink(props.deepLink);
  };

  // Check DeepLink type and generate the appropriate message title
  const deepLinkTitle = () => {
    switch (props.deepLink.DeepLink) {
      case DeepLinkEnum.DeepLinkContactSupport:
        return t("chat.contactSupport.deepLinkContactSupportButtonLabel");
      case DeepLinkEnum.DeepLinkTripDetails:
        return t("chat.contactSupport.deepLinkTripDetailsButtonLabel");
      case DeepLinkEnum.DeepLinkTripCancellation:
        return t("chat.contactSupport.deepLinkTripCancellationButtonLabel");
      default:
        console.warn("DeepLink type not found");
        return "";
    }
  };

  return (
    <div className={styles.deepLinkContent}>
      <div
        className={clsx(styles.deepLinkMessageContainer)}
        onClick={handleClick}
      >
        <div className={styles.deepLinkMessage}>{deepLinkTitle()}</div>
        <div className={styles.chevronIcon}>
          <IconComponent
            className={styles.icon}
            name={IconName.SupportChevronRight}
          />
        </div>
      </div>
    </div>
  );
};
