import LloydsBold from "./assets/fonts/lloyds/lloydsBankJackBold.woff2";
import LloydsLight from "./assets/fonts/lloyds/lloydsBankJackLight.woff2";
import LloydsRegular from "./assets/fonts/lloyds/lloydsBankJackRegular.woff2";
import LloydsMedium from "./assets/fonts/lloyds/lloydsBankJackMedium.woff2";
import HalifaxRegular from "./assets/fonts/halifax/SourceSansPro-Regular.woff";
import HalifaxBold from "./assets/fonts/halifax/SourceSansPro-SemiBold.woff";
import BOSRegular from "./assets/fonts/bankofscotland/avenirnextltproregular.woff";
import BOSSemiBold from "./assets/fonts/bankofscotland/avenirnextltprodemi.woff";
import BOSBold from "./assets/fonts/bankofscotland/avenirnextltprobold.woff";
import BOSHeading from "./assets/fonts/bankofscotland/bospillgothicbold.woff";

export const LloydsDefaults = {
  typography: {
    fontFamily: "LloydsBankJack",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          color: "var(--primary-text)",
        },
        "@font-face": [
          {
            fontFamily: "LloydsBankJack",
            fontWeight: 300,
            src: `
              url(${LloydsLight}) format("woff2");
            `,
          },
          {
            fontFamily: "LloydsBankJack",
            fontWeight: 400,
            src: `
              url(${LloydsRegular}) format("woff2");
            `,
          },
          {
            fontFamily: "LloydsBankJack",
            fontWeight: 500,
            src: `
              url(${LloydsMedium}) format("woff2");
            `,
          },
          {
            fontFamily: "LloydsBankJack",
            fontWeight: 700,
            src: `
              url(${LloydsBold}) format("woff2");
            `,
          },
        ],
      },
    },
  },
};

export const HalifaxDefaults = {
  typography: {
    fontFamily: "Source Sans Pro",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          color: "var(--primary-text)",
        },
        "@font-face": [
          {
            fontFamily: "Source Sans Pro",
            fontStyle: "Regular",
            fontWeight: 400,
            src: `
              local("SourceSansPro-Regular"),
              url(${HalifaxRegular}) format("woff");
            `,
          },
          {
            fontFamily: "Source Sans Pro",
            fontStyle: "Bold",
            fontWeight: 800,
            src: `
              local("SourceSansPro-Bold"),
              url(${HalifaxBold}) format("woff");
            `,
          },
        ],
      },
    },
  },
};

export const BankOfScotlandDefaults = {
  typography: {
    fontFamily: "Avenir Next LT Pro Regular",
    h1: {
      fontFamily: "BoS Pill Gothic",
      fontSize: 28,
      fontWeight: 800,
    },
    h2: {
      fontFamily: "BoS Pill Gothic",
      fontSize: 24,
      fontWeight: 800,
    },
    h3: {
      fontFamily: "BoS Pill Gothic",
      fontSize: 22,
      fontWeight: 800,
    },
    h4: {
      fontFamily: "BoS Pill Gothic",
      fontSize: 22,
      fontWeight: 800,
    },
    h5: {
      fontFamily: "BoS Pill Gothic",
      fontSize: 20,
      fontWeight: 600,
    },
    h6: {
      fontFamily: "BoS Pill Gothic",
      fontSize: 16,
      fontWeight: 800,
    },
    subtitle1: {
      fontFamily: "Avenir Next LT Pro Medium",
      fontSize: 16,
      fontWeight: 800,
    },
    subtitle2: {
      fontFamily: "Avenir Next LT Pro Medium",
      fontSize: 14,
      fontWeight: 800,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 16,
    },
    caption: {
      fontSize: 14,
    },
    button: {
      lineHeight: "1.25rem",
      textTransform: "none" as const,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          color: "var(--primary-text)",
        },
        "@font-face": [
          {
            fontFamily: "Avenir Next LT Pro Regular",
            fontStyle: "Regular",
            fontWeight: 400,
            src: `
              local("AvenirNextLTPro-Regular"),
              url(${BOSRegular}) format("woff");
            `,
          },
          {
            fontFamily: "Avenir Next LT Pro Regular",
            fontStyle: "Medium",
            fontWeight: 600,
            src: `
              local("AvenirNextLTPro-Demi"),
              url(${BOSSemiBold}) format("woff");
            `,
          },
          {
            fontFamily: "Avenir Next LT Pro Medium",
            fontStyle: "Bold",
            src: `
              local("AvenirNextLTPro-Bold"),
              url(${BOSBold}) format("woff");
            `,
          },
          {
            fontFamily: "BoS Pill Gothic",
            fontStyle: "Bold",
            src: `
              url(${BOSHeading}) format("woff");
            `,
          },
        ],
      },
    },
  },
};
