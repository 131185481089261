import type { SessionData } from "@b2bportal/lloyds-user-api";
import {
  type ContactInformationContext,
  type IContactInfo,
  ParentState,
  getObjectKeysAsObject,
  setContextWithKey,
} from "@hopper-b2b/checkout";
import { parsePhoneNumber } from "@hopper-b2b/utilities";
import type { LloydsUserInfo } from "@lloyds/auth";
import { type DoneInvokeEvent, assign } from "xstate";
import type { CommonMachineContext } from "../types";

const buildContactInfoFromSession = ({
  phone,
  email,
}: LloydsUserInfo | SessionData): IContactInfo => {
  const parsedPhone = parsePhoneNumber(phone);

  return {
    countryCode: parsedPhone?.countryCode ? `+${parsedPhone?.countryCode}` : "",
    phoneNumber: parsedPhone?.nationalNumber ?? "",
    email: email ?? "",
  };
};

export const contactInformationActions = {
  setUserContactInformation: assign(
    (ctx: CommonMachineContext, event: DoneInvokeEvent<SessionData>) => {
      const contactInfo = event.data && buildContactInfoFromSession(event.data);

      return setContextWithKey(ctx, ParentState.contactInformation, {
        ...ctx[ParentState.contactInformation],
        visited: true,
        contactInfo,
      } as ContactInformationContext);
    }
  ),

  setContactInformationError: assign(
    (ctx: CommonMachineContext, event: DoneInvokeEvent<unknown>) =>
      setContextWithKey(ctx, ParentState.contactInformation, {
        ...ctx[ParentState.contactInformation],
        visited: true,
        error: event.data,
      } as ContactInformationContext)
  ),
};

export const ContactInformationActionTypes = getObjectKeysAsObject(
  contactInformationActions
);
