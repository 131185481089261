import type { ITripTerminus } from "@b2bportal/core-types";

export const destinationToCityCountry = (destination?: ITripTerminus) => {
  if (destination == null) {
    return "";
  }
  const partsByComma = destination.label.split(",");

  if (partsByComma.length >= 2) {
    const city = partsByComma[0];
    const country = partsByComma[1].split("(")[0];
    return city + ", " + country;
  }
  return destination.label;
};

export const destinationToCity = (destination?: ITripTerminus) => {
  if (destination == null) {
    return "";
  }
  return destination.label.split(",")[0];
};
