import { getObjectKeysAsObject } from "@checkout/helpers";
import { fetchPriceFreezeVoucher } from "./fetchPriceFreezeVoucher";
import { fetchFrozenProductToExercise } from "./fetchFrozenProductToExercise";

export const services = {
  fetchPriceFreezeVoucher,
  fetchFrozenProductToExercise,
};

export const ServiceTypes = getObjectKeysAsObject(services);
