import { Search } from "@lloyds/search";
import { useTenantImages } from "@hopper-b2b/utilities";
import { Box, Container, Hidden } from "@material-ui/core";
import styles from "./HomeRoute.module.scss";
import { DesaturatedBackgroundAVIF } from "@lloyds/theming";

export const HomeRouteComponent = () => {
  const images = useTenantImages();

  return (
    <Box
      style={{
        position: "relative",
        height: "100vh",
        width: "100%",
      }}
    >
      <Box
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <picture>
          <source type="image/avif" srcSet={DesaturatedBackgroundAVIF} />
          <img
            src={images?.homepageBackground}
            alt="homescreen"
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </picture>
      </Box>
      <Box
        style={{
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(33, 79, 59, 0.9)",
          pointerEvents: "none",
          zIndex: 1,
        }}
      />
      <Box
        style={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <Hidden smDown>
          <Box className={styles.desktopContainer}>
            <Container maxWidth="xl" disableGutters>
              <Box className={styles.desktopSearch}>
                <Search />
              </Box>
            </Container>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box className={styles.mobileContainer}>
            <Container disableGutters maxWidth="xl">
              <Search />
            </Container>
          </Box>
        </Hidden>
      </Box>
    </Box>
  );
};
