import {
  CategorizedResponse,
  LocationQueryEnum,
  Suggestion,
} from "@b2bportal/lodging-api";
import { fetchLodgingLocationAutocomplete } from "@hopper-b2b/api";
import { IApiConfig } from "@hopper-b2b/types";

export const fetchLocations = async (
  apiConfig: IApiConfig | undefined,
  search: string,
  onSuccess: (newOptions: Suggestion[]) => void
): Promise<void> => {
  try {
    const response = await fetchLodgingLocationAutocomplete(
      { l: search, LocationQuery: LocationQueryEnum.Label },
      apiConfig
    );

    const options: Suggestion[] = [];
    response?.reduce<Suggestion[]>(
      (acc: Suggestion[], categoryRes: CategorizedResponse) => {
        acc.push(...categoryRes.results);

        return acc;
      },
      options
    );

    onSuccess(options);
  } catch (e) {
    console.error("Could not fetch locations", e);
  }
};
