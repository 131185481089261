import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  MODAL_ALERT,
  type ModalAlertProperties,
  ModalCategoryType,
  ModalScreens,
  type ProductType,
} from "@hopper-b2b/types";
import { ActionButton } from "@lloyds/ui-core";
import { memo, type ReactNode, useEffect, useMemo } from "react";
import { LbgModal } from "../LbgModal";

export type LbgErrorModalProps = LbgErrorModalErrorProps & {
  open: boolean;
  className?: string;

  trackingEventProperties?: ModalAlertProperties;
  productType?: ProductType;

  onClose?: () => void;
};

export type LbgErrorModalErrorProps = {
  title?: ReactNode | string;
  subtitle?: ReactNode | string;
  primaryButtonText: ReactNode | string;
  primaryOnClick: () => void;
  secondaryButtonText?: ReactNode | string;
  secondaryOnClick?: () => void;
};

export const LbgErrorModal = memo(
  ({
    primaryButtonText,
    primaryOnClick,
    secondaryButtonText,
    secondaryOnClick,
    title,
    subtitle,
    open,
    trackingEventProperties,
    onClose,
    productType,
  }: LbgErrorModalProps) => {
    const { t } = useI18nContext();

    const hasSecondaryButton = secondaryButtonText && secondaryOnClick;
    const modalEventProperties = useMemo(
      () => ({
        ...trackingEventProperties,
        primary_button: primaryButtonText,
        secondary_button: hasSecondaryButton ? secondaryButtonText : undefined,
        screen: ModalScreens.FLIGHTS_CHECKOUT,
        category: ModalCategoryType.TROUBLE,
        product_type: productType,
      }),
      [
        hasSecondaryButton,
        primaryButtonText,
        productType,
        secondaryButtonText,
        trackingEventProperties,
      ]
    );

    useEffect(() => {
      if (open)
        trackEvent({
          eventName: MODAL_ALERT,
          properties: modalEventProperties,
        });
    }, [modalEventProperties, open]);

    return (
      <LbgModal
        open={open}
        title={title ?? t("checkoutError.genericErrorTitle")}
        subtitle={subtitle ?? t("checkoutError.genericErrorSubtitle")}
        onClose={onClose}
        primaryBottomButton={
          <ActionButton message={primaryButtonText} onClick={primaryOnClick} />
        }
        secondaryBottomButton={
          hasSecondaryButton && (
            <ActionButton
              defaultStyle="h4r-secondary"
              message={secondaryButtonText}
              onClick={secondaryOnClick}
            />
          )
        }
      />
    );
  }
);
