import { State, StateValue } from "xstate";
import { CommonParentContext } from "./context";

type CommonStateType = State<CommonParentContext>;
type CommonStateWithoutValue = Pick<CommonStateType, "context">;

export const getStateValue = ({ value }: { value: StateValue }) => value;

export const getSessionInfo = ({ context }: CommonStateWithoutValue) =>
  context.sessionInfo;

export const getFeatureFlags = ({ context }: CommonStateWithoutValue) =>
  context.featureFlags;

export const getHasLoadingTag = (state: CommonStateType) =>
  state.hasTag("loading");
