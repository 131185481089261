import { FiatPrice } from "@b2bportal/air-shopping-api";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { PriceField, Slider } from "@b2bportal/core-ui";
import defaultStyles from "./FilterPrice.module.scss";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { debounce } from "lodash-es";
import { Currency } from "@hopper-b2b/types";
import { useFilterPrice } from "./hooks/useFilterPrice";

export type FilterPriceValue = {
  minPrice: number;
  maxPrice: number;
};

export interface FilterPriceComponentProps {
  minimumPrice?: FiatPrice;
  maximumPrice?: FiatPrice;
  initialValue: FilterPriceValue;
  isMinValueConfigurable?: boolean;
  onFilterPriceChange: (value: FilterPriceValue) => void;
  className?: string;
  label: string;
}

const fallbackCurrency = {
  currencyCode: Currency.USD,
  currencySymbol: "$",
};

// Used when there are no products loaded to get the min and max price
const maximumPriceFallback = {
  value: Number.MAX_SAFE_INTEGER,
  ...fallbackCurrency,
};

const minimumPriceFallback = {
  value: Number.MIN_SAFE_INTEGER,
  ...fallbackCurrency,
};

export const FilterPrice = ({
  minimumPrice = minimumPriceFallback,
  maximumPrice = maximumPriceFallback,
  initialValue,
  onFilterPriceChange,
  className,
  label,
  isMinValueConfigurable = false,
}: FilterPriceComponentProps) => {
  const {
    text,
    context: {
      sliderValue,
      textField: { min, max },
    },
    handlers: {
      onChangeMaxTextField,
      onChangeMinTextField,
      formatValueLabel,
      onSliderChange,
    },
  } = useFilterPrice({
    minimumPrice,
    maximumPrice,
    initialValue,
    onFilterPriceChange,
    isMinValueConfigurable,
    fallbackCurrency,
  });
  const styles = useFlightStyles(CoreUiComponents.FilterPrice, defaultStyles);

  const [block, cn] = useModuleBEM(styles, CoreUiComponents.FilterPrice);

  return (
    <div className={clsx(block, className)}>
      <h2 className={cn("title")}>{label}</h2>
      <div className={cn("slider")}>
        <Slider
          min={minimumPrice.value}
          max={maximumPrice.value}
          arial=""
          formatValueLabel={formatValueLabel}
          value={sliderValue}
          onValueChange={onSliderChange}
          minStepsBetweenThumbs={1}
        />
      </div>
      <div className={cn("min-value")}>
        {isMinValueConfigurable ? (
          <PriceField
            label={text.textField.min}
            min={min.min}
            max={min.max}
            value={min.value}
            className={cn("min-price-input")}
            onChange={onChangeMinTextField}
            fallbackValue={min.fallbackValue}
          />
        ) : null}
      </div>
      <div className={cn("max-value")}>
        <PriceField
          label={text.textField.max}
          min={max.min}
          max={max.max}
          value={max.value}
          onChange={onChangeMaxTextField}
          className={cn("max-price-input")}
          fallbackValue={max.fallbackValue}
        />
      </div>
    </div>
  );
};
