import {
  enGB_lloydsTranslations,
  en_lloydsTranslations,
} from "@hopper-b2b/i18n";
import {
  branding,
  createBrandStyling,
  defaultLloydsIcons,
} from "@lloyds/theming";
import { BasicConfig, fromBasicConfig } from "@hopper-b2b/portal";
import { ClientName, TranslationLanguage } from "@hopper-b2b/types";
import { styleOverrides } from "../src/overrides/styles";

const bootstrapPortalConfig = (): BasicConfig => {
  const { variables } = createBrandStyling();
  return {
    clientName: ClientName.LLOYDS,
    // Environments and Datadog configuration
    bootConfig: {},
    theme: {
      variables: variables,
      styleOverrides: styleOverrides,
      icons: defaultLloydsIcons,
      images: {},
    },
    featureFlags: {
      enablePricePrediction: true,
    },
    branding: branding,
    translations: {
      [TranslationLanguage.en]: en_lloydsTranslations,
      [TranslationLanguage["en-GB"]]: enGB_lloydsTranslations,
    },
  };
};

export default fromBasicConfig(bootstrapPortalConfig());
