import {
  Fare,
  FareSliceOutbound,
  Flights,
  Prices,
  Tags,
  TripDetailsV2,
} from "@b2bportal/air-shopping-api";

export interface IFareTrip {
  tripId: string;
  fareId: string;
}

export type FlightFareSelectionFunctionParams = {
  sliceId: string;
  fareId: string;
  tripId: string;
};

export type FlightFareSelectionFunction = (
  params: FlightFareSelectionFunctionParams
) => void;

export enum FlightShopStep {
  departure = "departure",
  return = "return",
  review = "review",
  reviewFare = "review-fare",
  book = "book",
}

export enum FlightShopQueryParams {
  ORIGIN = "origin",
  DESTINATION = "destination",
  DEPARTURE_DATE = "departureDate",
  RETURN_DATE = "returnDate",
  TRIP_CATEGORY = "tripCategory",
  STOP_OPTIONS = "stopOptions",
}

export type ResponseWithStatus<T> = {
  isLoading: boolean;
  isError: boolean;
  data?: T;
};

export enum AlgomerchTag {
  BestFlight = "BestFlight",
  Cheapest = "Cheapest",
  BestQuality = "BestQuality",
  Fastest = "Fastest",
}
export interface IFlightData {
  departureDateTime: string;
  arrivalDateTime: string;
  duration: {
    days: number;
    hours: number;
    minutes: number;
  };
  originCode: string;
  destinationCode: string;
  airlineCode: string;
  airlineName: string;
  flightStops: number;
  plusDays: number;
}

export interface IFareData {
  fareId: string;
  fareClass: string;
  fareName: string;
  fareShelf: number;
  priceText: string;
  rawPriceText: string;
  price: Prices;
  tags?: AlgomerchTag[];
  numberOfFares?: number;
  segments: { availableSeats: number }[];
}

export enum FlightRatingsEnum {
  basic,
  standard,
  enhanced,
  premium,
  luxury,
}

export interface IFlightFares {
  [FlightRatingsEnum.basic]?: IFareData;
  [FlightRatingsEnum.standard]?: IFareData;
  [FlightRatingsEnum.enhanced]?: IFareData;
  [FlightRatingsEnum.premium]?: IFareData;
  [FlightRatingsEnum.luxury]?: IFareData;
}

export interface IFlightListData<T extends Fare | FareSliceOutbound> {
  sliceId: string;
  fares: T[];
}

export interface ITripDetailsByTripId {
  [key: string]: TripDetailsV2;
}

export interface ISelectedTrip {
  tripId: string | null;
  outgoingSliceId: string | null;
  outgoingFareId: string | null;
  outgoingFareRating?: number;
  returnSliceId?: string | null;
  returnFareId?: string | null;
  returnFareRating?: number;
}

export enum AirRestrictionStatus {
  UNKNOWN = "Unknown",
  PURCHASABLE = "Purchasable",
  UNAVAILABLE = "Unavailable",
  INCLUDED = "Included",
  GENERIC = "GENERIC",
}

export interface IRestrictionProps {
  symbol: AirRestrictionStatus;
  description: string;
  key?: string;
  name: string;
}

export type FareclassShelfBrandName =
  | "basic"
  | "standard"
  | "enhanced"
  | "premium"
  | "luxury";

export enum SortOptionEnum {
  Price = "price",
  FareScore = "fareScore",
  Stops = "stops",
  DepartureTime = "departureTime",
  ArrivalTime = "arrivalTime",
  Duration = "duration",
  StopAndDepartureTime = "stopAndDepartureTime",
}
