import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  airCfarApi,
  type AirCfarExerciseQuoteRequest,
  type AirCfarExerciseQuoteResponse,
} from "@b2bportal/air-cfar-api";

export const fetchQuote = createAsyncThunk<
  AirCfarExerciseQuoteResponse,
  AirCfarExerciseQuoteRequest
>(
  "cfar/exercise/fetchQuote",
  async (request: AirCfarExerciseQuoteRequest, thunkAPI) => {
    try {
      const res = await airCfarApi(axios).apiV1CfarAirExerciseQuotePost(
        request
      );

      return res.data;
    } catch (error) {
      console.error("Failed to fetch quote", error);

      return thunkAPI.rejectWithValue(error);
    }
  }
);
