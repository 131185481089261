import { ActionLink, FloatingBox } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Close } from "@lloyds/theming";
import { Box, Dialog, Grid, Slide, Typography } from "@material-ui/core";
import clsx from "clsx";
import type { PropsWithChildren, ReactNode } from "react";
import "./LbgModal.scss";

export interface ILbgModalProps {
  id?: string;
  open: boolean;
  icon?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  onClose?: () => void;
  className?: string;
  fullScreen?: boolean;
  primaryBottomButton?: ReactNode;
  secondaryBottomButton?: ReactNode;
  additionalInfoHeader?: ReactNode;
  children?: ReactNode;
  diableContentPadding?: boolean;
}

export const LbgModal = ({
  id,
  open,
  icon,
  title,
  subtitle,
  onClose,
  fullScreen,
  className,
  primaryBottomButton,
  secondaryBottomButton,
  additionalInfoHeader,
  children,
  diableContentPadding,
}: PropsWithChildren<ILbgModalProps>) => {
  const { matchesMobile } = useDeviceTypes();
  const platform = matchesMobile ? "lloyds-mobile-" : "lloyds-desktop-";

  return (
    <Dialog
      id={id}
      hideBackdrop={false}
      BackdropProps={{
        invisible: false,
      }}
      open={open}
      onClose={onClose}
      TransitionComponent={Slide}
      className={clsx(platform + "popover-card-root", className)}
      classes={{
        paper: clsx(platform + "popover-paper", {
          "full-screen": fullScreen,
        }),
      }}
      TransitionProps={{
        // See https://mui.com/material-ui/transitions/#performance-amp-seo
        unmountOnExit: true,
        /* eslint-disable */
        // @ts-ignore: Fix the type definition of TransitionProps to include direction.
        direction: "up",
        /* eslint-enable */
      }}
      transitionDuration={300}
    >
      <Box className={platform + "popover-container"}>
        <Box
          className={platform + "popover-header"}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems="flex-start"
            justifyContent={"space-between"}
          >
            <Grid container spacing={2} alignItems="center">
              {icon && <Grid item>{icon}</Grid>}
              {title && (
                <Grid item xs>
                  <Typography
                    className={platform + "popover-title"}
                    variant="h2"
                    gutterBottom
                  >
                    {title}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {additionalInfoHeader}
            {onClose && (
              <Box className={clsx(platform + "popover-top-button", "right")}>
                <ActionLink
                  content={<Close />}
                  useStopPropagation
                  label="Close"
                  onClick={onClose}
                />
              </Box>
            )}
          </Box>
          {subtitle && (
            <Typography
              className={platform + "popover-subtitle"}
              variant="inherit"
            >
              {subtitle}
            </Typography>
          )}
        </Box>
        <Box
          p={diableContentPadding ? 0 : 5}
          pt={0}
          className={platform + "popover-content-container"}
        >
          {children}
        </Box>
        {(primaryBottomButton || secondaryBottomButton) && (
          <LbgModalButtonWrapper>
            <Grid
              container
              direction={matchesMobile ? "column" : "row-reverse"}
              spacing={2}
            >
              {primaryBottomButton && (
                <Grid xs item>
                  {primaryBottomButton}
                </Grid>
              )}
              {secondaryBottomButton && (
                <Grid xs item>
                  {secondaryBottomButton}
                </Grid>
              )}
            </Grid>
          </LbgModalButtonWrapper>
        )}
      </Box>
    </Dialog>
  );
};

const LbgModalButtonWrapper = ({ children }: PropsWithChildren) => {
  const { matchesMobile } = useDeviceTypes();

  return matchesMobile ? (
    <FloatingBox>{children}</FloatingBox>
  ) : (
    <Box m={5}>{children}</Box>
  );
};
