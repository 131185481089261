import {
  AuthMethodEnum,
  CreateSessionRequestEnum,
  authApi,
} from "@b2bportal/auth-api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { trackEvent } from "../tracking/trackEvent";

/**
 * @description
 * @param {string} uid The user id to impersonate
 * @param {string} [refreshHeaderKey=""] Optional key in response headers to get a refresh token
 * @param {string} [refreshStorageKey=""] Optional key in local storage to store a refresh token
 * @return {Promise<CreateSessionResponse>}
 */
export async function impersonateUserAsAgent(
  uid: string,
  tenant?: string,
  refreshHeaderKey = "",
  refreshStorageKey = ""
) {
  const body = {
    authMethod: {
      tenant,
      user: uid,
      AuthMethod: AuthMethodEnum.Agent,
    },
    CreateSessionRequest: CreateSessionRequestEnum.SignIn,
  };

  try {
    const response = await authApi(axiosInstance).apiV0AuthSessionCreatePost(
      body
    );
    const { data, headers } = response;

    if (headers[refreshHeaderKey] && refreshStorageKey) {
      // setRefreshToken
      window.localStorage.setItem(refreshStorageKey, headers[refreshHeaderKey]);
    }

    return data;
  } catch {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
  }
}
