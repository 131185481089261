import styles from "./PriceRangeTags.module.scss";
import { Box } from "@material-ui/core";
import { useHighContrast } from "@lloyds/utils";
import { PriceRangeTags as B2bPriceRangeTags } from "@hopper-b2b/ui";

export type IPriceRangeTags = {
  priceTags?: string[];
};

export const PriceRangeTags = ({ priceTags }: IPriceRangeTags) => {
  const { isHighContrast } = useHighContrast();

  return (
    <Box className={"price-range-tags-section"}>
      <B2bPriceRangeTags
        priceTags={priceTags}
        className={isHighContrast ? styles.highContrast : ""}
      />
    </Box>
  );
};
