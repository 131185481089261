import { AuthSessionState } from "./authReducer";

export function getAuthState<T extends { auth: AuthSessionState }>(
  appState: T
) {
  return appState.auth;
}

export function getDelegatedTo(state) {
  return getSessionInfoSelector(state)?.delegatedTo;
}

export function getSessionInfoSelector(state) {
  return getAuthState(state).sessionInfo;
}
