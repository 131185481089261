import { useI18nContext } from "@hopper-b2b/i18n";
import {
  GuestPickerInput,
  CalendarPickerButton,
  HotelCalendarPicker,
} from "@lloyds/ui-connected";
import { ActionButton } from "@lloyds/ui-core";
import { Box, Grid } from "@material-ui/core";
import { useHotelsRecentSearches } from "../../hooks/useLodgingRecentSearch";
import { HotelLocationAutoComplete } from "@lloyds/ui-connected";
import { useHotelSearch } from "../../hooks";

export const DesktopHotelSearch = () => {
  const { t } = useI18nContext();

  const {
    context: {
      destination,
      checkinDate,
      checkoutDate,
      guestCount,
      isSearchReady,
    },
    handlers: {
      setCheckoutDate,
      setCheckinDate,
      setGuestCount,
      setDestination,
      handleSearch,
      onRecentSearchClick,
    },
  } = useHotelSearch();

  const { recentSearches, filterByDestination } = useHotelsRecentSearches();

  return (
    <Grid container spacing={3} direction="row" alignItems="stretch">
      <Grid item xs={12} lg={4}>
        <HotelLocationAutoComplete
          id="destination"
          label=""
          onChange={setDestination}
          recentSearches={recentSearches}
          filterRecentSearches={filterByDestination}
          onRecentSearchClick={onRecentSearchClick}
          placeholder={t("lodging.search.whereAreYouStaying")}
          value={destination}
          closeIcon={null}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4} className="hotel-search-container">
        <CalendarPickerButton
          startDate={checkinDate ?? null}
          endDate={checkoutDate ?? null}
          startLabel={t("checkin") ?? undefined}
          endLabel={t("checkout") ?? undefined}
          setStartDate={setCheckinDate}
          setEndDate={setCheckoutDate}
          CalendarComponent={HotelCalendarPicker}
        />
      </Grid>
      <Grid item xs={12} sm={3} lg={2}>
        <GuestPickerInput
          guestCounts={guestCount}
          updateGuestCounts={setGuestCount}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={3}
        lg={2}
        className="hotel-search-button-container"
      >
        <Box height="100%" display={"flex"}>
          <ActionButton
            onClick={handleSearch}
            disabled={!isSearchReady}
            size="medium"
            message={t("searchButton")}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
