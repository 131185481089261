import { closeChatbotConversation } from "@hopper-b2b/api";
import { ActionButton } from "@hopper-b2b/ui-core";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useContext, useState } from "react";

import { ChatInput } from "../ChatInput";
import { ChatRating } from "../ChatRating";
import { ChatContext } from "../ChatWindow";

import styles from "./CSATForm.module.scss";
import { BottomSheetContentEnum } from "../../types";

export interface ICSATFormProps {
  /**
   * Callback for after the form is submitted (via submitting CSAT or skipping)
   */
  onConversationEnd?: () => void;
}

const CSATForm = (props: ICSATFormProps): JSX.Element => {
  const { onConversationEnd } = props;
  const { bottomSheetContent, conversationId, setConversationId } =
    useContext(ChatContext);
  const { t } = useI18nContext();
  const [inputValue, setInputValue] = useState("");
  const [rating, setRating] = useState(0);

  const endChatConversation = useCallback(
    async (csatScore?: number, csatComment?: string) => {
      if (conversationId) {
        try {
          await closeChatbotConversation(
            conversationId,
            csatScore,
            csatComment,
            bottomSheetContent === BottomSheetContentEnum.CSATFormResolved
          );
        } catch (err) {
          console.error(err);
        }
      }

      if (onConversationEnd) {
        onConversationEnd();
      }

      setConversationId(null);
    },
    [bottomSheetContent, conversationId, onConversationEnd, setConversationId]
  );

  function handleSubmit() {
    const feedbackText = inputValue.trim();

    endChatConversation(rating, feedbackText);
  }

  return (
    <Box className={clsx("csat-form-container", styles.csatFormContainer)}>
      <ChatRating onChange={setRating} showSubtitle />
      <Box className={clsx("csat-input-container", styles.csatInputContainer)}>
        <Typography
          className={clsx("csat-input-title", styles.csatInputTitle)}
          variant="h2"
        >
          {t("chat.csatForm.freeformInput.title")}
        </Typography>
        <ChatInput
          multiline
          onChange={setInputValue}
          parentValue={inputValue}
          placeholder={t("chat.csatForm.freeformInput.placeholder")}
          rows={5}
        />
      </Box>
      <Box
        className={clsx("csat-actions-container", styles.csatActionsContainer)}
      >
        <ActionButton
          className={clsx("csat-skip-btn", styles.csatBtn, styles.csatSkipBtn)}
          defaultStyle="h4r-secondary"
          message={t("chat.csatForm.skipCsat")}
          onClick={() => endChatConversation()}
        />
        <ActionButton
          className={clsx(
            "csat-submit-btn",
            styles.csatBtn,
            styles.csatSubmitBtn
          )}
          defaultStyle="h4r-primary"
          disabled={!rating}
          message={t("chat.csatForm.submit")}
          onClick={handleSubmit}
        />
      </Box>
    </Box>
  );
};

export default CSATForm;
