import { ChatConversation, ChatMessage } from "@b2bportal/chat-api";
import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { apiConfig } from "@hopper-b2b/utilities";

export const createChatbotConversation =
  async (): Promise<ChatConversation> => {
    try {
      const res = await axiosInstance.put("/api/v0/chatbot/create");
      return res.data.conversation;
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      throw e;
    }
  };

export const sendChatbotMessage = async (
  conversationId: string,
  message: string
): Promise<ChatMessage> => {
  try {
    const res = await axiosInstance.put("/api/v0/chatbot/sendMessage", {
      conversationId,
      message,
    });
    return res.data.message;
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      apiConfig
    );
    throw e;
  }
};
