import { Box, Divider, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useState } from "react";

import {
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
  FlightItinerarySlice,
  Person,
  ScheduleChangeSeverity,
} from "@b2bportal/air-booking-api";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import {
  SelectedSeatsSegment,
  getItinerarySummaryProps,
  getRestrictionInfo,
} from "@hopper-b2b/types";
import {
  DATE_FORMAT,
  getIsMixedClass,
  sliceHasScheduleChange,
} from "@hopper-b2b/utilities";
import { ActionButton } from "@hopper-b2b/ui-core";

import { FlightDetailsSummary } from "../FlightDetailsSummary";
import { MixedCabinToolTip } from "../MixedCabinToolTip";
import { Restriction } from "../Restriction";
import { SelectedSeatsConfirmation } from "../SelectedSeatsConfirmation";

import styles from "./styles.module.scss";

export enum SliceToShow {
  /**
   * show just the original slice
   */
  original,
  /**
   * show just the updated slice (if available)
   */
  updated,
  /**
   * show both slices with as an inline diff
   */
  both,
}

export interface ISliceDetailsProps {
  airlineMap: Record<string, Airline | undefined>;
  airportMap: Record<string, Airport | undefined>;
  flight: BookedFlightItineraryWithDepartureTime;
  isOutgoing?: boolean;
  openScheduleChangeModal?: () => void;
  passengerMap: Record<string, Person>;
  seatSegments?: SelectedSeatsSegment[];
  showScheduleChangeTakeover?: boolean;
  slice: FlightItinerarySlice;
  sliceToShow?: SliceToShow;
}

const SKCH_EXP_FORMAT = "ddd, MMM DD, h:mm A";

const SliceDetails = (props: ISliceDetailsProps): JSX.Element => {
  const {
    airlineMap,
    airportMap,
    flight,
    isOutgoing,
    openScheduleChangeModal,
    seatSegments,
    showScheduleChangeTakeover = false,
    slice,
    sliceToShow = SliceToShow.original,
  } = props;
  const { formatFiatCurrency, t } = useI18nContext();
  const [isMixedClass] = useState(getIsMixedClass(slice));
  const { scheduleChange } = flight.bookedItinerary;
  // TODO pass sliceIdx in as a prop to support multi-city itineraries (i.e. >2 slices)
  const sliceIdx = isOutgoing ? 0 : 1;
  let hasMajorScheduleChange = false;
  let hasMinorScheduleChange = false;

  if (scheduleChange) {
    const { severity } = scheduleChange;
    const sliceChanged = sliceHasScheduleChange(
      flight.bookedItinerary,
      isOutgoing
    );

    if (sliceChanged) {
      hasMajorScheduleChange = severity === ScheduleChangeSeverity.Major;
      hasMinorScheduleChange = severity === ScheduleChangeSeverity.Minor;
    }
  }

  const getItineraryDetailsHeader = () => {
    const { segments } = slice;
    const firstSegment = segments[0];
    const lastSegment = segments.at(-1);

    return (
      <Trans
        i18nKey={
          isOutgoing
            ? "itineraryDetailsHeaderSimpleOutgoing"
            : "itineraryDetailsHeaderSimpleReturn"
        }
        components={[<span className={styles.directionLabel} />]}
        values={{
          destination:
            airportMap[lastSegment.destination.locationCode]?.cityName,
          date: dayjs(firstSegment.scheduledDeparture).format(DATE_FORMAT),
        }}
      />
    );
  };

  return (
    <Box
      className={clsx(styles.sliceDetails, {
        [styles.majorChange]:
          sliceToShow !== SliceToShow.updated && hasMajorScheduleChange,
        [styles.originalSlice]:
          sliceToShow === SliceToShow.original && hasMajorScheduleChange,
        [styles.showTakeover]: showScheduleChangeTakeover,
      })}
    >
      {showScheduleChangeTakeover &&
        hasMajorScheduleChange &&
        openScheduleChangeModal && (
          <Box className={styles.majorSkchCtaContainer}>
            <Typography
              className={styles.majorSkchCopy}
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: t("scheduleChange.majorBannerLabel", {
                  context: isOutgoing ? "outbound" : "return",
                  expDate: dayjs(scheduleChange.expiry).format(SKCH_EXP_FORMAT),
                }),
              }}
            />
            <ActionButton
              className={styles.majorSkchCta}
              fill="blue"
              message={t("scheduleChange.reviewChange")}
              onClick={openScheduleChangeModal}
              size="medium"
            />
          </Box>
        )}
      <Box className={styles.sliceDetailsContainer}>
        <Box className={styles.sliceDetailsTitle}>
          <Typography variant="subtitle2">
            {getItineraryDetailsHeader()}
            {isMixedClass && <MixedCabinToolTip />}
          </Typography>
        </Box>
        <FlightDetailsSummary
          className={clsx(
            styles.tripsFlightCard,
            isOutgoing ? styles.outgoing : styles.return
          )}
          {...getItinerarySummaryProps(
            flight,
            isOutgoing,
            airportMap,
            airlineMap,
            sliceToShow === SliceToShow.original
          )}
          hasMajorScheduleChange={hasMajorScheduleChange}
          hasMinorScheduleChange={hasMinorScheduleChange}
          isMixedCabinClass={isMixedClass}
          renderAirlineIconSection={false}
          sliceToShow={sliceToShow}
          skchSlice={
            sliceToShow === SliceToShow.original
              ? undefined
              : scheduleChange?.next[sliceIdx]
          }
          showTitle={false}
        />
        <Divider className={styles.divider} />
        <Typography className={styles.fareDetailsHeader} variant="subtitle1">
          {t("fareDetails")}
        </Typography>
        <Box className={styles.tripItineraryRestrictions}>
          {getRestrictionInfo(flight, t, formatFiatCurrency).map(
            (restriction, index) => (
              <Restriction
                descriptionString={restriction.description}
                key={`restriction-${index}`}
                name={restriction.name}
                symbol={restriction.symbol}
              />
            )
          )}
        </Box>
        {seatSegments?.length ? (
          <>
            <Divider className={styles.divider} />
            <Typography
              className={styles.seatSelectionHeader}
              variant="subtitle1"
            >
              {t("seatSelection")}
            </Typography>
            <Box
              className={clsx(
                styles.seatSelection,
                isOutgoing ? styles.outgoing : styles.return
              )}
            >
              <SelectedSeatsConfirmation
                airports={airportMap}
                outboundOriginCode={slice.segments[0].origin.locationCode}
                outboundSeatSegments={seatSegments}
                returnOriginCode={""}
              />
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default SliceDetails;
