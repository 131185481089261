import { installCssConfig } from "@hopper-b2b/themes";
import { useWindowSize } from "@hopper-b2b/utilities";
import { CssBaseline } from "@material-ui/core";
import {
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";
import { type PropsWithChildren, useEffect, useMemo } from "react";
import { createBrandStyling } from "../defaultTheme";
import type { Brand } from "@b2bportal/lloyds-auth-api";
import "core-theme/normalize";

const generateClassName = createGenerateClassName({
  productionPrefix: "ptBaseModule",
  seed: "ptBaseModule",
});

const setViewWidthAndHeight = (width: string, height: string) => {
  document.body.style.setProperty(`--vw`, width);
  document.body.style.setProperty(`--vh`, height);
};

export const ThemingProvider = ({
  brand,
  children,
}: PropsWithChildren<{ brand: Brand }>) => {
  const windowSize = useWindowSize();

  const { variables, theme } = useMemo(
    () => createBrandStyling(brand),
    [brand]
  );

  useEffect(() => {
    // Add a variable for vh to use for specifying full-screen height
    // 100vh does not work properly on iOS. https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    setViewWidthAndHeight(
      `${windowSize.width * 0.01}px`,
      `${windowSize.height * 0.01}px`
    );
  }, [windowSize.height, windowSize.width]);

  installCssConfig(variables);

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
};
