import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionButton } from "@lloyds/ui-core";
import { Grid } from "@material-ui/core";
import "./styles.scss";

export type DesktopTravelerFormButtonsProps = {
  onClose?: () => void;
  onSave?: () => void;
};

export const DesktopTravelerFormButtons = ({
  onClose,
  onSave,
}: DesktopTravelerFormButtonsProps) => {
  const { t } = useI18nContext();

  return (
    <Grid container justifyContent="flex-end" spacing={2}>
      <Grid item>
        <ActionButton
          size="small"
          defaultStyle="h4r-secondary"
          message={t("cancel")}
          onClick={onClose}
        />
      </Grid>
      <Grid item>
        <ActionButton
          size="small"
          onClick={onSave}
          message={t("saveTraveler")}
          className={"save-traveler"}
        />
      </Grid>
    </Grid>
  );
};
