import { useI18nContext } from "@hopper-b2b/i18n";
import {
  CallState,
  ClientName,
  LodgingShopTrackingEvents,
} from "@hopper-b2b/types";
import {
  getEnvVariables,
  useDeviceTypes,
  useEnableWallet,
} from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTrackEvents } from "../../../../tracking";
import { getTotalNights } from "../../../availability/reducer";
import { setSelectedRate, setSelectedRoom } from "../../actions/actions";
import { SECTION_ID } from "../../constants";
import { useGoToNextStep } from "../../hooks";
import {
  getRooms,
  getRoomsCallState,
  getSelectedRoomId,
} from "../../reducer/selectors";
import { RoomCard } from "../RoomCard/RoomCard";
import "./styles.scss";
import { UnavailableRoomsBanner } from "../UnavailableRoomsBanner";
import { Slot } from "@hopper-b2b/ui";
import {
  getWalletBestOffers,
  getWalletOffersNoCredits,
} from "../../../wallet/reducer";

export const RoomSelect = forwardRef(
  (_props, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useI18nContext();

    const titleId = useId();

    const isLloydsClient = getEnvVariables("clientName") === ClientName.LLOYDS;

    const dispatch = useDispatch();
    const [selectedRoomId, setSelectedRoomId] = useState(null);
    const { matchesMobile: isMobile } = useDeviceTypes();
    const trackEvent = useTrackEvents();

    const enableWallet = useEnableWallet();
    const bestOffer = useSelector(getWalletBestOffers);
    const offers = useSelector(getWalletOffersNoCredits);

    const rooms = useSelector(getRooms);

    const isLoadingRooms =
      useSelector(getRoomsCallState) === CallState.InProcess;
    const roomId = useSelector(getSelectedRoomId);

    useEffect(() => {
      setSelectedRoomId(roomId);
    }, [roomId]);

    const nights = useSelector(getTotalNights);

    // const icons = useTenantIcons();

    const goToNextStep = useGoToNextStep();

    // const carouselElementRef = useRef<HTMLDivElement | null>(null);
    // const singleCardWidth = 380;
    const onSelectedRate = useCallback(
      (roomId: string, rateId: string) => {
        dispatch(
          setSelectedRoom(rooms.find((r) => r?.roomInfo?.roomId === roomId))
        );
        dispatch(setSelectedRate(rateId));
        trackEvent(
          LodgingShopTrackingEvents.hotel_tapped_room_selection_continue
        );
        goToNextStep();
      },
      [dispatch, goToNextStep, rooms, trackEvent]
    );

    const onRateChange = useCallback(
      (roomId: string, rateId: string) => {
        dispatch(
          setSelectedRoom(rooms.find((r) => r?.roomInfo?.roomId === roomId))
        );
        dispatch(setSelectedRate(rateId));
      },
      [dispatch, rooms]
    );

    const onSelectedRoomChange = useCallback(
      (roomId: string) => {
        dispatch(
          setSelectedRoom(rooms.find((r) => r?.roomInfo?.roomId === roomId))
        );
      },
      [dispatch, rooms]
    );

    const handleOfferBannerTap = useCallback(() => {
      trackEvent(LodgingShopTrackingEvents.viewed_offer, {
        screen: "room_select",
      });
    }, [trackEvent]);

    // const moveCarouselRight = useCallback(() => {
    //   carouselElementRef.current.scrollTo({
    //     top: 0,
    //     left: carouselElementRef.current.scrollLeft + singleCardWidth,
    //     behavior: "smooth",
    //   });
    // }, []);

    // const moveCarouselLeft = useCallback(() => {
    //   carouselElementRef.current.scrollTo({
    //     top: 0,
    //     left: carouselElementRef.current.scrollLeft - singleCardWidth,
    //     behavior: "smooth",
    //   });
    // }, []);

    const roomsRender = useMemo(() => {
      return rooms.map((room, i) => (
        <Slot
          id="lodging-room-card"
          room={room}
          key={`room-${room.roomInfo.roomId}-${i}`}
          onRateSelected={onSelectedRate}
          nights={nights}
          currSelectedRoom={selectedRoomId}
          onSelectedRoomChange={onSelectedRoomChange}
          onRateChange={onRateChange}
          component={
            <RoomCard
              room={room}
              key={`room-${room.roomInfo.roomId}-${i}`}
              onRateSelected={onSelectedRate}
              nights={nights}
              currSelectedRoom={selectedRoomId}
              onSelectedRoomChange={onSelectedRoomChange}
              onRateChange={onRateChange}
            />
          }
        />
      ));
    }, [
      nights,
      onRateChange,
      onSelectedRate,
      onSelectedRoomChange,
      rooms,
      selectedRoomId,
    ]);

    // const carousel = useMemo(() => {
    //   return (
    //     <>
    //       {rooms.length > 1 ? (
    //         <button
    //           className="Rooms-list-arrow left"
    //           onClick={moveCarouselLeft}
    //         >
    //           {/* //TODO fix icon */}
    //           <img src={icons.chevronLeft} alt="" />
    //         </button>
    //       ) : null}
    //       <div className="Rooms-list-scroll" ref={carouselElementRef}>
    //         {roomsRender}
    //       </div>
    //       {rooms.length > 1 ? (
    //         <button
    //           className="Rooms-list-arrow right"
    //           onClick={moveCarouselRight}
    //         >
    //           <img src={icons.chevron} alt="" />
    //         </button>
    //       ) : null}
    //     </>
    //   );
    // }, [
    //   rooms.length,
    //   moveCarouselLeft,
    //   icons.chevronLeft,
    //   icons.chevron,
    //   roomsRender,
    //   moveCarouselRight,
    // ]);

    return (
      <section
        id={SECTION_ID.rooms}
        className={clsx("room-select-container", { mobile: isMobile })}
        ref={ref}
        aria-labelledby={titleId}
      >
        <Slot
          id="lodging-room-select-header"
          titleId={titleId}
          component={
            <Box
              display="flex"
              className="room-select-content"
              justifyContent="space-between"
              alignItems="center"
              mb={isMobile ? 0 : 3}
              mx="auto"
              px={isMobile ? 0 : 4}
            >
              <Typography
                id={titleId}
                className="room-select-title"
                variant="h2"
              >
                {t("roomSelectionTitle")}
              </Typography>
            </Box>
          }
        />
        {enableWallet && bestOffer && rooms.length && !isLloydsClient ? (
          <Slot
            id="hotel-voucher-offer-banner"
            isEligible
            showMore
            minimal
            offer={bestOffer}
            offers={offers}
            onClick={handleOfferBannerTap}
          />
        ) : null}
        <div className={clsx("Rooms-list", { mobile: isMobile })}>
          {isLoadingRooms ? (
            <Skeleton className="room-select-skeleton" variant="rect" />
          ) : rooms.length ? (
            roomsRender
          ) : (
            <UnavailableRoomsBanner />
          )}
        </div>
      </section>
    );
  }
);
