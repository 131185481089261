export enum SelectedStatus {
  SELECTED = "SELECTED",
  DECLINED = "DECLINED",
  UNSELECTED = "UNSELECTED",
}

export type UnselectedFintechOfferSelection = {
  status: SelectedStatus.UNSELECTED;
};

export type DeclinedFintechOfferSelection = {
  status: SelectedStatus.DECLINED;
};

export type SelectedFintechOfferSelection<T> = {
  status: SelectedStatus.SELECTED;
  offer: T;
};

export type AnyFintechOfferSelection<T> =
  | UnselectedFintechOfferSelection
  | DeclinedFintechOfferSelection
  | SelectedFintechOfferSelection<T>;

/**
 * @deprecated Use AnyFintechOfferSelection instead
 */
export type SelectedFintechOfferStatus<T> = AnyFintechOfferSelection<T>;
