import {
  BookedFlightItinerary,
  FlightItinerarySegmentStatus,
  ScheduleChange,
  ScheduleChangeChoice,
} from "@b2bportal/air-booking-api";
import { NonCfarEnum } from "@b2bportal/air-cancel-api";
import {
  TimeGroupingEnum,
  getDepartureSlice,
  getReturnSlice,
} from "@hopper-b2b/types";

// for mapping selected schedule change time preferences to the request model
export const getTimeChoice = (
  time: TimeGroupingEnum,
  scheduleChange: ScheduleChange,
  isReturn = false
): ScheduleChangeChoice => {
  const [outboundSlice, returnSlice] = scheduleChange.next;
  const slice = isReturn ? returnSlice : outboundSlice;
  const segment = slice?.segments[0];
  const timeChoice: ScheduleChangeChoice = {
    departAfter: undefined,
    departBefore: undefined,
  };
  let arr, dep;

  if (segment) {
    const { scheduledArrival, scheduledDeparture } = segment;

    arr = scheduledArrival.split("T")[0];
    dep = scheduledDeparture.split("T")[0];
  }

  switch (time) {
    case TimeGroupingEnum.EARLY_MORNING:
      if (dep) timeChoice.departAfter = `${dep}T00:00:00.000Z`;
      if (arr) timeChoice.departBefore = `${arr}T04:59:00.000Z`;
      break;
    case TimeGroupingEnum.MORNING:
      if (dep) timeChoice.departAfter = `${dep}T05:00:00.000Z`;
      if (arr) timeChoice.departBefore = `${arr}T11:59:00.000Z`;
      break;
    case TimeGroupingEnum.AFTERNOON:
      if (dep) timeChoice.departAfter = `${dep}T12:00:00.000Z`;
      if (arr) timeChoice.departBefore = `${arr}T17:59:00.000Z`;
      break;
    case TimeGroupingEnum.EVENING:
      if (dep) timeChoice.departAfter = `${dep}T18:00:00.000Z`;
      if (arr) timeChoice.departBefore = `${arr}T23:59:00.000Z`;
      break;
  }

  return timeChoice;
};

/**
 * @description Maps self serve NonCfars to more intuitive strings for event
 * tracking in amplitude.
 * @param {NonCfarEnum} nonCfar
 * @return {string}
 */
export const getTrackingNonCfar = (nonCfar: NonCfarEnum): string => {
  switch (nonCfar) {
    case NonCfarEnum.AirlineRefund:
      return "Fully Refundable";
    case NonCfarEnum.ContactAirline:
      return "TRVK";
    case NonCfarEnum.ContactCustomerService:
      return "Unsupported Eligibility";
    case NonCfarEnum.Ftc:
      return "FTC";
    case NonCfarEnum.FtcWithPenalty:
      return "FTC with Penalty";
    case NonCfarEnum.PartialRefund:
      return "Partially Refundable";
    case NonCfarEnum.NonRefundable:
      return "Non Refundable";
    case NonCfarEnum.RefundableComplex:
      return "Refundable Complex";
    case NonCfarEnum.TicketedVoid:
      return "Void-Ticketed";
    case NonCfarEnum.TicketlessVoid:
      return "Void-Ticketless";
    default:
      return nonCfar;
  }
};

export const sliceHasScheduleChange = (
  bookedItinerary: BookedFlightItinerary,
  isOutgoing = false
) => {
  const slice = isOutgoing
    ? getDepartureSlice(bookedItinerary)
    : getReturnSlice(bookedItinerary);

  if (slice) {
    return slice.segments.some(
      (s) =>
        s.status === FlightItinerarySegmentStatus.ConfirmedPendingNewChange ||
        s.status === FlightItinerarySegmentStatus.UnMapped ||
        s.status === FlightItinerarySegmentStatus.UnMappedPersisted
    );
  }

  return false;
};

export const SELF_SERVE_URL_PARAMS = {
  SELF_SERVE_ACTION: "selfServeAction",
};

export const SELF_SERVE_ACTION = {
  CANCEL: "cancel",
} as const;
