import { ContainmentOutcome, chatApi } from "@b2bportal/chat-api";

import { trackEvent } from "../tracking/trackEvent";
import { axiosInstance } from "../AxiosInterceptor";

export async function closeChatbotConversation(
  conversationId: string,
  csatScore?: number,
  csatComment?: string,
  issueResolved = false
) {
  try {
    await chatApi(axiosInstance).apiV0ChatbotClosePut({
      conversationId,
      csatComment,
      csatScore,
      containmentOutcome: issueResolved
        ? ContainmentOutcome.Resolved
        : ContainmentOutcome.Unresolved,
    });
  } catch (err) {
    console.error(err);
    trackEvent({
      eventName: "NETWORK_CALL_FAILED",
      properties: {
        url: window.location.pathname,
      },
    });
  }
}
