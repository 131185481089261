import type { PaymentAuthActionOpaqueValue } from "@b2bportal/card-api";

export enum CartFulfillEventType {
  PREVIOUS = "PREVIOUS",

  CLEAR_CART_FULFILL_ERROR = "CLEAR_CART_FULFILL_ERROR",

  // 3DS Events
  COMPLETE_INTERACTIVE_SUCCESS = "COMPLETE_INTERACTIVE_SUCCESS",
  COMPLETE_INTERACTIVE_ERROR = "COMPLETE_INTERACTIVE_ERROR",
  // User Action Events
  USER_ACTION_SUCCESS = "USER_ACTION_SUCCESS",
  USER_ACTION_ERROR = "USER_ACTION_ERROR",
}

export interface ClearCartFulfillError {
  type: CartFulfillEventType.CLEAR_CART_FULFILL_ERROR;
}

export type CompleteInteractiveSuccess = {
  type: CartFulfillEventType.COMPLETE_INTERACTIVE_SUCCESS;
  data: PaymentAuthActionOpaqueValue;
};

export interface CompleteInteractiveError {
  type: CartFulfillEventType.COMPLETE_INTERACTIVE_ERROR;
}
export interface CartFulfillUserActionSuccess {
  type: CartFulfillEventType.USER_ACTION_SUCCESS;
}
export interface CartFulfillUserActionError {
  type: CartFulfillEventType.USER_ACTION_ERROR;
}

export type CartFulfillEvents =
  | CartFulfillUserActionSuccess
  | CartFulfillUserActionError
  | ClearCartFulfillError
  | CompleteInteractiveSuccess
  | CompleteInteractiveError;
