import { useContext } from "react";

import { ChatContext } from "../components";
import { IChatContext } from "../types";

export const useChatContext = (): Partial<IChatContext> => {
  const ctx = useContext(ChatContext);
  if (!ctx) throw new Error(`must be used within a ChatContextProvider`);
  return ctx;
};
