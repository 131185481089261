import type { FlightError } from "@b2bportal/air-booking-api";
import { Product, type ProductError } from "@b2bportal/purchase-api";
import { useCallback } from "react";
import { useFlightErrorProps } from "./useFlightErrorProps";

export const useProductErrorProps = (
  baseErrorProps,
  clearError: () => void
) => {
  const getFlightErrorProps = useFlightErrorProps(baseErrorProps, clearError);

  return useCallback(
    (error: ProductError) => {
      switch (error.value.type) {
        case Product.Flight:
          return getFlightErrorProps(error.value.value as FlightError);
        case Product.Hotel:
        case Product.AirPriceFreeze:
        case Product.Seats:
        default:
          return undefined;
      }
    },
    [getFlightErrorProps]
  );
};
