import { type IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { trackEvent } from "../tracking/trackEvent";
import { travelerApi as defaultTravelerApi } from "@b2bportal/traveler-api";
import axios from "axios";
import type { DeleteTravelerRequest } from "@b2bportal/traveler-api/lib/api";

export const deleteTraveler = (
  body: DeleteTravelerRequest,
  apiConfig?: IApiConfig
): Promise<object> =>
  new Promise((resolve, reject) => {
    const travelerApi = defaultTravelerApi(axios);
    travelerApi
      .apiV0TravelerDeletePut(body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });
