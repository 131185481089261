import { ChatConversation, ChatMessage } from "@b2bportal/chat-api";
import { createChatbotConversation, sendChatbotMessage } from "../api";

export interface ChatProvider {
  chatTransferSource: string;
  createConversation(): Promise<ChatConversation>;
  loadConversation(conversationId: string): Promise<ChatConversation>;
  closeConversation(conversationId: string): Promise<ChatConversation>;
  sendMessage(conversationId: string, content: string): Promise<ChatMessage>;
}

export const testChatProvider: ChatProvider = {
  chatTransferSource: "Voyager",
  createConversation(): Promise<ChatConversation> {
    return createChatbotConversation();
  },
  loadConversation(conversationId: string): Promise<ChatConversation> {
    throw new Error("Method not implemented.");
  },
  closeConversation(conversationId: string): Promise<ChatConversation> {
    throw new Error("Method not implemented.");
  },
  sendMessage(conversationId: string, content: string): Promise<ChatMessage> {
    return sendChatbotMessage(conversationId, content);
  },
};
