import { useI18nContext } from "@hopper-b2b/i18n";
import { IconButton, type IconButtonProps } from "@material-ui/core";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { ReactComponent as BackIcon } from "./back-icon.svg";

export type BackButtonProps = IconButtonProps;

export const BackButton = ({
  onClick,
  size = "small",
  color = "inherit",
  ...rest
}: BackButtonProps) => {
  const { t } = useI18nContext();

  const navigate = useNavigate();

  const onClickHandler = useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
        return;
      }

      navigate(-1);
    },
    [navigate, onClick]
  );

  return (
    <IconButton
      onClick={onClickHandler}
      size={size}
      color={color}
      aria-label={t("back")}
      {...rest}
    >
      <BackIcon />
    </IconButton>
  );
};
