import {
  LodgingMediaAssetEnum,
  MediaCategory,
  NonResizableImage,
  ResizableImage,
} from "@b2bportal/lodging-api";
import { createSelector } from "@reduxjs/toolkit";
import { groupBy, uniq } from "lodash-es";
import { LodgingAppState } from "../../../../reducer";
import { Amenity } from "@b2bportal/lodging-api/lib/api";
import {
  getLodgingInListIndex,
  getLodgingShopTrackingProperties,
} from "../../../../util/utils";
import { getLodgings } from "../../../availability/reducer";

export const getCheckInInstructions = (state: LodgingAppState) =>
  state.lodgingShop.checkInInstructions;

export const getShopCallState = (state: LodgingAppState) =>
  state.lodgingShop.shopCallState;

export const getSelectedLodging = (state: LodgingAppState) =>
  state.lodgingShop.selectedLodging;

export const getSelectedLodgingId = (state: LodgingAppState) =>
  state.lodgingShop.selectedLodging?.lodging?.id;

export const getSelectedLodgingName = (state: LodgingAppState) =>
  state.lodgingShop.selectedLodging?.lodging?.name;

export const getRooms = (state: LodgingAppState) => state.lodgingShop.rooms;

export const getRoomsCallState = (state: LodgingAppState) =>
  state.lodgingShop.roomsCallState;

export const getSelectedRoom = (state: LodgingAppState) =>
  state.lodgingShop.selectedRoom;

export const getSelectedRoomId = (state: LodgingAppState) =>
  state.lodgingShop.selectedRoom?.roomInfo?.roomId;

export const getSelectedRoomRateId = (state: LodgingAppState) =>
  state.lodgingShop.selectedRoomRateId;

export const getShopTrackingProperties = (state: LodgingAppState) =>
  state.lodgingShop.shopTrackingProperties;

export const getShopPriceChange = (state: LodgingAppState) =>
  state.lodgingShop.lodgingPriceChange;

export const getSelectedRoomRate = createSelector(
  getSelectedRoom,
  getSelectedRoomRateId,
  (selectedRoom, selectedRateId) => {
    return selectedRoom?.products?.find(
      (p) => p.rateId.value === selectedRateId
    );
  }
);

// Start of Hotel Hero Gallery Selectors ==================>
export const getSelectedLodgingMedia = createSelector(
  getSelectedLodging,
  (selectedLodging) => selectedLodging?.lodging?.media
);

export const getSelectedLodgingMediaImages = createSelector(
  getSelectedLodging,
  (selectedLodging): Array<NonResizableImage | ResizableImage> =>
    selectedLodging?.lodging?.media.filter(
      (mediaAsset) =>
        mediaAsset.LodgingMediaAsset !== LodgingMediaAssetEnum.Video
    ) || []
);

export const getSelectedLodgingMediaCategories = createSelector(
  getSelectedLodgingMediaImages,
  (images) => uniq(images.map((i) => i.category))
);

export const getSelectedLodgingGroupedMedia = createSelector(
  getSelectedLodgingMediaImages,
  (images) => {
    // Handle categories that don't map to MediaCategory
    const mappedMedia = images.map((m) =>
      MediaCategory[m?.category]
        ? m
        : { ...m, category: MediaCategory.Uncategorized }
    );
    return groupBy(mappedMedia, (media) => media.category);
  }
);
// End of Hotel Hero Gallery Selectors ==================>

export const getCheckInPolicies = (state: LodgingAppState) =>
  state.lodgingShop.checkInPolicies;

export const getCurrentStep = (state: LodgingAppState) =>
  state.lodgingShop.currentStep;

export const getHasSelectedRoom = createSelector(
  getSelectedLodging,
  getSelectedRoom,
  getSelectedRoomRateId,
  (lodging, room, rate) => {
    return !!lodging && !!room && !!rate;
  }
);

export const getSelectedLodgingCoordinatesForStaticMap = createSelector(
  getSelectedLodging,
  (lodging) => {
    const coordinates = lodging?.lodging?.location?.coordinates;
    if (coordinates) {
      return `${coordinates.lat},${coordinates.lon}`;
    }
    return null;
  }
);

export const isAdultsOnly = createSelector(getSelectedLodging, (lodging) => {
  const amenities = lodging?.lodging?.amenities || [];
  return amenities.some((amenity) => amenity.amenity === Amenity.AdultsOnly);
});

export const getSelectedLodgingAmenities = createSelector(
  getSelectedLodging,
  (lodging) => lodging?.lodging?.amenities
);
export const getSelectedLodgingOfferOpaque = (state: LodgingAppState) =>
  state.lodgingShop.selectedOfferOpaque;

export const getSelectedLodgingInListIndex = createSelector(
  getSelectedLodgingId,
  getLodgings,
  getLodgingInListIndex
);

export const getHotelShopTrackingProperties = createSelector(
  getSelectedLodging,
  getSelectedLodgingInListIndex,
  getLodgingShopTrackingProperties
);

export const getSelectedRoomTrackingProperties = createSelector(
  getSelectedRoom,
  (selectedRoom) => selectedRoom?.trackingPropertiesV2
);

export const getSelectedRoomRateTrackingProperties = createSelector(
  getSelectedRoomRate,
  (selectedRoomRate) => selectedRoomRate?.trackingPropertiesV2
);
