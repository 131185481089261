import { IconNameEnum } from "@b2bportal/core-themes";
import type { FiatPrice } from "@b2bportal/purchase-api";
import { Icon } from "@components/ui";
import { PATH_HOME, PATH_WALLET } from "@hopper-b2b/common-utils";
import { useExperiment } from "@hopper-b2b/experiments";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionLink } from "@hopper-b2b/ui";
import {
  PATH_AVAILABILITY_RELATIVE,
  PATH_BOOK_RELATIVE,
  useDeviceTypes,
  useIsSessionAuthenticated,
  useTenantIcons,
} from "@hopper-b2b/utilities";
import { FEATURE_FLAGS } from "@lloyds/theming";
import { ActionButton } from "@lloyds/ui-core";
import { useWallet } from "@lloyds/wallet";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import styles from "./WalletBanner.module.scss";

export const WalletBanner = () => {
  const isAuthenticated = useIsSessionAuthenticated();
  const isWalletEnabled = useExperiment(FEATURE_FLAGS.WALLET);
  const { matchesMobile } = useDeviceTypes();
  const { pathname } = useLocation();
  const { creditBalance, isLoadingWallet, fetchAndSetWalletSummary } =
    useWallet();

  const [showWalletBanner, setShowWalletBanner] = useState<boolean>(true);

  useEffect(() => {
    isAuthenticated && isWalletEnabled && fetchAndSetWalletSummary();
  }, [fetchAndSetWalletSummary, isAuthenticated, isWalletEnabled]);

  const sanitizePath = (path: string) => path.replaceAll("/", "");

  const showBanner = useMemo(() => {
    const isValidPathname = matchesMobile
      ? pathname === PATH_HOME
      : !pathname.includes(sanitizePath(PATH_BOOK_RELATIVE)) &&
        !pathname.includes(sanitizePath(PATH_AVAILABILITY_RELATIVE)) &&
        !pathname.includes(sanitizePath(PATH_WALLET));
    return (
      isValidPathname &&
      showWalletBanner &&
      creditBalance?.value > 0 &&
      !isLoadingWallet
    );
  }, [
    pathname,
    showWalletBanner,
    creditBalance,
    isLoadingWallet,
    matchesMobile,
  ]);

  return (
    <Box width="100%" bgcolor="var(--green-100)">
      <Container disableGutters maxWidth="xl">
        {showBanner &&
          (matchesMobile ? (
            <Box className={styles.mobileBanner}>
              <HeaderWalletLeftItem creditBalance={creditBalance} />
            </Box>
          ) : (
            <Box className={styles.desktopBanner}>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item>
                  <HeaderWalletLeftItem creditBalance={creditBalance} />
                </Grid>

                <Grid item xs />

                <Grid item>
                  <HeaderCloseItem onClose={() => setShowWalletBanner(false)} />
                </Grid>
              </Grid>
            </Box>
          ))}
      </Container>
    </Box>
  );
};

const HeaderWalletLeftItem = ({
  creditBalance,
}: {
  creditBalance: FiatPrice;
}) => {
  const { matchesMobile } = useDeviceTypes();
  const { formatFiatCurrency, t } = useI18nContext();
  const navigate = useNavigate();

  const absAmount = useMemo(() => {
    if (creditBalance) {
      return {
        ...creditBalance,
        value: Math.abs(creditBalance.value),
      };
    }
  }, [creditBalance]);

  const toWallet = useCallback(() => {
    navigate(PATH_WALLET);
  }, [navigate]);

  return matchesMobile ? (
    <Box
      display="flex"
      flexDirection="row"
      gridGap="12px"
      alignItems="flex-start"
    >
      <Icon iconName={IconNameEnum.credits} className={styles.icon} />
      <Typography
        variant="subtitle1"
        style={{ color: "var(--primary-2)", lineHeight: "18px" }}
      >
        {t("wallet.headerBanner", { amount: formatFiatCurrency(absAmount) })}
      </Typography>
    </Box>
  ) : (
    <Box display="flex" flexDirection="row" gridGap="12px" alignItems="center">
      <Icon iconName={IconNameEnum.credits} className={styles.icon} />
      <Typography variant="subtitle1" style={{ color: "var(--primary-2)" }}>
        {t("wallet.headerBanner", { amount: formatFiatCurrency(absAmount) })}
      </Typography>
      <ActionButton
        message={t("viewDetails")}
        onClick={toWallet}
        defaultStyle="h4r-secondary"
        className={styles.button}
      />
    </Box>
  );
};

const HeaderCloseItem = ({ onClose }: { onClose: () => void }) => {
  const icons = useTenantIcons();

  return (
    <Box width={24} height={24}>
      <ActionLink
        content={<img src={icons.close} alt="" />}
        label="Close"
        onClick={onClose}
      />
    </Box>
  );
};
