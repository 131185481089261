import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { GuestsSelection } from "@hopper-b2b/types";
import {
  B2BButton,
  DesktopPopupModal,
  Icon,
  IconName,
  PassengerCountPicker,
} from "@hopper-b2b/ui";
import { useTenantIcons } from "@hopper-b2b/utilities";
import { Grid, Typography } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useIsRoomsEnabled } from "../../../context";
import styles from "./GuestPicker.module.scss";

export type GuestPickerProps = {
  enableRooms?: boolean;
  initialGuestCount: GuestsSelection;
  icon?: React.ReactElement;
  updateGuestCount: (count: GuestsSelection) => void;
};

export const GuestPicker = ({
  enableRooms: enableRoomsProps,
  initialGuestCount,
  updateGuestCount,
  icon,
}: GuestPickerProps) => {
  const { t } = useI18nContext();

  const enableRoomsFlag = useIsRoomsEnabled();
  const enableRooms = enableRoomsProps ?? enableRoomsFlag;

  const [showGuestCountPicker, setShowGuestCountPicker] =
    useState<boolean>(false);
  const [guestCount, setGuestCount] =
    useState<GuestsSelection>(initialGuestCount);

  const numTravelers = guestCount.adults + guestCount.children.length;

  const handleClosePassengerPicker = useCallback(() => {
    setShowGuestCountPicker(false);
  }, []);

  const handleGuestCountsChange = useCallback(
    (counts: GuestsSelection) => {
      const nextGuestCount = {
        adults: counts.adults,
        children: counts.children,
        rooms: counts.rooms,
      };
      setGuestCount(nextGuestCount);
      updateGuestCount(nextGuestCount);
      handleClosePassengerPicker();
    },
    [handleClosePassengerPicker, updateGuestCount]
  );

  const onClick = useCallback(() => {
    setShowGuestCountPicker(true);
    trackEvent({
      eventName: "hotel_tapped_guests",
      properties: {
        adults_count: guestCount.adults,
        children_count: guestCount.children.length,
      },
    });
  }, [guestCount]);

  const setPassengerCounts = useCallback((counts: GuestsSelection) => {
    setGuestCount(counts);
    trackEvent({
      eventName: "hotel_tapped_apply_guests",
      properties: {
        adults_count: counts.adults,
        children_count: counts.children.length,
      },
    });
  }, []);

  const tenantIcons = useTenantIcons();
  return (
    <>
      <B2BButton variant="traveler-selector" onClick={onClick}>
        <Grid container alignItems="center" spacing={3} wrap="nowrap">
          <Grid item>
            {icon ??
              (tenantIcons.guest ? (
                <img
                  className={styles.tenantIcon}
                  src={tenantIcons.guest}
                  alt=""
                />
              ) : (
                <Icon aria-hidden={true} name={IconName.B2BUser} ariaLabel="" />
              ))}
          </Grid>
          <Grid item>
            <Typography noWrap variant="body2">
              {t("searchFilter.pickerTraveler", {
                count: numTravelers,
              })}
            </Typography>
          </Grid>
        </Grid>
      </B2BButton>
      <DesktopPopupModal
        open={showGuestCountPicker}
        onClose={handleClosePassengerPicker}
        invisibleBackdrop={false}
        contentClassName="guest-count-picker-desktop-modal"
      >
        <PassengerCountPicker
          showRooms={enableRooms}
          minimumCount={1}
          onClickApplyGuest={handleGuestCountsChange}
          setPassengerCounts={setPassengerCounts}
          counts={guestCount}
          onClose={handleClosePassengerPicker}
          titles={{
            modalTitle: t("passengerCount.modalTitle"),
            adultTitle: t("passengerCount.adultTitle"),
            adultSubtitle: t("guestCount.adultSubtitle"),
            childrenTitle: t("passengerCount.childrenTitle"),
            childrenSubtitle: t("guestCount.childrenSubtitle"),
            childrenAgeMessage: t("guestCount.childrenAgeMessage"),
            childrenAgePlaceholderKey: "guestCount.childrenAgePlaceholder",
            roomsTitle: enableRooms
              ? t("passengerCount.roomsTitle") ?? undefined
              : "",
          }}
          captureChildrenAges
        />
      </DesktopPopupModal>
    </>
  );
};
