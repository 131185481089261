import { useI18nContext } from "@hopper-b2b/i18n";
import { formatPhoneNumber } from "@hopper-b2b/utilities";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
  type TextFieldProps,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { B2BNumberTextField } from "../../TextField/B2BTextField";
import { uniquePhoneCodes } from "./countryPhoneCode";
import "./styles.scss";

export interface IPhoneInputFieldProps {
  onChange: (value: string, countryDialCode: string) => void;
  onBlur?: (value: string) => void;
  placeholder?: string;
  label?: string;
  defaultValue?: string | number;
  error?: boolean;
  errorHelper?: string;
  hideErrorHelperWhenEmpty?: boolean;
  type?: string;
  fullWidth?: boolean;
  width?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  countryCode?: string;
  selectProps?: SelectProps;
  inputRef?: TextFieldProps["inputRef"];
  uniquePhoneCodesOptions?: string[];
  className?: string;
  variant?: "filled" | "standard" | "outlined";
}

const renderPhoneSelectMenu = (
  uniquePhoneCodesOptions = uniquePhoneCodes
): ReactNode => {
  return uniquePhoneCodesOptions.map((countryCode) => {
    return (
      <MenuItem key={countryCode} value={`+${countryCode}`}>
        {`+${countryCode}`}
      </MenuItem>
    );
  });
};

const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: 500,
  },
}));

export const PhoneInputField = (props: IPhoneInputFieldProps) => (
  <PhoneInputFieldComponent {...props} />
);

export const PhoneInputFieldComponent = ({
  onChange,
  onBlur,
  errorHelper,
  hideErrorHelperWhenEmpty,
  disabled,
  label,
  defaultValue,
  countryCode,
  selectProps,
  inputRef,
  uniquePhoneCodesOptions,
  className,
  variant,
}: IPhoneInputFieldProps) => {
  const { brand } = useI18nContext();

  const classes = useStyles();
  const [baseNumber, setBaseNumber] = useState(defaultValue?.toString() || "");
  const [countryDialCode, setCountryDialCode] = useState(
    countryCode || brand.preferredAreaCode
  );
  const handleOnBlur = useCallback(
    (value: string) => {
      setBaseNumber(value);
      if (onBlur) onBlur(formatPhoneNumber(value, countryDialCode));
    },
    [onBlur, countryDialCode]
  );

  const handleSelectChange = useCallback(
    (
      event: ChangeEvent<{ name?: string | undefined; value: unknown }>
    ): void => {
      const code = (event?.target?.value as string) || countryDialCode;
      setCountryDialCode(code);
      onChange(baseNumber, code);
    },
    [baseNumber, countryDialCode, onChange]
  );

  const handleInputChange = useCallback(
    (value: string) => {
      const num = value.replace(/\D/g, "");
      setBaseNumber(num);
      onChange(num, countryDialCode);
    },
    [onChange, countryDialCode]
  );

  useEffect(() => {
    if (defaultValue) {
      setBaseNumber(defaultValue?.toString());
    }
  }, [defaultValue]);

  useEffect(() => {
    if (countryCode) {
      setCountryDialCode(countryCode);
    }
  }, [countryCode]);

  return (
    <Box className={clsx("phone-number-input-field-container", className)}>
      <label
        className="phone-number-input-field-outer-label"
        htmlFor="country-code-select"
      >
        {label}
      </label>
      <Box
        className={clsx("phone-number-input-field", { error: !!errorHelper })}
      >
        <FormControl variant="filled" className="country-code-select-container">
          <Select
            className="country-code-select"
            id="country-code-select"
            value={countryDialCode}
            onChange={handleSelectChange}
            disableUnderline
            disabled={disabled}
            MenuProps={{
              classes: { paper: classes.menuPaper },
            }}
            {...selectProps}
          >
            {renderPhoneSelectMenu(uniquePhoneCodesOptions)}
          </Select>
        </FormControl>

        <B2BNumberTextField
          onChange={handleInputChange}
          onBlur={handleOnBlur}
          disabled={disabled}
          label={label}
          defaultValue={
            defaultValue &&
            formatPhoneNumber(defaultValue?.toString(), countryDialCode)
          }
          value={formatPhoneNumber(baseNumber, countryDialCode)}
          type="tel"
          error={!!errorHelper}
          id={"phone-input-field"}
          inputRef={inputRef}
          variant={variant}
        />
      </Box>

      {hideErrorHelperWhenEmpty !== false && !errorHelper ? null : (
        <FormHelperText error>{errorHelper}</FormHelperText>
        // <Box className="error-label-container">
        //   <span className="error-label">{errorHelper}</span>
        // </Box>
      )}
    </Box>
  );
};
