import { TripDetailsV2 } from "@b2bportal/air-shopping-api";
import { flightsShoppingApi } from "@overrides/utilities";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface FetchTripDetailsAction {
  tripId: string;
}

export const fetchTripDetails = createAsyncThunk<
  TripDetailsV2,
  FetchTripDetailsAction
>(
  "flightsShop/fetchTripDetails",
  async (action: FetchTripDetailsAction, thunkAPI) => {
    try {
      const res = await flightsShoppingApi.apiV0TripDetailPost({
        value: action.tripId,
      });
      return res.data;
    } catch (error) {
      // Reject the thunk with an error message
      return thunkAPI.rejectWithValue(error);
    }
  }
);
