import { Dispatch, SetStateAction, useCallback } from "react";
import { Lodging } from "@b2bportal/lodging-api";
import clsx from "clsx";
import { useAvailabilitySearch } from "../../hooks/useAvailabilitySearch";
import { LodgingCard } from "../LodgingCard";
import { HorizontalScrollList } from "./HorizontalScrollList";
import styles from "./styles.module.scss";
import { ICentroid } from "../../reducer";

const MAX_ITEM_WIDTH = 310;

function itemWidth() {
  const vw = window.innerWidth;
  return Math.min(vw - 4 * 16, MAX_ITEM_WIDTH); // 4rem in pixels (assuming 1rem = 16px)
}

export const MobileHorizontalScrollList = ({
  setHoveredId,
  selectedHotelID,
  setMobileCardCentroidInView,
}: {
  setHoveredId: Dispatch<SetStateAction<string>>;
  selectedHotelID: string;
  setMobileCardCentroidInView: Dispatch<SetStateAction<ICentroid | null>>;
}) => {
  const { lodgings } = useAvailabilitySearch();
  const cardWidth = itemWidth();

  const onCardScroll = useCallback(
    (idx: number) => {
      const scrolledLodging = lodgings[idx];
      setHoveredId(scrolledLodging.lodging.id);
      if (
        scrolledLodging?.lodging?.location?.coordinates?.lat &&
        scrolledLodging?.lodging?.location?.coordinates?.lon
      ) {
        setMobileCardCentroidInView({
          lat: scrolledLodging.lodging.location.coordinates.lat,
          lng: scrolledLodging.lodging.location.coordinates.lon,
        });
      }
    },
    [lodgings, setHoveredId, setMobileCardCentroidInView]
  );

  return lodgings?.length > 0 ? (
    <HorizontalScrollList
      columns={lodgings}
      itemWidth={cardWidth}
      selectedId={selectedHotelID}
      getItemId={(lodging: Lodging) => lodging.lodging.id}
      parentDivClassName={clsx(
        styles.AvailabilityMobileHorizontalParent,
        "AvailabilityMobileHorizontalParent"
      )}
      scrollingDivClassName={clsx(
        styles.AvailabilityMobileHorizontalScrollList,
        "AvailabilityMobileHorizontalScrollList"
      )}
      listItemClassName={clsx(
        styles.AvailabilityMobileHorizontalListItem,
        "AvailabilityMobileHorizontalListItem"
      )}
      onItemScroll={onCardScroll}
      renderColumn={(lodging: Lodging, index: number) => {
        return (
          <LodgingCard
            id={lodging.lodging.id}
            lodging={lodging}
            lodgingListIndex={index}
            key={"lodgingCard-" + lodging.lodging.id}
            setOveredId={setHoveredId}
            scss={{ "max-width": MAX_ITEM_WIDTH }}
            className={clsx(
              styles.LodgingHorizontalListCard,
              "Horizontal-List-Lodging-Card-Container"
            )}
            singleImage
            isHorizontalScroll
          />
        );
      }}
    />
  ) : null;
};
