import { v4 as uuidv4 } from "uuid";

export const config = {
  env: import.meta.env.MODE,
  spreedlyKey: import.meta.env.VITE_SPREEDLY_ENVIRONMENT_KEY ?? "",
  adyenKey: import.meta.env.VITE_ADYEN_ENVIRONMENT_KEY ?? "",
  version: import.meta.env.VITE_REACT_APP_VERSION ?? `local-${uuidv4()}`,
  keepaliveUri: import.meta.env.VITE_KEEPALIVE_URI,
  keepaliveTimeout: import.meta.env.VITE_KEEPALIVE_TIMEOUT ?? 300000,
};
