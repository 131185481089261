import { assign } from "xstate";
import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import {
  InitAirFeatureFlagsEvent,
  PartialParentContextWithFeatureFlags,
} from "./types";

export const actions = {
  initAirFeatureFlags: assign(
    (
      context: PartialParentContextWithFeatureFlags,
      event: InitAirFeatureFlagsEvent
    ) => {
      context[ParentState.featureFlags] = { ...event.state };
      return context;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject({
  ...actions,
});
