import { trackEvent } from "@hopper-b2b/api";
import {
  MODAL_ALERT,
  ModalAlertProperties,
  ModalCategoryType,
  ModalScreens,
} from "@hopper-b2b/types";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import clsx from "clsx";
import { useEffect, useMemo } from "react";
import { MobilePopoverCard, Slot } from "../../index";
import "./styles.scss";

export interface IErrorPopup {
  open: boolean;
  title: string;
  subtitle: string | (string | JSX.Element)[];
  primaryButtonText: string;
  secondaryButtonText?: string;
  onClose?: () => void;
  primaryOnClick: () => void;
  secondaryOnClick?: () => void;
  trackingEventProperties?: ModalAlertProperties;
  reverse?: boolean;
  errorType?: string;
  modalType?: string;
  screen?: string;
  fullScreen?: boolean;
  centered?: boolean;
  topRightButton?: JSX.Element;
  topLeftButton?: JSX.Element;
  wideButton?: boolean;
  className?: string;
}

export const ErrorPopup = (props: IErrorPopup) => (
  <Slot
    id="error-popup"
    {...props}
    component={<ErrorPopupInternal {...props} />}
  />
);

const ErrorPopupInternal = ({
  primaryButtonText,
  primaryOnClick,
  secondaryButtonText,
  secondaryOnClick,
  onClose,
  title,
  subtitle,
  open,
  trackingEventProperties,
  reverse,
  errorType,
  modalType,
  screen = ModalScreens.FLIGHTS_CHECKOUT,
  fullScreen,
  centered = false,
  topRightButton,
  topLeftButton,
  wideButton,
  className,
}: IErrorPopup) => {
  const { matchesMobile } = useDeviceTypes();
  const hasPrimaryButton = primaryButtonText && primaryOnClick;
  const hasSecondaryButton = secondaryButtonText && secondaryOnClick;
  const modalEventProperties = useMemo(() => {
    return {
      ...trackingEventProperties,
      primary_button: primaryButtonText,
      secondary_button: hasSecondaryButton ? secondaryButtonText : undefined,
      screen,
      category: ModalCategoryType.TROUBLE,
      modalType,
      error_description: errorType || undefined,
    };
  }, [
    errorType,
    hasSecondaryButton,
    primaryButtonText,
    secondaryButtonText,
    trackingEventProperties,
    screen,
    modalType,
  ]);

  useEffect(() => {
    if (open)
      trackEvent({
        eventName: MODAL_ALERT,
        properties: modalEventProperties,
      });
  }, [modalEventProperties, open]);

  return (
    <MobilePopoverCard
      className={clsx("error-popup", className, {
        mobile: matchesMobile,
        "with-secondary": hasSecondaryButton,
        centered,
      })}
      contentClassName="error-popup-content"
      open={open}
      fullScreen={fullScreen}
      centered={centered}
      onClose={onClose}
      topRightButton={topRightButton}
      topLeftButton={topLeftButton}
    >
      <div className="error-popup-title">{title}</div>
      <div className="error-popup-subtitle">
        {Array.isArray(subtitle) ? (
          <>
            {subtitle.map((individualSubtitle) => (
              <div className="error-popup-subtitle-item">
                {individualSubtitle}
              </div>
            ))}
          </>
        ) : (
          subtitle
        )}
      </div>
      <div
        className={clsx("error-popup-button-container", {
          "reverse-flex-direction": reverse,
        })}
      >
        {hasPrimaryButton && (
          <button
            className={clsx(["error-popup-button", "primary"], {
              "wide-button": wideButton && matchesMobile,
            })}
            onClick={primaryOnClick}
          >
            {primaryButtonText}
          </button>
        )}
        {hasSecondaryButton && (
          <button
            className={clsx(["error-popup-button", "secondary"], {
              "wide-button": wideButton && matchesMobile,
            })}
            onClick={secondaryOnClick}
          >
            {secondaryButtonText}
          </button>
        )}
      </div>
    </MobilePopoverCard>
  );
};
