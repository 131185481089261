import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom-v5-compat";

export const QueryParamType = {
  optionalString: "optionalString",
  requiredString: "requiredString",
  optionalNumber: "optionalNumber",
  requiredNumber: "requiredNumber",
  optionalDate: "optionalDate",
  requiredDate: "requiredDate",
  optionalStringArray: "optionalStringArray",
  requiredStringArray: "requiredStringArray",
  optionalNumberArray: "optionalNumberArray",
  requiredNumberArray: "requiredNumberArray",
} as const;

export type QueryParamType =
  (typeof QueryParamType)[keyof typeof QueryParamType];

export type QueryParamDefinition<T extends QueryParamType = QueryParamType> = {
  type: T;
};

const DATE_FORMAT = "YYYY-MM-DD";

export const QueryParamParsers: Record<
  QueryParamType,
  (val: unknown) => unknown
> = {
  [QueryParamType.requiredString]: (value: string): string => {
    if (value == null) {
      throw new Error("Missing required string parameter");
    }
    return value;
  },

  [QueryParamType.optionalString]: (value: string | null): string | null => {
    return value ?? null;
  },

  [QueryParamType.requiredNumber]: (value: string): number => {
    if (value == null) {
      throw new Error("Missing required number parameter");
    }
    const parsed = Number(value);
    if (isNaN(parsed)) {
      throw new Error("Invalid number format");
    }
    return parsed;
  },

  [QueryParamType.optionalNumber]: (value: string | null): number | null => {
    if (value == null) {
      return null;
    }
    const parsed = Number(value);
    if (isNaN(parsed)) {
      throw new Error("Invalid number format");
    }
    return parsed;
  },

  [QueryParamType.requiredDate]: (value: string): string => {
    if (value == null) {
      throw new Error("Missing required date parameter");
    }
    const parsed = dayjs(value, DATE_FORMAT);
    if (!parsed.isValid()) {
      throw new Error(
        `Invalid date format [${value}]. Expected ${DATE_FORMAT}`
      );
    }
    return parsed.format(DATE_FORMAT);
  },

  [QueryParamType.optionalDate]: (value: string | null): string | null => {
    if (value == null) {
      return null;
    }
    const parsed = dayjs(value, DATE_FORMAT);
    if (!parsed.isValid()) {
      throw new Error(
        `Invalid date format [${value}]. Expected ${DATE_FORMAT}`
      );
    }
    return parsed.format(DATE_FORMAT);
  },

  [QueryParamType.requiredStringArray]: (values: string[]): string[] => {
    if (values.length === 0) {
      throw new Error("Missing required string array parameter");
    }
    return values;
  },

  [QueryParamType.optionalStringArray]: (values: string[]): string[] => {
    return values ?? [];
  },

  [QueryParamType.requiredNumberArray]: (values: string[]): number[] => {
    if (values.length === 0) {
      throw new Error("Missing required number array parameter");
    }

    const parsed = values.map(Number);
    if (parsed.some(isNaN)) {
      throw new Error("Invalid number format in array");
    }

    return parsed;
  },

  [QueryParamType.optionalNumberArray]: (values: string[]): number[] | null => {
    if (values.length === 0) {
      return null;
    }

    const parsed = values.map(Number);
    if (parsed.some(isNaN)) {
      throw new Error("Invalid number format in array");
    }
    return parsed;
  },
};

export type QueryParamValue<T extends QueryParamType> =
  T extends "optionalString"
    ? string | undefined
    : T extends "requiredString"
    ? string
    : T extends "optionalNumber"
    ? number | undefined
    : T extends "requiredNumber"
    ? number
    : T extends "optionalDate"
    ? string | undefined
    : T extends "requiredDate"
    ? string
    : T extends "optionalStringArray"
    ? string[] | undefined
    : T extends "requiredStringArray"
    ? string[]
    : T extends "optionalNumberArray"
    ? number[] | undefined
    : T extends "requiredNumberArray"
    ? number[]
    : never;

export const parseQueryParams = <
  Definitions extends Record<string, QueryParamDefinition>
>(
  searchParams: URLSearchParams,
  definitions: Definitions
): { [K in keyof Definitions]: QueryParamValue<Definitions[K]["type"]> } => {
  return Object.entries(definitions).reduce((acc, [key, definition]) => {
    const { type } = definition;
    const values = searchParams.getAll(key);

    const parser = QueryParamParsers[type];
    if (parser == null) {
      throw new Error(`Unsupported parameter type: ${type}`);
    }

    try {
      const parsed =
        type === QueryParamType.requiredNumberArray ||
        type === QueryParamType.requiredStringArray ||
        type === QueryParamType.optionalNumberArray ||
        type === QueryParamType.optionalStringArray
          ? parser(values)
          : parser(values[0]);
      return { ...acc, [key]: parsed };
    } catch (e) {
      throw new Error(
        `Error parsing the key [${key}] of type [${definition.type}] with value [${values}]. Error: {${e}}`
      );
    }
  }, {} as any);
};

export const createQueryParams = <T extends Record<string, unknown>>(
  params: T
): URLSearchParams => {
  return Object.entries(params).reduce((searchParams, [key, value]) => {
    if (value == null) return searchParams;

    if (Array.isArray(value)) {
      value.forEach((v) => {
        searchParams.append(key, formatValue(value));
      });
      return searchParams;
    }

    if (value != null) {
      searchParams.set(key, formatValue(value));
    }

    return searchParams;
  }, new URLSearchParams());
};

const formatValue = (value: unknown): string => {
  if (value instanceof Date) {
    return dayjs(value).format(DATE_FORMAT);
  }
  return String(value);
};

export const useParseQueryParams = <
  Definitions extends Record<string, QueryParamDefinition>
>(
  definitions: Definitions
): { [K in keyof Definitions]: QueryParamValue<Definitions[K]["type"]> } => {
  const [searchParams] = useSearchParams();
  return parseQueryParams(searchParams, definitions);
};
