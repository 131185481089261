import type { IImageSet } from "@hopper-b2b/utilities";
import BookingConfirmation from "./client/shared/Bunny-Confirmation-Default.png";
import DesaturatedBackground from "./client/lloyds/background-desaturated.jpg";
import DesaturatedBackgroundAVIF from "./client/lloyds/background-desaturated.avif";
import Background from "./client/lloyds/background.jpg";
import type { Brand } from "@b2bportal/lloyds-auth-api";

export { DesaturatedBackgroundAVIF };

export const lightModeImages = (brand: Brand): IImageSet => ({
  flightBookingConfirmationImage: BookingConfirmation,
  homepageBackground: homepageBackground(brand),
  pageBackground: pageBackground(brand),
});

const homepageBackground = (brand: Brand) => {
  const images: { [key in Brand]: string } = {
    Lloyds: DesaturatedBackground,
    Halifax: DesaturatedBackground,
    BankOfScotland: DesaturatedBackground,
    MBNA: DesaturatedBackground,
  };

  return images[brand];
};

const pageBackground = (brand: Brand) => {
  const images: { [key in Brand]: string } = {
    Lloyds: Background,
    Halifax: Background,
    BankOfScotland: Background,
    MBNA: Background,
  };

  return images[brand];
};
