import { useI18nContext } from "@hopper-b2b/i18n";
import { Dialog, Grid } from "@material-ui/core";
import { useCallback, useState } from "react";
import {
  BackButton,
  FlightCalendarPicker,
  HighContrastToggle,
  PassengerPickerInput,
  TripCategoryPicker,
} from "@lloyds/ui-connected";
import { HeaderUI, MobileHeaderTitle } from "@lloyds/ui-connected";
import { ActionButton } from "@lloyds/ui-core";
import {
  type BuildFlightShopQueryArgs,
  FlightSearchHooks,
  useFlightSearch,
  useFlightRecentSearches,
} from "@components/flights";
import { FlightLocationAutoComplete } from "@lloyds/ui-connected";
import { useTenantIcons } from "@hopper-b2b/utilities";
import { FloatingBox } from "@hopper-b2b/ui";
import "./styles.scss";
import {
  suggestionToITripTerminus,
  terminusToSuggestion,
} from "@b2bportal/core-utilities";

export const MobileFlightSearch = ({
  onComplete,
}: {
  onComplete?: () => void;
}) => {
  const { t } = useI18nContext();
  const icons = useTenantIcons();

  const [isCalendarStep, setIsCalendarStep] = useState(false);

  const {
    context: {
      tripCategory,
      passengerCounts,
      origin,
      destination,
      departureDate,
      returnDate,
      validSearchParams,
    },
    handlers: {
      onDepartureDateChange,
      onDestinationChange,
      onOriginChange,
      onPassengerCountChange,
      onReturnDateChange,
      onTripCategoryChange,
      onSearch,
    },
  } = useFlightSearch();

  const { recentSearches, filterByOrigin, filterByDestination } =
    useFlightRecentSearches();

  const onSearchClick = useCallback(() => {
    setIsCalendarStep(true);
  }, []);

  const onCalendarBack = useCallback(() => {
    setIsCalendarStep(false);
  }, []);

  const handleSearch = () => {
    onSearch(validSearchParams);
    onComplete?.();
    setIsCalendarStep(false);
  };

  const onRecentSearchClick = useCallback(
    (value: BuildFlightShopQueryArgs) => {
      onSearch(value);
      onComplete?.();
    },
    [onComplete, onSearch]
  );

  const { departureMonths, priceTags } = FlightSearchHooks.useFetchCalendar({
    origin,
    destination,
    tripCategory,
  });

  return (
    <>
      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Grid item xs={6} sm={3} lg={3}>
          <PassengerPickerInput
            paxCounts={passengerCounts}
            updatePaxCounts={onPassengerCountChange}
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={3}>
          <TripCategoryPicker
            tripCategory={tripCategory}
            setTripCategory={onTripCategoryChange}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        direction="row"
        alignItems="stretch"
        style={{
          marginTop: 6,
        }}
      >
        <Grid item xs={12} lg={3}>
          <FlightLocationAutoComplete
            id="origin"
            label=""
            placeholder={t("searchControl.whereFrom")}
            recentSearches={recentSearches}
            filterRecentSearches={filterByOrigin}
            onRecentSearchClick={onRecentSearchClick}
            onChange={(sug) =>
              sug && onOriginChange(suggestionToITripTerminus(sug))
            }
            defaultValue={origin && terminusToSuggestion(origin)}
            icon={<img src={icons.locationMarker} alt="" />}
            additionalSearchControl={{
              activeControl: "origin",
              destination: origin?.id?.code?.code ?? undefined,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <FlightLocationAutoComplete
            id="destination"
            label=""
            placeholder={t("searchControl.whereTo")}
            recentSearches={recentSearches}
            filterRecentSearches={filterByDestination}
            onRecentSearchClick={onRecentSearchClick}
            onChange={(sug) =>
              sug && onDestinationChange(suggestionToITripTerminus(sug))
            }
            defaultValue={destination && terminusToSuggestion(destination)}
            icon={<img src={icons.airplane} alt="" />}
            additionalSearchControl={{
              activeControl: "destination",
              destination: destination?.id?.code?.code ?? undefined,
            }}
          />
        </Grid>
        {destination && origin && (
          <FloatingBox>
            <ActionButton
              message={t("lodging.search.selectDestinationButton")}
              onClick={onSearchClick}
            />
          </FloatingBox>
        )}
      </Grid>

      <Dialog open={isCalendarStep} fullWidth fullScreen transitionDuration={0}>
        <HeaderUI
          centerItem={<MobileHeaderTitle title={t("calendarTitle")} />}
          leftItem={<BackButton onClick={onCalendarBack} />}
          rightItem={<HighContrastToggle />}
        />
        <FlightCalendarPicker
          tripCategory={tripCategory}
          startDate={departureDate}
          endDate={returnDate}
          priceTags={priceTags}
          months={departureMonths}
          setStartDate={onDepartureDateChange}
          setEndDate={onReturnDateChange}
          onComplete={handleSearch}
        />
      </Dialog>
    </>
  );
};
