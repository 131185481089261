const DEFAULT_DELAY_TIMES = [
  500, 2000, 4000, 6000, 8000, 10000, 20000, 30000, 30000, 30000,
];

export enum PollStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export async function pollRemote<TResp>({
  delayTimes = DEFAULT_DELAY_TIMES,
  poll,
  getPollStatus,
}: {
  delayTimes?: readonly number[];
  poll: (prevResponse: TResp | undefined) => Promise<TResp>;
  getPollStatus: (response: TResp) => PollStatus;
}): Promise<TResp> {
  let prevResponse: TResp | undefined = undefined;
  for (const delay of delayTimes) {
    await timeout(delay);
    const res = await poll(prevResponse);

    const pollStatus = getPollStatus(res);

    switch (pollStatus) {
      case PollStatus.PENDING:
        prevResponse = res;
        continue;
      case PollStatus.SUCCESS:
        return res;
      case PollStatus.FAILURE:
        console.error("Polling failed due to failure", res);

        throw new Error("Polling failed due to failure");
    }
  }

  throw new Error("Polling failed due to timeout");
}

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
