import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import {
  CoreUiComponents,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import defaultStyles from "./Card.module.scss";
import clsx from "clsx";

export interface CardComponentProps extends PlatformComponentProps {}

const COMPONENT_KEY = CoreUiComponents.Card;
export const Card = ({ className, children }: CardComponentProps) => {
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [block] = useModuleBEM(styles, COMPONENT_KEY);

  return <div className={clsx(block, className)}>{children}</div>;
};

export const CardContent = ({ className, children }: CardComponentProps) => {
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <div className={clsx(cn("content-container"), className)}>{children}</div>
  );
};
