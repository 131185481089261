import {
  DateRange,
  GuestsSelection,
  HotelStarRating,
  Suggestion,
  Amenity,
  Lodging,
  LodgingSelectionEnum,
} from "@b2bportal/lodging-api";

// Define a type for search details
export interface SearchDetails {
  dateRange: DateRange;
  guests: GuestsSelection;
  place: Suggestion;
  rooms: number;
  searchType: LodgingSelectionEnum;
}

export interface PriceRange {
  min: number;
  max: number;
}

export interface FilterState {
  priceRange: PriceRange;
  starRating: HotelStarRating[];
  freeCancellation: boolean;
  amenities: Amenity[];
}

export interface FilterOptions {
  priceRange: PriceRange;
  amenities: Amenity[];
}

export type AvailableHotelStarRatingValue = 1 | 2 | 3 | 4 | 5;

export type StarRatingValue = AvailableHotelStarRatingValue | undefined;

export const HotelStarRatingWithoutNotAvailable = (({
  NotAvailable,
  ...rest
}) => rest)(HotelStarRating);

export type AvailableHotelStarRating = Omit<
  typeof HotelStarRating,
  "NotAvailable"
>;

export enum HotelSortOptionEnum {
  Recommended = "recommended",
  StarRating = "starRating",
  UserRating = "userRating",
  Price = "price",
}

export type HotelComparator = (a: Lodging, b: Lodging) => number;
