const MOCK_LOCAL_STORAGE_KEY = "env.MOCK";

export const isMockMode = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const mockParam = urlParams.get("mock");

  const mockLocalStorage = localStorage.getItem(MOCK_LOCAL_STORAGE_KEY);

  const isMock = mockParam ? mockParam === "true" : mockLocalStorage === "true";

  // Keep mocking whole session
  if (isMock) {
    localStorage.setItem(MOCK_LOCAL_STORAGE_KEY, "true");
  } else {
    localStorage.removeItem(MOCK_LOCAL_STORAGE_KEY);
  }

  return isMock;
};
