import {
  IconNameEnum,
  useModuleBEM,
  useUiStyles,
} from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import * as RadixCheckbox from "@radix-ui/react-checkbox";
import defaultStyles from "./Checkbox.module.scss";
import React from "react";
import { Icon } from "@components/ui";

export interface CheckoxComponentProps
  extends RadixCheckbox.CheckboxProps,
    React.RefAttributes<HTMLButtonElement> {}

export const Checkbox = React.forwardRef<
  HTMLButtonElement,
  CheckoxComponentProps
>((props, forwardedRef) => {
  const COMPONENT_KEY = CoreUiComponents.Checkbox;
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <RadixCheckbox.Root className={block} {...props} ref={forwardedRef}>
      <RadixCheckbox.Indicator className={cn("indicator")}>
        <Icon iconName={IconNameEnum.check} className={cn("check")} />
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
  );
});
