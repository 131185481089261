import { useSelector } from "react-redux";
import { getSearchState } from "../../../store";

export const useSearchParameters = () => {
  const searchState = useSelector(getSearchState);

  return searchState;
};

export default useSearchParameters;
