import * as PopoverPrimitive from "@radix-ui/react-popover";
import defaultStyles from "./Popover.module.scss";
import {
  IconNameEnum,
  useModuleBEM,
  useUiStyles,
} from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import React from "react";
import clsx from "clsx";
import { Icon } from "@components/ui";

export interface PopoverContentComponentProps
  extends PopoverPrimitive.PopoverContentProps,
    React.RefAttributes<HTMLDivElement> {
  shopCloseButton?: boolean;
  align?: "start" | "center" | "end";
}

export const PopoverContent = React.forwardRef<
  HTMLDivElement,
  PopoverContentComponentProps
>(
  (
    { children, className, shopCloseButton = true, align = "start", ...props },
    forwardedRef
  ) => {
    const styles = useUiStyles(CoreUiComponents.Popover, defaultStyles);
    const [block, cn] = useModuleBEM(styles, CoreUiComponents.Popover);
    return (
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          className={clsx(block, className)}
          align={align}
          {...props}
          ref={forwardedRef}
        >
          {children}
          {shopCloseButton ? (
            <PopoverPrimitive.Close
              className={cn("close-button")}
              aria-label="Close"
            >
              <Icon iconName={IconNameEnum.close} />
            </PopoverPrimitive.Close>
          ) : null}
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    );
  }
);

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverAnchor = PopoverPrimitive.Anchor;
