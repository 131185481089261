import { EmptySlot, type Slots } from "@hopper-b2b/ui";
import { AdyenSlot } from "@lloyds/common-checkout";
import {
  AssistancePicker,
  CheckoutPaymentForm,
  ContactInfoForm,
  DesktopTravelerFormButtons,
  ErrorBanner,
  FrequentFlyerButton,
  FrequentFlyerModalButtons,
  FrequentFlyerPicker,
  LbgErrorModal,
  LbgModal,
  MobileBottomSection,
  MobileTravelerFormButtons,
  NumberInputField,
  PaymentSelectRow,
  PhoneInputField,
  SelectField,
  TextInputField,
  TravelerSelectRowEmpty,
} from "@lloyds/ui-connected";
import { PaymentWallet } from "@lloyds/wallet";

export const APP_SLOTS: Slots = {
  // Common Slots
  "text-input-field": TextInputField,
  "phone-input-field": PhoneInputField,
  "number-input-field": NumberInputField,
  "mobile-checkout-cta-section": MobileBottomSection,
  "error-banner": ErrorBanner,
  "error-popup": LbgErrorModal,
  "error-modal": LbgErrorModal,

  // Common Contact Information Slots
  "checkout-contact-info-form": ContactInfoForm,

  // Common Traveler Slots
  "traveler-select-row-empty": TravelerSelectRowEmpty,
  "mobile-traveler-edit-form-buttons": MobileTravelerFormButtons,
  "desktop-traveler-edit-form-buttons": DesktopTravelerFormButtons,
  "add-traveler-form-select": SelectField,
  "traveller-form-modal": LbgModal,
  "passenger-required-assistance-select": AssistancePicker,
  "passenger-required-assistance-redress-number": EmptySlot,
  "passenger-required-assistance-traveler-number": EmptySlot,
  "frequent-flyers-add-another-button": FrequentFlyerButton,
  "frequent-flyer-form-modal-bottom-buttons": FrequentFlyerModalButtons,
  "passenger-passport-country-select": SelectField,
  "frequent-flyer-form-modal": LbgModal,
  "passenger-frequent-flyer-select": FrequentFlyerPicker,

  // Common Payment Slots
  "payment-method-radio": PaymentSelectRow,
  "checkout-payment-form": CheckoutPaymentForm,
  "payment-form-modal": LbgModal,
  "3ds-challenge": AdyenSlot,
  "payment-wallet-content": PaymentWallet,
};
