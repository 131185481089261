import type { FiatPrice } from "@b2bportal/purchase-api";
import {
  type FC,
  type PropsWithChildren,
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";
import { fetchWalletSummary } from "../rq";
import type { WalletOffer } from "@b2bportal/hopper-wallet-api";
import { FEATURE_FLAGS } from "@lloyds/theming";
import { useExperiment } from "@hopper-b2b/experiments";

export interface WalletState {
  cashback?: number;
  creditBalance?: FiatPrice;
  isLoadingWallet: boolean;
  offers?: WalletOffer[];
  fetchAndSetWalletSummary: () => void;
}

const initValue: WalletState = {
  cashback: undefined,
  creditBalance: undefined,
  isLoadingWallet: false,
  offers: undefined,
  fetchAndSetWalletSummary: () => ({}),
};

export const WalletContext = createContext<WalletState>(initValue);

export type WalletProviderProps = {
  initState?: WalletState;
};

export const WalletProvider: FC<WalletProviderProps & PropsWithChildren> = ({
  initState = initValue,
  children,
}) => {
  const isWalletEnabled = useExperiment(FEATURE_FLAGS.WALLET);
  const [isLoadingWallet, setIsLoadingWallet] = useState(false);
  const [creditBalance, setCreditBalance] = useState<FiatPrice>(
    initState.creditBalance
  );
  const [cashback, setCashback] = useState<number | undefined>(
    initState.cashback
  );
  const [offers, setOffers] = useState<WalletOffer[]>(initState.offers);

  const fetchAndSetWalletSummary = useCallback(async () => {
    if (isWalletEnabled) {
      setIsLoadingWallet(true);
      const summary = await fetchWalletSummary();
      setCreditBalance(summary.creditBalance);
      setCashback(summary.earnPercentage);
      setIsLoadingWallet(false);
      setOffers(summary.offers);
    }
  }, [isWalletEnabled]);

  const value: WalletState = useMemo(
    () => ({
      cashback,
      creditBalance,
      isLoadingWallet,
      offers,
      fetchAndSetWalletSummary,
    }),
    [creditBalance, isLoadingWallet, offers, cashback, fetchAndSetWalletSummary]
  );

  return <WalletContext.Provider value={value} children={children} />;
};
