import { useMemo } from "react";
import clsx from "clsx";
import { ReactComponent as TripAdvisorIcon } from "../../assets/icons/tripadvisor/tripadvisor-icon.svg";
import { Slot } from "../Slots";
import styles from "./TripAdvisorBadge.module.scss";

import { ReactComponent as TripAdvisorRating00 } from "./assets/tripadvisor-0-0.svg";
import { ReactComponent as TripAdvisorRating05 } from "./assets/tripadvisor-0-5.svg";
import { ReactComponent as TripAdvisorRating10 } from "./assets/tripadvisor-1-0.svg";
import { ReactComponent as TripAdvisorRating15 } from "./assets/tripadvisor-1-5.svg";
import { ReactComponent as TripAdvisorRating20 } from "./assets/tripadvisor-2-0.svg";
import { ReactComponent as TripAdvisorRating25 } from "./assets/tripadvisor-2-5.svg";
import { ReactComponent as TripAdvisorRating30 } from "./assets/tripadvisor-3-0.svg";
import { ReactComponent as TripAdvisorRating35 } from "./assets/tripadvisor-3-5.svg";
import { ReactComponent as TripAdvisorRating40 } from "./assets/tripadvisor-4-0.svg";
import { ReactComponent as TripAdvisorRating45 } from "./assets/tripadvisor-4-5.svg";
import { ReactComponent as TripAdvisorRating50 } from "./assets/tripadvisor-5-0.svg";

export type TripAdvisorBadgeProps = {
  scoreOutOfFive: number;
  withTripAdvisorIcon?: boolean;
  translateToTheLeft?: boolean;
};

export const TripAdvisorBadge = (props: TripAdvisorBadgeProps) => (
  <Slot
    id="tripadvisor-badge"
    component={<TripAdvisorBadgeInternal {...props} />}
    {...props}
  />
);

const TripAdvisorBadgeInternal = ({
  scoreOutOfFive,
  withTripAdvisorIcon = true,
  translateToTheLeft = false,
}: TripAdvisorBadgeProps) => {
  const BadgeImage = useMemo(() => {
    if (!scoreOutOfFive) return TripAdvisorRating00;

    switch (scoreOutOfFive.toFixed(1)) {
      case "0.5":
        return TripAdvisorRating05;
      case "1.0":
        return TripAdvisorRating10;
      case "1.5":
        return TripAdvisorRating15;
      case "2.0":
        return TripAdvisorRating20;
      case "2.5":
        return TripAdvisorRating25;
      case "3.0":
        return TripAdvisorRating30;
      case "3.5":
        return TripAdvisorRating35;
      case "4.0":
        return TripAdvisorRating40;
      case "4.5":
        return TripAdvisorRating45;
      case "5.0":
        return TripAdvisorRating50;
      default:
        return TripAdvisorRating00;
    }
  }, [scoreOutOfFive]);

  return (
    <div
      className={clsx(
        styles.TripAdvisorBadge,
        // The SVG contains inner spacing that cannot be removed
        translateToTheLeft
          ? withTripAdvisorIcon
            ? styles.translateToTheLeftWithIcon
            : styles.translateToTheLeft
          : null
      )}
    >
      {withTripAdvisorIcon ? (
        <TripAdvisorIcon
          className={clsx(styles.TripAdvisorIcon, "trip-advisor-icon")}
        />
      ) : null}
      <BadgeImage
        className={clsx(styles.TripAdvisorScore, "trip-advisor-badge-ratings")}
      />
    </div>
  );
};
