import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { apiConfig } from "@hopper-b2b/utilities";
import {
  lloydsAuthApi,
  type LongLiveSessionRequest,
  type SessionData,
} from "@b2bportal/lloyds-auth-api";

export type StartSessionRequest = {
  token: string;
  initiator?: string;
};

export const startSession = async ({
  token,
  initiator,
}: StartSessionRequest): Promise<SessionData> => {
  const reqBody: LongLiveSessionRequest = {
    shortLiveSessionToken: token,
  };
  try {
    if (initiator) reqBody["initiator"] = initiator;
    const res = await lloydsAuthApi(axiosInstance).apiV0AuthStartSessionPost(
      reqBody
    );
    return res.data.sessionData;
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      apiConfig
    );
    throw e;
  }
};
